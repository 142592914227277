import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {Inputs, ProductFile} from "../../Child/Supplier/Child/style";
import {MAIN_URL, MAIN_URL_IMAGE} from "../../../../assets/scripts/GeneralVariables";
import {Images} from "../../../../assets/scripts/Images";
import {MainContent} from "../style";
import Chart from "react-apexcharts";
import moment from "moment-jalaali";
import {Modal} from "react-bootstrap";
import {
    CommaFormat,
    fixNumber,
    getData,
    remove_item_of_arr_with_id,
    setTitle
} from "../../../../assets/scripts/GeneralFunctions";
import Swal from "sweetalert2";

const commaNumber = require('comma-number')

function ProductPrice(props) {
    const [inputs, setInputs] = useState({
        product_id: null,
        chart_value: 'weekly',
        price_fillter: 'top',
        product_price: null,
        weekly_chart: '',
        monthly_chart: '',
        yearly_chart: '',
        product: {},
        prices: [],
        price_change: '',
        data_load: false
    })
    const [modal, setModal] = useState({show_edit_product: false});
    const [item, setItem] = useState({

        // Spline Area chart
        series: [{
            data: [],
        }],
        options: {
            chart: {
                height: 350,
                fontFamily: 'IranSans',
                type: 'area',
                dropShadow: {
                    // enabledOnSeries : [0],
                    enabled: true,
                    color: ['#D01936'],
                    top: 13,
                    left: 7,
                    blur: 10,
                    opacity: 0.5
                },
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                categories: []
            },
            stroke: {
                width: 4,
                curve: 'smooth'
            },
            colors: ['#D01936'],
        }
    })

    const [is_render, setRender] = useState(false)

    useEffect(async () => {
        setTitle('آذر فولاد امین | قیمت محصول')
        let product_id = props.match.params.product_id;
        setInputs(prevState => ({...prevState, product_id: product_id}))
        let api_result = await getData(MAIN_URL, `product-management/prices/${product_id}`, 'get', {}, true, true);
        if (api_result?.status === 200) {
            setInputs(prevState => ({
                ...prevState, product: api_result?.data?.product,
                prices: api_result?.data?.prices,
                weekly_chart: api_result?.data?.weekly_chart,
                monthly_chart: api_result?.data?.monthly_chart,
                yearly_chart: api_result?.data?.yearly_chart,
                price_change: api_result?.data?.price_change,
            }))

            let obj = item?.series[0]
            let option = item?.options

            obj['data'] = inputs?.chart_value === 'weekly' ? api_result?.data?.weekly_chart?.values
                : inputs?.chart_value === 'monthly' ? api_result?.data?.monthly_chart?.values
                    : api_result?.data?.yearly_chart?.values

            option['xaxis']['categories'] = inputs?.chart_value === 'weekly' ? api_result?.data?.weekly_chart?.labels
                : inputs?.chart_value === 'monthly' ? api_result?.data?.monthly_chart?.labels
                    : api_result?.data?.yearly_chart?.labels

            option['colors'][0] = api_result?.data?.price_change === 'asc' ? '#D01936' : '#59DE97'
            option['chart']['dropShadow']['color'][0] = api_result?.data?.price_change === 'asc' ? '#D01936' : '#00ff00'
            setItem(prevState => ({
                ...prevState,
                series: item?.series,
                options: item?.options
            }))

            setInputs(prevState => ({...prevState, data_load: true}))

        }
    }, [is_render])


    const editProduct = () => {
        setModal(prevState => ({...prevState, show_edit_product: true}))
    }

    const editProductPrice = async (e) => {
        e.preventDefault()

        let api_result = await getData(MAIN_URL, `product-management/prices/${inputs?.product_id}/add-price`, 'post', {price: inputs?.product_price?.replace(/,/g, '')}, true, true);
        if (api_result?.status === 200) {
            window.location.reload()
        }
        setModal(prevState => ({...prevState, show_edit_product: false}))
    }

    const closeModal = () => {
        setModal(prevState => ({...prevState, show_edit_product: false}))
    }

    const setSortedField = (value) => {
        let prices = inputs?.prices
        if (value === 'top') {
            prices.sort(function(a, b) {
                return a?.price - b?.price;
            });
            setInputs(prevState => ({...prevState, prices, price_fillter: 'bottom'}))
        } else {
            prices.sort(function(a, b) {
                return b?.price - a?.price;
            });
            setInputs(prevState => ({...prevState, prices, price_fillter: 'top'}))
        }
    }

    const removePriceItem = (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این مورد را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                let remove_result = await getData(MAIN_URL, `product-management/prices/remove-price/${id}`, 'post', {}, true, true);
                if (remove_result?.status === 200) {
                    swalWithBootstrapButtons.fire(
                        'حذف شد!',
                        'با موفقیت حذف شد',
                        'success'
                    )
                    let arr = inputs?.prices;
                    let new_arr = remove_item_of_arr_with_id(arr, id)
                    setInputs(prevState => ({...prevState, prices: new_arr}))
                    window.location.reload()
                }
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'لغو شد',
                    ' بدون تغییر باقی ماند',
                    'error'
                )
            }
        })
    }

    return (
        <MainContent>
            <div className='row'>
                <div className="col-12 d-flex flex-column flex-md-row align-items-center justify-content-between mb-3">
                    <h6 className='text-red fw-bold'>قیمت محصول</h6>
                    <button className='add-new-btn' onClick={editProduct}>
                        <span className="icon-edit afa-product-edit-icon"/>
                        {/*Todo add and edit price*/}
                        <span>ویرایش قیمت</span>
                    </button>
                </div>
                <div className="col-12 col-md-6 mb-5 d-flex justify-content-between align-items-center">
                    <span><span className="f-14 fw-bold">نام محصول :</span><span
                        className="fw-light">{inputs?.product?.title?.fa}</span></span>
                    <span><span className='fw-bold f-14'>کد محصول :</span><span
                        className="fw-light">{inputs?.product?.code}</span></span>
                </div>
                <div className="col-12 col-md-8 mb-3">
                    <div className="w-100 d-flex align-items-center justify-content-between">
                        <span className='f-16 fw-bold'>نمودار تغییرات قیمت</span>
                        <select className='afa-chart-select-value' name="chart_value" value={inputs?.chart_value}
                                onChange={(e) => {
                                    setInputs(prevState => ({...prevState, chart_value: e.target.value}))
                                    setRender(!is_render)
                                }}>
                            <option value="weekly">هفتگی</option>
                            <option value="monthly">ماهانه</option>
                            <option value="yearly">سالانه</option>
                        </select>
                    </div>
                    {
                        inputs?.data_load &&
                        <Chart
                            options={item.options}
                            series={item.series}
                            // width={600}
                            height={350}
                        />
                    }
                </div>
                <div className="col-12 col-md-4 mb-3">
                    <table className='afa-product-table'>
                        <thead>
                        <tr>
                            <th></th>
                            <th>تاریخ</th>
                            <th>
                                {
                                    inputs?.price_fillter === 'top' ?
                                        <button type="button" onClick={() => setSortedField('top')}>
                                            <span>قیمت (ریال)</span>
                                            <span className="icon-cheveron-down"/>
                                        </button>
                                        :
                                        <button type="button" onClick={() => setSortedField('bottom')}>
                                            <span>قیمت (ریال)</span>
                                            <span className="icon-cheveron-down afa-rotate"/>
                                        </button>
                                }
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {inputs?.prices.map(product => (
                            <tr key={product.id}>
                                <td>
                                    <span className='icon-delete dv-delete-price-table-item' onClick={()=>removePriceItem(product.id)}></span>
                                </td>
                                <td>{moment(product.created_at.split('T')[0]).format('jYYYY/jMM/jDD')}</td>
                                <td>{commaNumber(product.price)}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <Modal centered={true} show={modal?.show_edit_product}
                   onHide={closeModal}>
                <Modal.Body className='p-md-4' dir='rtl'>
                    <form className="row" onSubmit={editProductPrice}>
                        <div className="col-12 mb-4">
                            <p className='text-red fw-bold'>افزودن قیمت محصول</p>
                            <Inputs>
                                <span>قیمت محصول (ریال)</span>
                                <input className={'afa-error-input'}
                                       type="text"
                                       name={'product_price'}
                                       value={inputs.product_price}
                                       onChange={(e) =>
                                           setInputs(prevState => ({
                                               ...prevState, 'product_price': CommaFormat(e.target.value)
                                           }))
                                       }/>
                            </Inputs>
                        </div>
                        <div
                            className='col-12 w-100 d-flex align-items-center'>
                            <button type='submit' className='afa-btn-save-modal'>ذخیره</button>
                            <button type='button' onClick={closeModal}
                                    className='afa-btn-cancel-modal'>انصراف
                            </button>
                        </div>
                    </form>

                </Modal.Body>
            </Modal>
        </MainContent>
    );
}

export default ProductPrice;