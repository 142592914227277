import React from 'react';
import {Inputs} from "../../Child/Supplier/Child/style";

function InputField({label, title, value, setInputs, error}) {
    return (
        <>
            <Inputs className='mb-3'>
                <span>{label}</span>
                <input className={'afa-error-input'}
                       type="text"
                       placeholder={`توضیحات ${label}`}
                       name={title}
                       maxLength={76}
                       value={value}
                       onChange={(e) =>
                           setInputs(prevState => ({
                               ...prevState, [e.target.name]: e.target.value
                           }))
                       }/>
                {
                    value?.length > 75 ?
                        <p className='afa-error-text'>توضیحات نمی تواند بیشتر از 75 کاراکتر باشد</p> : null
                }
                {
                    error[title] && <p className='afa-error-text'>{error[title][0]}</p>
                }
            </Inputs>
        </>
    )
}

export default InputField