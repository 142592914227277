import React, {useEffect, useState} from 'react';
import {ArticleList, MainContent} from "../style";
import {SearchInput} from "../../Child/SupplierMangement/style";
import {Images} from "../../../../assets/scripts/Images";
import {Link} from "react-router-dom";
import {
    getData,
    remove_item_of_arr_with_id, setTitle,
} from "../../../../assets/scripts/GeneralFunctions";
import {MAIN_URL, MAIN_URL_IMAGE} from "../../../../assets/scripts/GeneralVariables";
import Swal from "sweetalert2";
import Skeleton from 'react-loading-skeleton'
import Flags from "../../../utils/Flags";

function Testimonial(props) {
    const [inputs, setInputs] = useState({
        search_value: '', testimonials: [NaN, NaN, NaN, NaN], locale: 'fa'
    })

    const [is_all_items_load, setAllLoad] = useState(true)
    const [data, setObject] = useState({
        'offset': 0,
        'limit': 30,
    })
    useEffect(async () => {
        setTitle('آذر فولاد امین | توصیه نامه ها')
        let api_result = await getData(MAIN_URL, `testimonial-management/testimonials/${inputs?.locale}`, 'get', data, true, false);
        if (api_result?.status === 200) {
            setInputs(prevState => ({
                ...prevState,
                testimonials: api_result?.data?.testimonials,
            }))
        }
    }, []);

    const searchHandler = async (e) => {
        let object = {};
        object = data;
        object['search'] = e.target.value
        setInputs(prevState => ({
            ...prevState,
            search_value: e.target.value,
            testimonials: [NaN, NaN, NaN, NaN],
        }))
        let api_result = await getData(MAIN_URL, `testimonial-management/testimonials/${inputs?.locale}`, 'get', data, true, false);
        if (api_result?.status === 200) {
            setInputs(prevState => ({
                ...prevState,
                testimonials: api_result?.data?.testimonials,
            }))
            setObject(object)
        }
    }

    // lazy load
    const getTableDataLazyLoad = async (obj) => {
        if (parseFloat(obj.target.offsetHeight + obj.target.scrollTop) == parseFloat(obj.target.scrollHeight)) {
            if (inputs?.testimonials?.length % 30 === 0 && is_all_items_load === true) {
                let object = {};
                object = data;
                object['offset'] = inputs?.testimonials?.length
                let moreData = await getData(MAIN_URL, `testimonial-management/testimonials/${inputs?.locale}`, 'get', object, true, true);
                if (moreData) {
                    setObject(obj)
                    setInputs(prevState => ({
                        ...prevState,
                        testimonials: inputs?.testimonials.concat(moreData.data?.testimonials)
                    }))
                    if (moreData.data?.testimonials?.length < 30) {
                        setAllLoad({is_all_items_load: false})
                    }
                }
            }
        }
    }

    const handleLanguage = async (locale) => {
        setInputs(prevState => ({...prevState, testimonials: [NaN, NaN, NaN, NaN]}))
        let api_result = await getData(MAIN_URL, `testimonial-management/testimonials/${locale?.value}`, 'get', data, true, false);
        if (api_result?.status === 200) {
            setInputs(prevState => ({
                ...prevState,
                testimonials: api_result?.data?.testimonials, locale: locale?.value
            }))
        }
    }
    const removeItem = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این مورد را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                let remove_result = await getData(MAIN_URL, `testimonial-management/testimonials/remove/${id}`, 'post', {}, true, true);
                if (remove_result?.status === 200) {
                    swalWithBootstrapButtons.fire(
                        'حذف شد!',
                        'با موفقیت حذف شد',
                        'success'
                    )
                    let arr = inputs?.testimonials;
                    let new_arr = remove_item_of_arr_with_id(arr, id)
                    setInputs(prevState => ({...prevState, testimonials: new_arr}))
                }
            }
        })
    }

    return (
        <MainContent>
            <div className='row'>
                <div className="col-12 d-flex flex-column flex-md-row align-items-center justify-content-between mb-3">
                    <h6 className='text-red fw-bold'>توصیه نامه ها</h6>
                    <div className="d-flex flex-wrap flex-md-nowrap">
                        <SearchInput className='mb-3 w-100'>
                            <span className='icon-Search dv-search-icon'/>
                            <input type="search" value={inputs?.search_value} onChange={searchHandler}
                                   placeholder='جستجو'/>
                        </SearchInput>
                        <Link to={'/new_testimonial'} className='add-new-btn w-100 ms-3'>
                            <span className="icon-add_paper"/>
                            <span>افزودن توصیه نامه جدید</span>
                        </Link>
                        <Flags handleLanguage={handleLanguage}/>
                    </div>
                </div>
                <div className="col-12 mb-3 afa-lazyload" onScroll={getTableDataLazyLoad}>
                    {
                        inputs?.testimonials?.length > 0 ?
                            !inputs?.testimonials[0] ?
                                inputs?.testimonials?.map((row, index) => (
                                    <>
                                        <Skeleton key={index} borderRadius={8} height={55}/>
                                    </>
                                )) :
                                inputs?.testimonials?.map((row, index) => (
                                    <ArticleList className='row align-items-center' key={index}>
                                        <div
                                            className="col-12 col-sm-6 col-lg-2 mb-3 d-flex align-items-center justify-content-around">
                                            <span className="icon-Delete dv-icon-delete pl-2 my-2"
                                                   onClick={() => removeItem(row.id)}></span>
                                            <div className="afa-article-img">
                                                <img
                                                    src={row?.image ? `${MAIN_URL_IMAGE}${row?.image}` : Images?.perseneli.default}
                                                    className='img-fluid' alt="مقاله"/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-9 mb-3">
                                            <div className="d-flex flex-column align-items-stretch">
                                                <span className='mb-3'>نام شخص : <span>{row?.announcer}</span></span>
                                                <p className='afa-text-p'>{row?.testimonial}</p>
                                            </div>
                                        </div>
                                        <div
                                            className="col-12 col-sm-6 col-lg-1 mb-3 d-flex align-items-center justify-content-center">
                                            <Link className={'text link-color'} to={`/manage-testimonial/${row?.id}`}>ویرایش<span
                                                className="icon-cheveron-left"></span></Link>
                                        </div>
                                    </ArticleList>
                                )) :
                            <p className='text-center'>موردی یافت نشد</p>
                    }
                </div>
            </div>
        </MainContent>
    );
}

export default Testimonial;