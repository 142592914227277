import {toast} from "react-toastify";
import axios from "axios"
import Swal from "sweetalert2";
import React from "react";
// const Compress = require('compress.js')
import Compress from "compress.js";
import {MAIN_URL} from "./GeneralVariables";
let authToken = localStorage.getItem("AFAToken");
export const getData = async (base_url, url, type, dataParams = {}, isToken = false, isLoader = true, isHeaderJson = false, default_token = null) => {
    if (isLoader) {
        loader(true)
    }
    let header = {};
    if (isToken && default_token) {
        header = {headers: {Authorization: `Bearer ${default_token}`}};
        if (isHeaderJson) {
            header = {
                headers: {
                    'Access-Control-Allow-Origin': 'http://localhost:3000',
                    'Access-Control-Allow-Credentials': 'true',
                    "Content-Type": "application/json; charset=utf-8",
                    Authorization: `Bearer ${default_token}`,
                    Accept: "*/*",
                    // "Accept-Encoding": "gzip, deflate, br",
                    // "Access-Control-Allow-Origin" : "*/*",
                    // Connection: "keep-alive"
                }
            };
        }
    }
    else if (isToken) {
        header = {headers: {Authorization: `Bearer ${authToken}`}};
        if (isHeaderJson) {
            header = {
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    Authorization: `Bearer ${authToken}`,
                    Accept: "*/*",
                    "Accept-Encoding": "gzip, deflate, br",
                    "Access-Control-Allow-Origin": "*/*",
                    // 'Access-Control-Allow-Origin':'http://localhost:3000',
                    'Access-Control-Allow-Credentials': 'true',
                    Connection: "keep-alive"
                }
            };
        }
    }

    if (type === "post") {
        let formData;
        if (isHeaderJson) {
            formData = dataParams;
        } else {
            formData = new URLSearchParams();
            for (let key in dataParams) {
                formData.append(key, dataParams[key])
            }
        }
        try {
            const {
                data,
                status
            } = isToken ? await axios.post(base_url + url, formData, header) : await axios.post(base_url + url, formData);
            loader();
            if (status === 200) {
                for (let key in data.messages) {
                    toast.success(data.messages[key])
                }
                return {'status': status, 'data': data};
            }

        } catch (e) {
            loader();
            // if (e.message === 'Network Error') {
            //     Swal.fire({
            //         icon: 'error',
            //         title: 'لطفا اتصال اینترنت را بررسی فرمایید',
            //         allowOutsideClick: false,
            //     })
            // } else
            if (e.response?.['status'] === 403) {
                Swal.fire({
                    icon: 'error',
                    title: 'خطای دسترسی',
                    text: 'شما به این بخش دسترسی ندارید',
                })
                // localStorage.clear();
                // window.location.replace('/login');
                // }
            } else if (e.response?.['status'] === 400) {
                return {'status': 400, 'error': e.response?.data['errors']}
            } else if (e.response?.['status'] === 404) {
                window.location.replace('/404');
                // } else if (e.response?.data['status'] > 404 && e.response?.data['status'] < 500) {
                //     for (let key in e.response?.data.messages) {
                //         toast.error(e.response?.data.messages[key])
                //     }
            } else if (e.response?.['status'] === 500 || e.response?.['status'] === 503) {
                Swal.fire({
                    icon: 'error',
                    title: 'خطای سرور',
                    text: 'خطای ناخواسته ای پیش آمده است',
                })
            } else if (e.response?.['status'] === 500) {
                Swal.fire({
                    icon: 'error',
                    title: 'خطای سرور',
                    text: 'خطای ناخواسته ای پیش آمده است',
                })
            } else if (e.response?.['status'] === 401) {
                Swal.fire({
                    icon: 'error',
                    title: 'عدم دسترسی',
                    text: 'احراز هویت نامعتبر',
                })
                window.location.replace('/login');
                localStorage.clear();
            }
        }

    }
    else {
        let formData = "?";
        for (let key in dataParams) {
            formData += formData.slice(-1) === "?" ? `${key}=${dataParams[key]}` : `&${key}=${dataParams[key]}`;
        }

        try {
            const {
                data,
                status
            } = isToken ? await axios.get(base_url + url + formData, header) : await axios.get(base_url + url + formData);
            loader();
            if (status === 200) {
                for (let key in data.messages) {
                    toast.success(data.messages[key])
                }
                return {'status': status, 'data': data};
            } else {
                toast.error("خطای ناخواسته ای پیش آمد");
                return false;
            }
        } catch (e) {

            loader();
            if (e.message === 'Network Error') {
                Swal.fire({
                    icon: 'error',
                    title: 'لطفا اتصال اینترنت را بررسی فرمایید',
                    allowOutsideClick: false,
                })
            } else if (e.response?.['status'] === 403) {
                Swal.fire({
                    icon: 'error',
                    title: 'خطای دسترسی',
                    text: 'شما به این بخش دسترسی ندارید',
                })
                // localStorage.clear();
                // window.location.replace('/login');
                // }
            } else if (e.response?.['status'] === 400) {
                return {'status': 400, 'error': e.response?.data['errors']}
            } else if (e.response?.['status'] === 404) {
                window.location.replace('/404');
                // } else if (e.response?.data['status'] > 404 && e.response?.data['status'] < 500) {
                //     for (let key in e.response?.data.messages) {
                //         toast.error(e.response?.data.messages[key])
                //     }
            } else if (e.response?.['status'] === 500 || e.response?.['status'] === 503) {
                Swal.fire({
                    icon: 'error',
                    title: 'خطای سرور',
                    text: 'خطای ناخواسته ای پیش آمده است',
                })
            } else if (e.response?.['status'] === 500) {
                Swal.fire({
                    icon: 'error',
                    title: 'خطای سرور',
                    text: 'خطای ناخواسته ای پیش آمده است',
                })
            } else if (e.response?.['status'] === 401) {
                Swal.fire({
                    icon: 'error',
                    title: 'عدم دسترسی',
                    text: 'احراز هویت نامعتبر',
                })
                window.location.replace('/login');
                localStorage.clear();
            }
        }

    }
};

export const getFormDataPost = async (url, formData) => {
    let token = localStorage.getItem('AFAToken')
    let header = {
        headers: {
            "Content-Type": "multipart/form-data",
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': 'true',
            Authorization: `Bearer ${token}`
        }
    };
    loader(true)
    try {
        const {data, status} = await axios.post(MAIN_URL + url, formData, header);
        loader();
        return {'status': status, 'data': data};

    } catch (e) {
        loader();
        if (e.response?.['status'] === 403) {
            Swal.fire({
                icon: 'error',
                title: 'خطای دسترسی',
                text: 'شما به این بخش دسترسی ندارید',
            })
            // localStorage.clear();
            // window.location.replace('/login');
        }
        else if (e.response?.['status'] === 400) {
            return {'status': e.response?.['status'], 'data': e.response?.data['errors']}
        }
        else if (e.response?.['status'] === 404) {
            window.location.replace('/404');
        }
        else if (e.response?.['status'] === 500 || e.response?.['status'] === 503) {
            Swal.fire({
                icon: 'error',
                title: 'خطای سرور',
                text: 'خطای ناخواسته ای پیش آمده است',
            })
        }
        else if (e.response?.['status'] === 500) {
            Swal.fire({
                icon: 'error',
                title: 'خطای سرور',
                text: 'خطای ناخواسته ای پیش آمده است',
            })
        }
        else if (e.response?.['status'] === 401) {
            Swal.fire({
                icon: 'error',
                title: 'عدم دسترسی',
                text: 'احراز هویت نامعتبر',
            })
            window.location.replace('/login');
            localStorage.clear();
        }
    }
}

export const loader = (state = false) => {

    try {
        state ? document.getElementById('loader').classList.remove('hidden')
            : document.getElementById('loader').classList.add('hidden');
    } catch (e) {

    }

};

export const file_loader = (state = false) => {

    try {
        state ? document.getElementById('file_loader').classList.remove('hidden')
            : document.getElementById('file_loader').classList.add('hidden');
    } catch (e) {

    }

};

export const setTitle = (title) => {
    document.title = title
};

function updateTextView(_obj){
    let num = getNumber(_obj);
    if(num==0){
        _obj = '';
    }else{
        _obj = num.toLocaleString()
    }
    return _obj
}
function getNumber(_str){
    let arr = _str?.split('');
    let out = new Array();
    for(let cnt=0;cnt<arr?.length;cnt++){
        if(isNaN(arr[cnt]) === false){
            out?.push(arr[cnt]);
        }
    }
    return Number(out?.join(''));
}
export const CommaFormat = (str) => {
    // let
    //     persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g],
    //     arabicNumbers = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g];
    // if (typeof str === 'string') {
    //     for (let i = 0; i < 10; i++) {
    //         str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
    //     }
    // }
    return updateTextView(str);
};
export const fixNumber = (str) => {
    let
        persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g],
        arabicNumbers = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g];
    if (typeof str === 'string') {
        for (let i = 0; i < 10; i++) {
            str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
        }
    }
    return str;
};

export const priceFormat = (price) => {
    let formattedPrice = "";
    price = price + "";
    for (let i = price.length; i >= 0; i--) {
        if (i !== price.length && i !== 0 && i % 3 === 0)
            formattedPrice += ",";
        formattedPrice += price.charAt(price.length - i);
    }
    return formattedPrice;
};


// Mobile Number regex
export const mobileRegex = (mobile) => {
    let numRegex = /^(\+98|0)9\d{9}$/;
    if (mobile === "" || !numRegex.test(mobile)) {
        Swal.fire({
            position: 'center',
            icon: 'error',
            title: "شماره موبایل نامعتبر است",
            showConfirmButton: false,
            timer: 1500
        })
        return false;
    }
}
// Home phone prev Number regex
export const homePrevPhoneRegex = (prevCode) => {
    let numRegex = /^(0)\d{1,3}$/;
    if (prevCode === "" || !numRegex.test(prevCode)) {
        Swal.fire({
            position: 'center',
            icon: 'error',
            title: "کد شهرستان نامعتبر است",
            showConfirmButton: false,
            timer: 1500
        })
        return false;
    }
}
// Home phone Number regex
export const homePhoneRegex = (phone) => {
    let numRegex = /^[1-9][0-9]{3,7}$/;
    if (phone === "" || !numRegex.test(phone)) {
        Swal.fire({
            position: 'center',
            icon: 'error',
            title: "شماره ثابت منزل نامعتبر است",
            showConfirmButton: false,
            timer: 1500
        })
        // toast.error("Invalid mobile number");
        return false;
    }
}
// Email Address regex
export const emailRegex = (email) => {
    let emailRgx = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (email === "" || !emailRgx.test(email)) {
        Swal.fire({
            position: 'center',
            icon: 'error',
            title: "آدرس ایمیل نامعتبر است",
            showConfirmButton: false,
            timer: 1500
        })
        // toast.error("Invalid email address");
        return false;
    }
}

export const editedItems = (lastItems, newItem) => {
    return lastItems.map(item => {
        if (item.name === newItem) {
            return {...newItem}
        } else {
            return {...item}
        }
    });
};

export const edit_item_with_id = (lastItems, editItem) => {
    return lastItems.map(item => {
        if (item?.id === editItem?.id) {
            return {...editItem}
        } else {
            return {...item}
        }
    });
};

export const remove_item_with_object = (arr, item) => {
    let new_array = [];
    arr.map((itm) => {
        if (item !== itm) {
            new_array.push(itm)
        }
    })
    return new_array
}

export const remove_item_of_arr_with_id = (arr, id) => {
    let new_array = [];
    arr.map((itm) => {
        if (id !== itm.id) {
            new_array.push(itm)
        }
    })
    return new_array
}

export const remove_item_of_arr_with_slug = (arr, slug) => {
    let new_array = [];
    arr.map((itm) => {
        if (slug !== itm.slug) {
            new_array.push(itm)
        }
    })
    return new_array
}

export const useToggle = (initialValue) => {
    const [value, setValue] = React.useState(initialValue);
    const toggle = React.useCallback(() => {
        setValue(v => !v);
    }, []);
    return [value, toggle];
}

export const fileUpload = async (e) => {
    if(e){
        let file = e;

        const compress = new Compress();
        const resizedImage = await compress.compress([file], {
            size: 5, // the max size in MB, defaults to 5MB
            quality: 1, // the quality of the image, max is 1,
            maxWidth: 1080, // the max width of the output image, defaults to 1920px
            maxHeight: 768, // the max height of the output image, defaults to 1920px
            resize: true // defaults to true, set false if you do not want to resize the image width and height
        })
        const img = resizedImage[0];
        const base64str = img.data
        const imgExt = img.ext
        const resizedFile = Compress.convertBase64ToFile(base64str, imgExt)

        let file_name = new File([resizedFile], file?.name, {lastModified: file?.lastModified, type: file?.type});
        return file_name
    }
}

// remove Swal
// const swalWithBootstrapButtons = Swal.mixin({
//     customClass: {
//         confirmButton: 'btn btn-success',
//         cancelButton: 'btn btn-danger'
//     },
//     buttonsStyling: false
// })
// swalWithBootstrapButtons.fire({
//     title: 'آیا مطمئن هستید؟',
//     text: "میخواهید این مورد را حذف کنید",
//     icon: 'warning',
//     showCancelButton: true,
//     confirmButtonText: 'بله حذف کن',
//     cancelButtonText: 'نه حذف نشود',
//     reverseButtons: true
// }).then(async (result) => {
//     if (result.isConfirmed) {
//         let remove_result = await getData(MAIN_URL, `account/portfolio/remove/${id}`, 'get', {}, token, false);
//         if (remove_result?.status === 200) {
//             swalWithBootstrapButtons.fire(
//                 'حذف شد!',
//                 'با موفقیت حذف شد',
//                 'success'
//             )
//             setRender(!is_render)
//         }
//     } else if (
//         /* Read more about handling dismissals below */
//         result.dismiss === Swal.DismissReason.cancel
//     ) {
//         swalWithBootstrapButtons.fire(
//             'لغو شد',
//             'بدون تغییر باقی ماند',
//             'error'
//         )
//     }
// })


// success Swal
// Swal.fire({
//     position: 'top-end',
//     icon: 'success',
//     title: 'Your work has been saved',
//     showConfirmButton: false,
//     timer: 1500
// })

export const checkCodeMelli = (code) => {

    let code_length = code?.length;

    if (code_length < 8 || parseInt(code, 10) === 0) return false;
    code = ('0000' + code).substr(code_length + 4 - 10);
    if (parseInt(code.substr(3, 6), 10) === 0) return false;
    let c = parseInt(code.substr(9, 1), 10);
    let s = 0;
    for (let i = 0; i < 9; i++)
        s += parseInt(code.substr(i, 1), 10) * (10 - i);
    s = s % 11;
    return (s < 2 && c === s) || (s >= 2 && c === (11 - s));
    return true;
}

export const checkShMelli = (code) => {

    let L = code.length;

    if (L < 11 || parseInt(code, 10) == 0) return false;

    if (parseInt(code.substr(3, 6), 10) == 0) return false;
    let c = parseInt(code.substr(10, 1), 10);
    let d = parseInt(code.substr(9, 1), 10) + 2;
    let z = new Array(29, 27, 23, 19, 17);
    let s = 0;
    for (let i = 0; i < 10; i++)
        s += (d + parseInt(code.substr(i, 1), 10)) * z[i % 5];
    s = s % 11;
    if (s == 10) s = 0;
    return (c == s);

}

export const just_persian = (str) => {
    let persian_rgx = /^$|^([\u0600-\u0659\s]|[\u0670-\u06EF\s]|[\u06FA-\u06FF\s])+$/;

    let banned_numbers = /^([A-Za-z]|[0-9])+$/
    if (!persian_rgx.test(str) || banned_numbers.test(str)) {
        let x = str;
        return false
    }
    return true
}

export const just_number = (str) => {
    let num_regex = /^([[0-9]|[۰-۹])*$/
    if (!num_regex.test(str)) {
        return false
    }else{
        return true
    }
}