import React, {useEffect, useState} from 'react';
import {ArticleList, MainContent} from "../../style";
import {Images} from "../../../../../assets/scripts/Images";
import {Link} from "react-router-dom";
import {
    getData,
    remove_item_of_arr_with_id, setTitle,
} from "../../../../../assets/scripts/GeneralFunctions";
import {MAIN_URL, MAIN_URL_IMAGE} from "../../../../../assets/scripts/GeneralVariables";
import Swal from "sweetalert2";
import Skeleton from 'react-loading-skeleton'

function BoardDirectors() {
    const [inputs, setInputs] = useState({
        search_value: '', directorates: [NaN, NaN, NaN, NaN], locale: 'fa'
    })
    useEffect(async () => {
        setTitle('آذر فولاد امین | اعضای هیئت مدیره')
        let api_result = await getData(MAIN_URL, `directorate-management/list`, 'get', {}, true, false);
        if (api_result?.status === 200) {
            setInputs(prevState => ({
                ...prevState,
                directorates: api_result?.data?.directorates,
            }))
        }
    }, []);


    const removeItem = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این مورد را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                let remove_result = await getData(MAIN_URL, `directorate-management/remove/${id}`, 'post', {}, true, true);
                if (remove_result?.status === 200) {
                    swalWithBootstrapButtons.fire(
                        'حذف شد!',
                        'با موفقیت حذف شد',
                        'success'
                    )
                    let arr = inputs?.directorates;
                    let new_arr = remove_item_of_arr_with_id(arr, id)
                    setInputs(prevState => ({...prevState, directorates: new_arr}))
                }
            }
        })
    }

    return (
        <MainContent>
            <div className='row'>
                <div className="col-12 d-flex flex-column flex-md-row align-items-center justify-content-between mb-3">
                    <h6 className='text-red fw-bold'>اعضای هیئت مدیره</h6>
                    <div className="d-flex flex-wrap flex-md-nowrap">
                        <Link to={'/manage-directorate/new'} className='add-new-btn w-100'>
                            <span className="icon-add_paper"/>
                            <span>افزودن عضو جدید</span>
                        </Link>
                    </div>
                </div>
                <div className="col-12 mb-3">
                    {
                        inputs?.directorates?.length > 0 ?
                            !inputs?.directorates[0] ?
                                inputs?.directorates?.map((row, index) => (
                                    <>
                                        <Skeleton key={index} borderRadius={8} height={100}/>
                                    </>
                                )) :
                                inputs?.directorates?.map((row, index) => (
                                    <ArticleList key={row.id} className='row align-items-center'>
                                        <div
                                            className="col-12 col-sm-6 col-lg-2 mb-3 d-flex align-items-center justify-content-around">
                                            <span className="icon-Delete dv-icon-delete pl-2 my-2"
                                                  onClick={() => removeItem(row.id)}></span>
                                            <div className="afa-article-img">
                                                <img
                                                    src={row?.image ? `${MAIN_URL_IMAGE}${row?.image}` : Images?.perseneli.default}
                                                    className='img-fluid' alt="مقاله"/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-9 mb-3">
                                            <div className="d-flex">
                                                <span className='mb-3'>نام و نام خانوادگی : <span>{row?.first_name?.['fa']} {row?.last_name?.['fa']}</span></span>
                                            </div>
                                            <div className="d-flex">
                                                <span className='mb-3'>سمت : <span>{row?.position?.['fa']}</span></span>
                                            </div>
                                        </div>
                                        <div
                                            className="col-12 col-sm-6 col-lg-1 mb-3 d-flex align-items-center justify-content-center">
                                            <Link className={'text link-color'} to={`/manage-directorate/${row.id}`}>مشاهده<span
                                                className="icon-cheveron-left"></span></Link>
                                        </div>
                                    </ArticleList>
                                )) :
                            <p className='text-center'>موردی یافت نشد</p>
                    }
                </div>
            </div>
        </MainContent>
    );
}

export default BoardDirectors;