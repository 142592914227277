import React, {useEffect, useState} from 'react';
import {MainContent} from "./style";
import {Modal} from "react-bootstrap";
import Swal from "sweetalert2";
import {
    edit_item_with_id,
    getData, getFormDataPost,
    remove_item_of_arr_with_id, setTitle
} from "../../../assets/scripts/GeneralFunctions";
import {MAIN_URL} from "../../../assets/scripts/GeneralVariables";
import {Inputs} from "../Child/Supplier/Child/style";
import {toast} from "react-toastify";
import Skeleton from 'react-loading-skeleton'

function Laboratory(props) {
    const [inputs, setInputs] = useState({
        title_en: '',
        title_fa: '',
        title_ar: '',
        title_tr: '',
        products: [NaN, NaN, NaN, NaN],
        clicked_tests: [],
        add_new_step: false,
        add_new_exam_modal_show: false,
        edit_year: '',
        exam_title_en: '',
        exam_title_fa: '',
        exam_title_ar: '',
        exam_title_tr: '',
        reference: '',
        product_id: ''
    })
    const [error, setError] = useState({})

    useEffect(async () => {
        setTitle('آذر فولاد امین | آزمایشگاه ها')
        let api_result = await getData(MAIN_URL, "laboratory-management/products", 'get', {}, true, false);
        if (api_result?.status === 200) {
            setInputs(prevState => ({
                ...prevState,
                products: api_result?.data?.products,
            }))
        }
    }, []);


    const removeLaboratory = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این مورد را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        })
            .then(async (result) => {
                if (result.isConfirmed) {
                    let remove_result = await getData(MAIN_URL, `laboratory-management/products/remove/${id}`, 'post', {}, true, true);
                    if (remove_result?.status === 200) {
                        swalWithBootstrapButtons.fire(
                            'حذف شد!',
                            'با موفقیت حذف شد',
                            'success'
                        )
                        let arr = inputs?.products;
                        let new_arr = remove_item_of_arr_with_id(arr, id)
                        setInputs(prevState => ({...prevState, products: new_arr}))
                    }
                }
            })
    }

    const removeExam = async (id, test) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این مورد را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        })
            .then(async (result) => {
                if (result.isConfirmed) {
                    let remove_result = await getData(MAIN_URL, `laboratory-management/tests/remove/${id}`, 'post', {}, true, true);
                    if (remove_result?.status === 200) {
                        swalWithBootstrapButtons.fire(
                            'حذف شد!',
                            'با موفقیت حذف شد',
                            'success'
                        )

                        let product_index = inputs?.products?.findIndex(elem => elem.id === test?.laboratory_product_id)

                        let product = inputs?.products[product_index]

                        let new_arr = remove_item_of_arr_with_id(product['tests'], id)

                        product['tests'] = new_arr

                        let products = edit_item_with_id(inputs.products, product)

                        setInputs(prevState => ({...prevState, products}))
                    }
                }
            })
    }

    const addNewLaboratory = () => {
        setInputs(prevState => ({...prevState, add_new_step: true}))
    }

    const saveLabName = async (e) => {
        e.preventDefault()

        let form_data = new FormData()
        form_data.append('title[fa]', inputs?.title_fa)
        form_data.append('title[en]', inputs?.title_en)
        form_data.append('title[ar]', inputs?.title_ar)
        form_data.append('title[tr]', inputs?.title_tr)

        let api_result = await getFormDataPost(`laboratory-management/products/create`, form_data)
        if (api_result?.status === 200) {
            toast.success('با موفقیت افزوده شد')
            setError('')
            setInputs(prevState => ({
                ...prevState,
                products: [...inputs.products, api_result?.data?.product],
                add_new_step: false,
                title_en: '',
                title_fa: '',
                title_ar: '',
                title_tr: '',
            }))
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }

    const addExam = (product_id, tests) => {
        setInputs(prevState => ({...prevState, add_new_exam_modal_show: true, product_id, clicked_tests: tests}))
    }

    const saveNewExam = async (e) => {
        e.preventDefault()

        let form_data = new FormData()
        form_data.append('title[fa]', inputs?.exam_title_fa)
        form_data.append('title[en]', inputs?.exam_title_en)
        form_data.append('title[ar]', inputs?.exam_title_ar)
        form_data.append('title[tr]', inputs?.exam_title_tr)
        form_data.append('reference', inputs?.reference)
        form_data.append('edit', inputs?.edit_year)

        let api_result = await getFormDataPost(`laboratory-management/tests/${inputs?.product_id}/add-test`, form_data)
        if (api_result?.status === 200) {
            let arr = []
            if (inputs['clicked_tests']?.length > 0) {
                arr = inputs[`clicked_tests`]
            }
            arr.push(api_result?.data?.test)

            let product_index = inputs?.products?.findIndex(elem => elem.id === inputs?.product_id)

            let product = inputs?.products[product_index]

            product['tests'] = arr

            toast.success('با موفقیت افزوده شد')
            setError('')

            setInputs(prevState => ({
                ...prevState,
                // products: arr,
                reference: '',
                edit_year: '',
                exam_title_fa: '',
                exam_title_en: '',
                exam_title_ar: '',
                exam_title_tr: '',
                add_new_exam_modal_show: false,
            }))
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }

    const closeModal = () => {
        setInputs(prevState => ({...prevState, add_new_exam_modal_show: false}))
    }
    return (
        <MainContent>
            <div className='row'>
                <div className="col-12 col-sm-6 mb-3"><h6 className='text-red fw-bold'>آزمایشگاه ها</h6></div>
                <div
                    className="col-12 col-sm-6 mb-3 d-flex flex-column flex-sm-row align-items-center justify-content-end">
                    <button className='add-new-btn w-100' onClick={addNewLaboratory}>
                        <span className="icon-add_paper"/>
                        <span>افزودن جدید</span>
                    </button>
                </div>
                {
                    inputs?.add_new_step &&
                    <form className="col-12 mb-3" onSubmit={saveLabName}>
                        <div className="row">
                            <div className="col-12 col-md-6 mb-3">
                                <Inputs>
                                    <span>نام محصول</span>
                                    <input
                                        className={'afa-error-input'}
                                        type="text" name={`title_fa`}
                                        value={inputs?.[`title_fa`]}
                                        onChange={(e) =>
                                            setInputs(prevState => ({
                                                ...prevState,
                                                [`title_fa`]: e.target.value
                                            }))
                                        }/>
                                    <p className='afa-error-text'>{error[`title.fa`]}</p>
                                </Inputs>
                            </div>
                            <div className="col-12 col-md-6 mb-3">
                                <Inputs>
                                    <span>نام محصول به انگلیسی</span>
                                    <input
                                        type={'text'}
                                        className={'afa-error-input'}
                                        name={`title_en`}
                                        dir={'ltr'}
                                        value={inputs?.[`title_en`]}
                                        onChange={(e) =>
                                            setInputs(prevState => ({
                                                ...prevState,
                                                [`title_en`]: e.target.value
                                            }))
                                        }/>
                                    <p className='afa-error-text'>{error[`title.en`]}</p>
                                </Inputs>
                            </div>
                            <div className="col-12 col-md-6 mb-3">
                                <Inputs>
                                    <span>نام محصول به ترکی</span>
                                    <input
                                        type={'text'}
                                        className={'afa-error-input'}
                                        name={`title_tr`}
                                        dir={'ltr'}
                                        value={inputs?.[`title_tr`]}
                                        onChange={(e) =>
                                            setInputs(prevState => ({
                                                ...prevState,
                                                [`title_tr`]: e.target.value
                                            }))
                                        }/>
                                    <p className='afa-error-text'>{error[`title.tr`]}</p>
                                </Inputs>
                            </div>
                            <div className="col-12 col-md-6 mb-3">
                                <Inputs>
                                    <span>نام محصول به عربی</span>
                                    <input
                                        className={'afa-error-input'}
                                        type="text" name={`title_ar`}
                                        value={inputs?.[`title_ar`]}
                                        onChange={(e) =>
                                            setInputs(prevState => ({
                                                ...prevState,
                                                [`title_ar`]: e.target.value
                                            }))
                                        }/>
                                    <p className='afa-error-text'>{error[`title.ar`]}</p>
                                </Inputs>
                            </div>

                            <div className="col-12 d-flex justify-content-center">
                                <button type='submit' className='dv-save-lab-btn'>ذخیره نام محصول</button>
                            </div>
                        </div>
                    </form>
                }

                <div className="col-12 mb-3 afa-lazyload">
                    <table id={'afa_user_table'}>
                        <thead>
                        <tr>
                            <td>نام محصول</td>
                            <td>جزئیات</td>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            inputs?.products?.length > 0 ?
                                !inputs?.products[0] ?
                                    inputs?.products?.map((row, index) => (
                                        <tr key={index}>
                                            <td><Skeleton borderRadius={8} height={35}/></td>
                                            <td><Skeleton borderRadius={8} height={35}/></td>
                                            <td><Skeleton borderRadius={8} height={35}/></td>
                                            <td><Skeleton borderRadius={8} height={35}/></td>
                                        </tr>
                                    )) :
                                    inputs?.products?.map((row, index) => (
                                        <tr key={index}>
                                            {/*lab fa title*/}
                                            <td className={'d-flex align-items-center dv-laboratory-title'}>
                                                <span className='icon-delete dv-delete-price-table-item pl-2 pb-1' onClick={() => removeLaboratory(row?.id)}/>
                                                <div className="d-flex flex-column">
                                                    <p className="afa-tbody-text mb-2"> <b>فارسی :</b> {row?.title?.fa}</p>
                                                    <p className="afa-tbody-text"> <b>انگلیسی :</b> {row?.title?.en}</p>
                                                    <p className="afa-tbody-text"> <b>ترکی :</b> {row?.title?.tr}</p>
                                                    <p className="afa-tbody-text"> <b>عربی :</b> {row?.title?.ar}</p>
                                                </div>
                                            </td>
                                            {/*lab details*/}
                                            <td>
                                                <table className='w-100'>
                                                    <thead>
                                                    <tr>
                                                        <th className='dv-exam-titles'>نام آزمون</th>
                                                        <th className='dv-exam-titles'>مرجع</th>
                                                        <th className='dv-exam-titles'>ویرایش (میلادی)</th>
                                                        <th>
                                                            <button className={'dv-exam-button'}
                                                                    onClick={() => addExam(row?.id, row.tests)}>افزودن
                                                                آزمون
                                                            </button>
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        row?.tests?.length > 0 ?
                                                            row?.tests?.map((exam, index) => (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <div className='d-flex align-items-center'>
                                                                        <span
                                                                            className='icon-delete dv-delete-price-table-item pl-2 pb-1'
                                                                            onClick={() => removeExam(exam?.id, exam)}/>
                                                                            <div>
                                                                                <p className="afa-tbody-text">
                                                                                    <span> فارسی : </span>
                                                                                    <span>{exam?.title['fa']}</span>
                                                                                </p>
                                                                                <p className="afa-tbody-text">
                                                                                    <span> انگلیسی : </span>
                                                                                    <span>{exam?.title['en']}</span>
                                                                                </p>
                                                                                <p className="afa-tbody-text">
                                                                                    <span> ترکی : </span>
                                                                                    <span>{exam?.title['tr']}</span>
                                                                                </p>
                                                                                <p className="afa-tbody-text">
                                                                                    <span> عربی : </span>
                                                                                    <span>{exam?.title['ar']}</span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <p className="afa-tbody-text mb-2">{exam?.reference}</p>
                                                                    </td>
                                                                    <td>
                                                                        <p className="afa-tbody-text mb-2">{exam?.edit}</p>
                                                                    </td>
                                                                </tr>
                                                            )) : <tr>
                                                                <td></td>
                                                                <td>آزمونی تعریف نشده</td>
                                                                <td></td>
                                                            </tr>
                                                    }
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    )) : <tr>
                                    <td></td>
                                    <td>آزمایشی وجود ندارد</td>
                                    <td></td>
                                </tr>
                        }
                        </tbody>
                    </table>
                </div>
            </div>

            <Modal style={{textAlign: 'center'}} centered={true} show={inputs?.add_new_exam_modal_show}
                   onHide={closeModal}>
                <Modal.Body dir='rtl'>
                    <form onSubmit={saveNewExam} className="row py-4">
                        <div className="mb-3 w-100">
                            <h5 className='k-text-right'>افزودن آزمون</h5>
                        </div>
                        <div className='col-12 w-100'>
                            <Inputs>
                                <span className='d-flex'>نام آزمون</span>
                                <input
                                    type={'text'}
                                    className={'afa-error-input'}
                                    name={`exam_title_fa`}
                                    value={inputs?.[`exam_title_fa`]}
                                    onChange={(e) =>
                                        setInputs(prevState => ({
                                            ...prevState,
                                            [`exam_title_fa`]: e.target.value
                                        }))
                                    }/>
                                <p className='afa-error-text'>{error[`title[fa]`]}</p>
                            </Inputs>
                        </div>
                        <div className='col-12 w-100'>
                            <Inputs>
                                <span className='d-flex'>نام آزمون به انگلیسی</span>
                                <input
                                    type={'text'}
                                    className={'afa-error-input text-left'}
                                    name={`exam_title_en`}
                                    value={inputs?.[`exam_title_en`]}
                                    onChange={(e) =>
                                        setInputs(prevState => ({
                                            ...prevState,
                                            [`exam_title_en`]: e.target.value
                                        }))
                                    }/>
                                <p className='afa-error-text'>{error[`title[en]`]}</p>
                            </Inputs>
                        </div>
                        <div className='col-12 w-100'>
                            <Inputs>
                                <span className='d-flex'>نام آزمون به عربی</span>
                                <input
                                    type={'text'}
                                    className={'afa-error-input'}
                                    name={`exam_title_ar`}
                                    value={inputs?.[`exam_title_ar`]}
                                    onChange={(e) =>
                                        setInputs(prevState => ({
                                            ...prevState,
                                            [`exam_title_ar`]: e.target.value
                                        }))
                                    }/>
                                <p className='afa-error-text'>{error[`title[ar]`]}</p>
                            </Inputs>
                        </div>
                        <div className='col-12 w-100'>
                            <Inputs>
                                <span className='d-flex'>نام آزمون به ترکی</span>
                                <input
                                    type={'text'}
                                    className={'afa-error-input text-left'}
                                    name={`exam_title_tr`}
                                    value={inputs?.[`exam_title_tr`]}
                                    onChange={(e) =>
                                        setInputs(prevState => ({
                                            ...prevState,
                                            [`exam_title_tr`]: e.target.value
                                        }))
                                    }/>
                                <p className='afa-error-text'>{error[`title[tr]`]}</p>
                            </Inputs>
                        </div>

                        <div className='col-12 w-100'>
                            <Inputs>
                                <span className='d-flex'>مرجع</span>
                                <input
                                    type={'text'}
                                    className={'afa-error-input'}
                                    name={`reference`}
                                    value={inputs?.[`reference`]}
                                    onChange={(e) =>
                                        setInputs(prevState => ({
                                            ...prevState,
                                            [`reference`]: e.target.value
                                        }))
                                    }/>
                                <p className='afa-error-text'>{error[`reference`]}</p>
                            </Inputs>
                        </div>
                        <div className='col-12 w-100'>
                            <Inputs>
                                <span className='d-flex'>ویرایش (میلادی)</span>
                                <input
                                    type={'number'}
                                    step={1}
                                    className={'afa-error-input'}
                                    name={`edit_year`}
                                    placeholder={'2022'}
                                    value={inputs?.[`edit_year`]}
                                    onChange={(e) =>
                                        setInputs(prevState => ({
                                            ...prevState,
                                            [`edit_year`]: e.target.value
                                        }))
                                    }/>
                                <p className='afa-error-text'>{error[`edit`]}</p>
                            </Inputs>
                        </div>
                        <div className='col-12 w-100 d-flex align-items-center mt-3'>
                            <button type='submit' className='afa-btn-save-modal'>ذخیره</button>
                            <button type='button' onClick={closeModal}
                                    className='afa-btn-cancel-modal'>انصراف
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

        </MainContent>
    );
}

export default Laboratory;