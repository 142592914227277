import React, {useEffect, useState} from 'react';
import {
    TabStatusComplete,
    Inputs,
    LeftIcon,
    LargeFileInputs,
    HomeNumberInputs,
    MiniFileInputs,
    MiniFileInputsDocs, TabStatusContent
} from "./style";
import {
    fileUpload,
    just_persian,
    fixNumber,
    getData, getFormDataPost, just_number
} from "../../../../../assets/scripts/GeneralFunctions";
import {DateInput} from "react-hichestan-datetimepicker";
import {MAIN_URL, MAIN_URL_IMAGE} from "../../../../../assets/scripts/GeneralVariables";
import {toast} from "react-toastify";
import {LoopCircleLoading} from "react-loadingg";
import {Images} from "../../../../../assets/scripts/Images";

function StepperUserInformation(props) {
    const [error, setError] = useState({})
    const [inputs, setInputs] = useState({
        gender: 'انتخاب کنید',
        marital_status: 'مجرد',
        military_service_status: 'پایان خدمت',
        special_disease: '0',
        does_smoke: '0',
        introduction_method: 'انتخاب کنید',
        city: 'انتخاب کنید',
        state_id: 'انتخاب کنید',
        criminal_conviction: '0',
        first_name: '',
        last_name: '',
        identification_code: '',
        birth_certificate_id: '',
        father_name: '',
        issued: '',
        mobile: '',
        prev_code_number: '',
        home_number: '',
        address: null,
        postal_code: '',
        offspring_count: '',
        dependents_count: '',
        height: '',
        weight: '',
        insurance_number: '',
        introducer_info: '',
        states: [],
        cities: [],
        shaba_id: '',
        supplementary_insurance: '',
        supplementary_insurance_status: 'خیر'
    })
    const [inputs2, setInputs2] = useState({});
    const [removed_birth_certificate_images, set_removed_birth_certificate_images] = useState([]);
    const [removed_national_identification_images, set_removed_national_identification_images] = useState([]);
    const [is_render, setRender] = useState(false);
    const [more_data, setMoreData] = useState({sheet_status: {}, isLoad: true});
    const [init_birth_image, setInitBithImages] = useState([]);
    const [loader, setLoader] = useState(false)

    useEffect(async () => {
        let api_result = await getData(MAIN_URL, "profile/personal-info", 'get', {}, true, true);
        if (api_result?.status === 200) {
            let data = api_result?.data
            let personal = data?.personal_info
            setInputs(prevState => ({
                ...prevState,
                'first_name': personal?.first_name,
                'shaba_id': personal?.shaba_id,
                'supplementary_insurance': personal?.supplementary_insurance,
                'supplementary_insurance_status': personal?.supplementary_insurance ? 'بله' : 'خیر',
                'last_name': personal?.last_name,
                'mobile': personal?.mobile,
                'identification_code': personal?.identification_code,
                'father_name': personal?.father_name,
                'birth_date': personal?.birth_date,
                'birth_certificate_id': personal?.birth_certificate_id,
                'gender': personal?.gender,
                'marital_status': personal?.marital_status,
                'issued': personal?.issued,
                'military_service_status': personal?.military_service_status,
                'military_service_description': personal?.military_service_description,
                'insurance_number': (personal?.insurance_number !== "null" && personal?.insurance_number !== null) ? personal?.insurance_number : '',
                'offspring_count': personal?.offspring_count,
                'dependents_count': personal?.dependents_count,
                'weight': personal?.weight,
                'height': personal?.height,
                'does_smoke': personal?.does_smoke,
                'special_disease': personal?.special_disease,
                'special_disease_description': personal?.special_disease_description,
                'criminal_conviction': personal?.criminal_conviction,
                'introduction_method': personal?.introduction_method,
                'introducer_info': personal?.introducer_info,
                'address': personal?.address,
                'telephone': personal?.telephone,
                'home_number': personal?.telephone?.substr(3),
                'prev_code_number': personal?.telephone?.substr(0, 3),
                'postal_code': personal?.postal_code,
                'personal_photo': personal?.personal_photo,
                'military_service_document': personal?.military_service_document,
                'criminal_conviction_document': personal?.criminal_conviction_document,
                'national_identification_images[back]': personal?.national_identification_images?.['back'],
                'national_identification_images[front]': personal?.national_identification_images?.['front'],
                'birth_certificate_images[1]': personal?.birth_certificate_images?.[1],
                'birth_certificate_images[2]': personal?.birth_certificate_images?.[2],
                'birth_certificate_images[3]': personal?.birth_certificate_images?.[3],
                'birth_certificate_images[4]': personal?.birth_certificate_images?.[4],

                states: api_result?.data?.states ? api_result?.data?.states : [],
                cities: api_result?.data?.cities ? api_result?.data?.cities : [],
                'city': personal?.city?.id ? personal?.city?.id : 'انتخاب کنید',
                'state_id': personal?.city?.state?.id ? personal?.city?.state?.id : 'انتخاب کنید',
            }))
            setInitBithImages([personal?.birth_certificate_images?.[1] ? true : false, personal?.birth_certificate_images?.[2] ? true : false,
                personal?.birth_certificate_images?.[3] ? true : false, personal?.birth_certificate_images?.[4] ? true : false])
            setMoreData({sheet_status: data?.sheet_status, status_colors: data?.status_colors, isLoad: false})
        }
    }, [is_render]);

    const inputHandler = (e) => {
        setInputs(prevState => ({
            ...prevState, [e.target.name]: e.target.value
        }))
    }

    const stateHandler = async (id) => {
        setLoader(true)
        let api_result = await getData(MAIN_URL, `profile/cities/${id}`, 'get', {}, true, false);
        if (api_result?.status === 200) {
            setInputs(prevState => ({
                ...prevState, state_id: id,
                cities: api_result?.data?.cities,
                city: 'انتخاب کنید'
            }))
            setLoader(false)
        }
    }

    const datePickerHandler = (e) => {
        let today = new Date();
        let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        let selected_date = e.target.value
        if (date.normalize() < selected_date.normalize()) {
            toast.error('تاریخ انتخابی نمیتواند بعد امروز باشد')
            return false
        } else {
            let date2 = e.target.value
            setInputs(prevState => ({
                ...prevState, [e.target.name]: date2?.split('T')[0]
            }))
        }
    }

    const thisUploadDocs = async (e) => {
        let files = e.target.files;
        let file = URL.createObjectURL(await fileUpload(files[0]))
        setInputs(prevState => ({
            ...prevState, [e.target.name]: file
        }))

        let file_upload = await fileUpload(files[0])

        setInputs2(prevState => ({
            ...prevState, [e.target.name]: file_upload
        }))
    }

    const uploadBithImages = async (e, index) => {
        let files = e.target.files;
        let file = URL.createObjectURL(await fileUpload(files[0]))
        setInputs(prevState => ({
            ...prevState, [e.target.name]: file
        }))

        let file_upload = await fileUpload(files[0])

        setInputs2(prevState => ({
            ...prevState, [e.target.name]: file_upload
        }))
        init_birth_image[index] = true
        setInitBithImages(init_birth_image)
    }

    const removeImage = (val) => {
        let birth_arr = removed_birth_certificate_images ? removed_birth_certificate_images : [];
        let nation_arr = removed_national_identification_images ? removed_national_identification_images : [];
        if (val?.split('[')[0] === 'birth_certificate_images') {
            birth_arr.push(inputs[val])
        } else if (val?.split('[')[0] === 'national_identification_images') {
            nation_arr.push(inputs[val])
        }
        set_removed_birth_certificate_images(birth_arr)
        set_removed_national_identification_images(nation_arr)

        setInputs2(prevState => ({
            ...prevState, [val]: ''
        }))

        setInputs(prevState => ({
            ...prevState, [val]: ''
        }))

    }
    const removeBirthImage = (val, index) => {
        let birth_arr = removed_birth_certificate_images ? removed_birth_certificate_images : [];
        if (val?.split('[')[0] === 'birth_certificate_images') {
            birth_arr.push(inputs[val])
        }

        set_removed_birth_certificate_images(birth_arr)

        setInputs2(prevState => ({
            ...prevState, [val]: ''
        }))

        setInputs(prevState => ({
            ...prevState, [val]: ''
        }))
        init_birth_image[index] = false
        setInitBithImages(init_birth_image)

    }

    const formHandler = async (e) => {
        e.preventDefault()
        // return false
        let formData = new FormData()

        if (inputs['first_name']) {
            formData.append('first_name', inputs['first_name'])
        }
        if (inputs['shaba_id']) {
            formData.append('shaba_id', inputs['shaba_id'])
        }
        if (inputs['supplementary_insurance']) {
            formData.append('supplementary_insurance', inputs['supplementary_insurance'])
        }

        if (inputs?.[`supplementary_insurance_status`] == 'بله') {
            formData.append('supplementary_insurance', inputs?.[`supplementary_insurance`])
        } else {
            formData.append('supplementary_insurance', '')
        }

        if (inputs['last_name']) {
            formData.append('last_name', inputs['last_name'])
        }
        if (inputs['mobile']) {
            formData.append('mobile', inputs['mobile'])
        }
        if (inputs['identification_code']) {
            formData.append('identification_code', inputs['identification_code'])
        }
        if (inputs['father_name']) {
            formData.append('father_name', inputs['father_name'])
        }
        if (inputs?.birth_date?.split('T')[0]) {
            formData.append('birth_date', inputs?.birth_date?.split('T')[0])
        }
        if (inputs['birth_certificate_id']) {
            formData.append('birth_certificate_id', inputs['birth_certificate_id'])
        }
        if (inputs['gender']) {
            formData.append('gender', inputs['gender'])
        }
        if (inputs['marital_status']) {
            formData.append('marital_status', inputs['marital_status'])
        }
        if (inputs['issued']) {
            formData.append('issued', inputs['issued'])
        }
        if (inputs.gender === 'مرد') {
            if (inputs['military_service_status']) {
                formData.append('military_service_status', inputs['military_service_status'])
            }
            if (inputs['military_service_description']) {
                formData.append('military_service_description', inputs['military_service_description'])
            }
            inputs2['military_service_document'] !== undefined && formData.append('military_service_document', inputs2['military_service_document'])
        }
        if (inputs['insurance_number']) {
            formData.append('insurance_number', inputs['insurance_number'])
        }
        if (inputs['offspring_count']) {
            formData.append('offspring_count', inputs['offspring_count'])
        }
        if (inputs['dependents_count']) {
            formData.append('dependents_count', inputs['dependents_count'])
        }
        if (inputs['weight']) {
            formData.append('weight', inputs['weight'])
        }
        if (inputs.introduction_method !== 'معرف') {
            setInputs(prevState => ({...prevState, introducer_info: ''}))
        }
        if (inputs['introducer_info']) {
            formData.append('introducer_info', inputs['introducer_info'])
        }
        if (inputs['introduction_method']) {
            formData.append('introduction_method', inputs['introduction_method'])
        }
        if (inputs['height']) {
            formData.append('height', inputs['height'])
        }
        if (inputs['does_smoke']) {
            formData.append('does_smoke', inputs['does_smoke'])
        }
        if (inputs['special_disease']) {
            formData.append('special_disease', inputs['special_disease'])
        }
        if (inputs['special_disease_description']) {
            formData.append('special_disease_description', inputs['special_disease_description'])
        }
        if (inputs['criminal_conviction']) {
            formData.append('criminal_conviction', inputs['criminal_conviction'])
        }
        if (inputs['address']) {
            formData.append('address', inputs['address'])
        }
        if (inputs.prev_code_number && inputs.home_number) {
            formData.append('telephone', `${inputs.prev_code_number}${inputs.home_number}`)
        }
        if (inputs['postal_code']) {
            formData.append('postal_code', inputs['postal_code'])
        }
        if (inputs['city'] !== 'انتخاب کنید') {
            formData.append('city', inputs['city'])
        }

        inputs2['personal_photo'] !== undefined && formData.append('personal_photo', inputs2['personal_photo'])
        inputs2['criminal_conviction_document'] !== undefined && formData.append('criminal_conviction_document', inputs2['criminal_conviction_document'])
        inputs2['national_identification_images[back]'] && formData.append('national_identification_images[back]', inputs2['national_identification_images[back]'])
        inputs2['national_identification_images[front]'] && formData.append('national_identification_images[front]', inputs2['national_identification_images[front]'])


        !inputs['personal_photo'] && formData.append('remove_personal_photo', 'remove')
        !inputs['military_service_document'] && formData.append('remove_military_service_document', 'remove')
        !inputs['criminal_conviction_document'] && formData.append('remove_criminal_conviction_document', 'remove')

        let false_arr = [];
        init_birth_image?.map((row) => {
            if (row === false) {
                false_arr.push(row)
            }
        })

        let true_arr = [];
        init_birth_image?.map((row) => {
            if (row === true) {
                true_arr.push(row)
            }
        })
        inputs2['birth_certificate_images[1]'] && formData.append('birth_certificate_images[1]', inputs2['birth_certificate_images[1]'])
        inputs2['birth_certificate_images[2]'] && formData.append('birth_certificate_images[2]', inputs2['birth_certificate_images[2]'])
        inputs2['birth_certificate_images[3]'] && formData.append('birth_certificate_images[3]', inputs2['birth_certificate_images[3]'])
        inputs2['birth_certificate_images[4]'] && formData.append('birth_certificate_images[4]', inputs2['birth_certificate_images[4]'])
        for (let i = 0; i < removed_birth_certificate_images?.length; i++) {
            formData.append(`removed_birth_certificate_images[${i}]`, removed_birth_certificate_images[i])
        }

        if (removed_national_identification_images?.length > 0) {
            for (let i = 0; i < removed_national_identification_images?.length; i++) {
                formData.append(`removed_national_identification_images[${i}]`, removed_national_identification_images[i])
            }
        }

        let api_result = await getFormDataPost('profile/personal-info', formData)

        if (api_result?.status === 200) {
            toast.success('با موفقیت تغییر یافت')
            setError('')
            setRender(!is_render)
            setInputs2('')
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            window.scroll(0, 0)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
        // }
    }

    const goToNextPage = () => {
        props.handleChange(1)
    }
    return (
        <>
            {/*{*/}
            {/*    !more_data["isLoad"] &&*/}
            <form onSubmit={formHandler} className="row">
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <TabStatusComplete
                        style={{borderRightColor: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.border_right}}>
                        {
                            more_data.sheet_status?.status === 'pending' || more_data.sheet_status?.status === 'denied' ?
                                <span style={{
                                    color: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.color,
                                    backgroundColor: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.background
                                }} className='icon-Iconly-Bulk-Info-Square afa-icon-tick'/> :
                                <span style={{
                                    color: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.color,
                                    backgroundColor: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.background
                                }} className='icon-tick afa-icon-tick'/>
                        }
                        <p className='mb-0 f-14 '>
                            <span>وضعیت : </span><span>{more_data.status_colors?.[more_data.sheet_status?.status]?.title}</span>
                        </p>
                    </TabStatusComplete>
                </div>
                <div className="col-12 col-sm-6 col-lg-8 mb-4">
                    {
                        more_data.sheet_status?.message &&
                        <TabStatusContent
                            style={{borderRightColor: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.border_right}}>
                            <span className="icon-Iconly-Bulk-Message afa-icon-message">
                                <span className="path1"></span>
                                <span className="path2"></span>
                            </span>
                            <p className='mb-0 f-14'>
                                <span>پیام ادمین : </span><span>{more_data.sheet_status?.message}</span>
                            </p>
                        </TabStatusContent>
                    }
                </div>

                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>نام
                        <span className='dv-required-star'>*</span>
                        </span>
                        <input className={error['first_name'] && 'afa-error-input'} type="text" name={'first_name'}
                               value={inputs.first_name} onChange={(e) =>
                            just_persian(e.target.value) && setInputs(prevState => ({
                                ...prevState, 'first_name': e.target.value
                            }))
                        } placeholder='نام'/>
                        <p className='afa-error-text'>{error['first_name']}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>نام خانوادگی
                        <span className='dv-required-star'>*</span>
                        </span>
                        <input className={error['last_name'] && 'afa-error-input'} type="text" name={'last_name'}
                               value={inputs.last_name} onChange={(e) =>
                            just_persian(e.target.value) && setInputs(prevState => ({
                                ...prevState, 'last_name': e.target.value
                            }))
                        } placeholder='نام خانوادگی'/>
                        <p className='afa-error-text'>{error['last_name']}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>کد ملی
                        <span className='dv-required-star'>*</span>
                        </span>
                        <input className={error['identification_code'] && 'afa-error-input'}
                               type="text"
                               max={9999999999}
                               maxLength={10}
                               name={'identification_code'}
                               value={inputs.identification_code} onChange={(e) =>
                            just_number(e.target.value) && setInputs(prevState => ({
                                ...prevState, 'identification_code': fixNumber(e.target.value)
                            }))
                        } placeholder='- - - - - - - - - - '/>
                        <p className='afa-error-text'>{error['identification_code']}</p>
                    </Inputs>
                </div>

                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs className='mb-3'>
                        <span>شماره شناسنامه
                            {/*<span className='dv-required-star'>*</span>*/}
                        </span>
                        <input className={error['birth_certificate_id'] && 'afa-error-input'} type="text"
                               max={9999999999}
                               maxLength={10}
                               name={'birth_certificate_id'} value={inputs.birth_certificate_id} onChange={(e) =>
                            just_number(e.target.value) && setInputs(prevState => ({
                                ...prevState, 'birth_certificate_id': fixNumber(e.target.value)
                            }))
                        }
                               placeholder='- - - - - - - - - - '/>
                        <p className='afa-error-text'>{error['birth_certificate_id']}</p>
                    </Inputs>
                    <Inputs>
                        <span>جنسیت
                        <span className='dv-required-star'>*</span>
                        </span>
                        <select className={error['gender'] && 'afa-error-input'} value={inputs.gender}
                                onChange={inputHandler} name={'gender'}>
                            <option value='انتخاب کنید'>انتخاب کنید</option>
                            <option value="مرد">مرد</option>
                            <option value="زن">زن</option>
                        </select>
                        <p className='afa-error-text'>{error['gender']}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs className='mb-3'>
                        <span>نام پدر
                            {/*<span className='dv-required-star'>*</span>*/}
                        </span>
                        <input className={error['father_name'] && 'afa-error-input'} type="text"
                               name={'father_name'}
                               value={inputs.father_name} onChange={(e) =>
                            just_persian(e.target.value) && setInputs(prevState => ({
                                ...prevState, 'father_name': e.target.value
                            }))
                        } placeholder='نام پدر'/>
                        <p className='afa-error-text'>{error['father_name']}</p>
                    </Inputs>
                    <Inputs>
                        <span>تاریخ تولد
                        <span className='dv-required-star'>*</span>
                        </span>
                        <DateInput
                            value={inputs['birth_date']}
                            name={'birth_date'}
                            className={error['birth_date'] && 'afa-error-input'}
                            placeholder={'تاریخ تولد را انتخاب کنید'}
                            onChange={datePickerHandler}/>
                        {/*<input className={error['father_name'] && 'afa-error-input'} type="text" name={'father_name'}*/}
                        {/*       value={inputs.father_name} onChange={inputHandler} placeholder='نام پدر'/>*/}
                        <p className='afa-error-text'>{error['birth_date']}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <span>عکس پرسنلی
                    </span>
                    <LargeFileInputs className={error['personal_photo'] && 'afa-error-input'}>
                        {
                            inputs['personal_photo'] &&
                            <div className="afa-perseneli-img">
                                <img
                                    src={!inputs2['personal_photo'] ? `${MAIN_URL_IMAGE}${inputs['personal_photo']}` : inputs['personal_photo']}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = `${Images.placeHolder.default}`
                                    }}
                                    alt="آذر فولاد امین"/>
                                <span className='icon-close-solid afa-remove-position'
                                      onClick={() => removeImage('personal_photo')}/>
                            </div>
                        }
                        <label className={inputs['personal_photo'] ? 'afa-content-after-img' : 'afa-center-pos'}>
                            <span className='icon-cloud-computing personal-icon'/>
                            <input type={'file'} accept="image/*"
                                   onChange={thisUploadDocs}
                                   name='personal_photo'
                                   className='dv-upload-file'/>
                            <span className='upload-text personal-text'>عکس پرسنلی رسمی</span>
                        </label>
                    </LargeFileInputs>
                    <p className='afa-error-text'>{error['personal_photo']}</p>
                </div>

                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>صادره
                            {/*<span className='dv-required-star'>*</span>*/}
                        </span>
                        <input className={error['issued'] && 'afa-error-input'} type="text" name={'issued'}
                               value={inputs.issued} onChange={(e) =>
                            just_persian(e.target.value) && setInputs(prevState => ({
                                ...prevState, 'issued': e.target.value
                            }))
                        } placeholder='تبریز'/>
                        <p className='afa-error-text'>{error['issued']}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>شماره موبایل
                        <span className='dv-required-star'>*</span>
                        </span>
                        <input className={error['mobile'] && 'afa-error-input'} type="text"
                               max={99999999999}
                               maxLength={11} name={'mobile'}
                               value={inputs.mobile} onChange={(e) =>
                            just_number(e.target.value) && setInputs(prevState => ({
                                ...prevState, 'mobile': fixNumber(e.target.value)
                            }))
                        } placeholder='09123456789'/>
                        <p className='afa-error-text'>{error['mobile']}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <HomeNumberInputs>
                        <span>شماره تماس ثابت
                            {/*<span className='dv-required-star'>*</span>*/}
                        </span>
                        <div
                            className={error['home_number'] ? 'afa-error-input afa_home_number' : "afa_home_number"}>
                            <input className={'afa-phone-input'} type="text" max={99999999}
                                   maxLength={8} name={'home_number'}
                                   value={inputs.home_number} onChange={(e) =>
                                just_number(e.target.value) && setInputs(prevState => ({
                                    ...prevState, 'home_number': fixNumber(e.target.value)
                                }))
                            } placeholder='43332020'/>
                            <input className={'afa-prev-code'} type="text" max={9999} maxLength={4}
                                   name={'prev_code_number'}
                                   value={inputs.prev_code_number} onChange={(e) =>
                                just_number(e.target.value) && setInputs(prevState => ({
                                    ...prevState, 'prev_code_number': fixNumber(e.target.value)
                                }))
                            } placeholder='041'/>
                        </div>
                        <p className='afa-error-text'>{error['telephone']}</p>
                    </HomeNumberInputs>
                </div>

                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>استان
                        <span className='dv-required-star'>*</span>
                        </span>
                        <select className={error['state'] && 'afa-error-input'} value={inputs.state_id}
                                onChange={(e) => stateHandler(e.target.value)} name={'state_id'}>
                            <option disabled value='انتخاب کنید'>انتخاب کنید</option>
                            {
                                inputs?.states?.map((row, index) => (
                                    <option value={row?.id} key={index}>{row?.name}</option>
                                ))
                            }
                        </select>
                        {
                            error['city'] && <p className='afa-error-text'>انتخاب استان الزامی است</p>
                        }
                    </Inputs>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mb-4 position-relative">
                    <Inputs>
                        <span>شهر
                        <span className='dv-required-star'>*</span>
                        </span>
                        <select disabled={loader} className={error['city'] && 'afa-error-input'} value={inputs.city}
                                onChange={inputHandler} name={'city'}>
                            <option value='انتخاب کنید' disabled>انتخاب کنید</option>
                            {
                                inputs?.cities?.map((row, index) => (
                                    <option value={row?.id} key={index}>{row?.name}</option>
                                ))
                            }
                        </select>
                        <p className='afa-error-text'>{error['city']}</p>
                        {
                            loader && <div className="dv-skill-loader">
                                <LoopCircleLoading color={'green'}/>
                            </div>
                        }
                    </Inputs>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>کد پستی
                            {/*<span className='dv-required-star'>*</span>*/}
                        </span>
                        <input className={error['postal_code'] && 'afa-error-input'} type="text"
                               max={9999999999}
                               maxLength={10}
                               name={'postal_code'}
                               value={inputs.postal_code} onChange={(e) =>
                            just_number(e.target.value) && setInputs(prevState => ({
                                ...prevState, 'postal_code': fixNumber(e.target.value)
                            }))
                        }/>
                        <p className='afa-error-text'>{error['postal_code']}</p>
                    </Inputs>
                </div>

                <div className="col-12 mb-4">
                    <Inputs>
                        <span>نشانی محل سکونت
                            {/*<span className='dv-required-star'>*</span>*/}
                        </span>
                        <input className={error['address'] && 'afa-error-input'} type="text" name={'address'}
                               value={inputs.address} onChange={(e) =>
                            setInputs(prevState => ({
                                ...prevState, 'address': e.target.value
                            }))
                        }/>
                        <p className='afa-error-text'>{error['address']}</p>
                    </Inputs>
                </div>

                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>

                        <span>شماره شبا
                            {/*<span className='dv-required-star'>*</span>*/}
                        </span>
                        <div className="input-group afa-shaba-input d-flex align-items-center">
                            <input
                                className={error['shaba_id'] ? 'afa-error-input form-control afa-shba-number-input' : 'form-control afa-shba-number-input'}
                                aria-label="shaba_id"
                                maxLength={24}
                                type="text" name={'shaba_id'}
                                value={inputs.shaba_id}
                                onChange={(e) => just_number(e.target.value) && setInputs(prevState => ({
                                    ...prevState, [`shaba_id`]: fixNumber(e.target.value)
                                }))}
                                aria-describedby="basic-addon1"/>
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">IR</span>
                            </div>
                        </div>
                        <p className='afa-error-text'>{error['shaba_id']}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>تعداد فرزندان
                            {/*<span className='dv-required-star'>*</span>*/}
                        </span>
                        <input className={error['offspring_count'] && 'afa-error-input'} type="text"
                               max={99}
                               maxLength={2}
                               name={'offspring_count'}
                               value={inputs.offspring_count} onChange={(e) =>
                            just_number(e.target.value) && setInputs(prevState => ({
                                ...prevState, 'offspring_count': fixNumber(e.target.value)
                            }))
                        } placeholder='2'/>
                        <p className='afa-error-text'>{error['offspring_count']}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>تعداد افراد تحت تکفل
                            {/*<span className='dv-required-star'>*</span>*/}
                        </span>
                        <input className={error['dependents_count'] && 'afa-error-input'} type="text"
                               max={99}
                               maxLength={2}
                               name={'dependents_count'}
                               value={inputs.dependents_count} onChange={(e) =>
                            just_number(e.target.value) && setInputs(prevState => ({
                                ...prevState, 'dependents_count': fixNumber(e.target.value)
                            }))
                        } placeholder='2'/>
                        <p className='afa-error-text'>{error['dependents_count']}</p>
                    </Inputs>
                </div>

                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>قد
                            {/*<span className='dv-required-star'>*</span>*/}
                        </span>
                        <input className={error['height'] && 'afa-error-input'} type="number"
                               max={999}
                               step={0.01}
                               min={0} name={'height'}
                               value={inputs.height} onChange={(e) =>
                            setInputs(prevState => ({
                                ...prevState, 'height': fixNumber(e.target.value)
                            }))
                        } placeholder='185'/>
                        <p className='afa-error-text'>{error['height']}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>وزن
                            {/*<span className='dv-required-star'>*</span>*/}
                        </span>
                        <input className={error['weight'] && 'afa-error-input'} type="number"
                               max={999}
                               step={0.01}
                               min={0} name={'weight'}
                               value={inputs.weight} onChange={(e) =>
                            setInputs(prevState => ({
                                ...prevState, 'weight': fixNumber(e.target.value)
                            }))
                        } placeholder='78'/>
                        <p className='afa-error-text'>{error['weight']}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>شماره بیمه
                        </span>
                        <input className={error['insurance_number'] && 'afa-error-input'} type="text"
                               max={999999999999}
                               maxLength={12}
                               name={'insurance_number'}
                               value={inputs.insurance_number} onChange={(e) =>
                            just_number(e.target.value) && setInputs(prevState => ({
                                ...prevState, 'insurance_number': fixNumber(e.target.value)
                            }))
                        }/>
                        <p className='afa-error-text'>{error['insurance_number']}</p>
                    </Inputs>
                </div>

                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <div className="d-flex flex-column">
                        <span className=' f-14 mb-2'>درخواست بیمه تکمیلی دارید؟</span>
                        <div className="d-flex flex-wrap">
                            <label className="afa-custom-checkbox ml-2">
                                بله
                                <input type="radio" value='بله' onChange={() => {
                                    setInputs(prevState => ({
                                        ...prevState,
                                        'supplementary_insurance_status': 'بله'
                                    }))
                                }}
                                       checked={inputs?.supplementary_insurance_status == 'بله'}
                                       name="supplementary_insurance_status"/>
                                <span className="checkmark"/>
                            </label>
                            <label className="afa-custom-checkbox ml-2">خیر
                                <input type="radio" value='خیر' onChange={() => {
                                    setInputs(prevState => ({
                                        ...prevState,
                                        'supplementary_insurance_status': 'خیر'
                                    }))
                                }}
                                       checked={inputs?.supplementary_insurance_status == 'خیر'}
                                       name="supplementary_insurance_status"/>
                                <span className="checkmark"/>

                            </label>
                        </div>
                    </div>
                    {
                        inputs['supplementary_insurance_status'] === 'بله' &&
                        <Inputs>
                            <span>نوع بیمه</span>
                            <select className={error['supplementary_insurance'] && 'afa-error-input'}
                                    value={inputs.supplementary_insurance}
                                    onChange={inputHandler} name={'supplementary_insurance'}>
                                <option value='انتخاب کنید'>انتخاب کنید</option>
                                <option value="عمومی">عمومی</option>
                                <option value="عمومی + دندانپزشکی">عمومی + دندانپزشکی</option>
                            </select>
                            {
                                error['supplementary_insurance'] &&
                                <p className='afa-error-text'>{error['supplementary_insurance']}</p>
                            }

                        </Inputs>
                    }
                </div>

                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <div className="d-flex flex-column">
                        <span className=' f-14 mb-2'>آیا تا کنون بیماری قابل توجهی داشته اید</span>
                        <div className="d-flex flex-wrap">
                            <label className="afa-custom-checkbox ml-2">
                                بله
                                <input type="radio" value='1' onChange={inputHandler}
                                       checked={inputs.special_disease == 1} name="special_disease"/>
                                <span className="checkmark"/>
                            </label>

                            <label className="afa-custom-checkbox ml-2">
                                خیر
                                <input type="radio" value='0' onChange={inputHandler}
                                       checked={inputs.special_disease == 0} name="special_disease"/>
                                <span className="checkmark"/>
                            </label>
                        </div>
                        {
                            error['special_disease'] && <p className='afa-error-text'>{error['special_disease']}</p>
                        }
                    </div>
                    {
                        inputs.special_disease == 1 &&
                        <Inputs>
                            <span>عنوان بیماری</span>
                            <input className={error['dependents_count'] && 'afa-error-input'} type="text"
                                   name={'special_disease_description'}
                                   value={inputs.special_disease_description} onChange={(e) =>
                                just_persian(e.target.value) && setInputs(prevState => ({
                                    ...prevState, 'special_disease_description': e.target.value
                                }))
                            } placeholder='عنوان بیماری'/>
                            <p className='afa-error-text'>{error['special_disease_description']}</p>
                        </Inputs>
                    }
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <div className="d-flex flex-column">
                        <span className=' f-14 mb-2'>وضعیت تاهل
                            {/*<span className='dv-required-star'>*</span>*/}
                        </span>
                        <div className="d-flex flex-wrap">
                            <label className="afa-custom-checkbox ml-2">
                                مجرد
                                <input type="radio" value='مجرد' onChange={inputHandler}
                                       checked={inputs.marital_status === 'مجرد'} name="marital_status"/>
                                <span className="checkmark"/>
                            </label>

                            <label className="afa-custom-checkbox ml-2">
                                متاهل
                                <input type="radio" value='متاهل' onChange={inputHandler}
                                       checked={inputs.marital_status === 'متاهل'} name="marital_status"/>
                                <span className="checkmark"/>
                            </label>
                        </div>
                        <p className='afa-error-text'>{error['marital_status']}</p>
                    </div>

                    <div className="d-flex flex-column mt-2">
                        <span className=' f-14 mb-2'>آیا سیگار میکشید
                            {/*<span className='dv-required-star'>*</span>*/}
                        </span>
                        <div className="d-flex flex-wrap">
                            <label className="afa-custom-checkbox ml-2">
                                بله
                                <input type="radio" value='1' onChange={inputHandler}
                                       checked={inputs.does_smoke == 1} name="does_smoke"/>
                                <span className="checkmark"/>
                            </label>

                            <label className="afa-custom-checkbox ml-2">
                                خیر
                                <input type="radio" value='0' onChange={inputHandler}
                                       checked={inputs.does_smoke == 0} name="does_smoke"/>
                                <span className="checkmark"/>
                            </label>
                        </div>
                        <p className='afa-error-text'>{error['does_smoke']}</p>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <div className="d-flex flex-column mt-2">
                        <Inputs>
                            <span>طریقه آشنایی
                                {/*<span className='dv-required-star'>*</span>*/}
                        </span>
                            <select name="introduction_method" value={inputs?.introduction_method}
                                    onChange={inputHandler}
                                    className={error['introduction_method'] && 'afa-error-input'}>
                                <option value="انتخاب کنید" disabled>انتخاب کنید</option>
                                <option value="اداره کار">اداره کار</option>
                                <option value="موسسات کاریابی">موسسات کاریابی</option>
                                <option value="مراجعه شخصی">مراجعه شخصی</option>
                                <option value="معرف">معرف</option>
                                <option value="سایت و شبکه های اجتماعی">سایت و شبکه های اجتماعی</option>
                            </select>
                        </Inputs>
                        {
                            inputs.introduction_method === 'معرف' &&
                            <Inputs className='mt-3'>
                                <span>نام و نام خانوادگی معرف
                                    {/*<span className='dv-required-star'>*</span>*/}
                                </span>
                                <input className={error['introducer_info'] && 'afa-error-input'} type="text"
                                       name={'introducer_info'}
                                       value={inputs.introducer_info !== 'null' ? inputs?.introducer_info : ''}
                                       onChange={(e) =>
                                           just_persian(e.target.value) && setInputs(prevState => ({
                                               ...prevState, 'introducer_info': e.target.value
                                           }))
                                       }/>
                                <p className='afa-error-text'>{error['introducer_info']}</p>
                            </Inputs>
                        }
                    </div>
                </div>
                {
                    inputs.gender !== 'زن' &&
                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                        <div className="d-flex flex-column mb-4">
                            <span className=' f-14 mb-2'>وضعیت نظام وظیفه
                            <span className='dv-required-star'>*</span>
                            </span>
                            <div className="d-flex flex-wrap">
                                <label className="afa-custom-checkbox" style={{paddingLeft: '20px'}}>
                                    پایان خدمت
                                    <input type="radio" value='پایان خدمت' onChange={inputHandler}
                                           checked={inputs.military_service_status === 'پایان خدمت'}
                                           name="military_service_status"/>
                                    <span className="checkmark"/>
                                </label>

                                <label className="afa-custom-checkbox" style={{paddingLeft: '20px'}}>
                                    معافیت
                                    <input type="radio" value='معاف' onChange={inputHandler}
                                           checked={inputs.military_service_status === 'معاف'}
                                           name="military_service_status"/>
                                    <span className="checkmark"/>
                                </label>

                                <label className="afa-custom-checkbox" style={{paddingLeft: '20px'}}>
                                    سایر
                                    <input type="radio" value='سایر' onChange={inputHandler}
                                           checked={inputs.military_service_status === 'سایر'}
                                           name="military_service_status"/>
                                    <span className="checkmark"/>
                                </label>
                            </div>
                            <p className='afa-error-text'>{error['military_service_status']}</p>
                            {
                                inputs.military_service_status === 'سایر' ?
                                    <Inputs>
                                    <textarea name="military_service_description"
                                              value={inputs.military_service_description} onChange={inputHandler}
                                              className={error['military_service_description'] && 'afa-error-input'}
                                              id="military_service_description" cols="30" rows="3"/>
                                        <p className='afa-error-text'>{error['military_service_description']}</p>
                                    </Inputs> :
                                    <>
                                        <MiniFileInputs
                                            className={error['military_service_document'] && 'afa-error-input'}>
                                            {
                                                inputs['military_service_document'] &&
                                                <div className="afa-img">
                                                    <img
                                                        onError={(e) => {
                                                            e.target.onerror = null;
                                                            e.target.src = `${Images.placeHolder.default}`
                                                        }}
                                                        src={!inputs2['military_service_document'] ? `${MAIN_URL_IMAGE}${inputs['military_service_document']}` : inputs['military_service_document']}
                                                        alt="آذر فولاد امین"/>
                                                    <span className='icon-close-solid afa-remove-position'
                                                          onClick={() => removeImage('military_service_document')}/>
                                                </div>
                                            }
                                            <label className="afa-center-pos">
                                                <span className='icon-cloud-computing'></span>
                                                <input type="file" accept="image/*"
                                                       onChange={thisUploadDocs}
                                                       name='military_service_document'
                                                       className='dv-upload-file'/>
                                                <span className='upload-text'>آپلود</span>
                                            </label>
                                        </MiniFileInputs>
                                        <p className='afa-error-text'>{error['military_service_document']}</p>
                                    </>
                            }
                        </div>
                    </div>
                }
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <div className="d-flex flex-column">
                        <span className=' f-14 mb-2'>آیا سابقه محکومیت کیفری داشته اید</span>
                        <div className="d-flex flex-wrap mb-3">
                            <label className="afa-custom-checkbox ml-2">
                                بله
                                <input type="radio" value='1' onChange={inputHandler}
                                       checked={inputs.criminal_conviction == 1} name="criminal_conviction"/>
                                <span className="checkmark"/>
                            </label>
                            <label className="afa-custom-checkbox ml-2">
                                خیر
                                <input type="radio" value='0' onChange={inputHandler}
                                       checked={inputs.criminal_conviction == 0} name="criminal_conviction"/>
                                <span className="checkmark"/>
                            </label>
                            <p className='afa-error-text'>{error['criminal_conviction']}</p>
                        </div>
                        {
                            inputs?.criminal_conviction == 1 &&
                            <MiniFileInputs className={error['criminal_conviction_document'] && 'afa-error-input'}>
                                {
                                    inputs['criminal_conviction_document'] &&
                                    <div className="afa-img">
                                        <img
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = `${Images.placeHolder.default}`
                                            }}
                                            src={!inputs2['criminal_conviction_document'] ? `${MAIN_URL_IMAGE}${inputs['criminal_conviction_document']}` : inputs['criminal_conviction_document']}
                                            alt="آذر فولاد امین"/>
                                        <span className='icon-close-solid afa-remove-position'
                                              onClick={() => removeImage('criminal_conviction_document')}/>
                                    </div>
                                }
                                <label className="afa-center-pos">
                                    <span className='icon-cloud-computing'></span>
                                    <input type="file" accept="image/*"
                                           onChange={thisUploadDocs}
                                           name='criminal_conviction_document'
                                           className='dv-upload-file'/>
                                    <span className='upload-text'>آپلود</span>
                                </label>
                            </MiniFileInputs>
                        }
                        <p className='afa-error-text'>{error['criminal_conviction_document']}</p>
                    </div>
                </div>

                <div className="col-12 mb-4">
                    <div className="d-flex flex-column">
                        <span className=' f-14 mb-2'>ضمایم و مدارک</span>
                        <div className="row px-0">

                            <div className="col-12 col-sm-6 col-lg-4 mb-2">
                                <MiniFileInputsDocs
                                    className={error['birth_certificate_images[2]'] && 'afa-error-input'}>
                                    {
                                        inputs['birth_certificate_images[2]'] &&
                                        <div className="afa-img">
                                            <img
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src = `${Images.placeHolder.default}`
                                                }}
                                                src={!inputs2['birth_certificate_images[2]'] ? `${MAIN_URL_IMAGE}${inputs['birth_certificate_images[2]']}` : inputs['birth_certificate_images[2]']}
                                                alt="آذر فولاد امین"/>
                                            <span className='icon-close-solid afa-remove-position'
                                                  onClick={() => removeBirthImage('birth_certificate_images[2]', 1)}/>
                                        </div>
                                    }
                                    <label className="afa-center-pos">
                                        <input type="file" accept="image/*"
                                               onChange={(e) => uploadBithImages(e, 1)}
                                               name='birth_certificate_images[2]'
                                               className='dv-upload-file'/>
                                        {!inputs['birth_certificate_images[2]'] &&
                                            <span className='upload-text'>صفحه اطلاعات همسر(شناسنامه)</span>}
                                        <span className='icon-cloud-computing'/>
                                    </label>
                                </MiniFileInputsDocs>
                                <p className='afa-error-text'>{error['birth_certificate_images[2]']}</p>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4 mb-2">
                                <MiniFileInputsDocs
                                    className={error['birth_certificate_images.1'] && 'afa-error-input'}>
                                    {
                                        inputs['birth_certificate_images[1]'] &&
                                        <div className="afa-img">
                                            <img
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src = `${Images.placeHolder.default}`
                                                }}
                                                src={!inputs2['birth_certificate_images[1]'] ? `${MAIN_URL_IMAGE}${inputs['birth_certificate_images[1]']}` : inputs['birth_certificate_images[1]']}
                                                alt="آذر فولاد امین"/>
                                            <span className='icon-close-solid afa-remove-position'
                                                  onClick={() => removeBirthImage('birth_certificate_images[1]', 0)}/>
                                        </div>
                                    }
                                    <label className="afa-center-pos">
                                        <input type="file" accept="image/*"
                                               onChange={(e) => uploadBithImages(e, 0)}
                                               name='birth_certificate_images[1]'
                                               className='dv-upload-file'/>
                                        {!inputs['birth_certificate_images[1]'] &&
                                            <span className='upload-text'>صفحه اطلاعات شخصی(شناسنامه)</span>}
                                        <span className='icon-cloud-computing'/>
                                    </label>
                                </MiniFileInputsDocs>
                                <p className='afa-error-text'>{error['birth_certificate_images[1]']}</p>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4 mb-2">
                                <MiniFileInputsDocs
                                    className={error['birth_certificate_images[4]'] && 'afa-error-input'}>
                                    {
                                        inputs['birth_certificate_images[4]'] &&
                                        <div className="afa-img">
                                            <img
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src = `${Images.placeHolder.default}`
                                                }}
                                                src={!inputs2['birth_certificate_images[4]'] ? `${MAIN_URL_IMAGE}${inputs['birth_certificate_images[4]']}` : inputs['birth_certificate_images[4]']}
                                                alt="آذر فولاد امین"/>
                                            <span className='icon-close-solid afa-remove-position'
                                                  onClick={() => removeBirthImage('birth_certificate_images[4]', 3)}/>
                                        </div>
                                    }
                                    <label className="afa-center-pos">
                                        <input type="file" accept="image/*"
                                               onChange={(e) => uploadBithImages(e, 3)}
                                               name='birth_certificate_images[4]'
                                               className='dv-upload-file'/>
                                        {!inputs['birth_certificate_images[4]'] &&
                                            <span className='upload-text'>صفحه توضیحات(شناسنامه)</span>}
                                        <span className='icon-cloud-computing'/>
                                    </label>
                                </MiniFileInputsDocs>
                                <p className='afa-error-text'>{error['birth_certificate_images[4]']}</p>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4 mb-2">
                                <MiniFileInputsDocs
                                    className={error['birth_certificate_images[3]'] && 'afa-error-input'}>
                                    {
                                        inputs['birth_certificate_images[3]'] &&
                                        <div className="afa-img">
                                            <img
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src = `${Images.placeHolder.default}`
                                                }}
                                                src={!inputs2['birth_certificate_images[3]'] ? `${MAIN_URL_IMAGE}${inputs['birth_certificate_images[3]']}` : inputs['birth_certificate_images[3]']}
                                                alt="آذر فولاد امین"/>
                                            <span className='icon-close-solid afa-remove-position'
                                                  onClick={() => removeBirthImage('birth_certificate_images[3]', 2)}/>
                                        </div>
                                    }
                                    <label className="afa-center-pos">
                                        <input type="file" accept="image/*"
                                               onChange={(e) => uploadBithImages(e, 2)}
                                               name='birth_certificate_images[3]'
                                               className='dv-upload-file'/>
                                        {!inputs['birth_certificate_images[3]'] &&
                                            <span className='upload-text'>صفحه اطلاعات فرزندان(شناسنامه)</span>}
                                        <span className='icon-cloud-computing'/>
                                    </label>
                                </MiniFileInputsDocs>
                                <p className='afa-error-text'>{error['birth_certificate_images[3]']}</p>
                            </div>

                            <div className="col-12 col-sm-6 col-lg-4 mb-2">
                                <MiniFileInputsDocs
                                    className={error['national_identification_images[back]'] && 'afa-error-input'}>
                                    {
                                        inputs['national_identification_images[back]'] &&
                                        <div className="afa-img">
                                            <img
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src = `${Images.placeHolder.default}`
                                                }}
                                                src={!inputs2['national_identification_images[back]'] ? `${MAIN_URL_IMAGE}${inputs['national_identification_images[back]']}` : inputs['national_identification_images[back]']}
                                                alt="آذر فولاد امین"/>
                                            <span className='icon-close-solid afa-remove-position'
                                                  onClick={() => removeImage('national_identification_images[back]')}/>
                                        </div>
                                    }
                                    <label className="afa-center-pos">
                                        <input type="file" accept="image/*"
                                               onChange={thisUploadDocs}
                                               name='national_identification_images[back]'
                                               className='dv-upload-file'/>
                                        {!inputs['national_identification_images[back]'] &&
                                            <span className='upload-text'>عکس پشت کارت ملی</span>}
                                        <span className='icon-cloud-computing'/>
                                    </label>
                                </MiniFileInputsDocs>
                                <p className='afa-error-text'>{error['national_identification_images[back]']}</p>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4 mb-2">
                                <MiniFileInputsDocs
                                    className={error['national_identification_images[front]'] && 'afa-error-input'}>
                                    {
                                        inputs['national_identification_images[front]'] &&
                                        <div className="afa-img">
                                            <img
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src = `${Images.placeHolder.default}`
                                                }}
                                                src={!inputs2['national_identification_images[front]'] ? `${MAIN_URL_IMAGE}${inputs['national_identification_images[front]']}` : inputs['national_identification_images[front]']}
                                                alt="آذر فولاد امین"/>
                                            <span className='icon-close-solid afa-remove-position'
                                                  onClick={() => removeImage('national_identification_images[front]')}/>
                                        </div>
                                    }
                                    <label className="afa-center-pos">
                                        <input type="file" accept="image/*"
                                               onChange={thisUploadDocs}
                                               name='national_identification_images[front]'
                                               className='dv-upload-file'/>
                                        {!inputs['national_identification_images[front]'] &&
                                            <span className='upload-text'>عکس کارت ملی</span>}
                                        <span className='icon-cloud-computing'/>
                                    </label>
                                </MiniFileInputsDocs>
                                <p className='afa-error-text'>{error['national_identification_images[front]']}</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="col-12">
                    <div className="row d-flex flex-column flex-sm-row justify-content-between">
                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                            <Inputs>
                                <button type='submit'>
                                    <span>ثبت این صفحه</span>
                                    {/*<LeftIcon><span className='icon-cheveron-left'></span></LeftIcon>*/}
                                </button>
                            </Inputs>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                            <Inputs>
                                <button type='button' onClick={goToNextPage} className={'dv-save-information-btn'}>
                                    <span>مرحله بعد</span>
                                    <LeftIcon><span className='icon-cheveron-left'/></LeftIcon>
                                </button>
                            </Inputs>
                        </div>
                    </div>
                </div>
            </form>
            {/*}*/}
        </>
    )
}

export default StepperUserInformation;