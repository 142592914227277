import React, {useState, useEffect} from 'react';
import {MainContent} from "../../style";
import {Images} from "../../../../../assets/scripts/Images";
import {ArticleFile, Inputs} from "../../../Child/Supplier/Child/style";
import {MAIN_URL, MAIN_URL_IMAGE} from "../../../../../assets/scripts/GeneralVariables";
import {fileUpload, getData, getFormDataPost, setTitle} from "../../../../../assets/scripts/GeneralFunctions";
import {toast} from "react-toastify";
import Skeleton from 'react-loading-skeleton'
import {useHistory} from "react-router-dom";

function BoardDirectorsInfo(props) {
    const [inputs, setInputs] = useState({
        ['first_name[fa]']: '', ['last_name[fa]']: '', ['description[fa]']: '', ['position[fa]']: '',
        ['first_name[en]']: '', ['last_name[en]']: '', ['description[en]']: '', ['position[en]']: '',
        image: '', image_url: ''
    })
    const [error, setError] = useState({})
    const [loader, setLoader] = useState(true)
    const history = useHistory();
    useEffect(async () => {
        setTitle('آذر فولاد امین | هیئت مدیره')
        let board_id = props.match.params.id;
        let api_result = await getData(MAIN_URL, `directorate-management/info/${board_id}`, 'get', {}, true, false);
        if (api_result?.status === 200) {
            setInputs(prevState => ({
                ...prevState,
                ['first_name[fa]']: api_result?.data?.directorate?.first_name['fa'],
                ['last_name[fa]']: api_result?.data?.directorate?.last_name['fa'],
                ['description[fa]']: api_result?.data?.directorate?.description['fa'],
                ['position[fa]']: api_result?.data?.directorate?.position['fa'],

                ['first_name[en]']: api_result?.data?.directorate?.first_name['en'],
                ['last_name[en]']: api_result?.data?.directorate?.last_name['en'],
                ['description[en]']: api_result?.data?.directorate?.description['en'],
                ['position[en]']: api_result?.data?.directorate?.position['en'],

                ['first_name[tr]']: api_result?.data?.directorate?.first_name['tr'],
                ['last_name[tr]']: api_result?.data?.directorate?.last_name['tr'],
                ['description[tr]']: api_result?.data?.directorate?.description['tr'],
                ['position[tr]']: api_result?.data?.directorate?.position['tr'],

                ['first_name[ar]']: api_result?.data?.directorate?.first_name['ar'],
                ['last_name[ar]']: api_result?.data?.directorate?.last_name['ar'],
                ['description[ar]']: api_result?.data?.directorate?.description['ar'],
                ['position[ar]']: api_result?.data?.directorate?.position['ar'],
                image_url: '',
                image: api_result?.data?.directorate?.['image']
            }))
        }
        setLoader(false)
    }, []);

    const thisUploadDocs = async (e) => {
        let files = e.target.files;
        let file = URL.createObjectURL(await fileUpload(files[0]))
        let upload_file = await fileUpload(files[0]);
        setInputs(prevState => ({
            ...prevState,
            [e.target.name + '_url']: file,
            [e.target.name]: upload_file,
            [e.target.name + '_file']: e.target.files[0]
        }))
    }

    const removeImage = (val) => {

        setInputs(prevState => ({
            ...prevState, [val]: null, [val + '_url']: null, [val + '_file']: null, [`remove_${val}`]: 1
        }))
    }


    const formHandler = async (e) => {
        e.preventDefault()
        let board_id = props.match.params.id;
        let formData = new FormData()

        formData.append(`first_name[en]`, inputs?.['first_name[en]'])
        formData.append(`first_name[fa]`, inputs?.['first_name[fa]'])
        formData.append(`first_name[tr]`, inputs?.['first_name[tr]'])
        formData.append(`first_name[ar]`, inputs?.['first_name[ar]'])

        formData.append(`last_name[en]`, inputs?.['last_name[en]'])
        formData.append(`last_name[fa]`, inputs?.['last_name[fa]'])
        formData.append(`last_name[tr]`, inputs?.['last_name[tr]'])
        formData.append(`last_name[ar]`, inputs?.['last_name[ar]'])

        formData.append(`position[fa]`, inputs?.['position[fa]'])
        formData.append(`position[en]`, inputs?.['position[en]'])
        formData.append(`position[tr]`, inputs?.['position[tr]'])
        formData.append(`position[ar]`, inputs?.['position[ar]'])

        formData.append(`description[en]`, inputs?.['description[en]'])
        formData.append(`description[fa]`, inputs?.['description[fa]'])
        formData.append(`description[tr]`, inputs?.['description[tr]'])
        formData.append(`description[ar]`, inputs?.['description[ar]'])

        formData.append(`image`, inputs?.image_file)

        let api_result = await getFormDataPost(`directorate-management/edit/${board_id}`, formData)

        if (api_result?.status === 200) {
            setError('')
            toast.success('با موفقیت ثبت شد')
            history.push('/manage-directorate')
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
        }
    }
    return (
        <MainContent>
            <form onSubmit={formHandler} className='row'>
                <div className="col-12 col-sm-6 col-lg-6 mb-3"><h6 className='text-red fw-bold'>پیام مدیریت</h6></div>
                <div className="col-12 col-md-9 mb-3">
                    <div className="row px-0">
                        {/*first name*/}
                        <div className="col-12 col-md-6 mb-3">
                            <Inputs>
                                <span>نام (فارسی)</span>
                                {
                                    loader ?
                                        <Skeleton borderRadius={8} height={50}/> :
                                        <input
                                            className={'afa-error-input'}
                                            maxLength={75}
                                            type="text" name={`first_name[fa]`}
                                            value={inputs?.[`first_name[fa]`]}
                                            placeholder={'نام (فارسی)'}
                                            onChange={(e) =>
                                                setInputs(prevState => ({
                                                    ...prevState,
                                                    [`first_name[fa]`]: e.target.value
                                                }))
                                            }/>
                                }
                            </Inputs>
                            <p className='afa-error-text'>{error['first_name.fa']}</p>

                            <Inputs>
                                <span>نام (انگلیسی)</span>
                                {
                                    loader ?
                                        <Skeleton borderRadius={8} height={50}/> :
                                        <input
                                            className={'afa-error-input'}
                                            maxLength={75}
                                            type="text" name={`first_name[en]`}
                                            value={inputs?.[`first_name[en]`]}
                                            placeholder={'نام (انگلیسی)'}
                                            onChange={(e) =>
                                                setInputs(prevState => ({
                                                    ...prevState,
                                                    [`first_name[en]`]: e.target.value
                                                }))
                                            }/>
                                }
                            </Inputs>
                            <p className='afa-error-text'>{error[`first_name.en`]}</p>

                            <Inputs>
                                <span>نام (ترکی)</span>
                                {
                                    loader ?
                                        <Skeleton borderRadius={8} height={50}/> :
                                        <input
                                            className={'afa-error-input'}
                                            maxLength={75}
                                            type="text" name={`first_name[tr]`}
                                            value={inputs?.[`first_name[tr]`]}
                                            placeholder={'نام (ترکی)'}
                                            onChange={(e) =>
                                                setInputs(prevState => ({
                                                    ...prevState,
                                                    [`first_name[tr]`]: e.target.value
                                                }))
                                            }/>
                                }
                            </Inputs>
                            <p className='afa-error-text'>{error['first_name.tr']}</p>

                            <Inputs>
                                <span>نام (عربی)</span>
                                {
                                    loader ?
                                        <Skeleton borderRadius={8} height={50}/> :
                                        <input
                                            className={'afa-error-input'}
                                            maxLength={75}
                                            type="text" name={`first_name[ar]`}
                                            value={inputs?.[`first_name[ar]`]}
                                            placeholder={'نام (عربی)'}
                                            onChange={(e) =>
                                                setInputs(prevState => ({
                                                    ...prevState,
                                                    [`first_name[ar]`]: e.target.value
                                                }))
                                            }/>
                                }
                            </Inputs>
                            <p className='afa-error-text'>{error[`first_name.ar`]}</p>
                        </div>
                        {/*last name*/}
                        <div className="col-12 col-md-6 mb-3">
                            <Inputs>
                                <span>نام خانوادگی (فارسی)</span>
                                {
                                    loader ?
                                        <Skeleton borderRadius={8} height={50}/> :
                                        <input
                                            className={'afa-error-input'}
                                            maxLength={75}
                                            type="text" name={`last_name[fa]`}
                                            value={inputs?.[`last_name[fa]`]}
                                            placeholder={'نام خانوادگی (فارسی)'}
                                            onChange={(e) =>
                                                setInputs(prevState => ({
                                                    ...prevState,
                                                    [`last_name[fa]`]: e.target.value
                                                }))
                                            }/>
                                }
                            </Inputs>
                            <p className='afa-error-text'>{error['last_name.fa']}</p>

                            <Inputs>
                                <span>نام خانوادگی (انگلیسی)</span>
                                {
                                    loader ?
                                        <Skeleton borderRadius={8} height={50}/> :
                                        <input
                                            className={'afa-error-input'}
                                            maxLength={75}
                                            type="text" name={`last_name[en]`}
                                            value={inputs?.[`last_name[en]`]}
                                            placeholder={'نام خانوادگی (انگلیسی)'}
                                            onChange={(e) =>
                                                setInputs(prevState => ({
                                                    ...prevState,
                                                    [`last_name[en]`]: e.target.value
                                                }))
                                            }/>
                                }
                            </Inputs>
                            <p className='afa-error-text'>{error['last_name.en']}</p>

                            <Inputs>
                                <span>نام خانوادگی (ترکی)</span>
                                {
                                    loader ?
                                        <Skeleton borderRadius={8} height={50}/> :
                                        <input
                                            className={'afa-error-input'}
                                            maxLength={75}
                                            type="text" name={`last_name[tr]`}
                                            value={inputs?.[`last_name[tr]`]}
                                            placeholder={'نام خانوادگی (ترکی)'}
                                            onChange={(e) =>
                                                setInputs(prevState => ({
                                                    ...prevState,
                                                    [`last_name[tr]`]: e.target.value
                                                }))
                                            }/>
                                }
                            </Inputs>
                            <p className='afa-error-text'>{error['last_name.tr']}</p>

                            <Inputs>
                                <span>نام خانوادگی (عربی)</span>
                                {
                                    loader ?
                                        <Skeleton borderRadius={8} height={50}/> :
                                        <input
                                            className={'afa-error-input'}
                                            maxLength={75}
                                            type="text" name={`last_name[ar]`}
                                            value={inputs?.[`last_name[ar]`]}
                                            placeholder={'نام خانوادگی (عربی)'}
                                            onChange={(e) =>
                                                setInputs(prevState => ({
                                                    ...prevState,
                                                    [`last_name[ar]`]: e.target.value
                                                }))
                                            }/>
                                }
                            </Inputs>
                            <p className='afa-error-text'>{error['last_name.ar']}</p>
                        </div>

                        {/*position*/}
                        <div className="col-12 col-md-6">
                            <Inputs>
                                <span>سمت (فارسی)</span>
                                {
                                    loader ?
                                        <Skeleton borderRadius={8} height={50}/> :
                                        <input
                                            className={'afa-error-input'}
                                            maxLength={75}
                                            type="text" name={`position[fa]`}
                                            value={inputs?.[`position[fa]`]}
                                            placeholder={'سمت'}
                                            onChange={(e) =>
                                                setInputs(prevState => ({
                                                    ...prevState,
                                                    [`position[fa]`]: e.target.value
                                                }))
                                            }/>
                                }
                            </Inputs>
                            <p className='afa-error-text'>{error['position.fa']}</p>
                        </div>
                        <div className="col-12 col-md-6">
                            <Inputs>
                                <span>سمت (انگلیسی)</span>
                                {
                                    loader ?
                                        <Skeleton borderRadius={8} height={50}/> :
                                        <input
                                            className={'afa-error-input'}
                                            maxLength={75}
                                            type="text" name={`position[en]`}
                                            value={inputs?.[`position[en]`]}
                                            placeholder={'سمت'}
                                            onChange={(e) =>
                                                setInputs(prevState => ({
                                                    ...prevState,
                                                    [`position[en]`]: e.target.value
                                                }))
                                            }/>
                                }
                            </Inputs>
                            <p className='afa-error-text'>{error['position.en']}</p>
                        </div>
                        <div className="col-12 col-md-6 mb-3">
                            <Inputs>
                                <span>سمت (ترکی)</span>
                                {
                                    loader ?
                                        <Skeleton borderRadius={8} height={50}/> :
                                        <input
                                            className={'afa-error-input'}
                                            maxLength={75}
                                            type="text" name={`position[tr]`}
                                            value={inputs?.[`position[tr]`]}
                                            placeholder={'سمت'}
                                            onChange={(e) =>
                                                setInputs(prevState => ({
                                                    ...prevState,
                                                    [`position[tr]`]: e.target.value
                                                }))
                                            }/>
                                }
                            </Inputs>
                            <p className='afa-error-text'>{error['position.tr']}</p>
                        </div>

                        <div className="col-12 col-md-6 mb-3">
                            <Inputs>
                                <span>سمت (عربی)</span>
                                {
                                    loader ?
                                        <Skeleton borderRadius={8} height={50}/> :
                                        <input
                                            className={'afa-error-input'}
                                            maxLength={75}
                                            type="text" name={`position[ar]`}
                                            value={inputs?.[`position[ar]`]}
                                            placeholder={'سمت'}
                                            onChange={(e) =>
                                                setInputs(prevState => ({
                                                    ...prevState,
                                                    [`position[ar]`]: e.target.value
                                                }))
                                            }/>
                                }
                            </Inputs>
                            <p className='afa-error-text'>{error['position.ar']}</p>
                        </div>

                        <div className="col-12 mb-3">
                            <Inputs>
                                <span className="f-14">متن پیام شخصی (فارسی)</span>
                                {
                                    loader ?
                                        <Skeleton borderRadius={8} height={250}/> :
                                        <textarea
                                            className={'afa-error-input'}
                                            rows="5" name={`description[fa]`}
                                            value={inputs?.[`description[fa]`]}
                                            onChange={(e) =>
                                                setInputs(prevState => ({
                                                    ...prevState,
                                                    [`description[fa]`]: e.target.value
                                                }))
                                            }/>
                                }
                            </Inputs>
                            <p className='afa-error-text'>{error['description.fa']}</p>
                            <Inputs >
                                <span className="f-14">متن پیام شخصی (انگلیسی)</span>
                                {
                                    loader ?
                                        <Skeleton borderRadius={8} height={250}/> :
                                        <textarea
                                            className={'afa-error-input'}
                                            rows="5" name={`description[en]`}
                                            value={inputs?.[`description[en]`]}
                                            onChange={(e) =>
                                                setInputs(prevState => ({
                                                    ...prevState,
                                                    [`description[en]`]: e.target.value
                                                }))
                                            }/>
                                }
                            </Inputs>
                            <p className='afa-error-text'>{error['description.en']}</p>

                            <Inputs className={'mb-3'}>
                                <span className="f-14">متن پیام شخصی (ترکی)</span>
                                {
                                    loader ?
                                        <Skeleton borderRadius={8} height={250}/> :
                                        <textarea
                                            className={'afa-error-input'}
                                            rows="5" name={`description[tr]`}
                                            value={inputs?.[`description[tr]`]}
                                            onChange={(e) =>
                                                setInputs(prevState => ({
                                                    ...prevState,
                                                    [`description[tr]`]: e.target.value
                                                }))
                                            }/>
                                }
                            </Inputs>
                            <p className='afa-error-text'>{error['description.tr']}</p>
                            <Inputs>
                                <span className="f-14">متن پیام شخصی (عربی)</span>
                                {
                                    loader ?
                                        <Skeleton borderRadius={8} height={250}/> :
                                        <textarea
                                            className={'afa-error-input'}
                                            rows="5" name={`description[ar]`}
                                            value={inputs?.[`description[ar]`]}
                                            onChange={(e) =>
                                                setInputs(prevState => ({
                                                    ...prevState,
                                                    [`description[ar]`]: e.target.value
                                                }))
                                            }/>
                                }
                            </Inputs>
                            <p className='afa-error-text'>{error['description.ar']}</p>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-3 mb-3">
                    <div className="afa-upload-file-parent">
                        <span className='f-14'>تصویر مدیر</span>
                        {
                            loader ?
                                <Skeleton borderRadius={8} height={280}/> :
                                <ArticleFile
                                    style={{height: '270px'}}
                                    className={error[`image`] && 'afa-error-input'}>
                                    {
                                        (inputs[`image_url`] || inputs[`image`]) ?
                                            <div className="afa-img">
                                                <img
                                                    src={inputs[`image_url`] ? inputs[`image_url`] : `${MAIN_URL_IMAGE}${inputs[`image`]}`}
                                                    alt="آذر فولاد امین" className={'img-fluid'}
                                                    style={{maxWidth: '100%', maxHeight: '100%'}}/>
                                                <span
                                                    className='afa-remove-position icon-delete'
                                                    onClick={() => removeImage(`image`)}/>
                                            </div> :
                                            <label
                                                className="afa_upload_file d-flex flex-column align-items-center justify-content-center">
                                                <img src={Images?.article_upload?.default}
                                                     className='afa-article-image-to-upload'
                                                     style={{width: '110px', marginTop: '2rem', height: 'auto'}}
                                                     alt="آذر فولاد امین"/>
                                                <input type="file" accept="image/*"
                                                       onChange={thisUploadDocs}
                                                       name={`image`}
                                                       className='dv-upload-file'/>
                                                {
                                                    !inputs[`image`] &&
                                                    <span className='upload-text'>اپلود عکس مربوط به مدیر</span>
                                                }
                                            </label>
                                    }
                                </ArticleFile>
                        }
                        <p className='afa-error-text'>{error[`image`]}</p>
                    </div>
                </div>

                <div className="col-12 d-flex align-items-center justify-content-center mb-4">
                    <Inputs>
                        <button type='submit'
                                className='m-auto afa-save-super-admin-btn'>
                            <span className='pr-3'>ذخیره</span>
                        </button>
                    </Inputs>
                </div>
            </form>
        </MainContent>
    );
}

export default BoardDirectorsInfo;