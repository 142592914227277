import React, {useState, useEffect} from 'react';
import {MainContent} from "../style";
import { Inputs} from "../../Child/Supplier/Child/style";
import {getFormDataPost, setTitle} from "../../../../assets/scripts/GeneralFunctions";
import CkEditorText from "./CkEditorText";
import {toast} from "react-toastify";
import Skeleton from "react-loading-skeleton";

function MissionVision() {
    const [inputs, setInputs] = useState({content: ''})
    const [error, setError] = useState({})
    const [loader, setLoader] = useState(true)

    useEffect(() => {
        setTitle('آذر فولاد امین | ماموریت و چشم انداز')

        setLoader(false)
    }, []);

    const setData = (value) => {
        setInputs(prevState => ({...prevState, content: value}))
    }


    const formHandler = async (e) => {
        e.preventDefault()

        let formData = new FormData()
        formData.append(`content`, inputs?.content)

        let api_result = await getFormDataPost(`blog-management/posts/create/fa`, formData)

        if (api_result?.status === 200) {
            setError('')
            toast.success('با موفقیت ثبت شد')
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
        }
    }
    return (
        <MainContent>
            <form onSubmit={formHandler} className='row'>
                <div className="col-12 col-sm-6 col-lg-6 mb-3"><h6 className='text-red fw-bold'>بیانیه ماموریت و چشم انداز</h6></div>
                <div className="col-12 mb-3">
                    <div className="col-12 mb-3 dv-about-us-textarea">
                        <span className="f-14">متن پیام</span>
                        {
                            loader ?
                                <Skeleton borderRadius={8} height={300}/> :
                                <CkEditorText setData={setData} fa={true}/>
                        }
                        <p className='afa-error-text'>{error['content']}</p>
                    </div>
                </div>

                <div className="col-12 d-flex align-items-center justify-content-center mb-4">
                    <Inputs>
                        <button type='submit'
                                className='m-auto afa-save-super-admin-btn'>
                            <span className='pr-3'>ذخیره</span>
                        </button>
                    </Inputs>
                </div>
            </form>
        </MainContent>
    );
}

export default MissionVision;