import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {getData, setTitle} from "../../../../assets/scripts/GeneralFunctions";
import {MAIN_URL, MAIN_URL_IMAGE} from "../../../../assets/scripts/GeneralVariables";
import {setUserInformation} from "../../../../redux";
import {PanelContent} from "../UserInformation/style";
import {ProgressBar} from "react-bootstrap";
import {Stepper} from "@progress/kendo-react-layout";
import PublicInformation from "./Child/PublicInformation";
import Board_directors from "./Child/Board_directors";
import Staff from "./Child/Staff";
import Work_experience from "./Child/Work_experience";
import FinancialIndex from "./Child/FinancialIndex";
import Equipment from "./Child/Equipment";
import Services from "./Child/Services";
import Bachelors from "./Child/Bachelors";
import Licenses from "./Child/Licenses";

const items = [{
    label: 'اطلاعات عمومی',
    icon: 'icon-steper_user'
}, {
    label: 'هیئت مدیره',
    icon: 'icon-managers'
}, {
    label: 'کادر فنی',
    icon: 'icon-Technical-staff'
}, {
    label: 'سوابق شغلی',
    icon: 'icon-steper_job',
}, {
    label: 'لیسانس ها',
    icon: 'icon-steper_certificate'
}, {
    label: 'پروانه ها',
    icon: 'icon-steper_certificate'
}, {
    label: 'شاخص های مالی',
    icon: 'icon-Financial'
}, {
    label: 'تجهیزات',
    icon: 'icon-Equipment-and-production'
}, {
    label: 'خدمات',
    icon: 'icon-services'
}
];


function SupplierParent(props) {
    const [progress_value, setProgressValue] = useState(0)
    const [stepper_value, setValue] = useState(0);
    const [status_items, setStatus] = useState({list: {}, colors: {}});
    const [guideFile, setGuide] = useState(null)
    const dispatch = useDispatch()
    useEffect(async () => {
        setTitle('آذر فولاد امین | حساب کاربری')
        let api_result = await getData(MAIN_URL, "profile/supplier", 'get', {}, true, true);
        if (api_result?.status === 200) {
            let data = api_result?.data
            setProgressValue(parseFloat(data.progress) * 100)
            setStatus({list: data.sheet_status, colors: data.status_colors})
            dispatch(setUserInformation(false))
            setGuide(data?.hr_help)
        }
    }, [stepper_value]);

    const handleChange = e => {
        setValue(e.value);
    };

    return (
        <>
            {
                guideFile &&
                <div className="dv-guide-file-btn">
                    <a href={`${MAIN_URL_IMAGE}${guideFile}`} download className='afa-excel-export2 w-100 mb-3'>
                        <span className='afa-information-btn-title'>دانلود فایل راهنمای ثبت نام</span>
                    </a>
                </div>
            }

            <PanelContent>
                <ProgressBar now={progress_value}/>
                <div className="d-flex flex-row-reverse flex-lg-column">
                    <Stepper className='mt-4 mb-3' value={stepper_value} onChange={handleChange}
                             orientation={window.innerWidth > 992 ? 'horizontal' : 'vertical'} items={items}/>
                    <div className="afa-size-parent">
                        {
                            stepper_value === 0 &&
                            <PublicInformation status_value={status_items.list?.supplier_info}
                                               status_colors={status_items.colors[status_items.list?.supplier_info]}/>
                        }
                        {
                            stepper_value === 1 &&
                            <Board_directors status_value={status_items.list?.director_boards}
                                             status_colors={status_items.colors[status_items.list?.director_boards]}/>
                        }
                        {
                            stepper_value === 2 &&
                            <Staff status_value={status_items.list?.technical_staff}
                                   status_colors={status_items.colors[status_items.list?.technical_staff]}/>
                        }
                        {
                            stepper_value === 3 &&
                            <Work_experience status_value={status_items.list?.professional_experiences}
                                             status_colors={status_items.colors[status_items.list?.professional_experiences]}/>
                        }
                        {
                            stepper_value === 4 &&
                            <Bachelors status_value={status_items.list?.bachelors}
                                       status_colors={status_items.colors[status_items.list?.bachelors]}/>
                        }
                        {
                            stepper_value === 5 &&
                            <Licenses status_value={status_items.list?.licences}
                                      status_colors={status_items.colors[status_items.list?.licences]}/>
                        }
                        {
                            stepper_value === 6 &&
                            <FinancialIndex status_value={status_items.list?.financial_indicators}
                                            status_colors={status_items.colors[status_items.list?.financial_indicators]}/>
                        }
                        {
                            stepper_value === 7 &&
                            <Equipment status_value={status_items.list?.equipments}
                                       status_colors={status_items.colors[status_items.list?.equipments]}/>
                        }
                        {
                            stepper_value === 8 &&
                            <Services status_value={status_items.list?.services}
                                      status_colors={status_items.colors[status_items.list?.services]}/>
                        }
                    </div>
                </div>
            </PanelContent>
        </>
    );
}

export default SupplierParent;