import styled from "styled-components";

const main_red = '#E30613';
export const PanelContent = styled.div`
  background-color: #fff;
  border-radius: 0;
  border: none;
  box-shadow: 0 3px 30px rgb(0 0 0 / 13%);
  margin: 3rem;
  padding: 2rem;

  .progress {
    height: 0.5rem !important;

    .progress-bar {
      background-color: ${main_red};
    }
  }
  
  .accordion{
    border: none;
  }

  @media (max-width: 992px) {
    box-shadow: none;
    padding: 1rem;
  }
  @media (max-width: 576px) {
    box-shadow: none;
    padding: 0.5rem;
    margin : 0;
  }
`
