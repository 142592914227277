import React, {useEffect, useState} from 'react';
import {MainContent} from "../../super admin child/style";
import moment from "moment-jalaali";
import {getData, setTitle} from "../../../../assets/scripts/GeneralFunctions";
import {MAIN_URL} from "../../../../assets/scripts/GeneralVariables";
import {setNotificationCount} from "../../../../redux/user_information/action";
import {useDispatch} from "react-redux";
import Skeleton from 'react-loading-skeleton'

function Chats(props) {
    const [messages, setMessages] = useState([NaN, NaN, NaN, NaN])
    const dispatch = useDispatch()
    const [is_all_items_load, setAllLoad] = useState(true)
    useEffect(async () => {
        setTitle('آذر فولاد امین | پیام ها')
        if (localStorage.getItem('AFAToken')) {
            let api_result = await getData(MAIN_URL, "messages?offset=0&limit=100", 'get', {}, true, false);
            console.log(api_result)
            if (api_result?.status === 200) {
                setMessages(api_result?.data?.messages)
                dispatch(setNotificationCount(api_result?.data?.unseen_count))
            }
        }
    }, []);

    const getTableDataLazyLoad = async (obj_data) => {
        if (parseFloat(obj_data.target.offsetHeight + obj_data.target.scrollTop) == parseFloat(obj_data.target.scrollHeight)) {
            if (messages?.length % 100 === 0 && is_all_items_load === true) {

                let obj = {};
                obj['limit'] = 100
                obj['offset'] = messages?.length

                let moreData = await getData(MAIN_URL, `messages`, 'get', obj, true, true);
                if (moreData) {
                    setMessages(messages.concat(moreData?.data?.messages))
                    dispatch(setNotificationCount(moreData?.data?.unseen_count))
                    if (moreData?.data?.messages?.length < 100) {
                        setAllLoad({is_all_items_load: false})
                    }
                }
            }
        }
    }
    console.log(messages)
    return (
        <MainContent>
            <div className='row'>
                <div className="col-12 mb-3"><h6 className='text-red fw-bold'>پیام ها</h6></div>
                <div className="col-12 mb-3 afa-lazyload" onScroll={getTableDataLazyLoad}>
                    {
                        messages?.length > 0 ?
                            !messages[0] ?
                                messages?.map((row, index) => (
                                    <>
                                        <Skeleton borderRadius={8} height={50} className='mb-3'/>
                                    </>
                                )) :
                                messages?.map((row, index) => (
                                    <div className="afa-logs-divs">
                                        <div className="d-flex align-items-center justify-content-between w-100"
                                             style={{paddingRight: '1rem'}}>
                                            <span>{row?.log}</span>
                                            <span>{moment(row?.created_at?.split('T')[0]).format('jYYYY-jMM-jDD')}</span>
                                        </div>
                                    </div>
                                )) :
                            <div className="d-flex align-items-center justify-content-between w-100"
                                 style={{paddingRight: '1rem'}}>
                                <span>موردی وجود ندارد</span>
                            </div>
                    }
                    {/*<Accordion className='afa-notifications-tab' allowZeroExpanded>*/}
                    {/*    <AccordionItem className='mb-3 afa-notification-item'>*/}
                    {/*        <AccordionItemHeading>*/}
                    {/*            <AccordionItemButton>*/}
                    {/*                */}
                    {/*            </AccordionItemButton>*/}
                    {/*        </AccordionItemHeading>*/}
                    {/*        <AccordionItemPanel>*/}
                    {/*            <p>sslsd kvsdfvk dfsv mbfds ;fd vfds vlsd fvfdsk jv fcv cxzv cxv sdfdfsg sdfgsd fsdfgf*/}
                    {/*                sdfgsd fg fdsgds gdfsgdf</p>*/}
                    {/*        </AccordionItemPanel>*/}
                    {/*    </AccordionItem>*/}
                    {/*    <AccordionItem className='mb-3 afa-notification-item'>*/}
                    {/*        <AccordionItemHeading>*/}
                    {/*            <AccordionItemButton>*/}
                    {/*                <div className="d-flex align-items-center justify-content-between w-100" style={{paddingRight : '1rem'}}>*/}
                    {/*                    <span>تماشای سومین رویداد آنلاین را از دست ندهید</span>*/}
                    {/*                    <span>{moment('1998-12-08').format('jYYYY-jMM-jDD')}</span>*/}
                    {/*                </div>*/}
                    {/*            </AccordionItemButton>*/}
                    {/*        </AccordionItemHeading>*/}
                    {/*        <AccordionItemPanel>*/}
                    {/*            <p>sslsd kvsdfvk dfsv mbfds ;fd vfds vlsd fvfdsk jv fcv cxzv cxv sdfdfsg sdfgsd fsdfgf*/}
                    {/*                sdfgsd fg fdsgds gdfsgdf</p>*/}
                    {/*        </AccordionItemPanel>*/}
                    {/*    </AccordionItem>*/}
                    {/*    <AccordionItem className='mb-3 afa-notification-item'>*/}
                    {/*        <AccordionItemHeading>*/}
                    {/*            <AccordionItemButton>*/}
                    {/*                <div className="d-flex align-items-center justify-content-between w-100" style={{paddingRight : '1rem'}}>*/}
                    {/*                    <span>تماشای سومین رویداد آنلاین را از دست ندهید</span>*/}
                    {/*                    <span>{moment('1998-12-08').format('jYYYY-jMM-jDD')}</span>*/}
                    {/*                </div>*/}
                    {/*            </AccordionItemButton>*/}
                    {/*        </AccordionItemHeading>*/}
                    {/*        <AccordionItemPanel>*/}
                    {/*            <p>sslsd kvsdfvk dfsv mbfds ;fd vfds vlsd fvfdsk jv fcv cxzv cxv sdfdfsg sdfgsd fsdfgf*/}
                    {/*                sdfgsd fg fdsgds gdfsgdf</p>*/}
                    {/*        </AccordionItemPanel>*/}
                    {/*    </AccordionItem>*/}
                    {/*    <AccordionItem className='mb-3 afa-notification-item'>*/}
                    {/*        <AccordionItemHeading>*/}
                    {/*            <AccordionItemButton>*/}
                    {/*                <div className="d-flex align-items-center justify-content-between w-100" style={{paddingRight : '1rem'}}>*/}
                    {/*                    <span>تماشای سومین رویداد آنلاین را از دست ندهید</span>*/}
                    {/*                    <span>{moment('1998-12-08').format('jYYYY-jMM-jDD')}</span>*/}
                    {/*                </div>*/}
                    {/*            </AccordionItemButton>*/}
                    {/*        </AccordionItemHeading>*/}
                    {/*        <AccordionItemPanel>*/}
                    {/*            <p>sslsd kvsdfvk dfsv mbfds ;fd vfds vlsd fvfdsk jv fcv cxzv cxv sdfdfsg sdfgsd fsdfgf*/}
                    {/*                sdfgsd fg fdsgds gdfsgdf</p>*/}
                    {/*        </AccordionItemPanel>*/}
                    {/*    </AccordionItem>*/}
                    {/*</Accordion>*/}
                </div>
            </div>


        </MainContent>
    );
}

export default Chats;