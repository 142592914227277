import React, {useEffect, useState} from 'react';
import {
    fileUpload,
    getData,
    getFormDataPost, remove_item_of_arr_with_id,
    setTitle
} from "../../../../assets/scripts/GeneralFunctions";
import {MainContent} from "../style";
import {ArticleFile, BannerFile, BannerFileMini, Inputs} from "../../Child/Supplier/Child/style";
import {MAIN_URL, MAIN_URL_IMAGE} from "../../../../assets/scripts/GeneralVariables";
import {Images} from "../../../../assets/scripts/Images";
import CkEditorText from "./CkEditorText";
import TagsInput from "react-tagsinput";
import {toast} from "react-toastify";
import Swal from "sweetalert2";
import {ProgressBar} from "react-bootstrap";
import axios from "axios";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import Flags from "../../../utils/Flags";

function Archive(props) {

    const [inputs, setInputs] = useState({
        title: '',
        description: '',
        content: '',
        tags: [],
        article_file_api: '',
        article_file_url: '',
        article_file: '',
        slug: '',
        id: null,
        en_null: false,
        locale: 'fa',
        galleries: [],
        companies: [],
        company_selected: [],
    })
    const [error, setError] = useState({})
    const [progress, setProgress] = useState()

    useEffect(async () => {
        setTitle('آذر فولاد امین | جزئیات خبر')
        let article_id = props.match.params.article_id;
        let api_result = await getData(MAIN_URL, `blog-management/posts/info/${article_id}`, 'get', {}, true, true);
        if (api_result?.status === 200) {
            let data = api_result?.data?.post

            let companies_arr = []

            for(let i = 0; i< data?.related_about_companies?.length; i++){
                companies_arr.push(data?.related_about_companies[i].id)
            }

            setInputs(prevState => ({
                ...prevState,
                locale: data?.locale,
                title: data?.title,
                article_file_api: data?.image,
                description: data?.description,
                content: data?.content,
                tags: data?.tags,
                slug: article_id,
                id: data?.id,
                galleries: data?.galleries,
                uploading_file_list: [],
                uploading_file_list_base64: [],
                companies : api_result?.data?.about_companies,
                company_selected : companies_arr
            }))
        }
    }, [])

    const thisUploadDocs = async (e) => {
        let files = e.target.files;
        let file = URL.createObjectURL(await fileUpload(files[0]))
        setInputs(prevState => ({
            ...prevState,
            [e.target.name + '_url']: file,
            [e.target.name + '_file']: e.target.files[0]
        }))
    }

    const removeImage = (val) => {
        setInputs(prevState => ({
            ...prevState, [val]: null, [val + '_url']: null, [val + '_file']: null, [val + '_api']: null
        }))
    }

    const handleChange = (tags) => {
        setInputs(prevState => ({...prevState, tags: tags}))
    }

    const setData = (value) => {
        setInputs(prevState => ({...prevState, content: value}))
    }

    const handleLanguage = async (lang) => {
        let api_result = await getData(MAIN_URL, `blog-management/posts/info/${inputs?.slug}/${lang?.value}`, 'get', {}, true, true);
        if (api_result?.status === 200) {
            let data = api_result?.data?.post
            if (data !== null) {
                setInputs(prevState => ({
                    ...prevState,
                    locale: lang?.value,
                    title: data?.title,
                    article_file_api: data?.image,
                    description: data?.description,
                    content: data?.content,
                    tags: data?.tags,
                }))
            } else {
                setInputs(prevState => ({
                    ...prevState,
                    locale: lang?.value,
                    title: '',
                    article_file_api: '',
                    description: '',
                    content: '',
                    tags: [],
                    en_null: true
                }))
            }
        }
    }

    const checkBoxHandler = (id) => {
        let selected = [];
        if (inputs?.company_selected?.length > 0) {
            selected = inputs?.company_selected
        }
        let find = selected?.indexOf(id)

        if (find > -1) {
            selected?.splice(find, 1)
        } else {
            selected?.push(id)
        }
        setInputs(prevState => ({...prevState, company_selected: selected}))
    }

    const formHandler = async (e) => {
        e.preventDefault()
        let formData = new FormData()

        formData.append(`title`, inputs?.title)

        if (inputs?.article_file_file !== undefined) {
            formData.append(`image`, inputs?.article_file_file)
        }

        formData.append(`description`, inputs?.description)

        formData.append(`content`, inputs?.content)

        for (let i = 0; i < inputs?.tags?.length; i++) {
            formData.append(`tags[${i}]`, inputs?.tags[i])
        }

        for (let i = 0; i < inputs?.company_selected?.length; i++) {
            formData.append(`related_about_companies[${i}]`, inputs?.company_selected[i])
        }

        let api_result = inputs?.en_null ? await getFormDataPost(`blog-management/posts/create/${inputs?.locale}/${inputs?.id}`, formData) : await getFormDataPost(`blog-management/posts/edit/${inputs?.slug}`, formData)

        if (api_result?.status === 200) {
            setError('')
            // history.push({
            //     pathname: '/manage-blog',
            // })
            toast.success('با موفقیت ثبت شد')
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
        }
    }

    // Upload File
    const uploadBlogGalleryFiles = async (e) => {
        if (e.target.files) {
            let files = e.target.files;
            let fileBase64 = URL.createObjectURL(files[0]);
            let authToken = localStorage.getItem('AFAToken');
            let file_type = files[0]?.type?.split('/')[0];
            let formData = new FormData()
            formData.append("file", files[0])
            formData.append("type", file_type === 'image' ? 'photo' : 'video')
            let article_id = props.match.params.article_id;
            axios.post(`${MAIN_URL}blog-management/posts/add-gallery/${article_id}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${authToken}`,
                },
                onUploadProgress: data => {
                    //Set the progress value to show the progress bar
                    setProgress(Math.round((100 * data.loaded) / data.total))
                },
            }).then(response => {
                setInputs(prevState => ({
                    ...prevState,
                    // let uploading_file_list_base64 = []
                    uploading_file_list_base64: [...inputs?.uploading_file_list_base64, {
                        'file': fileBase64,
                        'type': file_type,
                        'id': response?.data?.post_gallery.id,
                    }],
                }))
                setProgress()
                setError({})
            }).catch(e => {
                if (e.response?.['status'] === 400) {
                    setError(e.response?.data['errors'])
                    setProgress()
                }
            })
        }
    }

// Remove File
    const handleRemoveBlogGalley = async (id, title) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این مورد را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                let remove_result = await getData(MAIN_URL, `blog-management/posts/remove-gallery/${id}`, 'post', {}, true, false);
                if (remove_result?.status === 200) {
                    if (title === 'base64') {
                        let arr = inputs['uploading_file_list_base64'];
                        let new_arr = remove_item_of_arr_with_id(arr, id)
                        setInputs(prevState => ({...prevState, uploading_file_list_base64: new_arr}))
                    } else {
                        let arr = inputs['galleries'];
                        let new_arr = remove_item_of_arr_with_id(arr, id)
                        setInputs(prevState => ({...prevState, galleries: new_arr}))
                    }
                    swalWithBootstrapButtons.fire(
                        'حذف شد!',
                        'با موفقیت حذف شد',
                        'success'
                    )
                }
            }
        })
    }

    return (
        <MainContent>
            <form onSubmit={formHandler} className='row'>
                <div
                    className="col-12 w-100 d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center mb-3 position-relative">
                    <h6 className='text-red fw-bold'>ویرایش محتوا</h6>
                    <Flags handleLanguage={handleLanguage}/>
                </div>
                <div className="col-12 col-md-8 mb-3">
                    <div className="row px-0">
                        <div className="col-12 mb-4">
                            <Inputs>
                                <span>عنوان محتوا</span>
                                <input
                                    className={'afa-error-input'}
                                    type="text" name={`title`}
                                    value={inputs?.[`title`]}
                                    placeholder={'عنوان محتوا'}
                                    onChange={(e) =>
                                        setInputs(prevState => ({
                                            ...prevState,
                                            [`title`]: e.target.value
                                        }))
                                    }/>
                            </Inputs>
                            <p className='afa-error-text'>{error['title']}</p>
                        </div>
                        <div className="col-12 mb-4">
                            <Inputs>
                                <span>توضیحات کوتاه محتوا</span>
                                <textarea
                                    rows={3}
                                    className={'afa-error-input'}
                                    name={`description`}
                                    placeholder={'توضیحات کوتاه محتوا'}
                                    value={inputs?.[`description`]}
                                    onChange={(e) =>
                                        setInputs(prevState => ({
                                            ...prevState,
                                            [`description`]: e.target.value
                                        }))
                                    }/>
                            </Inputs>
                            <p className='afa-error-text'>{error['description']}</p>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4 mb-3">
                    <div className="afa-upload-file-parent">
                        <span className='f-14'>تصویر محتوا</span>
                        <ArticleFile
                            className={error[`article_file`] && 'afa-error-input'}>
                            {
                                (inputs[`article_file_url`] || inputs[`article_file_api`]) ?
                                    <div className="afa-img">
                                        <img
                                            src={inputs[`article_file_api`] ? `${MAIN_URL_IMAGE}${inputs[`article_file_api`]}` : inputs[`article_file_url`]}
                                            alt="آذر فولاد امین"/>
                                        <span
                                            className='afa-remove-position icon-delete'
                                            onClick={() => removeImage(`article_file`)}/>
                                    </div> :
                                    <label
                                        className="afa_upload_file d-flex flex-column align-items-center justify-content-center">
                                        <img src={Images?.article_upload?.default}
                                             className='afa-article-image-to-upload' alt="آذر فولاد امین"/>
                                        <input type="file" accept="image/*"
                                               onChange={thisUploadDocs}
                                               name={`article_file`}
                                               className='dv-upload-file'/>
                                        {
                                            (!inputs[`article_file_api`] || !inputs[`article_file_url`]) &&
                                            <span className='upload-text'>اپلود عکس مربوط به محتوا</span>
                                        }
                                    </label>
                            }
                        </ArticleFile>
                        <p className='afa-error-text'>{error[`image`]}</p>
                    </div>
                </div>
                <div className="col-12 mb-3">
                    <span className="f-14">توضیحات کامل محتوا</span>
                    <CkEditorText data={inputs?.content} setData={setData}/>
                    <p className='afa-error-text'>{error['content']}</p>
                </div>
                <div className="col-12 mb-4">
                    <span className='f-14 mb-2'>افزودن تگ</span>
                    <TagsInput value={inputs?.tags} onChange={handleChange}/>
                    <p className='afa-error-text'>{error['tags']}</p>
                </div>

                <div className="col-12 mb-4">
                    <span className='f-14 mb-2'>مرتبط به بخش</span>
                    <Accordion className='dv-filter-drop-down-items dv-article-dropdown' allowZeroExpanded>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <span>انتخاب شرکت</span>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div className="d-flex flex-column w-100">
                                    {
                                        inputs?.companies?.map((item, index) => (
                                            <div className="py-2" key={index}>
                                                <label className="afa-custom-checkbox">{item?.display_title?.fa}
                                                    <input type="checkbox" name='certificate_type'
                                                           value={item?.id}
                                                           onChange={() => checkBoxHandler(item?.id)}
                                                           checked={inputs.company_selected?.includes(item?.id)}
                                                    />
                                                    <span className="checkmark2"></span>
                                                </label>
                                            </div>
                                        ))
                                    }
                                </div>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                    <p className='afa-error-text'>{error['related_about_companies']}</p>
                </div>

                <div className="col-12 d-flex align-items-center justify-content-center mb-4">
                    <Inputs>
                        <button type='submit'
                                className='m-auto afa-save-super-admin-btn'>
                            <span className='pr-3'>ذخیره</span>
                        </button>
                    </Inputs>
                </div>
            </form>

            <div className={'row'}>
                <div className="col-12 mb-3">
                    <span className='f-14 mb-2'>تصاویر و ویدیو های مربوط به این بلاگ</span>
                    <div className="row px-0">
                        <div className="col-12 col-md-6 col-lg-3 mb-3 d-flex justify-content-start">
                            <div className="afa-upload-file-parent w-100"
                                 style={{marginLeft: 'auto', marginRight: '0'}}>
                                {
                                    progress ?
                                        <div
                                            className={'d-flex flex-column align-items-center justify-content-center w-100 h-100'}>
                                            <ProgressBar className={'mt-3 w-100'} now={progress}
                                                         label={`${progress}%`}
                                                         variant="danger" animated/>
                                            <h6 className={'pt-2 text-center'}>در حال بارگزاری</h6>
                                        </div> :
                                        <BannerFileMini style={{height: '100%'}}
                                                        className={error[`image`] && 'afa-error-input'}>
                                            <label
                                                className="afa_upload_file d-flex flex-column align-items-center justify-content-center h-100">
                                                <img src={Images?.article_upload?.default}
                                                     className='afa-article-image-to-upload'
                                                     alt="آذر فولاد امین"/>
                                                <input type="file" accept="video/*,image/*"
                                                       onChange={uploadBlogGalleryFiles}
                                                       name={`photos_image`} className='dv-upload-file'/>

                                                <span className='upload-text'>بارگزاری</span>

                                            </label>
                                        </BannerFileMini>
                                }
                                <p className='afa-error-text'>{error[`file`]}</p>
                                <p className='afa-error-text'>{error[`type`]}</p>
                            </div>
                        </div>
                        {
                            inputs?.uploading_file_list_base64?.length > 0 &&
                            inputs?.uploading_file_list_base64?.map((row, index) => (
                                <div className="col-12 col-md-6 col-lg-3 mb-3" key={index}>
                                    <div className="afa-upload-file-parent me-0 ms-auto">
                                        <BannerFile>
                                            <div className="afa-img d-flex align-items-center">
                                                {
                                                    row?.type === 'video' ?
                                                        <video
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            autoPlay={true}
                                                            muted={true}
                                                            controls
                                                        >
                                                            <source src={row.file}/>
                                                        </video>
                                                        :
                                                        <img
                                                            src={row.file}
                                                            alt="آذر فولاد امین"/>
                                                }
                                                <span
                                                    className='afa-remove-position icon-delete'
                                                    onClick={() => handleRemoveBlogGalley(row.id, 'base64')}/>
                                            </div>
                                        </BannerFile>
                                    </div>
                                </div>
                            ))
                        }

                        {
                            inputs?.galleries?.length > 0 &&
                            inputs?.galleries?.map((row, index) => (
                                <div className="col-12 col-md-6 col-lg-3 mb-3" key={index}>
                                    <div className="afa-upload-file-parent me-0 ms-auto">
                                        <BannerFile>
                                            <div className="afa-img d-flex align-items-center">
                                                {
                                                    row?.type === 'video' ?
                                                        <video
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            autoPlay={true}
                                                            muted={true}
                                                            controls
                                                        >
                                                            <source src={`${MAIN_URL_IMAGE}${row.src}`}/>
                                                        </video>
                                                        :
                                                        <img
                                                            src={`${MAIN_URL_IMAGE}${row.src}`}
                                                            alt="آذر فولاد امین"/>
                                                }
                                                <span
                                                    className='afa-remove-position icon-delete'
                                                    onClick={() => handleRemoveBlogGalley(row.id, 'api')}/>
                                            </div>
                                        </BannerFile>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>

        </MainContent>
    );
}

export default Archive;