import React, {useEffect, useState} from 'react';
import {ArticleList, MainContent} from "../style";
import {SearchInput} from "../../Child/SupplierMangement/style";
import {Link} from "react-router-dom";
import {getData,setTitle,} from "../../../../assets/scripts/GeneralFunctions";
import {MAIN_URL} from "../../../../assets/scripts/GeneralVariables";
import Skeleton from 'react-loading-skeleton'

function CommentsAndComplaints() {
    const [inputs, setInputs] = useState({
        search_value: '', contacts: [NaN, NaN, NaN, NaN], locale: 'fa'
    })

    const [is_all_items_load, setAllLoad] = useState(true)

    const [data, setObject] = useState({
        'offset': 0,
        'limit': 30,
    })

    useEffect(async () => {
        setTitle('آذر فولاد امین | پیشنهادات و شکایات')
        let api_result = await getData(MAIN_URL, `contacts/contact/list`, 'get', data, true, false);
        if (api_result?.status === 200) {
            setInputs(prevState => ({
                ...prevState,
                contacts: api_result?.data?.contacts,
            }))
        }
    }, []);

    const searchHandler = async (e) => {
        let object = {};
        object = data;
        object['search'] = e.target.value
        setInputs(prevState => ({
            ...prevState,
            search_value: e.target.value,
            contacts: [NaN, NaN, NaN, NaN],
        }))
        let api_result = await getData(MAIN_URL, `contacts/contact/list`, 'get', data, true, false);
        if (api_result?.status === 200) {
            setInputs(prevState => ({
                ...prevState,
                contacts: api_result?.data?.contacts,
            }))
            setObject(object)
        }
    }

    // lazy load
    const getTableDataLazyLoad = async (obj) => {
        if (parseFloat(obj.target.offsetHeight + obj.target.scrollTop) == parseFloat(obj.target.scrollHeight)) {
            if (inputs?.contacts?.length % 30 === 0 && is_all_items_load === true) {
                let object = {};
                object = data;
                object['offset'] = inputs?.contacts?.length
                let moreData = await getData(MAIN_URL, `contacts/contact/list`, 'get', object, true, true);
                if (moreData) {
                    setObject(obj)
                    setInputs(prevState => ({
                        ...prevState,
                        contacts: inputs?.contacts.concat(moreData.data?.contacts)
                    }))
                    if (moreData.data?.contacts?.length < 30) {
                        setAllLoad({is_all_items_load: false})
                    }
                }
            }
        }
    }

    return (
        <MainContent>
            <div className='row'>
                <div className="col-12 col-md-8 mb-3">
                    <h6 className='text-red fw-bold'>پیشنهادات و شکایات</h6>
                </div>
                <div className="col-12 col-md-4 mb-3">
                    <SearchInput className='mb-3 w-100' style={{maxWidth: '100%'}}>
                        <span className='icon-Search dv-search-icon'/>
                        <input type="search" value={inputs?.search_value} onChange={searchHandler}
                               placeholder='جستجو'/>
                    </SearchInput>
                </div>
                <div className="col-12 mb-3 afa-lazyload" onScroll={getTableDataLazyLoad}>

                    {
                        inputs?.contacts?.length > 0 ?
                            !inputs?.contacts[0] ?
                                inputs?.contacts?.map((row, index) => (
                                    <>
                                        <Skeleton key={index} borderRadius={8} height={55}/>
                                    </>
                                )) :
                                inputs?.contacts?.map((row) => (
                                    <ArticleList className='row align-items-center p-4 gb-4' key={row.id}>
                                        <div className="col-12 col-md-11 mb-3">
                                            <div className="row g-4 px-0">
                                                <span className='col-12 col-md-6'>نوع ارتباط : <span>{row?.reason}</span></span>
                                                <span className='col-12 col-md-6'>نام و نام خانوادگی : <span>{row?.name}</span></span>
                                                <span className='col-12 col-md-6'>نوع گزارش : <span>{row?.type === 'public' ? 'عمومی' : 'محرمانه'}</span></span>
                                                <span className='col-12 col-md-6'>شماره تماس : <span>{row?.phone}</span></span>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-1 d-flex align-items-center justify-content-center">
                                            <Link className={'text link-color'} to={`/comments-complaints/${row.id}`}>مشاهده<span
                                                className="icon-cheveron-left"></span></Link>
                                        </div>
                                    </ArticleList>
                                )) :
                            <p className='text-center'>موردی یافت نشد</p>
                    }
                </div>
            </div>
        </MainContent>
    );
}

export default CommentsAndComplaints;