import React, {useEffect, useState} from 'react';
import {setTitle , getData} from "../../../../assets/scripts/GeneralFunctions";
import {MAIN_URL} from "../../../../assets/scripts/GeneralVariables";
import {MainContent} from "../style";
import {Inputs} from "../../Child/Supplier/Child/style";
import {Link} from "react-router-dom";
import Skeleton from 'react-loading-skeleton'

function CommunicationManagementInfo(props) {
    const [inputs, setInputs] = useState({
        full_name: "",
        email: "",
        company_name: "",
        phone: "",
        management_name: '',
        message: "",
    });
    const [loader, setLoader] = useState(true)

    useEffect(async () => {
        setTitle('آذر فولاد امین |ارتباط با مدیریت ')
        let id = props.match.params.id;
        let api_result = await getData(MAIN_URL, `contacts/management/info/${id}`, 'get', {}, true, false);
        if (api_result?.status === 200) {
            setLoader(false)
            setInputs(prevState => ({
                ...prevState,
                full_name: api_result?.data?.contact_management?.full_name,
                email: api_result?.data?.contact_management?.email,
                company_name: api_result?.data?.contact_management?.company_name,
                phone: api_result?.data?.contact_management?.phone,
                management_name: api_result?.data?.contact_management?.management?.title,
                message: api_result?.data?.contact_management?.message,
            }))
        }
    }, []);

    return (
        <MainContent>
            <div className='row gx-3 gy-4'>
                <div
                    className="col-12 w-100 d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center mb-3">
                    <h6 className='text-red fw-bold'>ارتباط با مدیریت</h6>
                </div>

                <div className="col-12 col-md-6">
                    <Inputs>
                        <span>نام و نام خانوادگی</span>
                        {
                            loader ?
                                <Skeleton borderRadius={8} height={55}/> :
                                <input
                                    readOnly={true}
                                    className={'afa-error-input'}
                                    type="text" name={`full_name`}
                                    value={inputs?.[`full_name`]}
                                />
                        }
                    </Inputs>
                </div>

                <div className="col-12 col-md-6">
                    <Inputs>
                        <span>آدرس ایمیل</span>
                        {
                            loader ?
                                <Skeleton borderRadius={8} height={55}/> :
                                <input
                                    readOnly={true}
                                    className={'afa-error-input'}
                                    type="email" name={`email`}
                                    value={inputs?.[`email`]}
                                />
                        }
                    </Inputs>
                </div>

                <div className="col-12 col-md-6 col-lg-4">
                    <Inputs>
                        <span>نام شرکت</span>
                        {
                            loader ?
                                <Skeleton borderRadius={8} height={55}/> :
                                <input
                                    readOnly={true}
                                    className={'afa-error-input'}
                                    type="text" name={`company_name`}
                                    value={inputs?.[`company_name`]}
                                />
                        }
                    </Inputs>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                    <Inputs>
                        <span>شماره تماس</span>
                        {
                            loader ?
                                <Skeleton borderRadius={8} height={55}/> :
                                <input
                                    readOnly={true}
                                    className={'afa-error-input text-left'}
                                    type="number" name={`phone`}
                                    value={inputs?.[`phone`]}
                                />
                        }
                    </Inputs>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                    <Inputs>
                        <span>ارسال پیام به</span>
                        {
                            loader ?
                                <Skeleton borderRadius={8} height={55}/> :
                                <input
                                    readOnly={true}
                                    className={'afa-error-input text-left'}
                                    type="text" name={`management_name`}
                                    value={inputs?.[`management_name`]}
                                />
                        }
                    </Inputs>
                </div>

                <div className="col-12">
                    <Inputs>
                        <span>پیام</span>
                        {
                            loader ?
                                <Skeleton borderRadius={8} height={100}/> :
                                <textarea
                                    readOnly={true}
                                    className={inputs['message'] && 'afa-error-input'}
                                    rows="3"
                                    name={`message`}
                                    value={inputs?.[`message`]}
                                />
                        }
                    </Inputs>
                </div>


                <div className="col-12 d-flex align-items-center justify-content-center mb-4">
                    <Link to={'/communication-management'} className={'dv-back-to-parent-btn rounded-pill'}>
                        <span className='pr-3'>بازگشت به لیست فرم ها</span>
                    </Link>
                </div>
            </div>
        </MainContent>
    );
}

export default CommunicationManagementInfo;