import React, {useState, useEffect} from 'react';
import {MainContent} from "../style";
import {Images} from "../../../../assets/scripts/Images";
import {ArticleFile, Inputs} from "../../Child/Supplier/Child/style";
import {MAIN_URL, MAIN_URL_IMAGE} from "../../../../assets/scripts/GeneralVariables";
import {
    fileUpload, getData,
    getFormDataPost,
    setTitle
} from "../../../../assets/scripts/GeneralFunctions";

import 'react-tagsinput/react-tagsinput.css'
import {toast} from "react-toastify";
import Flags from "../../../utils/Flags";

function AboutUs(props) {
    const [inputs, setInputs] = useState({
        azar_foulad_amin : {},
        foulad_amir_azarbaijan : {},
        azar_sakht_artin : {},
        company_id : null,
        company_title : null,
        companies : [],
        about_image_file : '',
        about_image_url : '',
    })
    const [error, setError] = useState({})
    const [locale, setLocale] = useState('fa')

    useEffect(() => {
        async function fetchData() {
            setTitle('آذر فولاد امین | درباره ما')
            let api_result = await getData(MAIN_URL, `site-management/about-companies`, 'get', {}, true, true);
            if (api_result?.status === 200) {
                setInputs(prevState => ({
                    ...prevState,
                    azar_foulad_amin : api_result?.data?.azar_foulad_amin,
                    foulad_amir_azarbaijan : api_result?.data?.foulad_amir_azarbaijan,
                    azar_sakht_artin : api_result?.data?.azar_sakht_artin,
                    companies : api_result?.data?.list,
                    company_id : api_result?.data?.list[0]?.id,
                    company_title : api_result?.data?.list[0]?.title,
                    about_image_url : api_result?.data?.[`${api_result?.data?.list[0]?.title}`]?.image,
                    about_image_file : '',
                }))
            }
        }
        fetchData()
    }, []);

    const removeImage = (val) => {
        setInputs(prevState => ({
            ...prevState, [val]: null, [val + '_url']: null, [val + '_file']: null,
        }))
    }

    const thisUploadDocs = async (e) => {
        let files = e.target.files;
        let file = URL.createObjectURL(await fileUpload(files[0]))
        setInputs(prevState => ({
            ...prevState,
            [e.target.name]: file,
            [e.target.name + '_file']: e.target.files[0]
        }))
    }

    const handleLanguage = (locale) => {
        setLocale(locale?.value)
    }

    const inputHandler = (e) => {
        let obj_changes = inputs?.[`${inputs?.company_title}`];
        let name = e.target.name
        if (obj_changes?.[`${name}`]) {
            obj_changes[`${name}`][`${locale}`] = e.target.value
        }
        setInputs(prevState => ({
            ...prevState,
            [`${inputs?.company_title}`]: obj_changes
        }))
    }

    const customerCountHandler = (e) => {
        let obj_changes = inputs?.[`${inputs?.company_title}`];
        let name = e.target.name
        obj_changes[`${name}`] = e.target.value
        setInputs(prevState => ({
            ...prevState,
            [`${inputs?.company_title}`]: obj_changes
        }))
    }

    const companyTitleHandler = (e) => {
        let company = inputs?.companies.find(company => company.id === parseInt(e.target.value))
        setInputs(prevState => ({
            ...prevState,
            company_id: e.target.value,
            company_title: company?.title,
            about_image_file : "",
            about_image_url : inputs?.[`${company?.title}`]?.image,
        }))
    }

    const formHandler = async (e) => {
        e.preventDefault()

        let formData = new FormData()
        formData.append(`display_title[en]`, inputs?.[`${inputs?.company_title}`]?.display_title?.en)
        formData.append(`display_title[fa]`, inputs?.[`${inputs?.company_title}`]?.display_title?.fa)
        formData.append(`display_title[ar]`, inputs?.[`${inputs?.company_title}`]?.display_title?.ar)
        formData.append(`display_title[tr]`, inputs?.[`${inputs?.company_title}`]?.display_title?.tr)
        formData.append(`description[en]`, inputs?.[`${inputs?.company_title}`]?.description?.en)
        formData.append(`description[fa]`, inputs?.[`${inputs?.company_title}`]?.description?.fa)
        formData.append(`description[ar]`, inputs?.[`${inputs?.company_title}`]?.description?.ar)
        formData.append(`description[tr]`, inputs?.[`${inputs?.company_title}`]?.description?.tr)
        formData.append(`internal_customer_count`, inputs?.[`${inputs?.company_title}`]?.internal_customer_count)
        formData.append(`foreign_customer_count`, inputs?.[`${inputs?.company_title}`]?.foreign_customer_count)
        formData.append(`successful_project_count`, inputs?.[`${inputs?.company_title}`]?.successful_project_count)
        if(inputs?.about_image_file){
            formData.append(`image`, inputs?.about_image_file)
        }


        let api_result = await getFormDataPost(`site-management/about-companies/edit/${inputs?.company_id}`, formData)

        if (api_result?.status === 200) {
            setError('')
            toast.success('با موفقیت ثبت شد')
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
        }
    }

    return (
        <MainContent>
            <form onSubmit={formHandler} className='row'>
                <div className="col-12 col-sm-6 col-lg-6 mb-3"><h6 className='text-red fw-bold'>درباره ما</h6></div>
                <div className="col-12 col-sm-6 col-lg-6 mb-3 dv-about-us-flag">
                    <Flags handleLanguage={handleLanguage}/>
                </div>

                <div className="col-12 col-md-8 mb-4">
                    <div className="row px-0">
                        <div className="col-12 mb-4">
                            <Inputs>
                                <span>عنوان بخش</span>
                                <select
                                    className={'afa-error-input'}
                                    name={`company_id`}
                                    value={inputs?.[`company_id`]}
                                    onChange={companyTitleHandler}>
                                    <option value="انتخاب کنید" disabled={true}>انتخاب کنید</option>
                                    {
                                        inputs?.companies?.map((company)=>(
                                            <option key={company.id} value={company?.id}>{company?.title}</option>
                                        ))
                                    }
                                </select>
                                <p className='afa-error-text'>{error[`company_id`]}</p>
                            </Inputs>
                        </div>

                        <div className="col-12 mb-4">
                            <Inputs>
                                <span>عنوان محتوا</span>
                                <input
                                    className={'afa-error-input'}
                                    name={`display_title`}
                                    value={inputs?.[`${inputs?.company_title}`]?.display_title?.[locale]}
                                    onChange={inputHandler}/>
                                <p className='afa-error-text'>{error[`display_title?.${[locale]}`]}</p>
                            </Inputs>
                        </div>
                        <div className="col-12 mb-4 dv-about-text-editor">
                            <Inputs>
                                <span className="f-14">توضیحات</span>
                                <textarea rows={'6'}
                                    className={'afa-error-input'}
                                    name={`description`}
                                    value={inputs?.[`${inputs?.company_title}`]?.description?.[locale]}
                                    onChange={inputHandler}/>
                                <p className='afa-error-text'>{error[`description?.${[locale]}`]}</p>
                            </Inputs>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4 mb-4">
                    <div className="afa-upload-file-parent">
                        <span className='f-14'>تصویر</span>
                        <ArticleFile
                            className={error[`image`] && 'afa-error-input'}>
                            {
                                inputs[`about_image_file`] ?
                                    <div className="afa-img">
                                        <img
                                            src={inputs[`about_image`]}
                                            alt="آذر فولاد امین"/>
                                        <span
                                            className='afa-remove-position icon-delete'
                                            onClick={() => removeImage(`about_image`)}/>
                                    </div> : inputs[`about_image_url`] ?
                                        <div className="afa-img">
                                            <img
                                                src={`${MAIN_URL_IMAGE}${inputs[`about_image_url`]}`}
                                                alt="آذر فولاد امین"/>
                                            <span
                                                className='afa-remove-position icon-delete'
                                                onClick={() => removeImage(`about_image`)}/>
                                        </div> :
                                    <label
                                        className="afa_upload_file d-flex flex-column align-items-center justify-content-center">
                                        <img src={Images?.article_upload?.default} className='afa-article-image-to-upload' alt="آذر فولاد امین"/>
                                        <input type="file" accept="image/*" onChange={thisUploadDocs} name={`about_image`} className='dv-upload-file'/>
                                        {
                                            !inputs[`about_image_file`] &&
                                            <span className='upload-text'>اپلود عکس مربوط به محتوا</span>
                                        }
                                    </label>
                            }
                        </ArticleFile>
                        <p className='afa-error-text'>{error[`image`]}</p>
                    </div>
                </div>

                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>تعداد مشتریان خارچی</span>
                        <input
                            className={'afa-error-input'}
                            maxLength={75}
                            type="text" name={`foreign_customer_count`}
                            value={inputs?.[`${inputs?.company_title}`]?.[`foreign_customer_count`]}
                            onChange={customerCountHandler}/>
                    </Inputs>
                    <p className='afa-error-text'>{error['foreign_customer_count']}</p>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>تعداد مشتریان داخلی</span>
                        <input
                            className={'afa-error-input'}
                            maxLength={75}
                            type="text" name={`internal_customer_count`}
                            value={inputs?.[`${inputs?.company_title}`]?.[`internal_customer_count`]}
                            onChange={customerCountHandler}/>
                    </Inputs>
                    <p className='afa-error-text'>{error['internal_customer_count']}</p>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>تعداد پروژه های موفق</span>
                        <input
                            className={'afa-error-input'}
                            maxLength={75}
                            type="text" name={`successful_project_count`}
                            value={inputs?.[`${inputs?.company_title}`]?.[`successful_project_count`]}
                            onChange={customerCountHandler}/>
                    </Inputs>
                    <p className='afa-error-text'>{error['successful_project_count']}</p>
                </div>

                <div className="col-12 d-flex align-items-center justify-content-center mb-4">
                    <Inputs>
                        <button type='submit'
                                className='m-auto afa-save-super-admin-btn'>
                            <span className='pr-3'>ذخیره</span>
                        </button>
                    </Inputs>
                </div>
            </form>
        </MainContent>
    );
}

export default AboutUs;