import React, {useState, useEffect} from 'react';
import {EditNumber, LeftContent, ConfirmCodeDiv, LoginTheme, RightContent} from "./styles";
import {fixNumber, getData, mobileRegex} from "../../assets/scripts/GeneralFunctions";
import Swal from "sweetalert2";
import {Images} from "../../assets/scripts/Images";
import {Link} from "react-router-dom";
import {CountdownCircleTimer} from 'react-countdown-circle-timer';
import ReactCodeInput from 'react-verification-code-input';
import {useHistory, useLocation} from "react-router";
import {MAIN_URL} from "../../assets/scripts/GeneralVariables";
import {LoopCircleLoading} from "react-loadingg";

function VerifyCode(props) {
    const [code, setCode] = useState(null);
    const history = useHistory()
    let location = useLocation();
    const [duration_time, setDuration] = useState(location.state?.time);
    const [loader, setLoader] = useState(false)
    useEffect(() => {
        if (duration_time > 0) {
            setTimeout(() => setDuration(duration_time - 1), 1000);
        }
    });

    const setTimeAgain = async () => {
        if (location.state?.user_type === 'legal') {
            setLoader(true)
            let api_result = await getData(MAIN_URL, "sign-in", 'post', {
                type: 'حقوقی',
                entity_identification_code: location.state.entity_identification_code
            }, null, false);
            if (api_result.status === 200) {
                setLoader(false)
                history.push({
                    pathname: '/verify_code',
                    state: {
                        time: api_result.data['remaining'],
                        user_type: 'legal',
                        mobile: api_result.data['mobile'],
                        entity_identification_code: location.state.entity_identification_code
                    }
                })
            }
        } else {
            setLoader(true)
            let api_result = await getData(MAIN_URL, "sign-in", 'post', {
                type: 'حقیقی',
                individual_identification_code: location.state.individual_identification_code
            }, null, false);
            if (api_result.status === 200) {
                setLoader(false)
                history.push({
                    pathname: '/verify_code',
                    state: {
                        time: api_result.data['remaining'],
                        user_type: 'real',
                        mobile: api_result.data['mobile'],
                        individual_identification_code: location.state.individual_identification_code
                    }
                })
            }
        }
    }

    const backToLogin = () => {
        location.state?.user_type === 'legal' ?
            history.push({
                pathname: '/login',
                state: {user_type: 'legal', entity_identification_code: location.state.entity_identification_code}
            }) :
            history.push({
                pathname: '/login',
                state: {
                    user_type: 'real',
                    individual_identification_code: location.state.individual_identification_code
                }
            })
    }


    const formHandler = async (e) => {
        e.preventDefault()
        setLoader(true)
        let api_result =
            location.state?.user_type === 'legal' ?
                await getData(MAIN_URL, "verify", 'post', {
                    type: 'حقوقی',
                    entity_identification_code: location.state?.entity_identification_code,
                    verify_code: code
                }, null, false) :
                await getData(MAIN_URL, "verify", 'post', {
                    type: 'حقیقی',
                    individual_identification_code: location.state?.individual_identification_code,
                    verify_code: code
                }, null, false);
        if (api_result?.status === 200) {
            setLoader(false)
            localStorage.setItem('AFAToken', api_result?.data.token)
            window.location.href = '/profile'
            // history.push({
            //     pathname: '/profile',
            // pathname: '/admin',
            // state: {time: api_result.data['remaining'], user_type: 'legal'}
            // })
        } else {
            setLoader(false)
        }

    }
    return (
        <>
            <LoginTheme>
                <RightContent>
                    <img src={Images.main_logo.default} alt="آذر فولاد امین"/>
                    <h6>ورود به حساب کاربری</h6>
                    <ConfirmCodeDiv onSubmit={formHandler}>
                        <EditNumber>
                            <p>کد فعالسازی به شماره زیر ارسال شد.</p>
                            <div>
                                <p className='f-14 text-red k-cursor-pointer' onClick={backToLogin}>ویرایش </p>
                                <p>{location.state?.mobile}</p>
                            </div>
                        </EditNumber>
                        <div className=" my-4 mx-auto f-20">
                            <CountdownCircleTimer
                                className={'m-auto'}
                                isPlaying
                                duration={duration_time}
                                strokeWidth={3}
                                size={50}
                                // onComplete={() => [true, 1000]}
                                onComplete={() => {
                                    return [true, 1000]
                                }}
                                colors={[
                                    ['#3eba09', 0.33],
                                    ['#F7B801', 0.33],
                                    ['#E30613', 0.33],
                                ]}
                            >
                                {
                                    () => duration_time > 0 ? duration_time :
                                        <div className="timer" >
                                            {
                                                loader ?
                                                    <div className="d-flex align-items-center">
                                                        <LoopCircleLoading/>
                                                        <span>در حال درخواست مجدد</span>
                                                    </div>
                                                    : <span onClick={setTimeAgain} className='dv-again-link'>ارسال مجدد کد فعالسازی</span>
                                            }
                                        </div>
                                }
                            </CountdownCircleTimer>
                        </div>
                        <span>کد فعالسازی</span>
                        <ReactCodeInput
                            className={'ltr w-100'}
                            fields={6}
                            fieldWidth={50}
                            fieldHeight={50}
                            type={'number'}
                            onChange={(e) => setCode(fixNumber(e))}
                        />
                        <div className="position-relative w-100">
                            <button type='submit' disabled={code?.length > 0 ? false : true}>ورود</button>
                            {
                                loader && <LoopCircleLoading/>
                            }
                        </div>
                    </ConfirmCodeDiv>
                </RightContent>
                <LeftContent>
                    <img src={Images.login_left.default} alt="آذر فولاد امین"/>
                </LeftContent>
            </LoginTheme>
        </>
    );
}

export default VerifyCode;