import React, {useState, useEffect} from 'react';
import {
    TabStatusComplete,
    TabStatusNotComplete,
    Inputs,
    LeftIcon,
    FileInputs, TabStatusContent
} from "./style";
import moment from "moment-jalaali";
import {
    checkCodeMelli,
    fileUpload,
    fixNumber,
    just_persian,
    getData,
    getFormDataPost, edit_item_with_id, remove_item_of_arr_with_id
} from "../../../../../assets/scripts/GeneralFunctions";
import {MAIN_URL, MAIN_URL_IMAGE} from "../../../../../assets/scripts/GeneralVariables";
import {DateInput} from "react-hichestan-datetimepicker";
import {Accordion, Card} from "react-bootstrap";
import {toast} from "react-toastify";
import Swal from "sweetalert2";
import {LargeFileInputs} from "../../UserInformation/Child/style";

function WorkExperience(props) {
    const [error, setError] = useState({})
    const [inputs, setInputs] = useState({
        contracting_party: '',//طرف قرارداد
        starting_date: '',//شروع
        ending_date: '',//پایان
        technical_staff_performance: '',//عملکرد کادر فنی
        work_consent_image: '',//عکس
        work_consent_image_file: '',
        work_consent_image_url: '',
        contract_short_description: '',// شرح قرارداد
        remove_work_consent_image: '', // در صورت حذف شدن عکس
        supplier_experience_list: [] // لیست
    })
    const [arrow_item, setArrowItem] = useState({arrow_id: 0, arrow_status: false})
    const [is_render, setRender] = useState(false);
    const [new_collapse, setNewCollapse] = useState(false);
    const [more_data, setMoreData] = useState({sheet_status: {}, isLoad: true});

    useEffect(async () => {
        let api_result = await getData(MAIN_URL, "profile/supplier/supplier-experience", 'get', {}, true, true);
        if (api_result?.status === 200) {
            let data = api_result?.data
            let supplier_experience = data?.supplier_experience
            setInputs(prevState => ({
                ...prevState,
                contracting_party: '',
                starting_date: '',
                ending_date: '',
                technical_staff_performance: '',
                work_consent_image: '',
                contract_short_description: '',
                remove_work_consent_image: '',
                supplier_experience_list: supplier_experience
            }))
            setMoreData({sheet_status: data?.sheet_status, status_colors: data?.status_colors, isLoad: false})
        }
    }, [is_render]);

    const inputHandler = (e) => {
        setInputs(prevState => ({
            ...prevState, [e.target.name]: e.target.value
        }))
    }

    const datePickerHandler = (e) => {
        let today = new Date();
        let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        let selected_date = e.target.value
        if (date.normalize() < selected_date.normalize() + 1) {
            toast.error('تاریخ انتخابی نمیتواند بعد امروز باشد')
            setInputs(prevState => ({
                ...prevState, [e.target.name]: ''
            }))
            return false
        } else {
            let date2 = e.target.value
            setInputs(prevState => ({
                ...prevState, [e.target.name]: date2?.split('T')[0]
            }))
        }
    }

    const changeArrow = (id) => {
        if (arrow_item.arrow_id === id && arrow_item.arrow_status === true) {

            setArrowItem({arrow_id: id, arrow_status: false})

        } else if (arrow_item.arrow_id === id && arrow_item.arrow_status === false) {

            setArrowItem({arrow_id: id, arrow_status: true})

        } else {
            setArrowItem({arrow_id: id, arrow_status: true})
        }

        let data = inputs?.supplier_experience_list?.find((row) => {
            return row.id === id
        })
        if (data) {
            setInputs((prevState => ({
                ...prevState,
                [`contracting_party[${id}]`]: data?.contracting_party,
                [`starting_date[${id}]`]: data?.starting_date,
                [`ending_date[${id}]`]: data?.ending_date,
                [`technical_staff_performance[${id}]`]: data?.technical_staff_performance,
                [`work_consent_image[${id}]`]: data?.work_consent_image,
                [`contract_short_description[${id}]`]: data?.contract_short_description,
            })))
        }
    }

    const removeTab = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این مورد را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                let remove_result = await getData(MAIN_URL, `profile/supplier/supplier-experience/remove/${id}`, 'post', {}, true, true);
                if (remove_result?.status === 200) {
                    swalWithBootstrapButtons.fire(
                        'حذف شد!',
                        'با موفقیت حذف شد',
                        'success'
                    )
                    let arr = inputs?.supplier_experience_list;
                    let new_arr = remove_item_of_arr_with_id(arr, id)
                    setInputs(prevState => ({...prevState, supplier_experience_list: new_arr}))
                }
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'لغو شد',
                    ' بدون تغییر باقی ماند',
                    'error'
                )
            }
        })
    }

    const removeTab2 = async () => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این مورد را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                setNewCollapse(false)
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'لغو شد',
                    ' بدون تغییر باقی ماند',
                    'error'
                )
            }
        })
    }

    const saveEditChange = async (id) => {
        let formData = new FormData()
        if (inputs?.[`contracting_party[${id}]`]) {
            formData.append('contracting_party', inputs?.[`contracting_party[${id}]`])
        }
        if (inputs?.[`contracting_party[${id}]`]) {
            formData.append('contracting_party', inputs?.[`contracting_party[${id}]`])
        }
        if (inputs?.[`starting_date[${id}]`]) {
            formData.append('starting_date', inputs?.[`starting_date[${id}]`])
        }
        if (inputs?.[`ending_date[${id}]`]) {
            formData.append('ending_date', inputs?.[`ending_date[${id}]`])
        }
        if (inputs?.[`technical_staff_performance[${id}]`]) {
            formData.append('technical_staff_performance', inputs?.[`technical_staff_performance[${id}]`])
        }
        if (inputs?.[`work_consent_image_file[${id}]`]) {
            formData.append('work_consent_image', inputs?.[`work_consent_image_file[${id}]`])
        }
        if (inputs?.[`contract_short_description[${id}]`]) {
            formData.append('contract_short_description', inputs?.[`contract_short_description[${id}]`])
        }
        if (inputs?.[`remove_work_consent_image[${id}]`]) {
            formData.append('remove_work_consent_image', inputs?.[`remove_work_consent_image[${id}]`])
        }

        let api_result = await getFormDataPost(`profile/supplier/supplier-experience/${id}`, formData)
        if (api_result?.status === 200) {
            toast.success('با موفقیت تغییر یافت')
            setError('')
            let arrays = edit_item_with_id(inputs?.supplier_experience_list, api_result?.data?.supplier_experience)
            setInputs(prevState => ({...prevState, supplier_experience_list: arrays}))
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }

    const addNewCollapse = () => {
        setInputs(prevState => ({
            ...prevState,
            contracting_party: '',
            starting_date: '',
            ending_date: '',
            technical_staff_performance: '',
            work_consent_image: '',
            contract_short_description: '',
            remove_work_consent_image: '',
        }));
        setNewCollapse(true)
    }

    const saveNewCollapse = async () => {
        let formData = new FormData()
        if (inputs?.[`contracting_party`]) {
            formData.append('contracting_party', inputs?.[`contracting_party`])
        }
        if (inputs?.[`starting_date`]) {
            formData.append('starting_date', inputs?.[`starting_date`])
        }
        if (inputs?.[`ending_date`]) {
            formData.append('ending_date', inputs?.[`ending_date`])
        }
        if (inputs?.[`technical_staff_performance`]) {
            formData.append('technical_staff_performance', inputs?.[`technical_staff_performance`])
        }
        if (inputs?.[`work_consent_image_file`]) {
            formData.append('work_consent_image', inputs?.[`work_consent_image_file`])
        }
        if (inputs?.[`contract_short_description`]) {
            formData.append('contract_short_description', inputs?.[`contract_short_description`])
        }
        let api_result = await getFormDataPost('profile/supplier/supplier-experience', formData)

        if (api_result?.status === 200) {
            toast.success('با موفقیت افزوده شد')
            setError('')

            if (inputs?.supplier_experience_list?.length > 0) {
                let arr = inputs?.supplier_experience_list;
                arr.push(api_result?.data?.supplier_experience)
                setInputs(prevState => ({
                    ...prevState,
                    contracting_party: '',
                    starting_date: '',
                    ending_date: '',
                    technical_staff_performance: '',
                    work_consent_image: '',
                    work_consent_image_file: '',
                    work_consent_image_url: '',
                    contract_short_description: '',
                    remove_work_consent_image: '',
                    supplier_experience_list: arr
                }))
                setNewCollapse(false)
            } else {
                setNewCollapse(false)
                setRender(!is_render)
            }
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }

    const thisUploadDocs = async (e) => {
        let files = e.target.files;
        let file = URL.createObjectURL(await fileUpload(files[0]))
        let upload_file = await fileUpload(files[0]);
        setInputs(prevState => ({
            ...prevState,
            [e.target.name + '_url']: file,
            [e.target.name]: upload_file,
            [e.target.name + '_file']: files[0]
        }))
    }

    const removeImage = (val) => {
        setInputs(prevState => ({
            ...prevState, [val]: null, [val + '_url']: null, [val + '_file']: null, remove_work_consent_image: 1
        }))

        // if (val.search('work_consent_image') > -1) {
        //     setInputs(prevState => ({
        //         ...prevState, remove_identification_image: 1
        //     }))
        // } else {
        //     setInputs(prevState => ({
        //         ...prevState, remove_birth_certificate_image: 1
        //     }))
        // }
    }

    const formHandler = async (e) => {
        e.preventDefault()
        let formData = new FormData()

        if (inputs?.[`contracting_party`]) {
            formData.append('contracting_party', inputs?.[`contracting_party`])
        }
        if (inputs?.[`starting_date`]) {
            formData.append('starting_date', inputs?.[`starting_date`])
        }
        if (inputs?.[`ending_date`]) {
            formData.append('ending_date', inputs?.[`ending_date`])
        }
        if (inputs?.[`technical_staff_performance`]) {
            formData.append('technical_staff_performance', inputs?.[`technical_staff_performance`])
        }
        if (inputs?.[`work_consent_image_file`]) {
            formData.append('work_consent_image', inputs?.[`work_consent_image_file`])
        }
        if (inputs?.[`contract_short_description`]) {
            formData.append('contract_short_description', inputs?.[`contract_short_description`])
        }
        if (inputs?.[`remove_work_consent_image`]) {
            formData.append('remove_work_consent_image', inputs?.[`remove_work_consent_image`])
        }

        let api_result = await getFormDataPost('profile/supplier/supplier-experience', formData)

        if (api_result?.status === 200) {
            toast.success('با موفقیت افزوده شد')
            setError('')

            if (inputs?.supplier_experience_list?.length > 0) {
                let arr = inputs?.supplier_experience_list;
                arr.push(api_result?.data?.supplier_experience)
                setInputs(prevState => ({
                    ...prevState,
                    contracting_party: '',
                    starting_date: '',
                    ending_date: '',
                    technical_staff_performance: '',
                    work_consent_image: '',
                    work_consent_image_file: '',
                    work_consent_image_url: '',
                    contract_short_description: '',
                    remove_work_consent_image: '',
                    supplier_experience_list: arr
                }))
            } else {
                setRender(!is_render)
            }

        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }
    return (
        <>
            {
                !inputs?.["isLoad"] &&
                <>
                    <form onSubmit={formHandler} className="row">
                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                            <TabStatusComplete style={{borderRightColor: props.status_colors?.colors?.border_right}}>
                                {
                                    props.status_value === 'pending' || props.status_value === 'denied' ?
                                        <span style={{
                                            color: props.status_colors?.colors?.color,
                                            backgroundColor: props.status_colors?.colors?.background
                                        }} className='icon-Iconly-Bulk-Info-Square afa-icon-tick'/> :
                                        <span style={{
                                            color: props.status_colors?.colors?.color,
                                            backgroundColor: props.status_colors?.colors?.background
                                        }} className='icon-tick afa-icon-tick'/>
                                }
                                <p className='mb-0 f-14 '><span>وضعیت : </span><span>{props.status_colors?.title}</span>
                                </p>
                            </TabStatusComplete>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-8 mb-4">
                            {
                                more_data.sheet_status?.message &&
                                <TabStatusContent style={{borderRightColor: props.status_colors?.colors?.border_right}}>
                            <span className="icon-Iconly-Bulk-Message afa-icon-message">
                                <span className="path1"></span>
                                <span className="path2"></span>
                            </span>
                                    <p className='mb-0 f-14'>
                                        <span>پیام ادمین : </span><span>{more_data.sheet_status?.message}</span>
                                    </p>
                                </TabStatusContent>
                            }
                        </div>
                        {
                            inputs?.supplier_experience_list?.length > 0 ?
                                <>
                                    <Accordion defaultActiveKey="0">
                                        {
                                            inputs?.supplier_experience_list?.map((item, i) => (
                                                <Card key={i}>
                                                    <Accordion.Toggle as={Card.Header} eventKey={`${item.id}`} key={i}
                                                                      onClick={() => changeArrow(item.id)}>
                                                        <div className="d-flex flex-wrap">
                                                        <span className="icon-Delete dv-icon-delete pl-2 my-2"
                                                              onClick={() => removeTab(item.id)}></span>
                                                            {
                                                                item?.contracting_party && <span
                                                                    className="pl-4 f-14 my-2"> طرف قرارداد : {item?.contracting_party}</span>
                                                            }
                                                            {
                                                                item?.starting_date &&
                                                                <span
                                                                    className="pl-4 f-14 my-2 d-flex align-items-center"> تاریخ شروع : <p
                                                                    className="mb-0 px-2">{moment(item?.starting_date).format('jYYYY-jMM-jDD')}</p></span>
                                                            }
                                                            {
                                                                item?.ending_date &&
                                                                <span
                                                                    className="pl-4 f-14 my-2 d-flex align-items-center"> تاریخ پایان : <p
                                                                    className="px-2 mb-0">{moment(item?.ending_date).format('jYYYY-jMM-jDD')}</p></span>
                                                            }
                                                        </div>
                                                        <span
                                                            className={(arrow_item.arrow_id === item.id && arrow_item.arrow_status) ? "icon-cheveron-up dv-collapse-arrow" : "icon-cheveron-down dv-collapse-arrow"}/>
                                                    </Accordion.Toggle>

                                                    <Accordion.Collapse eventKey={`${item.id}`}>
                                                        <Card.Body className='row'>
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>طرف قرارداد (کارفرما)
                                                                        <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <input
                                                                        className={error[`contracting_party[${item.id}]`] && 'afa-error-input'}
                                                                        type="text"
                                                                        name={`contracting_party[${item.id}]`}
                                                                        value={inputs?.[`contracting_party[${item.id}]`]}
                                                                        onChange={(e) =>
                                                                            setInputs(prevState => ({
                                                                                ...prevState,
                                                                                [`contracting_party[${item.id}]`]: e.target.value
                                                                            }))
                                                                        }/>
                                                                    <p className='afa-error-text'>{error[`contracting_party[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>تاریخ شروع
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <DateInput
                                                                        value={inputs?.[`starting_date[${item.id}]`]}
                                                                        name={`starting_date[${item.id}]`}
                                                                        className={error[`starting_date[${item.id}]`] && 'afa-error-input'}
                                                                        placeholder={'تاریخ شروع را انتخاب کنید'}
                                                                        onChange={datePickerHandler}/>
                                                                    <p className='afa-error-text'>{error[`starting_date[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>تاریخ پایان
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <DateInput
                                                                        value={inputs?.[`ending_date[${item.id}]`]}
                                                                        name={`ending_date[${item.id}]`}
                                                                        className={error[`ending_date[${item.id}]`] && 'afa-error-input'}
                                                                        placeholder={'تاریخ پایان را انتخاب کنید'}
                                                                        onChange={datePickerHandler}/>
                                                                    <p className='afa-error-text'>{error[`ending_date[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>

                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>کفایت کادر فنی
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <input
                                                                        className={error[`technical_staff_performance[${item.id}]`] && 'afa-error-input'}
                                                                        type="text"
                                                                        name={`technical_staff_performance[${item.id}]`}
                                                                        value={inputs?.[`technical_staff_performance[${item.id}]`]}
                                                                        onChange={(e) =>
                                                                            setInputs(prevState => ({
                                                                                ...prevState,
                                                                                [`technical_staff_performance[${item.id}]`]: e.target.value
                                                                            }))
                                                                        }/>
                                                                    <p className='afa-error-text'>{error[`technical_staff_performance[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <span>رضایت نامه کار
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <FileInputs
                                                                    className={error[`work_consent_image[${item.id}]`] && 'afa-error-input'}>
                                                                    {
                                                                        (inputs[`work_consent_image[${item.id}]_url`] || inputs[`work_consent_image[${item.id}]`]) &&
                                                                        <div className="afa-img">
                                                                            <img
                                                                                src={inputs[`work_consent_image[${item.id}]_url`] ? inputs[`work_consent_image[${item.id}]_url`] : `${MAIN_URL_IMAGE}${inputs[`work_consent_image[${item.id}]`]}`}
                                                                                alt="آذر فولاد امین"/>
                                                                            <span
                                                                                className='icon-close-solid afa-remove-position'
                                                                                onClick={() => removeImage(`work_consent_image[${item.id}]`)}/>
                                                                        </div>
                                                                    }
                                                                    <label className="afa-center-pos">
                                                                        <span className='icon-cloud-computing'/>
                                                                        <input type="file" accept="image/*"
                                                                               onChange={thisUploadDocs}
                                                                               name={`work_consent_image[${item.id}]`}
                                                                               className='dv-upload-file'/>
                                                                        {
                                                                            !inputs[`work_consent_image[${item.id}]`] &&
                                                                            <span className='upload-text'>آپلود</span>
                                                                        }
                                                                    </label>
                                                                </FileInputs>
                                                                <p className='afa-error-text'>{error[`work_consent_image[${item.id}]`]}</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>خلاصه شرح قرارداد
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <textarea
                                                                        rows={2}
                                                                        className={error[`contract_short_description[${item.id}]`] && 'afa-error-input'}
                                                                        name={`contract_short_description[${item.id}]`}
                                                                        value={inputs?.[`contract_short_description[${item.id}]`]}
                                                                        onChange={(e) =>
                                                                            setInputs(prevState => ({
                                                                                ...prevState,
                                                                                [`contract_short_description[${item.id}]`]: e.target.value
                                                                            }))
                                                                        }/>
                                                                    <p className='afa-error-text'>{error[`contract_short_description[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>


                                                            <div className="col-12 d-flex justify-content-center">
                                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                    <Inputs>
                                                                        <button type='button'
                                                                                onClick={() => saveEditChange(item.id)}
                                                                                className='afa-add-more-btn'>
                                                                            <span className='pr-3'>ذخیره تغییرات</span>
                                                                        </button>
                                                                    </Inputs>
                                                                </div>
                                                            </div>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            ))
                                        }

                                        {
                                            new_collapse &&
                                            <Card>
                                                <Accordion.Toggle as={Card.Header} eventKey={'0'}
                                                                  onClick={() => changeArrow(0)}>
                                                    <div className="d-flex flex-wrap">
                                                        <span className="icon-Delete dv-icon-delete pl-2 my-2"
                                                              onClick={() => removeTab2('rasoul')}></span>
                                                        {
                                                            inputs?.contracting_party && <span
                                                                className="pl-4 f-14 my-2"> طرف قرارداد : {inputs?.contracting_party}</span>
                                                        }
                                                        {
                                                            inputs?.starting_date &&
                                                            <span className="pl-4 f-14 my-2 d-flex align-items-center"> تاریخ شروع : <p
                                                                className="mb-0 px-2">{moment(inputs?.starting_date).format('jYYYY-jMM-jDD')}</p></span>
                                                        }
                                                        {
                                                            inputs?.ending_date &&
                                                            <span className="pl-4 f-14 my-2 d-flex align-items-center"> تاریخ پایان : <p
                                                                className="px-2 mb-0">{moment(inputs?.ending_date).format('jYYYY-jMM-jDD')}</p></span>
                                                        }
                                                    </div>
                                                    <span
                                                        className={(arrow_item.arrow_id === 0 && arrow_item.arrow_status) ? "icon-cheveron-up dv-collapse-arrow" : "icon-cheveron-down dv-collapse-arrow"}/>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey={'0'}>
                                                    <Card.Body className='row'>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <span>طرف قرارداد (کارفرما)
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <input
                                                                    className={error[`contracting_party`] && 'afa-error-input'}
                                                                    type="text"
                                                                    name={`contracting_party`}
                                                                    value={inputs?.[`contracting_party`]}
                                                                    onChange={(e) =>
                                                                        setInputs(prevState => ({
                                                                            ...prevState,
                                                                            [`contracting_party`]: e.target.value
                                                                        }))
                                                                    }/>
                                                                <p className='afa-error-text'>{error[`contracting_party`]}</p>
                                                            </Inputs>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <span>تاریخ شروع
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <DateInput
                                                                    value={inputs['starting_date']}
                                                                    name={'starting_date'}
                                                                    className={error['starting_date'] && 'afa-error-input'}
                                                                    placeholder={'تاریخ شروع را انتخاب کنید'}
                                                                    onChange={datePickerHandler}/>
                                                                <p className='afa-error-text'>{error['starting_date']}</p>
                                                            </Inputs>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <span>تاریخ پایان
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <DateInput
                                                                    value={inputs['ending_date']}
                                                                    name={'ending_date'}
                                                                    className={error['ending_date'] && 'afa-error-input'}
                                                                    placeholder={'تاریخ پایان را انتخاب کنید'}
                                                                    onChange={datePickerHandler}/>
                                                                <p className='afa-error-text'>{error['ending_date']}</p>
                                                            </Inputs>
                                                        </div>

                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <span>کفایت کادر فنی
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <input
                                                                    className={error[`technical_staff_performance`] && 'afa-error-input'}
                                                                    type="text"
                                                                    name={`technical_staff_performance`}
                                                                    value={inputs?.[`technical_staff_performance`]}
                                                                    onChange={(e) =>
                                                                        setInputs(prevState => ({
                                                                            ...prevState,
                                                                            [`technical_staff_performance`]: e.target.value
                                                                        }))
                                                                    }/>
                                                                <p className='afa-error-text'>{error[`technical_staff_performance`]}</p>
                                                            </Inputs>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <span>رضایت نامه کار
                                                            <span className='dv-required-star'>*</span>
                                                            </span>
                                                            <FileInputs
                                                                className={error[`work_consent_image`] && 'afa-error-input'}>
                                                                {
                                                                    (inputs[`work_consent_image_url`] || inputs[`work_consent_image`]) &&
                                                                    <div className="afa-img">
                                                                        <img
                                                                            src={inputs[`work_consent_image_url`] ? inputs[`work_consent_image_url`] : `${MAIN_URL_IMAGE}${inputs[`work_consent_image`]}`}
                                                                            alt="آذر فولاد امین"/>
                                                                        <span
                                                                            className='icon-close-solid afa-remove-position'
                                                                            onClick={() => removeImage(`work_consent_image`)}/>
                                                                    </div>
                                                                }
                                                                <label className="afa-center-pos">
                                                                    <span className='icon-cloud-computing'/>
                                                                    <input type="file" accept="image/*"
                                                                           onChange={thisUploadDocs}
                                                                           name={`work_consent_image`}
                                                                           className='dv-upload-file'/>
                                                                    {
                                                                        !inputs[`work_consent_image`] &&
                                                                        <span className='upload-text'>آپلود</span>
                                                                    }
                                                                </label>
                                                            </FileInputs>
                                                            <p className='afa-error-text'>{error[`work_consent_image`]}</p>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <span>خلاصه شرح قرارداد
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <textarea
                                                                    rows={2}
                                                                    className={error[`contract_short_description`] && 'afa-error-input'}
                                                                    name={`contract_short_description`}
                                                                    value={inputs?.[`contract_short_description`]}
                                                                    onChange={(e) =>
                                                                        setInputs(prevState => ({
                                                                            ...prevState,
                                                                            [`contract_short_description`]: e.target.value
                                                                        }))
                                                                    }/>
                                                                <p className='afa-error-text'>{error[`contract_short_description`]}</p>
                                                            </Inputs>
                                                        </div>

                                                        <div className="col-12 d-flex justify-content-center">
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <button type='button'
                                                                            onClick={saveNewCollapse}
                                                                            className='afa-add-more-btn'>
                                                                        <span className='pr-3'>ذخیره تغییرات</span>
                                                                    </button>
                                                                </Inputs>
                                                            </div>
                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        }

                                    </Accordion>

                                    <div className="row">
                                        <div className="col-12 col-sm-6 col-lg-8 mb-4"/>

                                        <div className={

                                            inputs['relation'] === 'child' ? "col-12 col-sm-6 col-lg-4 mb-4" : "col-12 col-sm-6 col-lg-4 mb-4 d-flex align-items-center"}>

                                            <Inputs>

                                                <button type='button' onClick={addNewCollapse}
                                                        className='afa-add-more-btn'>

                                                    <span className='icon-add f-20'/>

                                                    <span className='pr-3'>افزودن اطلاعات</span>

                                                </button>

                                            </Inputs>

                                        </div>

                                        {/*<div className="col-12 col-sm-6 col-lg-4 mb-4"/>*/}

                                        {/*<div className="col-12 col-sm-6 col-lg-4 offset-lg-4 mb-4">*/}

                                        {/*    <Inputs>*/}

                                        {/*        <button type='submit'>*/}

                                        {/*            <span>ثبت نهایی</span>*/}

                                        {/*            <LeftIcon><span className='icon-cheveron-left'></span></LeftIcon>*/}

                                        {/*        </button>*/}

                                        {/*    </Inputs>*/}

                                        {/*</div>*/}
                                    </div>
                                </> :
                                <>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <span>طرف قرارداد (کارفرما)
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <input
                                                className={error[`contracting_party`] && 'afa-error-input'}
                                                type="text"
                                                name={`contracting_party`}
                                                value={inputs?.[`contracting_party`]}
                                                onChange={(e) =>
                                                    setInputs(prevState => ({
                                                        ...prevState,
                                                        [`contracting_party`]: e.target.value
                                                    }))
                                                }/>
                                            <p className='afa-error-text'>{error[`contracting_party`]}</p>
                                        </Inputs>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <span>تاریخ شروع
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <DateInput
                                                value={inputs['starting_date']}
                                                name={'starting_date'}
                                                className={error['starting_date'] && 'afa-error-input'}
                                                placeholder={'تاریخ شروع را انتخاب کنید'}
                                                onChange={datePickerHandler}/>
                                            <p className='afa-error-text'>{error['starting_date']}</p>
                                        </Inputs>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <span>تاریخ پایان
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <DateInput
                                                value={inputs['ending_date']}
                                                name={'ending_date'}
                                                className={error['ending_date'] && 'afa-error-input'}
                                                placeholder={'تاریخ پایان را انتخاب کنید'}
                                                onChange={datePickerHandler}/>
                                            <p className='afa-error-text'>{error['ending_date']}</p>
                                        </Inputs>
                                    </div>

                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <span>کفایت کادر فنی
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <input
                                                className={error[`technical_staff_performance`] && 'afa-error-input'}
                                                type="text"
                                                name={`technical_staff_performance`}
                                                value={inputs?.[`technical_staff_performance`]}
                                                onChange={(e) =>
                                                    setInputs(prevState => ({
                                                        ...prevState,
                                                        [`technical_staff_performance`]: e.target.value
                                                    }))
                                                }/>
                                            <p className='afa-error-text'>{error[`technical_staff_performance`]}</p>
                                        </Inputs>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <span>رضایت نامه کار
                                        <span className='dv-required-star'>*</span>
                                        </span>
                                        <FileInputs
                                            className={error[`work_consent_image`] && 'afa-error-input'}>
                                            {
                                                (inputs[`work_consent_image_url`] || inputs[`work_consent_image`]) &&
                                                <div className="afa-img">
                                                    <img
                                                        src={inputs[`work_consent_image_url`] ? inputs[`work_consent_image_url`] : `${MAIN_URL_IMAGE}${inputs[`work_consent_image`]}`}
                                                        alt="آذر فولاد امین"/>
                                                    <span
                                                        className='icon-close-solid afa-remove-position'
                                                        onClick={() => removeImage(`work_consent_image`)}/>
                                                </div>
                                            }
                                            <label className="afa-center-pos">
                                                <span className='icon-cloud-computing'/>
                                                <input type="file" accept="image/*"
                                                       onChange={thisUploadDocs}
                                                       name={`work_consent_image`}
                                                       className='dv-upload-file'/>
                                                {
                                                    !inputs[`work_consent_image`] &&
                                                    <span className='upload-text'>آپلود</span>
                                                }
                                            </label>
                                        </FileInputs>
                                        <p className='afa-error-text'>{error[`work_consent_image`]}</p>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <span>خلاصه شرح قرارداد
                                                <span className='dv-required-star'>*</span>
                                            </span>
                                            <textarea
                                                rows={2}
                                                className={error[`contract_short_description`] && 'afa-error-input'}
                                                name={`contract_short_description`}
                                                value={inputs?.[`contract_short_description`]}
                                                onChange={(e) =>
                                                    setInputs(prevState => ({
                                                        ...prevState,
                                                        [`contract_short_description`]: e.target.value
                                                    }))
                                                }/>
                                            <p className='afa-error-text'>{error[`contract_short_description`]}</p>
                                        </Inputs>
                                    </div>

                                    <div className="col-12 d-flex justify-content-center">
                                        <div className={"col-12 col-sm-6 col-lg-4 mb-4 d-flex align-items-center"}>
                                            <Inputs>
                                                <button type='submit' className='afa-add-more-btn'>
                                                    <span className='icon-add f-20'/>
                                                    <span className='pr-3'>افزودن اطلاعات</span>
                                                </button>
                                            </Inputs>
                                        </div>
                                    </div>
                                </>
                        }
                    </form>
                </>
            }
        </>
    )
}

export default WorkExperience;