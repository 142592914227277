import React, {useEffect, useState} from 'react';
import {Images} from "../../../assets/scripts/Images";
import {MainContent} from "./style";
import {MAIN_URL, MAIN_URL_IMAGE} from "../../../assets/scripts/GeneralVariables";
import {BannerFile, BannerFileMini, Inputs} from "../Child/Supplier/Child/style";
import {
    edit_item_with_id,
    getData,
    remove_item_of_arr_with_id, setTitle
} from "../../../assets/scripts/GeneralFunctions";
import {toast} from "react-toastify";
import Swal from "sweetalert2";
import {Modal} from "react-bootstrap";
import Skeleton from 'react-loading-skeleton'
import {ProgressBar} from "react-bootstrap";
import axios from "axios";

function Gallery(props) {
    const [inputs, setInputs] = useState({image_file: '', image: '', photos: [NaN, NaN, NaN, NaN, NaN, NaN, NaN]})
    const [error, setError] = useState({})
    const [modal, setModal] = useState({show_edit_banner: false, show_add_item_modal: false});
    const [progress, setProgress] = useState()
    const authToken = localStorage.getItem('AFAToken');
    useEffect(async () => {
        setTitle('آذر فولاد امین | گالری')
        window.scrollTo(0, 0)
        let api_result = await getData(MAIN_URL, `gallery-management/gallery`, 'get', {}, true, false);
        if (api_result?.status === 200) {
            setInputs(prevState => ({
                ...prevState,
                photos: api_result?.data?.photos,
            }))
        }
    }, []);

    const removeImage = (val) => {
        setInputs(prevState => ({
            ...prevState, [val + '_image_base64']: null, [val + '_image_file']: null, [val + '_image_type']: null
        }))
    }

    const thisUploadDocs = async (e) => {
        if (e.target.files) {
            let files = e.target.files;
            let fileBase64 = URL.createObjectURL(files[0]);
            let file_type = files[0]?.type?.split('/')[0];

            setInputs(prevState => ({
                ...prevState,
                [e.target.name + '_base64']: fileBase64,
                [e.target.name + '_file']: files[0],
                [e.target.name + '_type']: file_type
            }))
        }
    }

    // Add
    const addItemShowModal = (value, value_without_s) => {
        setInputs(prevState => ({
            ...prevState,
            [`${value}_id`]: null,
            [`${value}_image`]: '',
            [`${value}_title_en`]: '',
            [`${value}_title_fa`]: '',
            [`${value}_title_tr`]: '',
            [`${value}_title_ar`]: '',
            [`${value}_image_base64`]: '',
            [`${value}_image_file`]: '',
            [`${value}_image_type`]: '',
            show_open_modal_value: value,
            show_open_modal_value_without_s: value_without_s,
        }))
        setModal(prevState => ({...prevState, [`show_add_${value}_item_modal`]: true}))
    }
    const addModalSubmit = async (e) => {
        e.preventDefault()
        let form_data = new FormData();

        form_data.append('title[en]', inputs[`${inputs?.show_open_modal_value}_title_en`])
        form_data.append('title[fa]', inputs[`${inputs?.show_open_modal_value}_title_fa`])
        form_data.append('title[tr]', inputs[`${inputs?.show_open_modal_value}_title_tr`])
        form_data.append('title[ar]', inputs[`${inputs?.show_open_modal_value}_title_ar`])

        if (inputs[`${inputs?.show_open_modal_value}_image_file`]) {
            form_data.append('file', inputs[`${inputs?.show_open_modal_value}_image_file`])
            form_data.append('type', inputs[`${inputs?.show_open_modal_value}_image_type`] === 'image' ? 'photo' : inputs[`${inputs?.show_open_modal_value}_image_type`])
        }

        axios.post(`${MAIN_URL}gallery-management/gallery/create`, form_data, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${authToken}`,
            },
            onUploadProgress: data => {
                //Set the progress value to show the progress bar
                setProgress(Math.round((100 * data.loaded) / data.total))
            },
        })
            .then(response => {

                let arr = []
                arr[0] = response?.data[`${inputs?.show_open_modal_value_without_s}`];

                setInputs(prevState => ({
                    ...prevState,
                    [`${inputs?.show_open_modal_value}`]: arr.concat(inputs[`${inputs?.show_open_modal_value}`]),
                    [`${inputs?.show_open_modal_value}_image`]: '',
                    [`${inputs?.show_open_modal_value}_title`]: '',
                    [`${inputs?.show_open_modal_value}_image_base64`]: '',
                    [`${inputs?.show_open_modal_value}_image_file`]: '',
                    [`${inputs?.show_open_modal_value}_image_type`]: ''
                }))
                setModal(prevState => ({...prevState, [`show_add_${inputs?.show_open_modal_value}_item_modal`]: false}))
                setError('')
                toast.success('با موفقیت تغییر یافت')
                setProgress()
                setError({})
            })
            .catch(e => {
                if (e.response?.['status'] === 400) {
                    setError(e.response?.data['errors'])
                    toast.error(`خطا : لطفا اطلاعات را با دقت تکمیل فرمایید`)
                    setProgress()
                }
            })

        // if (api_result?.status === 200) {
        //     let arr = []
        //     arr[0] = api_result?.data[`${inputs?.show_open_modal_value_without_s}`];
        //     setInputs(prevState => ({
        //         ...prevState,
        //         [`${inputs?.show_open_modal_value}`]: arr.concat(inputs[`${inputs?.show_open_modal_value}`])
        //     }))
        //     toast.success('با موفقیت اضافه شد')
        //     setError('')
        //     setModal(prevState => ({...prevState, [`show_add_${inputs?.show_open_modal_value}_item_modal`]: false}))
        // } else if (api_result?.status === 400) {
        //     setError(api_result?.data)
        //     toast.error('خطا : لطفا اطلاعات بنر را با دقت تکمیل فرمایید')
        // }
    }

    // Edit && Remove
    const editBanner = (id, row, value, value_without_s) => {
        setInputs(prevState => ({
            ...prevState,
            [`${value}_id`]: id,
            [`${value}_title_en`]: row?.title['en'],
            [`${value}_title_fa`]: row?.title['fa'],
            [`${value}_title_ar`]: row?.title['ar'],
            [`${value}_title_tr`]: row?.title['tr'],
            [`${value}_api`]: row.src,
            [`${value}_image_base64`]: '',
            [`${value}_image_type`]: row.type,
            show_open_modal_value: value,
            show_open_modal_value_without_s: value_without_s,
        }))
        setModal(prevState => ({
            ...prevState, [`show_edit_${value}`]: true,
        }))
    }
    const removeImageEdit = (val) => {
        setInputs(prevState => ({
            ...prevState, [val + '_image_base64']: null, [val + '_image_base64']: null, [val + '_api']: null
        }))
    }
    const editBannerHandler = async (e) => {
        e.preventDefault()

        let form_data = new FormData();

        form_data.append('title[en]', inputs[`${inputs?.show_open_modal_value}_title_en`])
        form_data.append('title[fa]', inputs[`${inputs?.show_open_modal_value}_title_fa`])
        form_data.append('title[ar]', inputs[`${inputs?.show_open_modal_value}_title_ar`])
        form_data.append('title[tr]', inputs[`${inputs?.show_open_modal_value}_title_tr`])
        if (inputs[`${inputs?.show_open_modal_value}_image_file`]) {
            form_data.append('file', inputs[`${inputs?.show_open_modal_value}_image_file`])
            form_data.append('type', inputs[`${inputs?.show_open_modal_value}_image_type`] === 'image' ? 'photo' : inputs[`${inputs?.show_open_modal_value}_image_type`])
        }
        axios.post(`${MAIN_URL}gallery-management/gallery/edit/${inputs[`${inputs?.show_open_modal_value}_id`]}`, form_data, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${authToken}`,
            },
            onUploadProgress: data => {
                //Set the progress value to show the progress bar
                setProgress(Math.round((100 * data.loaded) / data.total))
            },
        })
            .then(response => {
                let arr = edit_item_with_id(inputs[`${inputs?.show_open_modal_value}`], response?.data[`${inputs?.show_open_modal_value_without_s}`])
                setInputs(prevState => ({
                    ...prevState,
                    [`${inputs?.show_open_modal_value}`]: arr,
                    [`${inputs?.show_open_modal_value}_id`]: null,
                    [`${inputs?.show_open_modal_value}_image`]: '',
                    [`${inputs?.show_open_modal_value}_title`]: '',
                    [`${inputs?.show_open_modal_value}_image_base64`]: '',
                    [`${inputs?.show_open_modal_value}_image_file`]: '',
                    [`${inputs?.show_open_modal_value}_image_type`]: ''
                }))
                setModal(prevState => ({...prevState, [`show_edit_${inputs?.show_open_modal_value}`]: false}))
                setError('')
                toast.success('با موفقیت تغییر یافت')
                setProgress()
                setError({})
            })
            .catch(e => {
                if (e.response?.['status'] === 400) {
                    setError(e.response?.data['errors'])
                    toast.error(`خطا : لطفا اطلاعات را با دقت تکمیل فرمایید`)
                    setProgress()
                }
            })
    }

    const handleRemoveBanner = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این مورد را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                let remove_result = await getData(MAIN_URL, `gallery-management/gallery/remove/${id}`, 'post', {}, true, false);
                if (remove_result?.status === 200) {
                    let arr = inputs[`${inputs?.show_open_modal_value}`];
                    let new_arr = remove_item_of_arr_with_id(arr, id)
                    setInputs(prevState => ({...prevState, [`${inputs?.show_open_modal_value}`]: new_arr}))
                    setModal(prevState => ({...prevState, [`show_edit_${inputs?.show_open_modal_value}`]: false}))
                    swalWithBootstrapButtons.fire(
                        'حذف شد!',
                        'با موفقیت حذف شد',
                        'success'
                    )
                }
            }
        })
    }

    const closeModal = () => {
        setModal(prevState => ({
            ...prevState,
            [`show_edit_${inputs?.show_open_modal_value}`]: false,
            [`show_add_${inputs?.show_open_modal_value}_item_modal`]: false
        }))
    }

    return (
        <MainContent>
            <div className='row'>
                <div className="col-12 mb-3">
                    <div className="row px-0">
                        <div className="col-12 mb-4">
                            <h6 className='text-red fw-bold'>مدیریت گالری</h6>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 mb-3">
                            <div className="afa-upload-file-parent">
                                <BannerFile>
                                    <label
                                        className="afa_upload_file d-flex flex-column align-items-center justify-content-center">
                                        <img src={Images?.article_upload?.default}
                                             className='afa-article-image-to-upload' alt="آذر فولاد امین"/>
                                        <span className='upload-text mt-4'
                                              onClick={() => addItemShowModal('photos', 'photo')}>افزودن فایل</span>
                                    </label>
                                </BannerFile>
                            </div>
                        </div>
                        {
                            inputs?.photos?.length > 0 &&
                            !inputs?.photos[0] ?
                                inputs?.photos?.map((row, index) => (
                                    <div className="col-12 col-md-6 col-lg-3 mb-3" key={index}>
                                        <div className="afa-upload-file-parent">
                                            <Skeleton key={index} borderRadius={8} height={210} width={300}/>
                                        </div>
                                    </div>
                                )) :
                                inputs?.photos?.map((row, index) => (
                                    <div className="col-12 col-md-6 col-lg-3 mb-3" key={index}>
                                        <div className="afa-upload-file-parent">
                                            <BannerFile>
                                                <div className="afa-img d-flex align-items-center">
                                                    {
                                                        row?.type === 'video' ?
                                                            <video
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                                autoPlay={true}
                                                                muted={true}
                                                                controls
                                                            >
                                                                <source className={'h-100'}
                                                                        src={`${MAIN_URL_IMAGE}${row?.src}`}/>
                                                            </video> :
                                                            <img
                                                                loading={'lazy'}
                                                                src={`${MAIN_URL_IMAGE}${row?.src}`}
                                                                alt="آذر فولاد امین"/>
                                                    }
                                                    <span
                                                        className='afa-remove-position icon-edit'
                                                        onClick={() => editBanner(row?.id, row, 'photos', 'photo')}/>
                                                </div>
                                            </BannerFile>
                                        </div>
                                    </div>
                                ))
                        }
                    </div>
                </div>
            </div>


            <Modal centered={true} className='afa-user-status-modal'
                   backdrop="static"
                   keyboard={false}
                   show={modal[`show_add_${inputs?.show_open_modal_value}_item_modal`]} onHide={closeModal}>
                <Modal.Body className='p-md-4' dir='rtl'>
                    <form className="row" onSubmit={addModalSubmit}>
                        {
                            (inputs[`${inputs?.show_open_modal_value}_image_base64`] && progress) ?
                                <div
                                    className={'d-flex flex-column align-items-center justify-content-center w-100 h-100'}>
                                    <ProgressBar className={'mt-3 w-100'} now={progress}
                                                 label={`${progress}%`}
                                                 variant="danger" animated/>
                                    <h6 className={'pt-2 text-center'}>در حال بارگزاری فایل و افزودن اطلاعات</h6>
                                </div> :
                                <>
                                    <div className="col-12 col-md-7 mb-3">
                                        <p className='text-red fw-bold'>افزودن فایل</p>
                                        <Inputs>
                                            <span>عنوان فایل (فارسی)</span>
                                            <input className={'afa-error-input'}
                                                   type="text"
                                                   name={[`${inputs?.show_open_modal_value}_title_fa`]}
                                                   value={inputs[`${inputs?.show_open_modal_value}_title_fa`]}
                                                   onChange={(e) =>
                                                       setInputs(prevState => ({
                                                           ...prevState,
                                                           [`${inputs?.show_open_modal_value}_title_fa`]: e.target.value
                                                       }))
                                                   }/>
                                        </Inputs>
                                        <p className='afa-error-text'>{error[`title[fa]`]}</p>
                                        <Inputs>
                                            <span>عنوان فایل (انگلیسی)</span>
                                            <input className={'afa-error-input'}
                                                   type="text"
                                                   name={[`${inputs?.show_open_modal_value}_title_en`]}
                                                   value={inputs[`${inputs?.show_open_modal_value}_title_en`]}
                                                   onChange={(e) =>
                                                       setInputs(prevState => ({
                                                           ...prevState,
                                                           [`${inputs?.show_open_modal_value}_title_en`]: e.target.value
                                                       }))
                                                   }/>
                                        </Inputs>
                                        <p className='afa-error-text'>{error[`title[en]`]}</p>

                                        <Inputs>
                                            <span>عنوان فایل (عربی)</span>
                                            <input className={'afa-error-input'}
                                                   type="text"
                                                   name={[`${inputs?.show_open_modal_value}_title_ar`]}
                                                   value={inputs[`${inputs?.show_open_modal_value}_title_ar`]}
                                                   onChange={(e) =>
                                                       setInputs(prevState => ({
                                                           ...prevState,
                                                           [`${inputs?.show_open_modal_value}_title_ar`]: e.target.value
                                                       }))
                                                   }/>
                                        </Inputs>
                                        <p className='afa-error-text'>{error[`title[ar]`]}</p>
                                        <Inputs>
                                            <span>عنوان فایل (ترکی)</span>
                                            <input className={'afa-error-input'}
                                                   type="text"
                                                   name={[`${inputs?.show_open_modal_value}_title_tr`]}
                                                   value={inputs[`${inputs?.show_open_modal_value}_title_tr`]}
                                                   onChange={(e) =>
                                                       setInputs(prevState => ({
                                                           ...prevState,
                                                           [`${inputs?.show_open_modal_value}_title_tr`]: e.target.value
                                                       }))
                                                   }/>
                                        </Inputs>
                                        <p className='afa-error-text'>{error[`title[tr]`]}</p>

                                    </div>
                                    <div className="col-12 col-md-5 mb-3">
                                        <div className="afa-upload-file-parent">
                                            <BannerFileMini
                                                className={error[`file`] && 'afa-error-input'}>
                                                {
                                                    (inputs[`${inputs?.show_open_modal_value}_image_base64`]) ?
                                                        <div className="afa-img d-flex align-items-center">
                                                            {
                                                                inputs[`${inputs?.show_open_modal_value}_image_type`] === 'image' ?
                                                                    <img
                                                                        src={inputs[`${inputs?.show_open_modal_value}_image_base64`]}
                                                                        alt="آذر فولاد امین"/> :
                                                                    <video
                                                                        style={{
                                                                            width: '100%',
                                                                        }}
                                                                        autoPlay={true}
                                                                        muted={true}
                                                                        controls
                                                                    >
                                                                        <source
                                                                            className={'h-100'}
                                                                            src={inputs[`${inputs?.show_open_modal_value}_image_base64`]}/>
                                                                    </video>
                                                            }

                                                            <span
                                                                className='afa-remove-position icon-delete'
                                                                onClick={() => removeImage(`${inputs?.show_open_modal_value}`)}/>
                                                        </div> :
                                                        <label
                                                            className="afa_upload_file d-flex flex-column align-items-center justify-content-center">
                                                            <img src={Images?.article_upload?.default}
                                                                 className='afa-article-image-to-upload'
                                                                 alt="آذر فولاد امین"/>
                                                            <input type="file" accept="image/*,video/*"
                                                                   onChange={thisUploadDocs}
                                                                   name={`${inputs?.show_open_modal_value}_image`}
                                                                   className='dv-upload-file'/>
                                                            {
                                                                !inputs[`${inputs?.show_open_modal_value}_image`] &&
                                                                <span className='upload-text'>بارگزاری
                                                                    {
                                                                        inputs?.show_open_modal_value === 'banners' ? ' بنر' :
                                                                            inputs?.show_open_modal_value === 'charts' ? ' ساختار سازمانی' : ' عکس'
                                                                    }
                                                    </span>
                                                            }
                                                        </label>
                                                }
                                            </BannerFileMini>
                                            <p className='afa-error-text'>{error[`file`]}</p>
                                        </div>
                                    </div>
                                    <div className='col-12 w-100 d-flex align-items-center'>
                                        <button type='submit' className='afa-btn-save-modal'>ذخیره</button>
                                        <button type='button' onClick={closeModal}
                                                className='afa-btn-cancel-modal'>انصراف
                                        </button>
                                    </div>
                                </>
                        }
                    </form>
                </Modal.Body>
            </Modal>

            <Modal centered={true} className='afa-user-status-modal'
                   backdrop="static"
                   keyboard={false}
                   show={modal[`show_edit_${inputs?.show_open_modal_value}`]} onHide={closeModal}>
                <Modal.Body className='p-md-4' dir='rtl'>
                    <form className="row" onSubmit={editBannerHandler}>
                        {
                            (inputs[`${inputs?.show_open_modal_value}_image_base64`] && progress) ?
                                <div
                                    className={'d-flex flex-column align-items-center justify-content-center w-100 h-100'}>
                                    <ProgressBar className={'mt-3 w-100'} now={progress}
                                                 label={`${progress}%`}
                                                 he
                                                 variant="danger" animated/>
                                    <h6 className={'pt-2 text-center'}>در حال ویرایش و بارگزاری فایل</h6>
                                </div> :
                                <>
                                    <div className="col-12 col-md-7 mb-3">
                                        <div className=" d-flex align-items-center justify-content-between">
                                            <p className='text-red fw-bold'>ویرایش فایل</p>
                                            <div className="afa-remove-link"
                                                 onClick={() => handleRemoveBanner(inputs[`${inputs?.show_open_modal_value}_id`])}>
                                                <span className="icon-Delete"/>
                                            </div>
                                        </div>
                                        <Inputs>
                                            <span>عنوان فایل (فارسی)</span>
                                            <input className={'afa-error-input'}
                                                   type="text"
                                                   name={[`${inputs?.show_open_modal_value}_title_fa`]}
                                                   value={inputs[`${inputs?.show_open_modal_value}_title_fa`]}
                                                   onChange={(e) =>
                                                       setInputs(prevState => ({
                                                           ...prevState,
                                                           [`${inputs?.show_open_modal_value}_title_fa`]: e.target.value
                                                       }))
                                                   }/>
                                        </Inputs>
                                        <p className='afa-error-text'>{error[`title[fa]`]}</p>
                                        <Inputs>
                                            <span>عنوان فایل (انگلیسی)</span>
                                            <input className={'afa-error-input'}
                                                   type="text"
                                                   name={[`${inputs?.show_open_modal_value}_title_en`]}
                                                   value={inputs[`${inputs?.show_open_modal_value}_title_en`]}
                                                   onChange={(e) =>
                                                       setInputs(prevState => ({
                                                           ...prevState,
                                                           [`${inputs?.show_open_modal_value}_title_en`]: e.target.value
                                                       }))
                                                   }/>
                                        </Inputs>
                                        <p className='afa-error-text'>{error[`title[en]`]}</p>
                                        <Inputs>
                                            <span>عنوان فایل (عربی)</span>
                                            <input className={'afa-error-input'}
                                                   type="text"
                                                   name={[`${inputs?.show_open_modal_value}_title_ar`]}
                                                   value={inputs[`${inputs?.show_open_modal_value}_title_ar`]}
                                                   onChange={(e) =>
                                                       setInputs(prevState => ({
                                                           ...prevState,
                                                           [`${inputs?.show_open_modal_value}_title_ar`]: e.target.value
                                                       }))
                                                   }/>
                                        </Inputs>
                                        <p className='afa-error-text'>{error[`title[ar]`]}</p>
                                        <Inputs>
                                            <span>عنوان فایل (ترکی)</span>
                                            <input className={'afa-error-input'}
                                                   type="text"
                                                   name={[`${inputs?.show_open_modal_value}_title_tr`]}
                                                   value={inputs[`${inputs?.show_open_modal_value}_title_tr`]}
                                                   onChange={(e) =>
                                                       setInputs(prevState => ({
                                                           ...prevState,
                                                           [`${inputs?.show_open_modal_value}_title_tr`]: e.target.value
                                                       }))
                                                   }/>
                                        </Inputs>
                                        <p className='afa-error-text'>{error[`title[tr]`]}</p>

                                    </div>
                                    <div className="col-12 col-md-5 mb-3">
                                        <div className="afa-upload-file-parent">
                                            <BannerFileMini
                                                className={error[`file`] && 'afa-error-input'}>
                                                {
                                                    (inputs[`${inputs?.show_open_modal_value}_api`] || inputs[`${inputs?.show_open_modal_value}_image_base64`]) ?
                                                        <div className="afa-img d-flex align-items-center">
                                                            {
                                                                inputs[`${inputs?.show_open_modal_value}_image_type`] === 'video' ?
                                                                    <video
                                                                        style={{
                                                                            width: '100%',
                                                                        }}
                                                                        autoPlay={true}
                                                                        muted={true}
                                                                        controls
                                                                    >
                                                                        <source
                                                                            className={'h-100'}
                                                                            src={inputs[`${inputs?.show_open_modal_value}_image_base64`] ? inputs[`${inputs?.show_open_modal_value}_image_base64`] :
                                                                                `${MAIN_URL_IMAGE}${inputs[`${inputs?.show_open_modal_value}_api`]}`}/>
                                                                    </video> :
                                                                    <img
                                                                        loading={'lazy'}
                                                                        src={inputs[`${inputs?.show_open_modal_value}_image_base64`] ? inputs[`${inputs?.show_open_modal_value}_image_base64`] :
                                                                            `${MAIN_URL_IMAGE}${inputs[`${inputs?.show_open_modal_value}_api`]}`}
                                                                        alt={'آذر فولاد امین'}
                                                                    />
                                                            }
                                                            <span
                                                                className='afa-remove-position icon-delete'
                                                                onClick={() => removeImageEdit(`${inputs?.show_open_modal_value}`)}/>
                                                        </div> :
                                                        <label
                                                            className="afa_upload_file d-flex flex-column align-items-center justify-content-center">
                                                            <img src={Images?.article_upload?.default}
                                                                 className='afa-article-image-to-upload'
                                                                 alt="آذر فولاد امین"/>
                                                            <input type="file" accept="image/*,video/*"
                                                                   onChange={thisUploadDocs}
                                                                   name={`${inputs?.show_open_modal_value}_image`}
                                                                   className='dv-upload-file'/>
                                                            {
                                                                !inputs[`${inputs?.show_open_modal_value}_image`] &&
                                                                <span className='upload-text'>بارگزاری فایل
                                                    </span>
                                                            }
                                                        </label>
                                                }
                                            </BannerFileMini>
                                            <p className='afa-error-text'>{error[`file`]}</p>
                                        </div>
                                    </div>
                                    <div className='col-12 w-100 d-flex align-items-center'>
                                        <button type='submit' className='afa-btn-save-modal'>ذخیره</button>
                                        <button type='button' onClick={closeModal}
                                                className='afa-btn-cancel-modal'>انصراف
                                        </button>
                                    </div>
                                </>
                        }
                    </form>
                </Modal.Body>
            </Modal>

        </MainContent>
    );
}

export default Gallery;