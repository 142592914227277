import React, {useEffect, useState} from 'react';
import {LeftContent, LoginInput, LoginTheme, RightContent} from "./styles";
import {fixNumber, getData, just_persian, mobileRegex} from "../../assets/scripts/GeneralFunctions";
import {Images} from "../../assets/scripts/Images";
import {useHistory, useLocation} from "react-router";
import {MAIN_URL} from "../../assets/scripts/GeneralVariables";
import {LoopCircleLoading} from "react-loadingg";

function RealRegister(props) {
    const [data, setData] = useState({first_name: '',last_name : '', phone: '' , purpose : 'job_seeker'})
    const [error, setError] = useState({})
    const [loader, setLoader] = useState(false)
    const history = useHistory()
    let location = useLocation();

    const formHandler = async (e) => {
        e.preventDefault()
        setLoader(true)
        let api_result = await getData(MAIN_URL, "register", 'post', {
            type: 'حقیقی',
            individual_identification_code: location.state.individual_identification_code,
            first_name: data?.first_name,
            last_name: data?.last_name,
            mobile: data?.phone,
            purpose : data?.purpose
        }, null, false);
        if (api_result?.status === 200) {
            setLoader(false)
            history.push({
                pathname: '/verify_code',
                state: {
                    time: api_result.data['remaining'],
                    user_type: 'real',
                    mobile: api_result.data['mobile'],
                    individual_identification_code: location.state.individual_identification_code
                }
            })
        } else if (api_result?.status === 400) {
            setLoader(false)
            let error_result = true
            let error = {};
            if (api_result.error['first_name']) {
                error['first_name'] = api_result.error['first_name'][0]
                error_result = false
            }
            if (api_result.error['last_name']) {
                error['last_name'] = api_result.error?.last_name[0]
                error_result = false
            }
            if (api_result.error['mobile']) {
                error['mobile'] = api_result.error?.mobile[0]
                error_result = false
            }

            setError(error)
            return error_result
        }
    }

    return (
        <>
            <LoginTheme>
                <RightContent>
                    <img src={Images.main_logo.default} alt="آذر فولاد امین"/>
                    <h6>ایجاد حساب کاربری</h6>
                    <p className='afa-error-text'>{error['individual_identification_code']}</p>
                    <LoginInput onSubmit={formHandler}>
                        <div className="d-flex flex-column mt-2 mb-4">
                            <span className=' f-14 mb-2'>نوع کاربری</span>
                            <div className="d-flex flex-wrap">
                                <label className="afa-custom-checkbox ml-2">
                                    کارجویان و کارکنان
                                    <input type="radio" value='job_seeker' onChange={(e)=>setData(prevState => ({
                                        ...prevState , purpose : e.target.value
                                    }))}
                                           checked={data?.purpose == 'job_seeker'} name="purpose"/>
                                    <span className="checkmark"/>
                                </label>

                                <label className="afa-custom-checkbox ml-2">
                                    تامین کننده
                                    <input type="radio" value='supplier' onChange={(e)=>setData(prevState => ({
                                        ...prevState , purpose : e.target.value
                                    }))}
                                           checked={data?.purpose == 'supplier'} name="purpose"/>
                                    <span className="checkmark"/>
                                </label>
                            </div>
                        </div>
                        <span>نام</span>
                        <input type="text" autoFocus name='first_name' value={data.first_name}
                               onChange={
                                   (e) =>
                                       just_persian(e.target.value) && setData(prevState => ({
                                           ...prevState,
                                           first_name: e.target.value
                                       }))
                               }
                               placeholder={'نام'}/>
                        <p className='afa-error-text'>{error['first_name']}</p>

                        <span>نام خانوادگی</span>
                        <input type="text" name='last_name' value={data.last_name}
                               onChange={(e) =>
                                   just_persian(e.target.value) && setData(prevState => ({
                                       ...prevState,
                                       last_name: e.target.value
                                   }))
                               }
                               placeholder={'نام خانوادگی'}/>
                        <p className='afa-error-text'>{error['last_name']}</p>

                        <span>شماره موبایل</span>
                        <input className='text-left' dir='ltr' type="number" name='phone' value={data.phone}
                               onChange={(e) => setData(prevState => ({
                                   ...prevState,
                                   phone: fixNumber(e.target.value)
                               }))}
                               placeholder={'09123456789'}/>
                        <p className='afa-error-text'>{error['mobile']}</p>

                        <div className="position-relative w-100">
                            <button type='submit'
                                    className='w-100'
                                    disabled={data.first_name?.length > 0 && data.last_name?.length > 0 && data.phone?.length > 0 ? false : true}>ثبت
                                نام
                            </button>
                            {
                                loader && <LoopCircleLoading/>
                            }
                        </div>

                    </LoginInput>
                </RightContent>
                <LeftContent>
                    <img src={Images.login_left.default} alt="آذر فولاد امین"/>
                </LeftContent>
            </LoginTheme>
        </>
    );
}

export default RealRegister;