import React, {useEffect, useState} from 'react';
import {ArticleList, MainContent} from "../style";
import {SearchInput} from "../../Child/SupplierMangement/style";
import {Link} from "react-router-dom";
import {getData, setTitle,} from "../../../../assets/scripts/GeneralFunctions";
import {MAIN_URL} from "../../../../assets/scripts/GeneralVariables";

import Skeleton from 'react-loading-skeleton'

import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel
} from "react-accessible-accordion";

function CommunicationManagement() {
    const [inputs, setInputs] = useState({
        search_value: '', contact_managements: [NaN, NaN, NaN, NaN], locale: 'fa',
        managements: [], company_selected: [], management_id: ''
    })

    const [is_all_items_load, setAllLoad] = useState(true)

    const [data, setObject] = useState({
        'offset': 0,
        'limit': 30,
    })

    useEffect(async () => {
        setTitle('آذر فولاد امین | ارتباط با مدیریت')
        let api_result = await getData(MAIN_URL, `contacts/management`, 'get', {}, true, false);
        if (api_result?.status === 200) {
            setInputs(prevState => ({
                ...prevState,
                managements: api_result?.data?.managements,
                contact_managements: []
            }))
        }
    }, []);

    // custom filter checkboxes
    const CheckBoxHandler = async (value) => {

        setInputs(prevState => ({...prevState, contact_managements: [NaN, NaN, NaN, NaN] , management_id: value}))
        let api_result = await getData(MAIN_URL, `contacts/management/list/${value}`, 'get', data, true, false);
        if (api_result?.status === 200) {
            setInputs(prevState => ({
                ...prevState,
                contact_managements: api_result?.data?.contact_managements,
            }))
        }
    }

    const searchHandler = async (e) => {

        if (inputs?.management_id) {
            let object = {};
            object = data;
            object['search'] = e.target.value
            setInputs(prevState => ({
                ...prevState,
                search_value: e.target.value,
                contact_managements: [NaN, NaN, NaN, NaN],
            }))
            let api_result = await getData(MAIN_URL, `contacts/management/list/${inputs?.management_id}`, 'get', data, true, false);
            if (api_result?.status === 200) {
                setInputs(prevState => ({
                    ...prevState,
                    contact_managements: api_result?.data?.contact_managements,
                }))
                setObject(object)
            }
        }
    }

    // lazy load
    const getTableDataLazyLoad = async (obj) => {
        if (parseFloat(obj.target.offsetHeight + obj.target.scrollTop) == parseFloat(obj.target.scrollHeight)) {
            if (inputs?.contact_managements?.length % 30 === 0 && is_all_items_load === true) {
                let object = {};
                object = data;
                object['offset'] = inputs?.contact_managements?.length
                let moreData = await getData(MAIN_URL, `contacts/management/list/${inputs?.management_id}`, 'get', object, true, true);
                if (moreData) {
                    setObject(obj)
                    setInputs(prevState => ({
                        ...prevState,
                        contact_managements: inputs?.contact_managements.concat(moreData.data?.contact_managements)
                    }))
                    if (moreData.data?.contact_managements?.length < 30) {
                        setAllLoad({is_all_items_load: false})
                    }
                }
            }
        }
    }

    return (
        <MainContent>
            <div className='row'>
                <div className="col-12 col-lg-3 col-xl-4 col-xxl-6 mb-3">
                    <h6 className='text-red fw-bold'>ارتباط با مدیریت</h6>
                </div>
                <div className="col-12 col-lg-9 col-xl-8 col-xxl-6 d-flex flex-column flex-md-row mb-3">
                    <SearchInput className='mb-3 w-100' style={{maxWidth: '100%'}}>
                        <span className='icon-Search dv-search-icon'/>
                        <input type="search" value={inputs?.search_value} onChange={searchHandler}
                               placeholder='جستجو'/>
                    </SearchInput>
                {/*Filter*/}
                    <Accordion className='dv-units-filter-drop-down mb-3' allowZeroExpanded>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <div className="d-flex align-items-center">
                                        <span>فیلتر بر اساس نوع کاربری</span>
                                        <span className="icon-Filter dv-filter-icon"/>
                                    </div>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div className="d-flex flex-column">
                                    {
                                        inputs?.managements?.map((item, index) => (
                                            <label className="afa-custom-checkbox" key={index}>{item.title}
                                                <input type="radio" name='company_type'
                                                       value={item.id}
                                                       onChange={() => CheckBoxHandler(item.id)}
                                                />
                                                <span className="checkmark2"></span>
                                            </label>
                                        ))
                                    }
                                </div>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                </div>
                <div className="col-12 mb-3 afa-lazyload" onScroll={getTableDataLazyLoad}>
                    {
                        !inputs?.management_id ? <p className='text-center'>لطفا یک نوع مدیریت انتخاب فرمایید</p>
                            :
                            inputs?.contact_managements?.length > 0 ?
                                !inputs?.contact_managements[0] ?
                                    inputs?.contact_managements?.map((row, index) => (
                                        <>
                                            <Skeleton key={index} borderRadius={8} height={100}/>
                                        </>
                                    )) :
                                    inputs?.contact_managements?.map((row) => (
                                        <ArticleList className='row align-items-center p-4 gb-4' key={row.id}>
                                            <div className="col-12 col-md-11 mb-3">
                                                <div className="row g-4 px-0">
                                                    <span
                                                        className='col-12 col-md-6'>نام و نام خانوادگی : <span>{row?.full_name}</span></span>
                                                    <span
                                                        className='col-12 col-md-6'>نام شرکت : <span>{row?.company_name}</span></span>
                                                    <span
                                                        className='col-12 col-md-6'>شماره تماس : <span>{row?.phone}</span></span>
                                                    <span
                                                        className='col-12 col-md-6'>ارسال پیام به : <span>{
                                                        inputs?.managements?.map((item) => {
                                                            if (item.id === row?.management_id) {
                                                                return item.title
                                                            }
                                                        })
                                                    }</span></span>
                                                </div>
                                            </div>
                                            <div
                                                className="col-12 col-md-1 d-flex align-items-center justify-content-center">
                                                <Link className={'text link-color'}
                                                      to={`/communication-management/${row.id}`}>مشاهده<span
                                                    className="icon-cheveron-left"></span></Link>
                                            </div>
                                        </ArticleList>
                                    )) :
                                <p className='text-center'>موردی یافت نشد</p>
                    }
                </div>
            </div>
        </MainContent>
    );
}

export default CommunicationManagement;