import React, {useState, useEffect} from 'react';
import {useHistory} from "react-router-dom";
import {MainContent} from "../style";
import {Images} from "../../../../assets/scripts/Images";
import {ArticleFile, Inputs} from "../../Child/Supplier/Child/style";
import {MAIN_URL_IMAGE} from "../../../../assets/scripts/GeneralVariables";
import {
    fileUpload,
    getFormDataPost,
    setTitle
} from "../../../../assets/scripts/GeneralFunctions";
import CkEditorText from "./CkEditorText";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import TagsInput from 'react-tagsinput'

import 'react-tagsinput/react-tagsinput.css'
import {toast} from "react-toastify";

function NewArticle(props) {
    const [inputs, setInputs] = useState({
        title: '', description: '', content: '', tags: [],
        show_add_item_modal: false, images: [], photos_image: '', photos_image_url: '', photos_image_file: '',
        uploading_file_list: [],
        uploading_file_list_base64: [],
        post_slug: '',
        companies: [],
        company_selected: [],
    })
    const history = useHistory()
    const [error, setError] = useState({})
    useEffect(() => {
        setTitle('آذر فولاد امین | افزودن خبر')
        console.log(props)
        setInputs(prevState => ({
            ...prevState,
            companies: props.location?.companies
        }))
        // console.log(props.location?.locale)
    }, []);

    const removeImage = (val) => {

        setInputs(prevState => ({
            ...prevState, [val]: null, [val + '_url']: null, [val + '_file']: null, [`remove_${val}`]: 1
        }))
    }

    const thisUploadDocs = async (e) => {
        let files = e.target.files;
        let file = URL.createObjectURL(await fileUpload(files[0]))
        let upload_file = await fileUpload(files[0]);
        setInputs(prevState => ({
            ...prevState,
            [e.target.name + '_url']: file,
            [e.target.name]: upload_file,
            [e.target.name + '_file']: e.target.files[0]
        }))
    }
    const handleChange = (tags) => {
        setInputs(prevState => ({...prevState, tags: tags}))
    }
    const setData = (value) => {
        setInputs(prevState => ({...prevState, content: value}))
    }

    const checkBoxHandler = (id) => {
        let selected = [];
        if (inputs?.company_selected?.length > 0) {
            selected = inputs?.company_selected
        }
        let find = selected?.indexOf(id)

        if (find > -1) {
            selected?.splice(find, 1)
        } else {
            selected?.push(id)
        }
        setInputs(prevState => ({...prevState, company_selected: selected}))
    }

    const formHandler = async (e) => {
        e.preventDefault()

        let formData = new FormData()
        formData.append(`title`, inputs?.title)
        formData.append(`image`, inputs?.article_file_file)
        formData.append(`description`, inputs?.description)
        formData.append(`content`, inputs?.content)

        for (let i = 0; i < inputs?.tags?.length; i++) {
            formData.append(`tags[${i}]`, inputs?.tags[i])
        }

        for (let i = 0; i < inputs?.company_selected?.length; i++) {
            formData.append(`related_about_companies[${i}]`, inputs?.company_selected[i])
        }

        let api_result = await getFormDataPost(`blog-management/posts/create/${props.location?.locale}`, formData)

        if (api_result?.status === 200) {
            setError('')
            toast.success('با موفقیت ثبت شد')
            history.push('/manage-blog')
            setInputs(prevState => ({...prevState, post_slug: api_result?.data?.post?.slug}))
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
        }
    }
    return (
        <MainContent>
            <form onSubmit={formHandler} className='row'>
                <div className="col-12 col-sm-6 col-lg-6 mb-3"><h6 className='text-red fw-bold'>افزودن محتوا</h6>
                </div>
                <div className="col-12 col-md-8 mb-3">
                    <div className="row px-0">
                        <div className="col-12 mb-4">
                            <Inputs>
                                <span>عنوان محتوا</span>
                                <input
                                    className={'afa-error-input'}
                                    maxLength={75}
                                    type="text" name={`title`}
                                    value={inputs?.[`title`]}
                                    placeholder={'عنوان محتوا'}
                                    onChange={(e) =>
                                        setInputs(prevState => ({
                                            ...prevState,
                                            [`title`]: e.target.value
                                        }))
                                    }/>
                            </Inputs>
                            <p className='afa-error-text'>{error['title']}</p>
                        </div>
                        <div className="col-12 mb-4">
                            <Inputs>
                                <span>توضیحات کوتاه محتوا</span>
                                <textarea
                                    rows={3}
                                    className={'afa-error-input'}
                                    name={`description`}
                                    maxLength={250}
                                    placeholder={'توضیحات کوتاه محتوا'}
                                    value={inputs?.[`description`]}
                                    onChange={(e) =>
                                        setInputs(prevState => ({
                                            ...prevState,
                                            [`description`]: e.target.value
                                        }))
                                    }/>
                            </Inputs>
                            <p className='afa-error-text'>{error['description']}</p>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4 mb-3">
                    <div className="afa-upload-file-parent">
                        <span className='f-14'>تصویر محتوا</span>
                        <ArticleFile
                            className={error[`image`] && 'afa-error-input'}>
                            {
                                (inputs[`article_file_url`] || inputs[`article_file`]) ?
                                    <div className="afa-img">
                                        <img
                                            src={inputs[`article_file_url`] ? inputs[`article_file_url`] : `${MAIN_URL_IMAGE}${inputs[`article_file`]}`}
                                            alt="آذر فولاد امین"/>
                                        <span
                                            className='afa-remove-position icon-delete'
                                            onClick={() => removeImage(`article_file`)}/>
                                    </div> :
                                    <label
                                        className="afa_upload_file d-flex flex-column align-items-center justify-content-center">
                                        <img src={Images?.article_upload?.default}
                                             className='afa-article-image-to-upload' alt="آذر فولاد امین"/>
                                        <input type="file" accept="image/*"
                                               onChange={thisUploadDocs}
                                               name={`article_file`}
                                               className='dv-upload-file'/>
                                        {
                                            !inputs[`article_file`] &&
                                            <span className='upload-text'>اپلود عکس مربوط به محتوا</span>
                                        }
                                    </label>
                            }
                        </ArticleFile>
                        <p className='afa-error-text'>{error[`image`]}</p>
                    </div>
                </div>
                <div className="col-12 mb-3">
                    <span className="f-14">توضیحات کامل محتوا</span>
                    <CkEditorText setData={setData} fa={true}/>
                    <p className='afa-error-text'>{error['content']}</p>
                </div>
                <div className="col-12 mb-4">
                    <span className='f-14 mb-2'>افزودن تگ</span>
                    <TagsInput value={inputs?.tags} onChange={handleChange}/>
                    <p className='afa-error-text'>{error['tags']}</p>
                </div>
                <div className="col-12 mb-4">
                    <span className='f-14 mb-2'>مرتبط به بخش</span>
                    <Accordion className='dv-filter-drop-down-items dv-article-dropdown' allowZeroExpanded>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <span>انتخاب شرکت</span>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div className="d-flex flex-column w-100">
                                    {
                                        inputs?.companies?.map((item, index) => (
                                            <div className="py-2" key={index}>
                                                <label className="afa-custom-checkbox">{item?.display_title?.fa}
                                                    <input type="checkbox" name='certificate_type' value={item?.id} onChange={() => checkBoxHandler(item?.id)} checked={inputs.company_selected?.includes(item?.id)}/>
                                                    <span className="checkmark2"/>
                                                </label>
                                            </div>
                                        ))
                                    }
                                </div>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                    <p className='afa-error-text'>{error['related_about_companies']}</p>
                </div>
                <div className="col-12 d-flex align-items-center justify-content-center mb-4">
                    <Inputs>
                        <button type='submit'
                                className='m-auto afa-save-super-admin-btn'>
                            <span className='pr-3'>ذخیره</span>
                        </button>
                    </Inputs>
                </div>
            </form>
        </MainContent>
    );
}

export default NewArticle;