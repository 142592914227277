import React, {useState, useEffect} from 'react';
import {
    TabStatusComplete,
    TabStatusNotComplete,
    Inputs,
    LeftIcon,
    FileInputs, TabStatusContent
} from "./style";
import moment from "moment-jalaali";
import {
    checkCodeMelli,
    fileUpload,
    fixNumber,
    just_persian,
    getData,
    getFormDataPost, just_number, mobileRegex
} from "../../../../../assets/scripts/GeneralFunctions";
import {MAIN_URL, MAIN_URL_IMAGE} from "../../../../../assets/scripts/GeneralVariables";
import {DateInput} from "react-hichestan-datetimepicker";
import {Accordion, Card} from "react-bootstrap";
import {toast} from "react-toastify";
import Swal from "sweetalert2";

function FamilyInformation(props) {
    const [error, setError] = useState({})
    const [inputs, setInputs] = useState({
        supplementary_insurance_status: 'خیر',
        relation: 'انتخاب کنید',
        child_gender: 'انتخاب کنید',
        supplementary_insurance: 'انتخاب کنید',
        full_name: null,
        name:'',
        identification_code: null,
        telephone: null,
        insurance_types: [],
        family_members: [],
        sheet_status: {}, isLoad: true,
        insurance_certificate: null,
        insurance_certificate_url: null,
        insurance_certificate_file: null
    })
    const [arrow_item, setArrowItem] = useState({arrow_id: 0, arrow_status: false})
    const [is_render, setRender] = useState(false);
    const [new_collapse, setNewCollapse] = useState(false);
    const [more_data, setMoreData] = useState({sheet_status: {}, isLoad: true});

    useEffect(async () => {
        let api_result = await getData(MAIN_URL, "profile/family-members", 'get', {}, true, true);
        if (api_result?.status === 200) {
            let data = api_result?.data
            let family_members = data?.family_members
            if (family_members?.length === 0) {
                setInputs(prevState => ({
                    ...prevState,
                    supplementary_insurance_status: 'بله',
                    relation: 'انتخاب کنید',
                    child_gender: 'انتخاب کنید',
                    supplementary_insurance: 'انتخاب کنید',
                    full_name: '',
                    identification_code: '',
                    telephone: '',
                    insurance_types: data?.insurance_types,
                    family_members: [],
                    sheet_status: data?.sheet_status,
                    isLoad: false,
                    insurance_certificate: data?.insurance_certificate?.insurance_certificate
                }))
            } else {
                setInputs(prevState => ({
                    ...prevState,
                    insurance_types: data.insurance_types,
                    family_members: family_members,
                    sheet_status: data?.sheet_status,
                    isLoad: false,
                    insurance_certificate: data?.insurance_certificate?.insurance_certificate
                }))
            }
            setMoreData({sheet_status: data?.sheet_status, status_colors: data?.status_colors, isLoad: false})
        }
    }, [is_render]);

    const inputHandler = (e) => {
        setInputs(prevState => ({
            ...prevState, [e.target.name]: e.target.value
        }))
    }

    const datePickerHandler = (e) => {
        let today = new Date();
        let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        let selected_date = e.target.value
        if (date.normalize() < selected_date.normalize()) {
            toast.error('تاریخ انتخابی نمیتواند بعد امروز باشد')
            return false
        } else {
            let date2 = e.target.value
            setInputs(prevState => ({
                ...prevState, [e.target.name]: date2?.split('T')[0]
            }))
        }
    }

    const thisUploadDocs = async (e) => {
        let files = e.target.files;
        let file = URL.createObjectURL(await fileUpload(files[0]))
        let upload_file = await fileUpload(files[0]);
        setInputs(prevState => ({
            ...prevState,
            [e.target.name + '_url']: file,
            [e.target.name]: upload_file,
            [e.target.name + '_file']: e.target.files
        }))
    }

    const removeImage = (val) => {
        setInputs(prevState => ({
            ...prevState, [val]: null, [val + '_url']: null, [val + '_file']: null
        }))

        if (val.search('identification_image') > -1) {
            setInputs(prevState => ({
                ...prevState, remove_identification_image: 1
            }))
        } else {
            setInputs(prevState => ({
                ...prevState, remove_birth_certificate_image: 1
            }))
        }
    }


    /* insurance-certificate */
    const uploadInsuranceCertificate = async (e) => {
        let files = e.target.files;
        let file = URL.createObjectURL(await fileUpload(files[0]))
        let upload_file = await fileUpload(files[0]);

        let formData = new FormData()

        if (upload_file) {
            formData.append('insurance_certificate', upload_file)
        }

        inputs['insurance_certificate'] === null && formData.append('remove_insurance_certificate', 1)

        let api_result = await getFormDataPost('profile/insurance-certificate', formData)

        if (api_result?.status === 200) {
            toast.success('با موفقیت افزوده شد')
            setError('')
            setInputs(prevState => ({
                ...prevState,
                [e.target.name + '_url']: file,
                [e.target.name]: upload_file,
                [e.target.name + '_file']: e.target.files
            }))
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }

    const removeInsuranceCertificateImage = async (val) => {

        let formData = new FormData()

        formData.append('remove_insurance_certificate', 1)

        let api_result = await getFormDataPost('profile/insurance-certificate', formData)

        if (api_result?.status === 200) {
            toast.success('با موفقیت حذف شد')
            setError('')
            setInputs(prevState => ({
                ...prevState, [val]: null, [val + '_url']: null, [val + '_file']: null
            }))
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }

    const changeArrow = (id) => {
        if (arrow_item.arrow_id === id && arrow_item.arrow_status === true) {

            setArrowItem({arrow_id: id, arrow_status: false})

        } else if (arrow_item.arrow_id === id && arrow_item.arrow_status === false) {

            setArrowItem({arrow_id: id, arrow_status: true})

        } else {
            setArrowItem({arrow_id: id, arrow_status: true})
        }

        let data = inputs?.family_members?.find((row) => {
            return row.id === id
        })
        if (data) {
            setInputs((prevState => ({
                ...prevState,
                [`name[${id}]`]: data?.name,
                [`full_name[${id}]`]: data?.full_name,
                [`father_name[${id}]`]: data?.father_name,
                [`relation[${id}]`]: data?.relation,
                [`identification_code[${id}]`]: data?.identification_code,
                [`birth_certificate_id[${id}]`]: data?.birth_certificate_id,
                [`birth_date[${id}]`]: data?.birth_date,
                [`child_gender[${id}]`]: data?.child_gender,
                [`telephone[${id}]`]: data?.telephone,
                [`supplementary_insurance[${id}]`]: data?.supplementary_insurance,
                [`supplementary_insurance_status[${id}]`]: data?.supplementary_insurance ? 'بله' : 'خیر',
                [`identification_image[${id}]`]: data?.identification_image,
                [`birth_certificate_image[${id}]`]: data?.birth_certificate_image
            })))
        }
    }

    const removeTab = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این مورد را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                let remove_result = await getData(MAIN_URL, `profile/family-members/remove/${id}`, 'post', {}, true, true);
                if (remove_result?.status === 200) {
                    swalWithBootstrapButtons.fire(
                        'حذف شد!',
                        'با موفقیت حذف شد',
                        'success'
                    )
                    setRender(!is_render)
                }
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'لغو شد',
                    ' بدون تغییر باقی ماند',
                    'error'
                )
            }
        })
    }
    const removeTab2 = async () => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این مورد را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                setNewCollapse(false)
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'لغو شد',
                    ' بدون تغییر باقی ماند',
                    'error'
                )
            }
        })
    }

    const saveEditChange = async (id) => {
        let formData = new FormData()
        if (inputs?.[`name[${id}]`]?.length > 0) {
            formData.append('name', inputs?.[`name[${id}]`])
        }
        if (inputs?.[`full_name[${id}]`]?.length > 0) {
            formData.append('full_name', inputs?.[`full_name[${id}]`])
        }
        if (inputs?.[`father_name[${id}]`]?.length > 0) {
            formData.append('father_name', inputs?.[`father_name[${id}]`])
        }
        if (inputs?.[`identification_code[${id}]`]?.length > 0) {
            formData.append('identification_code', inputs?.[`identification_code[${id}]`])
        }
        if (inputs?.[`birth_certificate_id[${id}]`]?.length > 0) {
            formData.append('birth_certificate_id', inputs?.[`birth_certificate_id[${id}]`])
        }
        if (inputs?.[`telephone[${id}]`]?.length > 0) {
            if (!mobileRegex(inputs[`telephone[${id}]`])) {
                formData.append('telephone', inputs?.[`telephone[${id}]`])
            }
        }
        if (inputs?.[`supplementary_insurance_status[${id}]`] == 'بله') {
            formData.append('supplementary_insurance', inputs?.[`supplementary_insurance[${id}]`])
        } else {
            formData.append('supplementary_insurance', '')
        }

        formData.append('birth_date', inputs?.[`birth_date[${id}]`]?.split('T')[0])
        formData.append('relation', inputs?.[`relation[${id}]`])
        if (inputs?.[`child_gender[${id}]`] !== null) {
            formData.append('child_gender', inputs?.[`child_gender[${id}]`])
        }
        if (inputs?.[`identification_image[${id}]_file`]) {
            formData.append('identification_image', inputs?.[`identification_image[${id}]_file`][0])
        }
        if (inputs?.[`birth_certificate_image[${id}]_file`]) {
            formData.append('birth_certificate_image', inputs?.[`birth_certificate_image[${id}]_file`][0])
        }

        inputs?.['remove_identification_image'] && formData.append('remove_identification_image', id)
        inputs?.['remove_birth_certificate_image'] && formData.append('remove_birth_certificate_image', id)

        let api_result = await getFormDataPost(`profile/family-members/${id}`, formData)
        if (api_result?.status === 200) {
            toast.success('با موفقیت تغییر یافت')
            setError('')
            setInputs(prevState => ({
                ...prevState, [`identification_image[${id}]_file`]: null,
                [`birth_certificate_image[${id}]_file`]: null,
                remove_identification_image: null,
                remove_birth_certificate_image: null
            }))
            setRender(!is_render)
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }

    const addNewCollapse = () => {
        setInputs(prevState => ({
            ...prevState,
            full_name: null,
            name: null,
            relation: 'انتخاب کنید',
            identification_code: null,
            birth_certificate_id: null,
            birth_date: null,
            child_gender: 'انتخاب کنید',
            telephone: null,
            supplementary_insurance: null,
            supplementary_insurance_status_type: 'خیر',
            identification_image2: null,
            identification_image: null,
            birth_certificate_image: null,
            birth_certificate_image2: null,
        }));
        setNewCollapse(true)
    }

    const saveNewCollapse = async () => {
        let formData = new FormData()
        if (inputs['name']?.length > 0) {
            formData.append('name', inputs['name'])
        }
        if (inputs['full_name']?.length > 0) {
            formData.append('full_name', inputs['full_name'])
        }
        if (inputs['father_name']?.length > 0) {
            formData.append('father_name', inputs['father_name'])
        }
        if (inputs['identification_code']?.length > 0) {
            formData.append('identification_code', inputs['identification_code'])
        }
        if (inputs['birth_certificate_id']?.length > 0) {
            formData.append('birth_certificate_id', inputs['birth_certificate_id'])
        }
        if (inputs['telephone']?.length > 0) {
            if (!mobileRegex(inputs['telephone'])) {
                formData.append('telephone', inputs['telephone'])
            }
        }
        if (inputs['supplementary_insurance']?.length > 0) {
            formData.append('supplementary_insurance', inputs['supplementary_insurance'])
        }

        formData.append('birth_date', inputs?.birth_date?.split('T')[0])
        formData.append('relation', inputs['relation'])
        if (inputs['child_gender'] !== 'انتخاب کنید') {
            formData.append('child_gender', inputs['child_gender'])
        }

        inputs['identification_image[3'] !== undefined && formData.append('identification_image', inputs['identification_image[3]'])
        inputs['birth_certificate_image[3]'] !== undefined && formData.append('birth_certificate_image', inputs['birth_certificate_image[3]'])

        !inputs['remove_identification_image'] && formData.append('remove_identification_image', 'remove')
        !inputs['remove_birth_certificate_image'] && formData.append('remove_birth_certificate_image', 'remove')

        let api_result = await getFormDataPost('profile/family-members', formData)

        if (api_result?.status === 200) {
            toast.success('با موفقیت افزوده شد')
            setError('')
            setRender(!is_render)
            setNewCollapse(false)
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }

    const formHandler = async (e) => {
        e.preventDefault()
        let formData = new FormData()
        if (inputs['name']?.length > 0) {
            formData.append('name', inputs['name'])
        }
        if (inputs['full_name']?.length > 0) {
            formData.append('full_name', inputs['full_name'])
        }
        if (inputs['father_name']?.length > 0) {
            formData.append('father_name', inputs['father_name'])
        }
        if (inputs['identification_code']?.length > 0) {
            formData.append('identification_code', inputs['identification_code'])
        }
        if (inputs['birth_certificate_id']?.length > 0) {
            formData.append('birth_certificate_id', inputs['birth_certificate_id'])
        }
        if (inputs['telephone']?.length > 0) {
            if (!mobileRegex(inputs['telephone'])) {
                formData.append('telephone', inputs['telephone'])
            }
        }
        if (inputs['supplementary_insurance'] === 'انتخاب کنید') {
            formData.append('supplementary_insurance', '')
        } else {
            formData.append('supplementary_insurance', inputs['supplementary_insurance'])
        }

        formData.append('birth_date', inputs?.birth_date?.split('T')[0])
        formData.append('relation', inputs['relation'])
        if (inputs['child_gender'] !== 'انتخاب کنید') {
            formData.append('child_gender', inputs['child_gender'])
        }

        inputs['identification_image'] !== undefined && formData.append('identification_image', inputs['identification_image'])
        inputs['birth_certificate_image'] !== undefined && formData.append('birth_certificate_image', inputs['birth_certificate_image'])

        !inputs['remove_identification_image'] && formData.append('remove_identification_image', 'remove')
        !inputs['remove_birth_certificate_image'] && formData.append('remove_birth_certificate_image', 'remove')


        let api_result = await getFormDataPost('profile/family-members', formData)

        if (api_result?.status === 200) {
            toast.success('با موفقیت افزوده شد')
            setError('')
            setRender(!is_render)
            setInputs(prevState => ({
                ...prevState,
                birth_certificate_image: null,
                birth_certificate_image_url: null,
                identification_image: null,
                identification_image_url: null
            }))
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }

    const goToNextPage = () => {
        props.handleChange(2)
    }

    return (
        <>
            {
                !inputs?.["isLoad"] &&
                <>
                    <form onSubmit={formHandler} className="row">
                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                            <TabStatusComplete
                                style={{borderRightColor: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.border_right}}>
                                {
                                    more_data.sheet_status?.status === 'pending' || more_data.sheet_status?.status === 'denied' ?
                                        <span style={{
                                            color: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.color,
                                            backgroundColor: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.background
                                        }} className='icon-Iconly-Bulk-Info-Square afa-icon-tick'/> :
                                        <span style={{
                                            color: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.color,
                                            backgroundColor: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.background
                                        }} className='icon-tick afa-icon-tick'/>
                                }
                                <p className='mb-0 f-14 '>
                                    <span>وضعیت : </span><span>{more_data.status_colors?.[more_data.sheet_status?.status]?.title}</span>
                                </p>
                            </TabStatusComplete>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-8 mb-4">
                            {
                                more_data.sheet_status?.message &&
                                <TabStatusContent
                                    style={{borderRightColor: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.border_right}}>
                            <span className="icon-Iconly-Bulk-Message afa-icon-message">
                                <span className="path1"></span>
                                <span className="path2"></span>
                            </span>
                                    <p className='mb-0 f-14'>
                                        <span>پیام ادمین : </span><span>{more_data.sheet_status?.message}</span>
                                    </p>
                                </TabStatusContent>
                            }
                        </div>
                        {
                            inputs?.family_members?.length > 0 ?
                                <>
                                    <Accordion defaultActiveKey="0">
                                        {
                                            inputs?.family_members?.map((item, i) => (
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey={`${item.id}`} key={i}
                                                                      onClick={() => changeArrow(item.id)}>
                                                        <div className="d-flex flex-wrap">
                                                        <span className="icon-Delete dv-icon-delete pl-2 my-2"
                                                              onClick={() => removeTab(item.id)}></span>
                                                            {
                                                                item?.name && <span
                                                                    className="pl-4 f-14 my-2"> نام : {item?.name}</span>
                                                            }
                                                            {
                                                                item?.full_name && <span className="pl-4 f-14 my-2"> نام خانوادگی : {item?.full_name}</span>
                                                            }
                                                            {
                                                                item?.relation && <span
                                                                    className="pl-4 f-14 my-2"> نسبت : {item?.relation}</span>
                                                            }
                                                            {
                                                                item?.child_gender && <span className="pl-4 f-14 my-2"> جنسیت : {item?.child_gender}</span>
                                                            }
                                                            {
                                                                item?.identification_code && <span
                                                                    className="pl-4 f-14 my-2"> کد ملی : {item?.identification_code}</span>
                                                            }
                                                            {
                                                                item?.birth_date &&
                                                                <span
                                                                    className="pl-4 f-14 my-2 d-flex align-items-center"> تاریخ تولد : <p
                                                                    className='mb-0 px-2'>{moment(item?.birth_date).format('jYYYY-jMM-jDD')}</p></span>
                                                            }
                                                            {
                                                                item?.telephone && <span className="pl-4 f-14 my-2"> شماره تماس : {item?.telephone}</span>
                                                            }
                                                        </div>
                                                        <span
                                                            className={(arrow_item.arrow_id === item.id && arrow_item.arrow_status) ? "icon-cheveron-up dv-collapse-arrow" : "icon-cheveron-down dv-collapse-arrow"}/>
                                                    </Accordion.Toggle>

                                                    <Accordion.Collapse eventKey={`${item.id}`}>
                                                        <Card.Body className='row'>
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>نام
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <input
                                                                        className={error[`name[${item.id}]`] && 'afa-error-input'}
                                                                        type="text" name={`name[${item.id}]`}
                                                                        value={inputs?.[`name[${item.id}]`]}
                                                                        onChange={(e) =>
                                                                            just_persian(e.target.value) && setInputs(prevState => ({
                                                                                ...prevState,
                                                                                [`name[${item.id}]`]: e.target.value
                                                                            }))
                                                                        } placeholder='نام'/>
                                                                    <p className='afa-error-text'>{error[`name[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>نام خانوادگی
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <input
                                                                        className={error[`full_name[${item.id}]`] && 'afa-error-input'}
                                                                        type="text" name={`full_name[${item.id}]`}
                                                                        value={inputs?.[`full_name[${item.id}]`]}
                                                                        onChange={(e) =>
                                                                            just_persian(e.target.value) && setInputs(prevState => ({
                                                                                ...prevState,
                                                                                [`full_name[${item.id}]`]: e.target.value
                                                                            }))
                                                                        } placeholder='نام خانوادگی'/>
                                                                    <p className='afa-error-text'>{error[`full_name[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>نام پدر
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <input
                                                                        className={error[`father_name[${item.id}]`] && 'afa-error-input'}
                                                                        type="text" name={`father_name[${item.id}]`}
                                                                        value={inputs?.[`father_name[${item.id}]`]}
                                                                        onChange={(e) =>
                                                                            just_persian(e.target.value) && setInputs(prevState => ({
                                                                                ...prevState,
                                                                                [`father_name[${item.id}]`]: e.target.value
                                                                            }))
                                                                        } placeholder='نام پدر'/>
                                                                    <p className='afa-error-text'>{error[`father_name[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>نسبت
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <select
                                                                        className={error[`relation[${item.id}]`] && 'afa-error-input'}
                                                                        value={inputs?.[`relation[${item.id}]`]}
                                                                        onChange={inputHandler}
                                                                        name={`relation[${item.id}]`}>
                                                                        <option value='انتخاب کنید' disabled>انتخاب کنید
                                                                        </option>
                                                                        <option value="پدر">پدر</option>
                                                                        <option value="مادر">مادر</option>
                                                                        <option value="فرزند">فرزند</option>
                                                                        <option value="همسر">همسر</option>
                                                                    </select>
                                                                    <p className='afa-error-text'>{error[`relation[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>کد ملی
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <input
                                                                        className={error[`identification_code[${item.id}]`] && 'afa-error-input'}
                                                                        type="text"
                                                                        max={9999999999}
                                                                        maxLength={10}
                                                                        name={`identification_code[${item.id}]`}
                                                                        value={inputs?.[`identification_code[${item.id}]`]}
                                                                        onChange={(e) =>
                                                                            just_number(e.target.value) && setInputs(prevState => ({
                                                                                ...prevState,
                                                                                [`identification_code[${item.id}]`]: fixNumber(e.target.value)
                                                                            }))
                                                                        } placeholder='- - - - - - - - - - -'/>
                                                                    <p className='afa-error-text'>{error[`identification_code[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>

                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs className='mb-3'>
                                                                    <span>شماره شناسنامه
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <input
                                                                        className={error[`birth_certificate_id[${item.id}]`] && 'afa-error-input'}
                                                                        type="text"
                                                                        max={9999999999}
                                                                        maxLength={10}
                                                                        name={`birth_certificate_id[${item.id}]`}
                                                                        value={inputs?.[`birth_certificate_id[${item.id}]`]}
                                                                        onChange={(e) =>
                                                                            just_number(e.target.value) && setInputs(prevState => ({
                                                                                ...prevState,
                                                                                [`birth_certificate_id[${item.id}]`]: fixNumber(e.target.value)
                                                                            }))
                                                                        }
                                                                        placeholder='- - - - - - - - - -'/>
                                                                    <p className='afa-error-text'>{error[`birth_certificate_id[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>تاریخ تولد
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <DateInput
                                                                        value={inputs?.[`birth_date[${item.id}]`]}
                                                                        name={`birth_date[${item.id}]`}
                                                                        className={error[`birth_date[${item.id}]`] && 'afa-error-input'}
                                                                        placeholder={'تاریخ تولد را انتخاب کنید'}
                                                                        onChange={datePickerHandler}/>
                                                                    <p className='afa-error-text'>{error[`birth_date[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>
                                                            {
                                                                inputs?.[`relation[${item.id}]`] === 'فرزند' &&
                                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                    <Inputs>
                                                                        <span>جنسیت فرزند</span>
                                                                        <select
                                                                            className={error[`child_gender[${item.id}]`] && 'afa-error-input'}
                                                                            value={inputs?.[`child_gender[${item.id}]`]}
                                                                            onChange={inputHandler}
                                                                            name={`child_gender[${item.id}]`}>

                                                                            <option value='انتخاب کنید' disabled>انتخاب
                                                                                کنید
                                                                            </option>

                                                                            <option value="دختر">دختر</option>

                                                                            <option value="پسر">پسر</option>

                                                                        </select>
                                                                        <p className='afa-error-text'>{error[`child_gender[${item.id}]`]}</p>
                                                                    </Inputs>
                                                                </div>
                                                            }
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>شماره تماس(اختیاری)</span>
                                                                    <input
                                                                        className={error[`telephone[${item.id}]`] && 'afa-error-input'}
                                                                        type="text"
                                                                        max={99999999999}
                                                                        maxLength={11} name={`telephone[${item.id}]`}

                                                                        value={inputs?.[`telephone[${item.id}]`]}
                                                                        onChange={(e) =>
                                                                            just_number(e.target.value) && setInputs(prevState => ({
                                                                                ...prevState,
                                                                                [`telephone[${item.id}]`]: fixNumber(e.target.value)
                                                                            }))
                                                                        } placeholder='09123456789'/>
                                                                    <p className='afa-error-text'>{error[`telephone[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>

                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <span>تصاویر ضمیمه</span>
                                                                <FileInputs
                                                                    className={error[`identification_image[${item.id}]`] && 'afa-error-input'}>
                                                                    {
                                                                        (inputs[`identification_image[${item.id}]_url`] || inputs[`identification_image[${item.id}]`]) &&
                                                                        <div className="afa-img">
                                                                            <img
                                                                                src={inputs[`identification_image[${item.id}]_url`] ? inputs[`identification_image[${item.id}]_url`] : `${MAIN_URL_IMAGE}${inputs[`identification_image[${item.id}]`]}`}
                                                                                alt="آذر فولاد امین"/>
                                                                            <span
                                                                                className='icon-close-solid afa-remove-position'
                                                                                onClick={() => removeImage(`identification_image[${item.id}]`, item.id)}/>
                                                                        </div>
                                                                    }
                                                                    <label className="afa-center-pos">
                                                                        <span className='icon-cloud-computing'></span>
                                                                        <input type="file" accept="image/*"
                                                                               onChange={thisUploadDocs}
                                                                               name={`identification_image[${item.id}]`}
                                                                               className='dv-upload-file'/>
                                                                        {
                                                                            !inputs[`identification_image[${item.id}]`] &&
                                                                            <span
                                                                                className='upload-text'>کارت ملی</span>
                                                                        }
                                                                    </label>
                                                                </FileInputs>
                                                                <p className='afa-error-text'>{error[`identification_image[${item.id}]`]}</p>

                                                                <FileInputs
                                                                    className={error['birth_certificate_image'] && 'afa-error-input'}>

                                                                    {
                                                                        (inputs[`birth_certificate_image[${item.id}]_url`] || inputs[`birth_certificate_image[${item.id}]`]) &&
                                                                        <div className="afa-img">
                                                                            <img
                                                                                src={inputs[`birth_certificate_image[${item.id}]_url`] ? inputs[`birth_certificate_image[${item.id}]_url`] : `${MAIN_URL_IMAGE}${inputs[`birth_certificate_image[${item.id}]`]}`}
                                                                                alt="آذر فولاد امین"/>
                                                                            <span
                                                                                className='icon-close-solid afa-remove-position'
                                                                                onClick={() => removeImage(`birth_certificate_image[${item.id}]`, item.id)}/>
                                                                        </div>
                                                                    }
                                                                    <label className="afa-center-pos">
                                                                        <span className='icon-cloud-computing'></span>
                                                                        <input type="file" accept="image/*"
                                                                               onChange={thisUploadDocs}
                                                                               name={`birth_certificate_image[${item.id}]`}
                                                                               className='dv-upload-file'/>
                                                                        {
                                                                            !inputs[`birth_certificate_image[${item.id}]`] &&
                                                                            <span className='upload-text'>دو صفحه اول شناسنامه</span>
                                                                        }
                                                                    </label>
                                                                </FileInputs>
                                                                <p className='afa-error-text'>{error['birth_certificate_image']}</p>
                                                            </div>

                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <div className="d-flex flex-column">
                                                                <span
                                                                    className=' f-14 mb-2'>درخواست بیمه تکمیلی دارید؟</span>
                                                                    <div className="d-flex flex-wrap mb-3">
                                                                        <label className="afa-custom-checkbox ml-2">
                                                                            بله
                                                                            <input type="radio" value='بله'
                                                                                   onChange={inputHandler}
                                                                                   checked={inputs?.[`supplementary_insurance_status[${item.id}]`] === 'بله'}
                                                                                   name={`supplementary_insurance_status[${item.id}]`}/>
                                                                            <span className="checkmark"/>
                                                                        </label>
                                                                        <label className="afa-custom-checkbox ml-2">
                                                                            خیر
                                                                            <input type="radio" value='خیر'
                                                                                   onChange={inputHandler}
                                                                                   checked={inputs?.[`supplementary_insurance_status[${item.id}]`] === 'خیر'}
                                                                                   name={`supplementary_insurance_status[${item.id}]`}/>
                                                                            <span className="checkmark"/>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    inputs[`supplementary_insurance_status[${item.id}]`] === 'بله' &&
                                                                    <Inputs>
                                                                        <select
                                                                            className={error[`supplementary_insurance[${item?.id}]`] && 'afa-error-input'}
                                                                            value={inputs?.[`supplementary_insurance[${item?.id}]`]}
                                                                            onChange={inputHandler}
                                                                            name={`supplementary_insurance[${item?.id}]`}>
                                                                            <option value='انتخاب کنید' disabled>انتخاب
                                                                                کنید
                                                                            </option>
                                                                            {
                                                                                inputs?.insurance_types?.map((row, index) => (
                                                                                    <option value={row}
                                                                                            key={index}>{row}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                        <p className='afa-error-text'>{error[`supplementary_insurance[${item?.id}]`]}</p>
                                                                    </Inputs>
                                                                }
                                                            </div>

                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <span>اپلود گواهی بیمه</span>
                                                                <FileInputs
                                                                    className={error['insurance_certificate'] && 'afa-error-input'}>

                                                                    {
                                                                        inputs['insurance_certificate'] &&
                                                                        <div className="afa-img">
                                                                            <img
                                                                                src={
                                                                                    inputs?.insurance_certificate_url ? inputs?.insurance_certificate_url : `${MAIN_URL_IMAGE}${inputs?.insurance_certificate}`
                                                                                }
                                                                                alt="آذر فولاد امین"/>
                                                                            <span
                                                                                className='icon-close-solid afa-remove-position'
                                                                                onClick={() => removeInsuranceCertificateImage('insurance_certificate')}/>
                                                                        </div>
                                                                    }
                                                                    <label className="afa-center-pos">
                                                                        <span className='icon-cloud-computing'></span>
                                                                        <input type="file" accept="image/*"
                                                                               onChange={uploadInsuranceCertificate}
                                                                               name='insurance_certificate'
                                                                               className='dv-upload-file'/>
                                                                        {
                                                                            !(inputs['insurance_certificate'] ||
                                                                                inputs?.['insurance_certificate']) &&
                                                                            <span className='upload-text'>آپلود</span>
                                                                        }
                                                                    </label>
                                                                </FileInputs>
                                                                <p className='afa-error-text'>{error['insurance_certificate']}</p>
                                                            </div>

                                                            <div className="col-12 d-flex justify-content-center">
                                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                    <Inputs>
                                                                        <button type='button'
                                                                                onClick={() => saveEditChange(item.id)}
                                                                                className='afa-add-more-btn'>
                                                                            <span className='pr-3'>ذخیره تغییرات</span>
                                                                        </button>
                                                                    </Inputs>
                                                                </div>
                                                            </div>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            ))
                                        }

                                        {
                                            new_collapse &&
                                            <Card>
                                                <Accordion.Toggle as={Card.Header} eventKey={'0'}
                                                                  onClick={() => changeArrow('0')}>
                                                    <div className="d-flex flex-wrap">
                                                        <span className="icon-Delete dv-icon-delete pl-2 my-2"
                                                              onClick={() => removeTab2('rasoul')}></span>
                                                        {
                                                            inputs?.name && <span
                                                                className="pl-4 f-14 my-2"> نام : {inputs?.name}</span>
                                                        }
                                                        {
                                                            inputs?.full_name && <span className="pl-4 f-14 my-2"> نام خانوادگی : {inputs?.full_name}</span>
                                                        }
                                                        {
                                                            inputs?.relation && <span
                                                                className="pl-4 f-14 my-2"> نسبت : {inputs?.relation}</span>
                                                        }
                                                        {
                                                            inputs?.child_gender && <span className="pl-4 f-14 my-2"> جنسیت : {inputs?.child_gender}</span>
                                                        }
                                                        {
                                                            inputs?.identification_code && <span
                                                                className="pl-4 f-14 my-2"> کد ملی : {inputs?.identification_code}</span>
                                                        }
                                                        {
                                                            inputs?.birth_date &&
                                                            <span className="pl-4 f-14 my-2 d-flex align-items-center"> تاریخ تولد : <p
                                                                className='mb-0 px-2'>{moment(inputs?.birth_date).format('jYYYY-jMM-jDD')}</p></span>
                                                        }
                                                        {
                                                            inputs?.telephone && <span className="pl-4 f-14 my-2"> شماره تماس : {inputs?.telephone}</span>
                                                        }
                                                    </div>
                                                    <span
                                                        className={(arrow_item.arrow_id === 0 && arrow_item.arrow_status) ? "icon-cheveron-up dv-collapse-arrow" : "icon-cheveron-down dv-collapse-arrow"}/>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey={'0'}>
                                                    <Card.Body className='row'>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <span>نام
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <input
                                                                    className={error['name'] && 'afa-error-input'}
                                                                    type="text" name={'name'}
                                                                    value={inputs.name} onChange={(e) =>
                                                                    just_persian(e.target.value) && setInputs(prevState => ({
                                                                        ...prevState, 'name': e.target.value
                                                                    }))
                                                                } placeholder='نام'/>
                                                                <p className='afa-error-text'>{error['name']}</p>
                                                            </Inputs>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <span>نام خانوادگی
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <input
                                                                    className={error['full_name'] && 'afa-error-input'}
                                                                    type="text" name={'full_name'}
                                                                    value={inputs.full_name} onChange={(e) =>
                                                                    just_persian(e.target.value) && setInputs(prevState => ({
                                                                        ...prevState, 'full_name': e.target.value
                                                                    }))
                                                                } placeholder='نام خانوادگی'/>
                                                                <p className='afa-error-text'>{error['full_name']}</p>
                                                            </Inputs>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <span>نام پدر
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <input
                                                                    className={error['father_name'] && 'afa-error-input'}
                                                                    type="text" name={'father_name'}
                                                                    value={inputs.father_name} onChange={(e) =>
                                                                    just_persian(e.target.value) && setInputs(prevState => ({
                                                                        ...prevState, 'father_name': e.target.value
                                                                    }))
                                                                } placeholder='نام پدر'/>
                                                                <p className='afa-error-text'>{error['father_name']}</p>
                                                            </Inputs>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <span>نسبت
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <select
                                                                    className={error['relation'] && 'afa-error-input'}
                                                                    value={inputs.relation}
                                                                    onChange={inputHandler} name={'relation'}>
                                                                    <option value='انتخاب کنید' disabled>انتخاب کنید
                                                                    </option>
                                                                    <option value="پدر">پدر</option>
                                                                    <option value="مادر">مادر</option>
                                                                    <option value="فرزند">فرزند</option>
                                                                    <option value="همسر">همسر</option>
                                                                </select>
                                                                <p className='afa-error-text'>{error['relation']}</p>
                                                            </Inputs>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <span>کد ملی
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <input
                                                                    className={error['identification_code'] && 'afa-error-input'}
                                                                    type="text"
                                                                    max={9999999999}
                                                                    maxLength={10} name={'identification_code'}
                                                                    value={inputs.identification_code} onChange={(e) =>
                                                                    just_number(e.target.value) && setInputs(prevState => ({
                                                                        ...prevState,
                                                                        [`identification_code`]: fixNumber(e.target.value)
                                                                    }))
                                                                } placeholder='- - - - - - - - - - -'/>
                                                                <p className='afa-error-text'>{error['identification_code']}</p>
                                                            </Inputs>
                                                        </div>

                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs className='mb-3'>
                                                                <span>شماره شناسنامه
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <input
                                                                    className={error['birth_certificate_id'] && 'afa-error-input'}
                                                                    type="text"
                                                                    max={9999999999}
                                                                    maxLength={10}
                                                                    name={'birth_certificate_id'}
                                                                    value={inputs.birth_certificate_id} onChange={(e) =>
                                                                    just_number(e.target.value) && setInputs(prevState => ({
                                                                        ...prevState,
                                                                        [`birth_certificate_id`]: fixNumber(e.target.value)
                                                                    }))
                                                                }
                                                                    placeholder='- - - - - - - - - -'/>
                                                                <p className='afa-error-text'>{error['birth_certificate_id']}</p>
                                                            </Inputs>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <span>تاریخ تولد
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <DateInput
                                                                    value={inputs?.birth_date}
                                                                    name={'birth_date'}
                                                                    className={error['birth_date'] && 'afa-error-input'}
                                                                    placeholder={'تاریخ تولد را انتخاب کنید'}
                                                                    onChange={datePickerHandler}/>
                                                                <p className='afa-error-text'>{error['birth_date']}</p>
                                                            </Inputs>
                                                        </div>

                                                        {
                                                            inputs['relation'] === 'فرزند' &&
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">

                                                                <Inputs>

                                                                    <span>جنسیت فرزند</span>

                                                                    <select
                                                                        className={error['child_gender'] && 'afa-error-input'}
                                                                        value={inputs.child_gender}

                                                                        onChange={inputHandler} name={'child_gender'}>

                                                                        <option value='انتخاب کنید' disabled>انتخاب
                                                                            کنید
                                                                        </option>

                                                                        <option value="دختر">دختر</option>

                                                                        <option value="پسر">پسر</option>

                                                                    </select>

                                                                    <p className='afa-error-text'>{error['child_gender']}</p>

                                                                </Inputs>

                                                            </div>
                                                        }

                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">

                                                            <Inputs>

                                                                <span>شماره تماس(اختیاری)</span>

                                                                <input
                                                                    className={error['telephone'] && 'afa-error-input'}
                                                                    type="text"
                                                                    max={99999999999}
                                                                    maxLength={11} name={'telephone'}

                                                                    value={inputs.telephone} onChange={(e) =>
                                                                    just_number(e.target.value) && setInputs(prevState => ({
                                                                        ...prevState,
                                                                        [`telephone`]: fixNumber(e.target.value)
                                                                    }))
                                                                } placeholder='09123456789'/>

                                                                <p className='afa-error-text'>{error['telephone']}</p>

                                                            </Inputs>

                                                        </div>

                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <span>تصاویر ضمیمه</span>
                                                            <FileInputs
                                                                className={error['identification_image'] && 'afa-error-input'}>

                                                                {
                                                                    (inputs['identification_image'] || inputs['identification_image_url']) &&
                                                                    <div className="afa-img">
                                                                        <img
                                                                            src={inputs['identification_image_url'] ? inputs['identification_image_url'] : `${MAIN_URL_IMAGE}${inputs['identification_image']}`}
                                                                            alt="آذر فولاد امین"/>
                                                                        <span
                                                                            className='icon-close-solid afa-remove-position'
                                                                            onClick={() => removeImage('identification_image')}/>
                                                                    </div>
                                                                }
                                                                <label className="afa-center-pos">
                                                                    <span className='icon-cloud-computing'></span>
                                                                    <input type="file" accept="image/*"
                                                                           onChange={thisUploadDocs}
                                                                           name='identification_image'
                                                                           className='dv-upload-file'/>
                                                                    {
                                                                        !(inputs['identification_image'] || inputs['identification_image_url']) &&
                                                                        <span className='upload-text'>
                                                    کارت ملی
                                                    </span>
                                                                    }
                                                                </label>
                                                            </FileInputs>
                                                            <p className='afa-error-text'>{error['identification_image']}</p>

                                                            <FileInputs
                                                                className={error['birth_certificate_image'] && 'afa-error-input'}>

                                                                {
                                                                    (inputs['birth_certificate_image'] || inputs['birth_certificate_image_url']) &&
                                                                    <div className="afa-img">
                                                                        <img
                                                                            src={inputs['birth_certificate_image_url'] ? inputs['birth_certificate_image_url'] : `${MAIN_URL_IMAGE}${inputs['birth_certificate_image']}`}
                                                                            alt="آذر فولاد امین"/>
                                                                        <span
                                                                            className='icon-close-solid afa-remove-position'
                                                                            onClick={() => removeImage('birth_certificate_image')}/>
                                                                    </div>
                                                                }
                                                                <label className="afa-center-pos">
                                                                    <span className='icon-cloud-computing'></span>
                                                                    <input type="file" accept="image/*"
                                                                           onChange={thisUploadDocs}
                                                                           name='birth_certificate_image'
                                                                           className='dv-upload-file'/>
                                                                    {
                                                                        !(inputs['birth_certificate_image'] || inputs['birth_certificate_image_url']) &&
                                                                        <span className='upload-text'>
                                                    دو صفحه اول شناسنامه
                                                    </span>
                                                                    }
                                                                </label>
                                                            </FileInputs>
                                                            <p className='afa-error-text'>{error['birth_certificate_image']}</p>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <div className="d-flex flex-column">
                                                                <span
                                                                    className=' f-14 mb-2'>درخواست بیمه تکمیلی دارید؟</span>
                                                                <div className="d-flex flex-wrap mb-3">
                                                                    <label className="afa-custom-checkbox ml-2">
                                                                        بله
                                                                        <input type="radio" value='بله'
                                                                               onChange={inputHandler}
                                                                               checked={inputs.supplementary_insurance_status === 'بله'}
                                                                               name="supplementary_insurance_status"/>
                                                                        <span className="checkmark"/>
                                                                    </label>
                                                                    <label className="afa-custom-checkbox ml-2">
                                                                        خیر
                                                                        <input type="radio" value='خیر'
                                                                               onChange={inputHandler}
                                                                               checked={inputs.supplementary_insurance_status === 'خیر'}
                                                                               name="supplementary_insurance_status"/>
                                                                        <span className="checkmark"/>
                                                                    </label>

                                                                </div>
                                                            </div>
                                                            {
                                                                inputs['supplementary_insurance_status'] === 'بله' &&
                                                                <Inputs>
                                                                    <select
                                                                        className={error['supplementary_insurance'] && 'afa-error-input'}
                                                                        value={inputs.supplementary_insurance}
                                                                        onChange={inputHandler}
                                                                        name={'supplementary_insurance'}>
                                                                        <option value='انتخاب کنید' disabled>انتخاب
                                                                            کنید
                                                                        </option>
                                                                        {
                                                                            inputs?.insurance_types?.map((row, index) => (
                                                                                <option value={row}
                                                                                        key={index}>{row}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                    <p className='afa-error-text'>{error['supplementary_insurance']}</p>
                                                                </Inputs>
                                                            }
                                                        </div>

                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <span>اپلود گواهی بیمه</span>
                                                            <FileInputs
                                                                className={error['insurance_certificate'] && 'afa-error-input'}>

                                                                {
                                                                    inputs['insurance_certificate'] &&
                                                                    <div className="afa-img">
                                                                        <img
                                                                            src={
                                                                                inputs?.insurance_certificate_url ? inputs?.insurance_certificate_url : `${MAIN_URL_IMAGE}${inputs?.insurance_certificate}`
                                                                            }
                                                                            alt="آذر فولاد امین"/>
                                                                        <span
                                                                            className='icon-close-solid afa-remove-position'
                                                                            onClick={() => removeInsuranceCertificateImage('insurance_certificate')}/>
                                                                    </div>
                                                                }
                                                                <label className="afa-center-pos">
                                                                    <span className='icon-cloud-computing'></span>
                                                                    <input type="file" accept="image/*"
                                                                           onChange={uploadInsuranceCertificate}
                                                                           name='insurance_certificate'
                                                                           className='dv-upload-file'/>
                                                                    {
                                                                        !(inputs['insurance_certificate'] ||
                                                                            inputs?.['insurance_certificate']) &&
                                                                        <span className='upload-text'>آپلود</span>
                                                                    }
                                                                </label>
                                                            </FileInputs>
                                                            <p className='afa-error-text'>{error['insurance_certificate']}</p>
                                                        </div>

                                                        <div className="col-12 d-flex justify-content-center">
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <button type='button'
                                                                            onClick={saveNewCollapse}
                                                                            className='afa-add-more-btn'>
                                                                        <span className='pr-3'>ذخیره تغییرات</span>
                                                                    </button>
                                                                </Inputs>
                                                            </div>
                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        }

                                    </Accordion>

                                    <div className="row d-flex flex-column flex-sm-row justify-content-between">
                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                            <Inputs>

                                                <button type='button' onClick={addNewCollapse}>

                                                    <span className='icon-add f-20'/>

                                                    <span className='pr-3'>افزودن اطلاعات</span>

                                                </button>

                                            </Inputs>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                            <Inputs>
                                                <button type='button' className={'dv-save-information-btn'}
                                                        onClick={goToNextPage}>
                                                    <span>مرحله بعد</span>
                                                    <LeftIcon><span className='icon-cheveron-left'/></LeftIcon>
                                                </button>
                                            </Inputs>
                                        </div>
                                    </div>
                                </> :
                                <>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <span>نام
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <input className={error['name'] && 'afa-error-input'} type="text"
                                                   name={'name'}
                                                   value={inputs.name} onChange={(e) =>
                                                just_persian(e.target.value) && setInputs(prevState => ({
                                                    ...prevState, 'name': e.target.value
                                                }))
                                            } placeholder='نام'/>
                                            <p className='afa-error-text'>{error['name']}</p>
                                        </Inputs>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <span>نام خانوادگی
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <input className={error['full_name'] && 'afa-error-input'} type="text"
                                                   name={'full_name'}
                                                   value={inputs.full_name} onChange={(e) =>
                                                just_persian(e.target.value) && setInputs(prevState => ({
                                                    ...prevState, 'full_name': e.target.value
                                                }))
                                            } placeholder='نام خانوادگی'/>
                                            <p className='afa-error-text'>{error['full_name']}</p>
                                        </Inputs>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <span>نام پدر
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <input className={error['father_name'] && 'afa-error-input'} type="text"
                                                   name={'father_name'}
                                                   value={inputs.father_name} onChange={(e) =>
                                                just_persian(e.target.value) && setInputs(prevState => ({
                                                    ...prevState, 'father_name': e.target.value
                                                }))
                                            } placeholder='نام پدر'/>
                                            <p className='afa-error-text'>{error['father_name']}</p>
                                        </Inputs>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <span>نسبت
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <select className={error['relation'] && 'afa-error-input'}
                                                    value={inputs.relation}
                                                    onChange={inputHandler} name={'relation'}>
                                                <option value='انتخاب کنید' disabled>انتخاب کنید</option>
                                                <option value="پدر">پدر</option>
                                                <option value="مادر">مادر</option>
                                                <option value="فرزند">فرزند</option>
                                                <option value="همسر">همسر</option>
                                            </select>
                                            <p className='afa-error-text'>{error['relation']}</p>
                                        </Inputs>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <span>کد ملی
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <input className={error['identification_code'] && 'afa-error-input'}
                                                   type="text"
                                                   max={9999999999}
                                                   maxLength={10}
                                                   name={'identification_code'}
                                                   value={inputs.identification_code} onChange={(e) =>
                                                just_number(e.target.value) && setInputs(prevState => ({
                                                    ...prevState, [`identification_code`]: fixNumber(e.target.value)
                                                }))
                                            } placeholder='- - - - - - - - - - -'/>
                                            <p className='afa-error-text'>{error['identification_code']}</p>
                                        </Inputs>
                                    </div>

                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs className='mb-3'>
                                            <span>شماره شناسنامه
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <input className={error['birth_certificate_id'] && 'afa-error-input'}
                                                   type="text"
                                                   max={9999999999}
                                                   maxLength={10}
                                                   name={'birth_certificate_id'} value={inputs.birth_certificate_id}
                                                   onChange={(e) =>
                                                       just_number(e.target.value) && setInputs(prevState => ({
                                                           ...prevState, [`birth_certificate_id`]: fixNumber(e.target.value)
                                                       }))
                                                   }
                                                   placeholder='- - - - - - - - - -'/>
                                            <p className='afa-error-text'>{error['birth_certificate_id']}</p>
                                        </Inputs>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <span>تاریخ تولد
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <DateInput
                                                value={inputs?.birth_date}
                                                name={'birth_date'}
                                                className={error['birth_date'] && 'afa-error-input'}
                                                placeholder={'تاریخ تولد را انتخاب کنید'}
                                                onChange={datePickerHandler}/>
                                            <p className='afa-error-text'>{error['birth_date']}</p>
                                        </Inputs>
                                    </div>

                                    {
                                        inputs['relation'] === 'فرزند' &&
                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">

                                            <Inputs>

                                                <span>جنسیت فرزند</span>

                                                <select className={error['child_gender'] && 'afa-error-input'}
                                                        value={inputs.child_gender}

                                                        onChange={inputHandler} name={'child_gender'}>

                                                    <option value='انتخاب کنید' disabled>انتخاب کنید</option>

                                                    <option value="دختر">دختر</option>

                                                    <option value="پسر">پسر</option>

                                                </select>

                                                <p className='afa-error-text'>{error['child_gender']}</p>

                                            </Inputs>

                                        </div>
                                    }

                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">

                                        <Inputs>

                                            <span>شماره تماس(اختیاری)</span>

                                            <input className={error['telephone'] && 'afa-error-input'} type="text"
                                                   max={99999999999}
                                                   maxLength={11}
                                                   name={'telephone'}

                                                   value={inputs.telephone} onChange={(e) =>
                                                just_number(e.target.value) && setInputs(prevState => ({
                                                    ...prevState, [`telephone`]: fixNumber(e.target.value)
                                                }))
                                            } placeholder='09123456789'/>

                                            <p className='afa-error-text'>{error['telephone']}</p>

                                        </Inputs>

                                    </div>

                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">

                                        <span>تصاویر ضمیمه</span>

                                        <FileInputs className={error['identification_image'] && 'afa-error-input'}>
                                            {
                                                (inputs['identification_image'] || inputs['identification_image_url']) &&
                                                <div className="afa-img">

                                                    <img
                                                        src={inputs['identification_image_url'] ? inputs['identification_image_url'] : `${MAIN_URL_IMAGE}${inputs['identification_image']}`}
                                                        alt="آذر فولاد امین"/>

                                                    <span className='icon-close-solid afa-remove-position'
                                                          onClick={() => removeImage('identification_image')}/>
                                                </div>
                                            }
                                            <label className="afa-center-pos">
                                                <span className='icon-cloud-computing'></span>
                                                <input type="file" accept="image/*"
                                                       onChange={thisUploadDocs}
                                                       name='identification_image'
                                                       className='dv-upload-file'/>
                                                {
                                                    !(inputs['identification_image'] || inputs['identification_image2']) &&
                                                    <span className='upload-text'>کارت ملی</span>
                                                }

                                            </label>

                                        </FileInputs>

                                        <p className='afa-error-text'>{error['identification_image']}</p>


                                        <FileInputs className={error['birth_certificate_image'] && 'afa-error-input'}>
                                            {
                                                (inputs['birth_certificate_image'] || inputs['birth_certificate_image_url']) &&
                                                <div className="afa-img">

                                                    <img
                                                        src={inputs['birth_certificate_image_url'] ? inputs['birth_certificate_image_url'] : `${MAIN_URL_IMAGE}${inputs['birth_certificate_image']}`}
                                                        alt="آذر فولاد امین"/>

                                                    <span className='icon-close-solid afa-remove-position'

                                                          onClick={() => removeImage('birth_certificate_image')}/>

                                                </div>
                                            }

                                            <label className="afa-center-pos">

                                                <span className='icon-cloud-computing'></span>

                                                <input type="file" accept="image/*"

                                                       onChange={thisUploadDocs}

                                                       name='birth_certificate_image'

                                                       className='dv-upload-file'/>

                                                {

                                                    !(inputs['birth_certificate_image'] || inputs['birth_certificate_image_url']) &&

                                                    <span className='upload-text'>دو صفحه اول شناسنامه</span>

                                                }

                                            </label>
                                        </FileInputs>
                                        <p className='afa-error-text'>{error['birth_certificate_image']}</p>
                                    </div>

                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <div className="d-flex flex-column">
                                            <span className=' f-14 mb-2'>درخواست بیمه تکمیلی دارید؟</span>
                                            <div className="d-flex flex-wrap mb-3">
                                                <label className="afa-custom-checkbox ml-2">
                                                    بله
                                                    <input type="radio" value='بله' onChange={() => {
                                                        setInputs(prevState => ({
                                                            ...prevState,
                                                            'supplementary_insurance_status': 'بله'
                                                        }))
                                                    }}
                                                           checked={inputs?.supplementary_insurance_status == 'بله'}
                                                           name="supplementary_insurance_status"/>
                                                    <span className="checkmark"/>
                                                </label>
                                                <label className="afa-custom-checkbox ml-2">خیر
                                                    <input type="radio" value='خیر' onChange={() => {
                                                        setInputs(prevState => ({
                                                            ...prevState,
                                                            'supplementary_insurance_status': 'خیر'
                                                        }))
                                                    }}
                                                           checked={inputs?.supplementary_insurance_status == 'خیر'}
                                                           name="supplementary_insurance_status"/>
                                                    <span className="checkmark"/>

                                                </label>
                                            </div>
                                        </div>
                                        {
                                            inputs['supplementary_insurance_status'] === 'بله' &&
                                            <Inputs>

                                                <select
                                                    className={error['supplementary_insurance'] && 'afa-error-input'}

                                                    value={inputs.supplementary_insurance}

                                                    onChange={inputHandler} name={'supplementary_insurance'}>

                                                    <option value='انتخاب کنید' disabled>انتخاب کنید</option>
                                                    {
                                                        inputs?.insurance_types?.map((row, index) => (
                                                            <option value={row} key={index}>{row}</option>
                                                        ))
                                                    }
                                                </select>

                                                <p className='afa-error-text'>{error['supplementary_insurance']}</p>

                                            </Inputs>
                                        }
                                    </div>

                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <span>اپلود گواهی بیمه</span>
                                        <FileInputs
                                            className={error['insurance_certificate'] && 'afa-error-input'}>

                                            {
                                                inputs['insurance_certificate'] &&
                                                <div className="afa-img">
                                                    <img
                                                        src={
                                                            inputs?.insurance_certificate_url ? inputs?.insurance_certificate_url : `${MAIN_URL_IMAGE}${inputs?.insurance_certificate}`
                                                        }
                                                        alt="آذر فولاد امین"/>
                                                    <span
                                                        className='icon-close-solid afa-remove-position'
                                                        onClick={() => removeInsuranceCertificateImage('insurance_certificate')}/>
                                                </div>
                                            }
                                            <label className="afa-center-pos">
                                                <span className='icon-cloud-computing'></span>
                                                <input type="file" accept="image/*"
                                                       onChange={uploadInsuranceCertificate}
                                                       name='insurance_certificate'
                                                       className='dv-upload-file'/>
                                                {
                                                    !(inputs['insurance_certificate'] ||
                                                        inputs?.['insurance_certificate']) &&
                                                    <span className='upload-text'>آپلود</span>
                                                }
                                            </label>
                                        </FileInputs>
                                        <p className='afa-error-text'>{error['insurance_certificate']}</p>
                                    </div>

                                    <div className="col-12">
                                        <div className="row d-flex flex-column flex-sm-row justify-content-between">
                                            <div className={"col-12 col-sm-6 col-lg-4 mb-4"}>
                                                <Inputs>
                                                    <button type='submit'>
                                                        <span className='icon-add f-20'/>
                                                        <span className='pr-3'>ثبت این صفحه</span>
                                                    </button>
                                                </Inputs>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                <Inputs>
                                                    <button type='button' className={'dv-save-information-btn'}
                                                            onClick={goToNextPage}>
                                                        <span>مرحله بعد</span>
                                                        <LeftIcon><span className='icon-cheveron-left'/></LeftIcon>
                                                    </button>
                                                </Inputs>
                                            </div>
                                        </div>
                                    </div>
                                </>
                        }
                    </form>
                </>
            }
        </>
    )
}

export default FamilyInformation;