import React, {useEffect, useState} from 'react';
import {fileUpload, getFormDataPost, setTitle} from "../../../../assets/scripts/GeneralFunctions";
import {MainContent} from "../style";
import {Images} from "../../../../assets/scripts/Images";
import {Inputs, ArticleFile} from "../../Child/Supplier/Child/style";
import {MAIN_URL_IMAGE} from "../../../../assets/scripts/GeneralVariables";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";

function NewTestimonial(props) {
    const [inputs, setInputs] = useState({announcer: '', image_url: '', image: '' , locale : 'fa' , testimonial : ''})

    const [error, setError] = useState({})

    const history = useHistory()

    useEffect(() => {
        setTitle('آذر فولاد امین | افزودن توصیه نامه')
    }, []);


    const thisUploadDocs = async (e) => {
        let files = e.target.files;
        let file = URL.createObjectURL(await fileUpload(files[0]))
        let upload_file = await fileUpload(files[0]);
        setInputs(prevState => ({
            ...prevState,
            [e.target.name + '_url']: file,
            [e.target.name]: upload_file,
            [e.target.name + '_file']: e.target.files[0]
        }))
    }

    const removeImage = (val) => {

        setInputs(prevState => ({
            ...prevState, [val]: null, [val + '_url']: null, [val + '_file']: null
        }))
    }
    const formHandler = async (e) => {
        e.preventDefault()

        let formData = new FormData()
        formData.append(`announcer`, inputs?.announcer)
        formData.append(`image`, inputs?.image)
        formData.append(`testimonial`, inputs?.testimonial)

        let api_result = await getFormDataPost(`testimonial-management/testimonials/create/${inputs?.locale}`, formData)

        if (api_result?.status === 200) {
            setError('')
            toast.success('با موفقیت ثبت شد')
            history.push({
                pathname: '/manage-testimonial',
            })
        }else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }

    }
    return (
        <MainContent>
            <form onSubmit={formHandler} className='row'>
                <div className="col-12 w-100 d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center mb-3">
                    <h6 className='text-red fw-bold'>افزودن توصیه نامه</h6>
                </div>
                <div className="col-12 col-md-8 mb-3">
                    <div className="row px-0">
                        <div className="col-12 col-md-6 mb-3">
                            <Inputs>
                                <span>نام شخص</span>
                                <input
                                    className={'afa-error-input'}
                                    maxLength={100}
                                    type="text" name={`announcer`}
                                    value={inputs?.[`announcer`]}
                                    onChange={(e) =>
                                        setInputs(prevState => ({
                                            ...prevState,
                                            [`announcer`]: e.target.value
                                        }))
                                    }/>
                                <p className='afa-error-text'>{error[`announcer`]}</p>
                            </Inputs>
                        </div>
                        <div className="col-12 col-md-6 mb-3">
                            <Inputs>
                                <span>زبان</span>
                                <select
                                    className={'afa-error-input'}
                                    name={`locale`}
                                    value={inputs?.[`locale`]}
                                    onChange={(e) =>
                                        setInputs(prevState => ({
                                            ...prevState,
                                            [`locale`]: e.target.value
                                        }))
                                    }>
                                    <option value="fa">فارسی</option>
                                    <option value="en">انگلیسی</option>
                                    <option value="ar">عربی</option>
                                    <option value="tr">ترکی</option>
                                </select>
                                <p className='afa-error-text'>{error[`locale`]}</p>
                            </Inputs>
                        </div>
                    </div>
                    <Inputs>
                        <span>توضیحات توصیه نامه</span>
                        <textarea
                            className={inputs['testimonial'] && 'afa-error-input'}
                            rows="3"
                            name={`testimonial`}
                            value={inputs?.[`testimonial`]}
                            onChange={(e) =>
                                setInputs(prevState => ({
                                    ...prevState,
                                    [`testimonial`]: e.target.value
                                }))
                            }/>
                        <p className='afa-error-text'>{error[`testimonial`]}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-md-4 mb-3">
                    <div className="afa-upload-file-parent">
                        <span className='f-14'>تصویر شخص</span>
                        <ArticleFile className={error[`image`] && 'afa-error-input'}>
                            {
                                (inputs[`image_url`] || inputs[`image`]) ?
                                    <div className="afa-img">
                                        <img
                                            src={inputs[`image_url`] ? inputs[`image_url`] : `${MAIN_URL_IMAGE}${inputs[`image`]}`}
                                            alt="آذر فولاد امین"/>
                                        <span
                                            className='afa-remove-position icon-delete'
                                            onClick={() => removeImage(`image`)}/>
                                    </div> :
                                    <label
                                        className="afa_upload_file d-flex flex-column align-items-center justify-content-center">
                                        <img src={Images?.article_upload?.default}
                                             className='afa-article-image-to-upload' alt="آذر فولاد امین"/>
                                        <input type="file" accept="image/*"
                                               onChange={thisUploadDocs}
                                               name={`image`}
                                               className='dv-upload-file'/>
                                        {
                                            !inputs[`image`] &&
                                            <span className='upload-text'>اپلود عکس مربوط به شخص</span>
                                        }
                                    </label>
                            }
                        </ArticleFile>
                        <p className='afa-error-text'>{error[`image`]}</p>
                    </div>
                </div>


                <div className="col-12 d-flex align-items-center justify-content-center mb-4">
                    <Inputs>
                        <button type='submit'
                                className='m-auto afa-save-super-admin-btn'>
                            <span className='pr-3'>ذخیره</span>
                        </button>
                    </Inputs>
                </div>
            </form>
        </MainContent>
    );
}

export default NewTestimonial;