import React, {useState, useEffect} from 'react';
import {MainContent} from "../style";
import {Images} from "../../../../assets/scripts/Images";
import {Inputs, ProductFile} from "../../Child/Supplier/Child/style";
import {MAIN_URL, MAIN_URL_IMAGE} from "../../../../assets/scripts/GeneralVariables";
import {fileUpload, getData, getFormDataPost, setTitle} from "../../../../assets/scripts/GeneralFunctions";

import 'react-tagsinput/react-tagsinput.css'
import {Link} from "react-router-dom";
import Swal from "sweetalert2";

function ProductInfo(props) {
    const [inputs, setInputs] = useState({
        title_fa: '', title_en: '',title_tr: '', title_ar: '',
        description_fa: '', description_en: '',description_tr: '', description_ar: '',
        longitudinal_weight_zero: '', longitudinal_weight_one: '',
        tensile_strength_zero: '', tensile_strength_one: '',
        surrender_tension_zero: '', surrender_tension_one: '',
        bending_jaw: '',
        standard_equivalent_to_GOST: '',
        characteristic_sign: '',
        image: '', image_file: '', image_url: '',
        product_id: null, code: null,
        product_categories: [],
        product_category_id : 'انتخاب کنید',
    })
    const [error, setError] = useState({})

    useEffect(async () => {
        setTitle('آذر فولاد امین | توضیحات محصول')
        let product_id = props.match.params.product_id;
        let api_result = await getData(MAIN_URL, `product-management/products/info/${product_id}`, 'get', {}, true, true);
        if (api_result?.status === 200) {
            let product = api_result?.data?.product
            setInputs(prevState => ({
                ...prevState,
                title_fa: product?.title?.fa,
                title_en: product?.title?.en,
                title_tr: product?.title?.tr,
                title_ar: product?.title?.ar,
                description_fa: product?.description?.fa,
                description_en: product?.description?.en,
                description_tr: product?.description?.tr,
                description_ar: product?.description?.ar,
                longitudinal_weight_zero: product?.longitudinal_weight[0],
                longitudinal_weight_one: product?.longitudinal_weight[1],
                tensile_strength_zero: product?.tensile_strength[0],
                tensile_strength_one: product?.tensile_strength[1],
                surrender_tension_zero: product?.surrender_tension[0],
                surrender_tension_one: product?.surrender_tension[1],
                bending_jaw: product?.bending_jaw,
                standard_equivalent_to_GOST: product?.standard_equivalent_to_GOST,
                characteristic_sign: product?.characteristic_sign,
                code: product?.code,
                product_id: product_id,
                image: product?.image,
                product_category_id: product?.product_category_id,
                product_categories: JSON.parse(localStorage.getItem('product_categories'))
            }))
        }
    }, [])

    const thisUploadDocs = async (e) => {
        let files = e.target.files;
        let file = URL.createObjectURL(await fileUpload(files[0]))
        let upload_file = await fileUpload(files[0]);
        setInputs(prevState => ({
            ...prevState,
            [e.target.name + '_url']: file,
            [e.target.name]: upload_file,
            [e.target.name + '_file']: e.target.files[0]
        }))
    }

    const removeImage = (val) => {
        setInputs(prevState => ({
            ...prevState, [val]: null, [val + '_url']: null, [val + '_file']: null, [`remove_${val}`]: 1
        }))
    }


    const formHandler = async (e) => {
        e.preventDefault()
        let form_data = new FormData()
        form_data.append('title[fa]', inputs?.title_fa)
        form_data.append('title[en]', inputs?.title_en)
        form_data.append('title[tr]', inputs?.title_tr)
        form_data.append('title[ar]', inputs?.title_ar)
        form_data.append('code', inputs?.code)
        form_data.append('longitudinal_weight[0]', inputs?.longitudinal_weight_zero)
        form_data.append('longitudinal_weight[1]', inputs?.longitudinal_weight_one)
        form_data.append('bending_jaw', inputs?.bending_jaw)
        form_data.append('tensile_strength[0]', inputs?.tensile_strength_zero)
        form_data.append('tensile_strength[1]', inputs?.tensile_strength_one)
        form_data.append('surrender_tension[0]', inputs?.surrender_tension_zero)
        form_data.append('surrender_tension[1]', inputs?.surrender_tension_one)
        form_data.append('standard_equivalent_to_GOST', inputs?.standard_equivalent_to_GOST)
        form_data.append('characteristic_sign', inputs?.characteristic_sign)
        form_data.append('description[en]', inputs?.description_en)
        form_data.append('description[fa]', inputs?.description_fa)
        form_data.append('description[tr]', inputs?.description_tr)
        form_data.append('description[ar]', inputs?.description_ar)
        form_data.append('product_category', inputs?.product_category_id)
        if (inputs?.image_file !== undefined) {
            form_data.append('image', inputs?.image_file)
        }
        let api_result = await getFormDataPost(`product-management/products/edit/${inputs?.product_id}`, form_data);
        if (api_result?.status === 200) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                text: 'تغییرات با موفقیت ثبت شد',
                showConfirmButton: false,
                timer: 1500
            })
        }else{
            setError(api_result?.data)
        }
    }
    return (
        <MainContent>
            <form onSubmit={formHandler} className='row'>
                <div className="col-12 d-flex flex-column flex-md-row align-items-center justify-content-between mb-3">
                    <h6 className='text-red fw-bold'>ویرایش محصول</h6>
                    <Link to={`/manage-product/${inputs?.product_id}/product_price`} className='add-new-btn'>
                        <span className="icon-edit afa-product-edit-icon"/>
                        <span>ویرایش قیمت</span>
                    </Link>
                </div>
                <div className="col-12 col-md-8 mb-3">
                    <div className="row px-0">
                        <div className="col-12 col-md-6 mb-5">
                            <Inputs>
                                <span>نام محصول به فارسی</span>
                                <input
                                    className={'afa-error-input'}
                                    type="text" name={`title_fa`}
                                    value={inputs?.[`title_fa`]}
                                    onChange={(e) =>
                                        setInputs(prevState => ({
                                            ...prevState,
                                            [`title_fa`]: e.target.value
                                        }))
                                    }/>
                                <p className='afa-error-text'>{error[`title.fa`]}</p>
                            </Inputs>
                        </div>
                        <div className="col-12 col-md-6 mb-5">
                            <Inputs>
                                <span>نام محصول به انگلیسی</span>
                                <input
                                    type={'text'}
                                    className={'afa-error-input'}
                                    name={`title_en`}
                                    dir={'ltr'}
                                    value={inputs?.[`title_en`]}
                                    onChange={(e) =>
                                        setInputs(prevState => ({
                                            ...prevState,
                                            [`title_en`]: e.target.value
                                        }))
                                    }/>
                                <p className='afa-error-text'>{error[`title.en`]}</p>
                            </Inputs>
                        </div>
                        <div className="col-12 col-md-6 mb-5">
                            <Inputs>
                                <span>نام محصول به ترکی</span>
                                <input
                                    type={'text'}
                                    className={'afa-error-input'}
                                    name={`title_tr`}
                                    dir={'ltr'}
                                    value={inputs?.[`title_tr`]}
                                    onChange={(e) =>
                                        setInputs(prevState => ({
                                            ...prevState,
                                            [`title_tr`]: e.target.value
                                        }))
                                    }/>
                                <p className='afa-error-text'>{error[`title.tr`]}</p>
                            </Inputs>
                        </div>
                        <div className="col-12 col-md-6 mb-5">
                            <Inputs>
                                <span>نام محصول به عربی</span>
                                <input
                                    type={'text'}
                                    className={'afa-error-input'}
                                    name={`title_ar`}
                                    value={inputs?.[`title_ar`]}
                                    onChange={(e) =>
                                        setInputs(prevState => ({
                                            ...prevState,
                                            [`title_ar`]: e.target.value
                                        }))
                                    }/>
                                <p className='afa-error-text'>{error[`title.ar`]}</p>
                            </Inputs>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4 mb-3">
                    <div className="afa-upload-file-parent">
                        <span className='f-14'>تصویر محصول</span>
                        <ProductFile
                            className={error[`image`] && 'afa-error-input'}>
                            {
                                (inputs[`image_url`] || inputs[`image`]) ?
                                    <div className="afa-img">
                                        <img
                                            src={inputs[`image_url`] ? inputs[`image_url`] : `${MAIN_URL_IMAGE}${inputs[`image`]}`}
                                            alt="آذر فولاد امین"/>
                                        <span
                                            className='afa-remove-position icon-delete'
                                            onClick={() => removeImage(`image`)}/>
                                    </div> :
                                    <label
                                        className="afa_upload_file d-flex flex-column align-items-center justify-content-center">
                                        <img src={Images?.article_upload?.default}
                                             className='afa-article-image-to-upload' alt="آذر فولاد امین"/>
                                        <input type="file" accept="image/*"
                                               onChange={thisUploadDocs}
                                               name={`image`}
                                               className='dv-upload-file'/>
                                        {
                                            (!inputs[`image_url`] || !inputs[`image`]) &&
                                            <span className='upload-text'>اپلود عکس مربوط به محصول</span>
                                        }
                                    </label>
                            }
                        </ProductFile>
                        <p className='afa-error-text'>{error[`image`]}</p>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>حد پایین وزن طولی</span>
                        <input
                            type={'number'}
                            step={0.01}
                            className={'afa-error-input'}
                            name={`longitudinal_weight_zero`}
                            placeholder={'0.427'}
                            value={inputs?.[`longitudinal_weight_zero`]}
                            onChange={(e) =>
                                setInputs(prevState => ({
                                    ...prevState,
                                    [`longitudinal_weight_zero`]: e.target.value
                                }))
                            }/>
                        <p className='afa-error-text'>{error[`longitudinal_weight[0]`]}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-md-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>حد بالا وزن طولی</span>
                        <input
                            type={'number'}
                            step={0.01}
                            className={'afa-error-input'}
                            name={`longitudinal_weight_one`}
                            placeholder={'0.627'}
                            value={inputs?.[`longitudinal_weight_one`]}
                            onChange={(e) =>
                                setInputs(prevState => ({
                                    ...prevState,
                                    [`longitudinal_weight_one`]: e.target.value
                                }))
                            }/>
                        <p className='afa-error-text'>{error[`longitudinal_weight[1]`]}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-md-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>دسته بندی</span>
                        <select
                            className={'afa-error-input'}
                            name={`product_category_id`}
                            value={inputs?.[`product_category_id`]}
                            onChange={(e) =>
                                setInputs(prevState => ({
                                    ...prevState,
                                    [`product_category_id`]: e.target.value
                                }))
                            }>
                            <option value="انتخاب کنید" disabled={true}>انتخاب کنید</option>
                            {
                                inputs?.product_categories?.map((category)=>(
                                    <option value={category?.id}>{category?.title['fa']}</option>
                                ))
                            }
                        </select>
                        <p className='afa-error-text'>{error[`longitudinal_weight[1]`]}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-md-6 mb-4">
                    <Inputs>
                        <span>حد پایین تنش تسلیم</span>
                        <input
                            type={'number'}
                            step={0.01}
                            className={'afa-error-input'}
                            name={`tensile_strength_zero`}
                            placeholder={'0.427'}
                            value={inputs?.[`tensile_strength_zero`]}
                            onChange={(e) =>
                                setInputs(prevState => ({
                                    ...prevState,
                                    [`tensile_strength_zero`]: e.target.value
                                }))
                            }/>
                        <p className='afa-error-text'>{error[`tensile_strength[0]`]}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-md-6 mb-4">
                    <Inputs>
                        <span>حد بالا تنش تسلیمی</span>
                        <input
                            type={'number'}
                            step={0.01}
                            className={'afa-error-input'}
                            name={`tensile_strength_one`}
                            placeholder={'0.427'}
                            value={inputs?.[`tensile_strength_one`]}
                            onChange={(e) =>
                                setInputs(prevState => ({
                                    ...prevState,
                                    [`tensile_strength_one`]: e.target.value
                                }))
                            }/>
                        <p className='afa-error-text'>{error[`tensile_strength[1]`]}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-md-6 mb-4">
                    <Inputs>
                        <span>حد پایین مقاومت کشش</span>
                        <input
                            type={'number'}
                            step={0.01}
                            className={'afa-error-input'}
                            name={`surrender_tension_zero`}
                            placeholder={'0.427'}
                            value={inputs?.[`surrender_tension_zero`]}
                            onChange={(e) =>
                                setInputs(prevState => ({
                                    ...prevState,
                                    [`surrender_tension_zero`]: e.target.value
                                }))
                            }/>
                        <p className='afa-error-text'>{error[`surrender_tension[0]`]}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-md-6 mb-4">
                    <Inputs>
                        <span>حد بالا مقاومت کشش</span>
                        <input
                            type={'number'}
                            step={0.01}
                            className={'afa-error-input'}
                            name={`surrender_tension_one`}
                            placeholder={'0.427'}
                            value={inputs?.[`surrender_tension_one`]}
                            onChange={(e) =>
                                setInputs(prevState => ({
                                    ...prevState,
                                    [`surrender_tension_one`]: e.target.value
                                }))
                            }/>
                        <p className='afa-error-text'>{error[`surrender_tension[1]`]}</p>
                    </Inputs>
                </div>

                <div className="col-12 col-md-6 col-lg-3 mb-4">
                    <Inputs>
                        <span>قطر فک خمش</span>
                        <input
                            type={'number'}
                            step={0.01}
                            className={'afa-error-input'}
                            name={`bending_jaw`}
                            value={inputs?.[`bending_jaw`]}
                            onChange={(e) =>
                                setInputs(prevState => ({
                                    ...prevState,
                                    [`bending_jaw`]: e.target.value
                                }))
                            }/>
                        <p className='afa-error-text'>{error[`bending_jaw`]}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-md-6 col-lg-3 mb-4">
                    <Inputs>
                        <span>استاندارد معادل GOST</span>
                        <input
                            type={'text'}
                            // step={0.01}
                            className={'afa-error-input'}
                            name={`standard_equivalent_to_GOST`}
                            value={inputs?.[`standard_equivalent_to_GOST`]}
                            onChange={(e) =>
                                setInputs(prevState => ({
                                    ...prevState,
                                    [`standard_equivalent_to_GOST`]: e.target.value
                                }))
                            }/>
                        <p className='afa-error-text'>{error[`standard_equivalent_to_GOST`]}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-md-6 col-lg-3 mb-4">
                    <Inputs>
                        <span>علامت مشخصه</span>
                        <input
                            type={'text'}
                            // step={0.01}
                            className={'afa-error-input'}
                            name={`characteristic_sign`}
                            value={inputs?.[`characteristic_sign`]}
                            onChange={(e) =>
                                setInputs(prevState => ({
                                    ...prevState,
                                    [`characteristic_sign`]: e.target.value
                                }))
                            }/>
                        <p className='afa-error-text'>{error[`characteristic_sign`]}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-md-6 col-lg-3 mb-4">
                    <Inputs>
                        <span>کد محصول</span>
                        <input
                            type={'text'}
                            // step={0.01}
                            className={'afa-error-input'}
                            name={`code`}
                            value={inputs?.[`code`]}
                            onChange={(e) =>
                                setInputs(prevState => ({
                                    ...prevState,
                                    [`code`]: e.target.value
                                }))
                            }/>
                        <p className='afa-error-text'>{error[`code`]}</p>
                    </Inputs>
                </div>

                <div className="col-12">
                    <div className="row px-0">
                        <div className="col-12 col-md-6 mb-4">
                            <Inputs>
                                <span>توضیحات محصول به فارسی</span>
                                <textarea
                                    className={'afa-error-input'}
                                    cols={2} name={`description_fa`}
                                    value={inputs?.[`description_fa`]}
                                    onChange={(e) =>
                                        setInputs(prevState => ({
                                            ...prevState,
                                            [`description_fa`]: e.target.value
                                        }))
                                    }/>
                                <p className='afa-error-text'>{error[`description.fa`]}</p>
                            </Inputs>
                        </div>
                        <div className="col-12 col-md-6 mb-4">
                            <Inputs>
                                <span>توضیحات محصول به انگلیسی</span>
                                <textarea
                                    dir='ltr'
                                    className={'afa-error-input'}
                                    cols={2} name={`description_en`}
                                    value={inputs?.[`description_en`]}
                                    onChange={(e) =>
                                        setInputs(prevState => ({
                                            ...prevState,
                                            [`description_en`]: e.target.value
                                        }))
                                    }/>
                                <p className='afa-error-text'>{error[`description.en`]}</p>
                            </Inputs>
                        </div>
                        <div className="col-12 col-md-6 mb-4">
                            <Inputs>
                                <span>توضیحات محصول به ترکی</span>
                                <textarea
                                    dir='ltr'
                                    className={'afa-error-input'}
                                    cols={2} name={`description_tr`}
                                    value={inputs?.[`description_tr`]}
                                    onChange={(e) =>
                                        setInputs(prevState => ({
                                            ...prevState,
                                            [`description_tr`]: e.target.value
                                        }))
                                    }/>
                                <p className='afa-error-text'>{error[`description.tr`]}</p>
                            </Inputs>
                        </div>
                        <div className="col-12 col-md-6 mb-4">
                            <Inputs>
                                <span>توضیحات محصول به عربی</span>
                                <textarea
                                    className={'afa-error-input'}
                                    cols={2} name={`description_ar`}
                                    value={inputs?.[`description_ar`]}
                                    onChange={(e) =>
                                        setInputs(prevState => ({
                                            ...prevState,
                                            [`description_ar`]: e.target.value
                                        }))
                                    }/>
                                <p className='afa-error-text'>{error[`description.ar`]}</p>
                            </Inputs>
                        </div>
                    </div>
                </div>


                <div className="col-12 d-flex align-items-center justify-content-center my-4">
                    <Inputs>
                        <button type='submit'
                                className='m-auto afa-save-super-admin-btn'>
                            <span className='pr-3'>ذخیره</span>
                        </button>
                    </Inputs>
                </div>
            </form>
        </MainContent>
    );
}

export default ProductInfo;