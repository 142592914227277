import styled from "styled-components";

const dark_green = '#379521';
const custom_gray = '#ECECEC';
const custom_red = '#E30613';
const light_red = '#FC4F59';
const label_text = '#2F4858'

export const TabStatusComplete = styled.div`
  width: 100%;
  height: 65px;
  background-color: #F8F8F8;
  border-radius: 8px;
  border-right: 13px solid;
  display: flex;
  align-items: center;
  padding-right: 1rem;

  .afa-icon-tick{
    width: 24px;
    height: 24px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
  p {
    padding-right: 1rem;
  }
`

export const TabStatusNotComplete = styled.div`
  width: 100%;
  height: 65px;
  background-color: #F8F8F8;
  border-radius: 8px;
  border-right: 13px solid ${light_red};
  display: flex;
  align-items: center;
  padding-right: 1rem;

  .afa-icon-info{
    width: 24px;
    height: 24px;
    background-color: #F9D7D9;
    color: ${custom_red};
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
  p {
    padding-right: 1rem;
  }
`

export const TabStatusContent = styled.div`
  border-right: 13px solid;
  width: 100%;
  min-height: 65px;
  background-color: #F8F8F8;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding-right: 1rem;

  .afa-icon-message{
    font-size: 22px;
  }
  p {
    padding-right: 1rem;
  }
  
`

export const Inputs = styled.label`
  width: 100%;

  span {
    font-size: 14px;
    //margin-bottom: 0.6rem;
    color: ${label_text};
    font-weight: 500;
  }

  input, select , textarea {
    background-color: ${custom_gray};
    padding: 0.75rem 1rem;
    border-radius: 10px !important;
    width: 100%;
    color: #212121;
    border: none;

    &:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }

    &::placeholder {
      color: #C2C2C2;
    }
  }

  button {
    background-color: ${custom_red};
    border-radius: 50px;
    width: 100%;
    height: 48px;
    border: none;
    margin-bottom: 1rem;
    position: relative;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: #c71111;
    }

    span {
      color: #fff;
    }
    @media (max-width: 576px){
      padding-right: 1rem;
    }
    &.dv-save-information-btn{
    background-color : #16A596;
        justify-content: center;
    label{
        background-color : transparent !important;
    }
    }
  }

`

export const LeftIcon = styled.label`
  background-color: #C40E19;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  left: 4px;
  top: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: #fff;
    margin-bottom: 0;
    font-size: 24px;
  }
  @media (max-width: 576px){
    width: 30px;
    height: 30px;
    top: 9.5px;
  }
`

export const LargeFileInputs = styled.div`
  border: 1px dashed ${custom_red};
  border-radius: 8px;
  padding: 2rem;
  position: relative;
  height: 161px;
  width: 100%;
  background-color: ${custom_gray};

  .dv-upload-file {
    visibility: hidden;
  }

  .afa-center-pos {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #341a1a;
    display: flex;
    cursor: pointer;
  }

  span.upload-text {
    position: absolute;
    right: 45%;
    top: 10%;
    font-size: 14px;
  }
  span.upload-text.personal-text{
    right: 30%;
  }

  span.icon-cloud-computing {
    font-size: 32px;
    color: ${custom_red};
    position: absolute;
    right: 25%;
  }
  span.icon-cloud-computing.personal-icon {
    right: 15%;
  }

  .afa-perseneli-img {
    width: 88px;
    height: 76px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .afa-content-after-img {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-55%, 50%);
    color: #341a1a;
    display: flex;
    cursor: pointer;
  }
`

export const MiniFileInputs = styled.div`
  border: 1px dashed ${custom_red};
  border-radius: 8px;
  padding: 2rem;
  cursor: pointer;
  position: relative;
  height: 80px;
  width: 100%;
  background-color: ${custom_gray};

  .afa-img {
    width: 70px;
    height: 60px;
    position: absolute;
    top: 50%;
    left: 20%;
    transform: translate(-50%, -50%);
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .dv-upload-file {
    visibility: hidden;
  }

  .afa-center-pos {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #341a1a;
    display: flex;
  }

  .upload-text {
    color: #341a1a !important;
  }
  span.upload-text {
    position: absolute;
    right: 48%;
    top: 10%;
    font-size: 14px;
    @media (max-width: 576px) {
        right: 34.5% !important;
        top: 75% !important;
        font-size: 12px !important;
    }
  }

  span.icon-cloud-computing {
    font-size: 32px;
    color: ${custom_red};
    position: absolute;
    right: 33%;
  }
`

export const MiniFileInputsDocs = styled.div`
  border: 1px dashed ${custom_red};
  border-radius: 8px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  height: 105px;
  width: 100%;
  background-color: ${custom_gray};

  .afa-img {
    width: 55px;
    height: 48px;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .dv-upload-file {
    visibility: hidden;
  }

  .afa-center-pos {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #341a1a;
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }

  span.upload-text {
    font-size: 14px;
    position: absolute;
    right: 50%;
    width: fit-content;
    transform: translate(50%, -30%);
  }

  span.icon-cloud-computing {
    font-size: 32px;
    color: ${custom_red};
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(50%, 10%);
  }
`

export const FileInputs = styled.div`
  border: 1px dashed ${custom_red};
  border-radius: 8px;
  padding: 1rem;
  cursor: pointer;
  position: relative;
  height: 75px;
  width: 100%;
  background-color: ${custom_gray};

  .afa-img {
    width: 70px;
    height: 60px;
    position: absolute;
    top: 50%;
    left: 20%;
    transform: translate(-50%, -50%);
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .dv-upload-file {
    visibility: hidden;
  }

  .afa-center-pos {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #341a1a;
    display: flex;
  }

  span.upload-text {
    position: absolute;
    right: 33%;
    font-size: 14px;
    text-align: center;
    @media (max-width: 576px) {
      top: 50%;
      right: 27%;
    }
  }

  span.icon-cloud-computing {
    font-size: 32px;
    color: ${custom_red};
    position: absolute;
    right: 10%;
    @media (max-width: 576px) {
      top: -40%;
      right: 43%;
    }
  }
`

export const HomeNumberInputs = styled.label`
  width: 100%;

  span {
    font-size: 14px;
    margin-bottom: 0.6rem;
    color: ${label_text};
    font-weight: 500;
  }

  input.afa-prev-code {
    background-color: ${custom_gray};
    padding: 0.75rem 1rem;
    border-radius: 10px 0 0 10px !important;
    width: 20%;
    color: #212121;
    border: none;
    text-align: left;
    border-right: 1px solid darkblue;

    &:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }

    &::placeholder {
      color: #C2C2C2;
    }
  }

  input.afa-phone-input {
    background-color: ${custom_gray};
    padding: 0.75rem 1rem;
    border-radius: 0 10px 10px 0 !important;
    width: 80%;
    color: #212121;
    border: none;
    text-align: left;
    border-left: 1px solid darkblue;

    &:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }

    &::placeholder {
      color: #C2C2C2;
    }
  }
`
// export const Inputs = styled.label``





