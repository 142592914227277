import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {setUserInformation} from "../../../../../redux";
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel
} from "react-accessible-accordion";
import {Images} from "../../../../../assets/scripts/Images";
import {InformationButton} from "../style";
import {Modal} from "react-bootstrap";
import {MAIN_URL, MAIN_URL_IMAGE} from "../../../../../assets/scripts/GeneralVariables";
import {getData} from "../../../../../assets/scripts/GeneralFunctions";
import {TabStatusComplete, TabStatusContent} from "../../UserInformation/Child/style";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";

function CertificateInformation(props) {

    // states
    const [modal, setModal] = useState({show_user_type: false, show_tab_status: false});
    const [inputs, setInput] = useState({
        certificate: 'بله',
        description: '',
        status: 'denied',
        status_description: ''
    });
    const [data, setData] = useState({certificates: [], sheet_status: {}, status_colors: {}, drivers_licence: {}})
    // Redux
    const dispatch = useDispatch()

    useEffect(async () => {
        let api_result = await getData(MAIN_URL, `hr-management/certifications/${props?.user_id}`, 'get', {}, true, true);
        if (api_result?.status === 200) {
            setData({
                certificates: api_result?.data?.certificates,
                sheet_status: api_result?.data?.sheet_status,
                status_colors: api_result?.data?.status_colors,
                drivers_licence: api_result?.data?.drivers_licence
            })
            setInput(prevState => ({
                ...prevState,
                status: api_result?.data?.sheet_status?.status,
                status_description: api_result?.data?.sheet_status?.message
            }))
        }
    }, [props?.user_id]);
    // back to list
    const usersListHandler = () => {
        dispatch(setUserInformation(false))
    }
    const inputHandler = (e) => {
        setInput(prevState => ({...prevState, [e.target.name]: e.target.value}))
    }

    // tab Status
    const tabStatus = () => {
        setModal(prevState => ({...prevState, show_tab_status: true}))
    }
    const tabStatusHandler = async (e) => {
        e.preventDefault()
        setModal(prevState => ({...prevState, show_tab_status: false}))
        let formData = {};
        formData['status'] = inputs?.status;
        if (inputs?.status_description) {
            formData['message'] = inputs?.status_description;
        }
        let api_result = await getData(MAIN_URL, `hr-management/sheet-status/${props?.user_id}/certifications`, 'post', formData, true, true);
        if (api_result?.status === 200) {
            setData(prevState => ({...prevState, sheet_status: api_result?.data?.sheet_status}))
            toast.success('با موفقیت ثبت شد')
        }
    }

    const closeModal = () => {
        setModal({show_user_type: false, show_tab_status: false})
    }
    return (
        <>
            <div className="row mb-3">
                <div className="col-12 col-sm-6 col-lg-4 mb-0">
                    <TabStatusComplete
                        style={{borderRightColor: data.status_colors?.[data?.sheet_status?.status]?.colors?.border_right}}>
                        {
                            data.sheet_status?.status === 'pending' || data.sheet_status?.status === 'denied' ?
                                <span style={{
                                    color: data.status_colors?.[data?.sheet_status?.status]?.colors?.color,
                                    backgroundColor: data.status_colors?.[data?.sheet_status?.status]?.colors?.background
                                }} className='icon-Iconly-Bulk-Info-Square afa-icon-tick'/> :
                                <span style={{
                                    color: data.status_colors?.[data?.sheet_status?.status]?.colors?.color,
                                    backgroundColor: data.status_colors?.[data?.sheet_status?.status]?.colors?.background
                                }} className='icon-tick afa-icon-tick'/>
                        }
                        <p className='mb-0 f-14 '>
                            <span>وضعیت : </span><span>{data.status_colors?.[data?.sheet_status?.status]?.title}</span>
                        </p>
                    </TabStatusComplete>
                </div>
                <div className="col-12 col-sm-6 col-lg-8 mb-0">
                    {
                        data?.sheet_status?.message &&
                        <TabStatusContent
                            style={{borderRightColor: data.status_colors?.[data?.sheet_status?.status]?.colors?.border_right}}>
                            <span className="icon-Iconly-Bulk-Message afa-icon-message">
                                <span className="path1"></span>
                                <span className="path2"></span>
                            </span>
                            <p className='mb-0 f-14'>
                                <span>پیام ادمین : </span><span>{data?.sheet_status?.message}</span>
                            </p>
                        </TabStatusContent>
                    }
                </div>
            </div>
            {
                data?.certificates?.length > 0 &&
                <Accordion className='afa-information-collapse' allowZeroExpanded>
                    {
                        data?.certificates?.map((row, index) => (
                            <AccordionItem className='mb-3' key={index}>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <div dir={'rtl'}>
                                            <span className='afa-title'>سال : </span>
                                            <span className="afa-value">{row?.year}</span>
                                        </div>
                                        <div dir={'rtl'}>
                                            <span className='afa-title'>محل آموزش : </span>
                                            <span className="afa-value">{row?.location}</span>
                                        </div>
                                        <div dir={'rtl'}>
                                            <span className='afa-title'> نام دوره : </span>
                                            <span className="afa-value">{row?.course}</span>
                                        </div>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div>
                                        <span className='afa-title'>مدت (ماه) : </span>
                                        <span className="afa-value">{row?.duration}</span>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <span className='afa-title'>گواهینامه : </span>
                                        {
                                            row?.certificate ?
                                                <span className="afa-img-value">
                                                <img className='afa-img-size-information2'
                                                     src={`${MAIN_URL_IMAGE}${row?.certificate}`} alt="آذر فولاد امین"/>
                                            </span> : 'ندارد'
                                        }
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>
                        ))
                    }
                </Accordion>
            }
            {
                data?.drivers_licence?.licences?.length > 0 &&
                <div className='d-flex align-items-center'>
                    <span className='afa-title'>دارای گواهینامه : </span>
                    {
                        data?.drivers_licence?.licences?.map((item, i) => (
                            <span className="afa-value px-3" key={i}>{item}</span>
                        ))
                    }

                </div>
            }
            <InformationButton>
                <button onClick={tabStatus}>
                    <span className='afa-information-btn-title'>اعلام وضعیت</span>
                </button>
                <Link to={'/manage-hr'}>
                    <span className='afa-information-btn-title'>لیست کاربران</span>
                </Link>
            </InformationButton>

            <Modal style={{textAlign: 'center'}} centered={true} show={modal?.show_tab_status}
                   onHide={closeModal}>
                <Modal.Body className='p-md-4' dir='rtl'>
                    <form className="row" onSubmit={tabStatusHandler}>
                        <div className="col-12 d-flex align-items-center mb-4">
                            <span style={{marginLeft: '1rem'}}>اعلام وضعیت  : </span>
                            <div className="d-flex align-items-center">
                                <label className="afa-custom-checkbox ml-4 mb-0">
                                    تکمیل
                                    <input type="radio" value='completed'
                                           onChange={inputHandler}
                                           checked={inputs[`status`] === 'completed'}
                                           name={`status`}/>
                                    <span className="checkmark"/>
                                </label>
                                <label className="afa-custom-checkbox ml-4 mb-0">
                                    ناقص
                                    <input type="radio" value='denied'
                                           onChange={inputHandler}
                                           checked={inputs[`status`] === 'denied'}
                                           name={`status`}/>
                                    <span className="checkmark"/>
                                </label>
                            </div>
                        </div>
                        <div className="mb-3 w-100">
                            <p className='k-text-right'>توضیحات</p>
                            <textarea className='afa-textarea' placeholder='توضیحات' name="status_description" rows="2"
                                      value={inputs?.status_description} onChange={inputHandler}></textarea>
                        </div>
                        <div
                            className='col-12 w-100 d-flex align-items-center'>
                            <button type='submit' className='afa-btn-save-modal'>ذخیره</button>
                            <button type='button' onClick={closeModal}
                                    className='afa-btn-cancel-modal'>انصراف
                            </button>
                        </div>
                    </form>

                </Modal.Body>
            </Modal>
        </>
    );
}

export default CertificateInformation;