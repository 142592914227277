import React, {useState, useEffect} from 'react';
import {MAIN_URL} from "../../../../assets/scripts/GeneralVariables";
import {edit_item_with_id, getData, setTitle} from "../../../../assets/scripts/GeneralFunctions";
import {MainContent} from "../style";
import {SearchInput} from "../../Child/SupplierMangement/style";
import {Modal} from "react-bootstrap";
import {Inputs} from "../../Child/Supplier/Child/style";
import {Link, useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel
} from "react-accessible-accordion";
import Skeleton from 'react-loading-skeleton'

function Units(props) {
    const [modal, setModal] = useState({show_edit_unit: false, show_new_unit: false});
    const [inputs, setInputs] = useState({
        search_value: '',
        edit_software_name: '',
        title: '', id: null,
        unit_name: '',
        units: [NaN, NaN, NaN, NaN],
        companies: [],
        companies_name_list: [],
        company_selected: [],
        company: 'selected',
        empty_array: []
    })
    const history = useHistory()
    const [data, setObject] = useState({})
    useEffect(async () => {
        setTitle('آذر فولاد امین | واحد ها')
        let api_result = await getData(MAIN_URL, `unit-management/units`, 'get', {}, true, false);
        if (api_result?.status === 200) {
            let companies_name_list = []
            for (let company of api_result?.data?.companies) {
                companies_name_list.push({
                    name: company?.name, id: company?.id
                })
            }

            setInputs(prevState => ({
                ...prevState,
                units: api_result?.data?.units,
                companies: api_result?.data?.companies,
                companies_name_list
            }))
        }
    }, []);
    const searchHandler = async (e) => {
        let object = {};
        object = data;
        object['search'] = e.target.value
        setInputs(prevState => ({
            ...prevState,
            search_value: e.target.value,
            units: [NaN, NaN, NaN, NaN]
        }))
        let api_result = await getData(MAIN_URL, `unit-management/units`, 'get', data, true, false);
        if (api_result?.status === 200) {

            let empty_array = []
            if (inputs?.company_selected?.length >= 1) {
                for (let select_item of inputs?.company_selected) {
                    for (let unit of api_result?.data?.units) {
                        if (select_item === unit.company?.id) {
                            empty_array.push(unit)
                        }
                    }
                }
            } else {
                empty_array = api_result?.data?.units
            }
            setInputs(prevState => ({
                ...prevState,
                units: api_result?.data?.units,
                empty_array
            }))
            setObject(object)
        }
    }

    const showNewUnit = () => {
        setModal(prevState => ({...prevState, show_new_unit: true}))
    }

    const newUnitHandler = async (e) => {
        e.preventDefault()
        let api_result = await getData(MAIN_URL, `unit-management/units/create`, 'post', {
            title: inputs?.title,
            company: inputs?.company
        }, true, true);
        if (api_result?.status === 200) {

            let empty_array = []
            if (inputs?.company_selected?.length >= 1) {
                for (let select_item of inputs?.company_selected) {
                    for (let unit of inputs?.units?.concat(api_result?.data?.unit)) {
                        if (select_item === parseInt(unit.company_id)) {
                            empty_array.push(unit)
                        }
                    }
                }
            } else {
                empty_array = inputs?.units?.concat(api_result?.data?.unit)
            }

            setInputs(prevState => ({
                ...prevState,
                units: inputs?.units?.concat(api_result?.data?.unit), title: '', company: 'selected', empty_array,
            }))
            toast.success('با موفقیت افزوده شد')
        }
        setModal(prevState => ({...prevState, show_new_unit: false}))
    }

    // Edit unit
    const editUnit = (id, row) => {
        setModal(prevState => ({...prevState, show_edit_unit: true}))
        setInputs(prevState => ({...prevState, id: id, title: row?.title, company: row?.company_id}))
    }
    const editUnitHandler = async (e) => {
        e.preventDefault()
        let api_result = await getData(MAIN_URL, `unit-management/units/edit/${inputs?.id}`, 'post', {
            title: inputs?.title,
            company: inputs?.company
        }, true, true);
        if (api_result?.status === 200) {
            let arr = edit_item_with_id(inputs?.units, api_result?.data?.unit)

            let empty_array = []
            if (inputs?.company_selected?.length >= 1) {
                for (let select_item of inputs?.company_selected) {
                    for (let unit of arr) {
                        if (select_item === parseInt(unit.company_id)) {
                            empty_array.push(unit)
                        }
                    }
                }
            } else {
                empty_array = arr
            }

            setInputs(prevState => ({
                ...prevState,
                units: arr, title: '', empty_array,
            }))
            toast.success('با موفقیت تغییر یافت')
        }
        setModal(prevState => ({...prevState, show_edit_unit: false}))
    }

    const closeModal = () => {
        setModal(prevState => ({...prevState, show_edit_unit: false, show_new_unit: false}))
    }

    const showUsers = (id, title) => {
        history.push('/manage-user', {id, title, route_type: 'unit'});
    }

    // custom filter checkboxes
    const CheckBoxHandler = async (value) => {
        let selected = inputs?.company_selected
        let find = selected.indexOf(value)

        if (find > -1) {
            selected.splice(find, 1)
        } else {
            selected.push(value)
        }

        let empty_array = []

        if (selected?.length >= 1) {
            for (let select_item of selected) {
                for (let unit of inputs?.units) {
                    if (select_item === unit.company?.id) {
                        empty_array.push(unit)
                    }
                }
            }
        } else {
            empty_array = inputs?.units
        }

        setInputs(prevState => ({...prevState, company_selected: selected, empty_array}))
    }

    return (
        <MainContent>
            <div className='row'>
                <div className="col-12 col-sm-6 col-lg-3 mb-3"><h6 className='text-red fw-bold'>واحد ها</h6></div>
                <div
                    className="col-12 col-sm-6 col-lg-9 d-flex flex-column flex-sm-row align-items-center justify-content-end">
                    <SearchInput className='mb-3 w-100'>
                        <span className='icon-Search dv-search-icon'/>
                        <input type="search" value={inputs?.search_value} onChange={searchHandler}
                               placeholder='جستجو'/>
                    </SearchInput>
                    <button className='add-new-btn mb-3' onClick={showNewUnit}>
                        <span className="icon-add_paper"/>
                        <span>افزودن جدید</span>
                    </button>
                    {/*Filter*/}
                    <Accordion className='dv-units-filter-drop-down mb-3' allowZeroExpanded>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <div className="d-flex align-items-center">
                                        <span>فیلتر بر اساس شرکت</span>
                                        <span className="icon-Filter dv-filter-icon"/>
                                    </div>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div className="d-flex flex-column">
                                    {
                                        inputs?.companies_name_list?.map((item, index) => (
                                            <label className="afa-custom-checkbox" key={index}>{item.name}
                                                <input type="checkbox" name='company_type'
                                                       value={item.id}
                                                       onChange={() => CheckBoxHandler(item.id)}
                                                       checked={inputs.company_selected?.includes(item.id)}
                                                />
                                                <span className="checkmark2"></span>
                                            </label>
                                        ))
                                    }
                                </div>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                </div>
                <div className="col-12 mb-3 afa-lazyload">
                    <table id={'afa_user_table'}>
                        <thead>
                        <tr>
                            <td>واحد</td>
                            <td>شرکت</td>
                            <td>تعداد پرسنل</td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            inputs?.empty_array?.length > 0 ?
                                inputs?.empty_array.map((row, index) => (
                                    <tr key={index}>
                                        <td>
                                            <span className='pl-2 afa-tbody-text'>{parseInt(index) + 1} -</span>
                                            <span className="afa-tbody-text">{row?.title}</span></td>
                                        <td><span
                                            className="afa-tbody-text">{row?.company?.name ? row?.company?.name : '- - -'}</span>
                                        </td>
                                        <td><span className="afa-tbody-text">{row?.users_count}</span></td>
                                        <td className='afa-table-btns-width'>
                                            <div className="d-flex align-items-center">
                                                <button type={'button'} className='afa-user-status-active-table-btn'
                                                        onClick={() => showUsers(row?.id, row?.title)}>مشاهده پرسنل
                                                </button>
                                                <button type={'button'} className='afa-user-status-table-btn'
                                                        onClick={() => editUnit(row?.id, row)}>ویرایش
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )) :
                                inputs?.units?.length > 0 ?
                                    !inputs?.units[0] ?
                                        inputs?.units?.map((row, index) => (
                                            <tr key={index}>
                                                <td><Skeleton borderRadius={8} height={35}/></td>
                                                <td><Skeleton borderRadius={8} height={35}/></td>
                                                <td><Skeleton borderRadius={8} height={35}/></td>
                                                <td><Skeleton borderRadius={8} height={35}/></td>
                                            </tr>
                                        )) :
                                        inputs?.units?.map((row, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <span className='pl-2 afa-tbody-text'>{parseInt(index) + 1} -</span>
                                                    <span className="afa-tbody-text">{row?.title}</span></td>
                                                <td><span
                                                    className="afa-tbody-text">{row?.company?.name ? row?.company?.name : '- - -'}</span>
                                                </td>
                                                <td><span className="afa-tbody-text">{row?.users_count}</span></td>
                                                <td className='afa-table-btns-width'>
                                                    <div className="d-flex align-items-center">
                                                        <button type={'button'}
                                                                className='afa-user-status-active-table-btn'
                                                                onClick={() => showUsers(row?.id, row?.title)}>مشاهده
                                                            پرسنل
                                                        </button>
                                                        <button type={'button'} className='afa-user-status-table-btn'
                                                                onClick={() => editUnit(row?.id, row)}>ویرایش
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )) : <tr>
                                        <td></td>
                                        <td className='text-center'>آیتمی وجود ندارد</td>
                                        <td></td>
                                    </tr>
                        }


                        </tbody>
                    </table>
                </div>
            </div>

            <Modal centered={true} className='afa-user-status-modal' show={modal?.show_new_unit}
                   onHide={closeModal}>
                <Modal.Body className='p-md-4' dir='rtl'>
                    <form className="row" onSubmit={newUnitHandler}>
                        <div className="col-12 mb-4">
                            <h6 className='mb-4 text-red'>افزودن واحد جدید</h6>
                            <Inputs>
                                <span>نام شرکت</span>
                                <select
                                    className={'afa-error-input'}
                                    name={`company`}
                                    value={inputs?.[`company`]}
                                    onChange={(e) =>
                                        setInputs(prevState => ({
                                            ...prevState,
                                            [`company`]: e.target.value
                                        }))
                                    }>
                                    <option value="selected" disabled>انتخاب کنید</option>
                                    {
                                        inputs?.companies?.map((row, index) => (
                                            <option value={row?.id} key={index}>{row?.name}</option>
                                        ))
                                    }
                                </select>
                            </Inputs>
                        </div>
                        <div className="col-12 mb-4">
                            <Inputs>
                                <span>نام واحد</span>
                                <input
                                    className={'afa-error-input'}
                                    type="text" name={`title`}
                                    value={inputs?.[`title`]}
                                    onChange={(e) =>
                                        setInputs(prevState => ({
                                            ...prevState,
                                            [`title`]: e.target.value
                                        }))
                                    }/>
                            </Inputs>
                        </div>
                        <div
                            className='col-12 w-100 d-flex align-items-center'>
                            <button disabled={inputs?.[`title`] ? false : true} type='submit'
                                    className='afa-btn-save-modal'>ذخیره
                            </button>
                            <button type='button' onClick={closeModal}
                                    className='afa-btn-cancel-modal'>انصراف
                            </button>
                        </div>
                    </form>

                </Modal.Body>
            </Modal>

            <Modal centered={true} className='afa-user-status-modal' show={modal?.show_edit_unit}
                   onHide={closeModal}>
                <Modal.Body className='p-md-4' dir='rtl'>
                    <form className="row" onSubmit={editUnitHandler}>
                        <div className="col-12 mb-4">
                            <h6 className='mb-4 text-red'>ویرایش واحد</h6>
                            <Inputs>
                                <span>نام شرکت</span>
                                <select
                                    className={'afa-error-input'}
                                    name={`company`}
                                    value={inputs?.[`company`]}
                                    onChange={(e) =>
                                        setInputs(prevState => ({
                                            ...prevState,
                                            [`company`]: e.target.value
                                        }))
                                    }>
                                    <option value="selected" disabled>انتخاب کنید</option>
                                    {
                                        inputs?.companies?.map((row, index) => (
                                            <option value={row?.id} key={index}>{row?.name}</option>
                                        ))
                                    }
                                </select>
                            </Inputs>
                        </div>
                        <div className="col-12 mb-4">
                            <Inputs>
                                <span>نام واحد</span>
                                <input
                                    className={'afa-error-input'}
                                    type="text" name={`title`}
                                    value={inputs?.[`title`]}
                                    onChange={(e) =>
                                        setInputs(prevState => ({
                                            ...prevState,
                                            [`title`]: e.target.value
                                        }))
                                    }/>
                            </Inputs>
                        </div>
                        <div
                            className='col-12 w-100 d-flex align-items-center'>
                            <button disabled={inputs?.[`title`] ? false : true} type='submit'
                                    className='afa-btn-save-modal'>ذخیره
                            </button>
                            <button type='button' onClick={closeModal}
                                    className='afa-btn-cancel-modal'>انصراف
                            </button>
                        </div>
                    </form>

                </Modal.Body>
            </Modal>

        </MainContent>
    );
}

export default Units;