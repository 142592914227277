import React, {useEffect, useState} from 'react';
import {MainContent} from "./style";
import {SearchInput} from "../Child/SupplierMangement/style";
import {Images} from "../../../assets/scripts/Images";
import {Modal} from "react-bootstrap";
import {edit_item_with_id, getData, getFormDataPost, setTitle} from "../../../assets/scripts/GeneralFunctions";
import {MAIN_URL} from "../../../assets/scripts/GeneralVariables";
import {MAIN_URL_IMAGE} from "../../../assets/scripts/GeneralVariables";
import {useHistory, useLocation} from "react-router-dom";
// Skeleton
import Skeleton from 'react-loading-skeleton'

function Users(props) {
    const [inputs, setInputs] = useState({
        search_value: '',
        user_status: 1,
        permissions: [],
        selected: [],
        users: [NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN],
        user_id: null,
        user_permission_index: null
    })

    const [is_all_items_load, setAllLoad] = useState(true)
    const [modal, setModal] = useState({show_user_status: false, show_user_permission: false});
    const [api_parametrs_obj, setObject] = useState({
        'limit': 30,
        'offset': 0,
    })

    let location = useLocation();
    const history = useHistory()
    useEffect(async () => {
        setTitle('آذر فولاد امین | کاربران')
        window.scrollTo(0, 0)
        if (location?.state?.route_type === 'unit' && location?.state?.id) {
            api_parametrs_obj.unit = location?.state?.id
        } else if (location?.state?.route_type === 'software' && location?.state?.id) {
            api_parametrs_obj.software = location?.state?.id
        } else if (location?.state?.route_type === 'skill' && location?.state?.id) {
            api_parametrs_obj.skill = location?.state?.id
        }
        if (localStorage.getItem('AFAToken')) {
            let api_result = await getData(MAIN_URL, "user-management/users", 'get', api_parametrs_obj, true, false);
            if (api_result?.status === 200) {
                setInputs(prevState => ({
                    ...prevState,
                    users: api_result?.data?.users,
                    permissions: api_result?.data?.permissions
                }))
                if (location?.state?.route_type === 'unit' && location?.state?.id) {
                    setObject(prevState => ({...prevState, unit: location?.state?.id}))
                } else if (location?.state?.route_type === 'software' && location?.state?.id) {
                    setObject(prevState => ({...prevState, software: location?.state?.id}))
                } else if (location?.state?.route_type === 'skill' && location?.state?.id) {
                    setObject(prevState => ({...prevState, skill: location?.state?.id}))
                }
            }
        }
    }, []);

    const searchHandler = async (e) => {
        let object = {};
        object = api_parametrs_obj;
        object['search'] = e.target.value
        setInputs(prevState => ({
            ...prevState,
            search_value: e.target.value,
            users: [NaN, NaN, NaN, NaN],
        }))
        let api_result = await getData(MAIN_URL, `user-management/users`, 'get', object, true, false);
        if (api_result?.status === 200) {
            let data = api_result?.data
            setObject(object)
            setInputs(prevState => ({
                ...prevState,
                users: data?.users
            }))
        }
    }

    // user status
    const showUserStatus = (id, status, fname, lname) => {
        setModal({show_user_status: true})
        setInputs(prevState => ({...prevState, user_id: id, status: status, name: `${fname} ${lname}`}))
    }
    const userStatusHandler = async (e) => {
        e.preventDefault()
        let api_result = await getData(MAIN_URL, `user-management/status/change/${inputs?.user_id}`, 'post', {}, true, true);
        if (api_result?.status === 200) {
            let items = edit_item_with_id(inputs?.users, api_result?.data?.user)
            setModal({show_user_status: false})
            setInputs(prevState => ({...prevState, users: items}))
        }
    }

    // user permisson
    const UserPermissionShow = (id, user_permission, index) => {
        setModal({show_user_permission: true})
        let arr = []
        for (let item of user_permission) {
            arr.push(item.id)
        }
        setInputs(prevState => ({...prevState, user_id: id, selected: arr, user_permission_index: index}))
    }
    const checkBoxHandler = (id) => {
        let selected = [];
        if (inputs?.selected?.length > 0) {
            selected = inputs?.selected
        }
        let find = selected?.indexOf(id)

        if (find > -1) {
            selected?.splice(find, 1)
        } else {
            selected?.push(id)
        }
        setInputs(prevState => ({...prevState, selected: selected}))
    }

    const userPermissionHandler = async (e) => {
        e.preventDefault()
        let formData = new FormData()
        if (inputs?.selected?.length > 0) {
            for (let i = 0; i < inputs?.selected?.length; i++) {
                formData.append(`permissions[${i}]`, inputs?.selected[i])
            }
        } else {
            formData.append(`permissions`, [])
        }

        let api_result = await getFormDataPost(`user-management/permissions/edit/${inputs?.user_id}`, formData,);
        if (api_result?.status === 200) {
            let obj = inputs?.users[inputs?.user_permission_index]
            obj['permissions'] = api_result?.data?.permissions
            let arr = edit_item_with_id(inputs?.users, obj)
            setInputs(prevState => ({...prevState, users: arr}))
        }
        setModal({show_user_permission: false})
    }

    // lazy load
    const getTableDataLazyLoad = async (obj) => {
        if (parseFloat(obj.target.offsetHeight + obj.target.scrollTop) == parseFloat(obj.target.scrollHeight)) {
            if (inputs?.users?.length % 30 === 0 && is_all_items_load === true) {
                let object = {};
                object = api_parametrs_obj;
                object['offset'] = inputs?.users?.length
                let moreData = await getData(MAIN_URL, `user-management/users`, 'get', object, true, true);
                if (moreData) {
                    setObject(object)
                    setInputs(prevState => ({...prevState, users: inputs?.users.concat(moreData.data?.users)}))
                    if (moreData?.data?.users?.length < 30) {
                        setAllLoad({is_all_items_load: false})
                    }
                }
            }
        }
    }

    const showUserDetails = (user_id, purpose) => {
        if (purpose === 'supplier') {
            history.push(`manage-supplier/${user_id}`);
        } else {
            history.push(`manage-hr/${user_id}`);
        }
    }

    const closeModal = () => {
        setModal({show_user_status: false})
    }

    return (
        <MainContent>
            <div className='row'>
                <div className="col-12 col-sm-6 col-lg-9 mb-3"><h6 className='text-red fw-bold'>کاربران
                    {
                        location?.state?.route_type &&
                        <span>{
                            location?.state?.route_type === 'unit' ? <span> واحد {location?.state?.title}</span>
                                : location?.state?.route_type === 'software' ?
                                    <span> نرم افزار {location?.state?.title}</span>
                                    : <span> مهارت {location?.state?.title}</span>
                        }</span>
                    }
                </h6></div>
                <div className="col-12 col-sm-6 col-lg-3 mb-3 d-flex flex-column flex-sm-row justify-content-end">
                    <SearchInput className='mb-3 w-100'>
                        <span className='icon-Search dv-search-icon'/>
                        <input type="search" value={inputs?.search_value} onChange={searchHandler} placeholder='جستجو'/>
                    </SearchInput>
                </div>
                <div className="col-12 mb-3 afa-lazyload" onScroll={getTableDataLazyLoad}>
                    <table id={'afa_user_table'}>
                        <thead>
                        <tr>
                            <td></td>
                            <td>نام کاربر</td>
                            <td>کد ملی</td>
                            <td>شماره موبایل</td>
                            <td>نوع کاربر</td>
                            <td>واحد</td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            inputs?.users?.length > 0 ?
                                !inputs?.users[0] ?
                                    inputs?.users?.map((row, index) => (
                                        <tr key={index}>
                                            <td><Skeleton borderRadius={8} height={35}/></td>
                                            <td><Skeleton borderRadius={8} height={35}/></td>
                                            <td><Skeleton borderRadius={8} height={35}/></td>
                                            <td><Skeleton borderRadius={8} height={35}/></td>
                                            <td><Skeleton borderRadius={8} height={35}/></td>
                                            <td><Skeleton borderRadius={8} height={35}/></td>
                                        </tr>
                                    )) :
                                    inputs?.users?.map((row, index) => (
                                        <tr key={index}>
                                            <td className='afa-user-width'>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <span className='pl-2 afa-tbody-text'>{parseInt(index) + 1}</span>
                                                    <span
                                                        className={row?.is_personnel == 1 ? "icon-star-full afa-gold-start" : "icon-star-full afa-empty-start"}/>
                                                    <img
                                                        onError={(e) => {
                                                            e.target.onerror = null;
                                                            e.target.src = `${Images.mini_placeHolder.default}`
                                                        }}
                                                        src={row?.personal_info?.personal_photo ? `${MAIN_URL_IMAGE}${row?.personal_info?.personal_photo}` : Images.perseneli.default}
                                                        className='afa-user-avatar' alt="آذر فولاد امین"/>
                                                </div>
                                            </td>
                                            <td>
                                            <span
                                                className='afa-tbody-text'>{`${row?.first_name} ${row?.last_name ? row?.last_name : ''}`}</span>
                                            </td>
                                            <td><span className="afa-tbody-text">{row?.identification_code}</span></td>
                                            <td><span className="afa-tbody-text">{row?.mobile}</span></td>
                                            <td><span
                                                className="afa-tbody-text">{row?.type === 'individual' ? 'حقیقی' : 'حقوقی'} - {row?.purpose === 'supplier' ? 'تامین کننده' : 'کارجو'}</span>
                                            </td>
                                            <td><span
                                                className="afa-tbody-text">{(row?.is_personnel == 1 && row?.unit?.title) ? row?.unit?.title : '- - -'}</span>
                                            </td>
                                            <td className='afa-table-btns-width'>
                                                <div className="d-flex align-items-center">
                                                    <button type={'button'} className='afa-user-status-active-table-btn'
                                                            onClick={() => showUserDetails(row?.id, row?.purpose)}>اطلاعات
                                                        کاربر
                                                    </button>
                                                    <button
                                                        className={row?.status == 1 ? 'afa-user-status-table-btn' : 'afa-user-status-active-table-btn'}
                                                        onClick={() => showUserStatus(row?.id, row?.status, row?.first_name, row?.last_name)}>{row?.status == 1 ? 'غیر فعالسازی' : 'فعال سازی'}</button>
                                                    {
                                                        row?.purpose !== 'supplier' &&
                                                        <button className="afa-table-edit-btn"
                                                                onClick={() => UserPermissionShow(row?.id, row?.permissions, index)}>
                                                            <span className="icon-edit"></span>
                                                        </button>
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    )) : <tr>
                                    <td>آیتمی وجود ندارد</td>
                                </tr>
                        }
                        </tbody>
                    </table>
                </div>
            </div>

            <Modal centered={true} show={modal?.show_user_status}
                   onHide={closeModal}>
                <Modal.Body className='p-md-4' dir='rtl'>
                    <form className="row" onSubmit={userStatusHandler}>
                        <div className="col-12 mb-4">
                            <h6 className='mb-4 text-red'>وضعیت کاربر</h6>
                            <p className='mb-5'>آیا مایل به {
                                inputs?.status == 1 ? 'غیر فعال' : 'فعال'
                            } سازی {inputs?.name} هستید ؟ </p>
                        </div>
                        <div
                            className='col-12 w-100 d-flex align-items-center'>
                            <button type='submit' className='afa-btn-save-modal'>بله</button>
                            <button type='button' onClick={closeModal}
                                    className='afa-btn-cancel-modal'>خیر
                            </button>
                        </div>
                    </form>

                </Modal.Body>
            </Modal>

            <Modal centered={true} className='afa-user-status-modal' show={modal?.show_user_permission}
                   onHide={closeModal}>
                <Modal.Body className='p-md-4' dir='rtl'>
                    <form className="row" onSubmit={userPermissionHandler}>
                        <div className="col-12 mb-4">
                            <h6 className='mb-4 text-red'>ویرایش دسترسی ها</h6>
                            <div className="d-flex flex-column w-100">
                                {
                                    inputs?.permissions?.map((item, index) => (
                                        <label className="afa-custom-checkbox afa-permission-checkbox"
                                               key={index}>{item?.name}
                                            <input type="checkbox" name='certificate_type'
                                                   value={item?.id}
                                                   onChange={() => checkBoxHandler(item?.id)}
                                                   checked={inputs.selected?.includes(item?.id)}
                                            />
                                            <span className="checkmark2"></span>
                                        </label>
                                    ))
                                }
                            </div>
                        </div>
                        <div
                            className='col-12 w-100 d-flex align-items-center'>
                            <button type='submit' className='afa-btn-save-modal'>ذخیره</button>
                            <button type='button' onClick={closeModal}
                                    className='afa-btn-cancel-modal'>انصراف
                            </button>
                        </div>
                    </form>

                </Modal.Body>
            </Modal>

        </MainContent>
    );
}

export default Users;