import React, {useEffect, useState} from 'react';
import {MainContent} from "./style";
import {SearchInput} from "../Child/SupplierMangement/style";
import {Modal} from "react-bootstrap";
import Swal from "sweetalert2";
import {
    edit_item_with_id,
    getData,
    remove_item_of_arr_with_id, setTitle
} from "../../../assets/scripts/GeneralFunctions";
import {MAIN_URL} from "../../../assets/scripts/GeneralVariables";
import {Inputs} from "../Child/Supplier/Child/style";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";
import Skeleton from 'react-loading-skeleton'

function Sofrwares(props) {
    const [inputs, setInputs] = useState({
        search_value: '',
        edit_software_name: '',
        new_software_name: '',
        softwares: [NaN, NaN, NaN, NaN],software_name : null , software_id : null
    })
    const [modal, setModal] = useState({show_edit_software: false, show_new_software: false});
    const history = useHistory()
    useEffect(async () => {
        setTitle('آذر فولاد امین | نرم افزار ها')
        window.scrollTo(0, 0)
        let api_result = await getData(MAIN_URL, "software-management/softwares", 'get', {}, true, false);
        if (api_result?.status === 200) {
            setInputs(prevState => ({
                ...prevState,
                softwares: api_result?.data?.softwares,
            }))
        }
    }, []);

    const searchHandler = async (e) => {
        setInputs(prevState => ({
            ...prevState,
            search_value: e.target.value,
            softwares: [NaN, NaN, NaN, NaN],
        }))
        let api_result = await getData(MAIN_URL, `software-management/softwares`, 'get',{search :  e.target.value}, true, false);
        if (api_result?.status === 200) {
            let data = api_result?.data
            setInputs(prevState => ({
                ...prevState,
                softwares: data?.softwares
            }))
        }
    }

    const removeSoftware = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این مورد را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                let remove_result = await getData(MAIN_URL, `software-management/softwares/remove/${id}`, 'post', {}, true, true);
                if (remove_result?.status === 200) {
                    swalWithBootstrapButtons.fire(
                        'حذف شد!',
                        'با موفقیت حذف شد',
                        'success'
                    )
                    let arr = inputs?.softwares;
                    let new_arr = remove_item_of_arr_with_id(arr, id)
                    setInputs(prevState => ({...prevState, softwares: new_arr}))
                }
            }
        })
    }

    const showEditSoftware = (id , row) => {
        setInputs(prevState => ({...prevState , software_id : id , software_name : row?.name}))
        setModal(prevState => ({...prevState, show_edit_software: true}))
    }

    const editSoftwareHandler = async (e) => {
        e.preventDefault()
        let api_result = await getData(MAIN_URL, `software-management/softwares/edit/${inputs?.software_id}`, 'post', {name: inputs?.software_name}, true, true);

        if (api_result?.status === 200) {
            toast.success('با موفقیت تغییر یافت')
            let arr = edit_item_with_id(inputs?.softwares , api_result?.data?.software)
            setInputs(prevState => ({...prevState , softwares: arr , software_name: ''}))
        }
        setModal(prevState => ({...prevState, show_edit_software: false}))
    }

    const showNewSoftware = () => {
        setModal(prevState => ({...prevState, show_new_software: true}))
    }

    const newSoftwareHandler = async (e) => {
        e.preventDefault()
        let api_result = await getData(MAIN_URL, `software-management/softwares/create`, 'post', {name: inputs?.software_name}, true, true);
        if (api_result?.status === 200) {
            toast.success('با موفقیت ثبت شد')
            let arr = [];
            arr[0] = api_result?.data?.software
            setInputs(prevState => ({...prevState , softwares: arr.concat(inputs?.softwares) , software_name: ''}))
        }
        setModal(prevState => ({...prevState, show_new_software: false}))
    }

    const showUsers = (id , title) => {
        history.push('/manage-user', { id , title , route_type : 'software'});
    }

    const closeModal = () => {
        setModal(prevState => ({...prevState, show_edit_software: false, show_new_software: false , show_users : false}))
    }

    return (
        <MainContent>
            <div className='row'>
                <div className="col-12 col-sm-6 mb-3"><h6 className='text-red fw-bold'>نرم افزار ها</h6></div>
                <div className="col-12 col-sm-6 mb-3 d-flex flex-column flex-sm-row align-items-center justify-content-end">
                    <SearchInput className='w-100'>
                        <span className='icon-Search dv-search-icon'/>
                        <input type="search" value={inputs?.search_value} onChange={searchHandler} placeholder='جستجو'/>
                    </SearchInput>
                    <button className='add-new-btn w-100' onClick={showNewSoftware}>
                        <span className="icon-add_paper"/>
                        <span>افزودن جدید</span>
                    </button>
                </div>
                <div className="col-12 mb-3 afa-lazyload" >
                    <table id={'afa_user_table'}>
                        <thead>
                        <tr>
                            <td>نام نرم افزار</td>
                            <td>تعداد افراد دارای مهارت</td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            inputs?.softwares?.length > 0 ?
                                !inputs?.softwares[0] ?
                                    inputs?.softwares?.map((row, index) => (
                                        <tr key={index}>
                                            <td><Skeleton borderRadius={8} height={35}/></td>
                                            <td><Skeleton borderRadius={8} height={35}/></td>
                                            <td><Skeleton borderRadius={8} height={35}/></td>
                                        </tr>
                                    )) :
                                inputs?.softwares?.map((row, index) => (
                                    <tr key={index}>
                                        <td>
                                            <span className='pl-2 afa-tbody-text'>{parseInt(index) +1} -</span>
                                            <span className="afa-tbody-text">{row?.name}</span></td>
                                        <td><span className="afa-tbody-text">{row?.users_count}</span></td>
                                        <td className='afa-table-btns-width'>
                                            <div className="d-flex align-items-center">
                                                <button type={'button'} className='afa-user-status-active-table-btn'
                                                        onClick={() => showUsers(row?.id , row.name)}>مشاهده نفرات
                                                </button>
                                                <button className='afa-user-status-table-btn'
                                                        onClick={() => removeSoftware(row?.id)}>حذف
                                                </button>
                                                <button className="afa-table-edit-btn"
                                                        onClick={() => showEditSoftware(row?.id , row)}>
                                                    <span className="icon-edit"></span>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )) : <tr>
                                    <td></td>
                                    <td>نرم افزاری وجود ندارد</td>
                                    <td></td>
                                </tr>
                        }

                        </tbody>
                    </table>
                </div>
            </div>

            <Modal centered={true} className='afa-user-status-modal' show={modal?.show_edit_software}
                   onHide={closeModal}>
                <Modal.Body className='p-md-4' dir='rtl'>
                    <form className="row" onSubmit={editSoftwareHandler}>
                        <div className="col-12 mb-4">
                            <h6 className='mb-4 text-red'>ویرایش نرم افزار</h6>
                            <Inputs>
                                <span>نام نرم افزار</span>
                                <input
                                    className={'afa-error-input'}
                                    type="text" name={`software_name`}
                                    value={inputs?.[`software_name`]}
                                    onChange={(e) =>
                                        setInputs(prevState => ({
                                            ...prevState,
                                            [`software_name`]: e.target.value
                                        }))
                                    }/>
                            </Inputs>
                        </div>
                        <div
                            className='col-12 w-100 d-flex align-items-center'>
                            <button type='submit' className='afa-btn-save-modal'>ذخیره</button>
                            <button type='button' onClick={closeModal}
                                    className='afa-btn-cancel-modal'>انصراف
                            </button>
                        </div>
                    </form>

                </Modal.Body>
            </Modal>

            <Modal centered={true} className='afa-user-status-modal' show={modal?.show_new_software}
                   onHide={closeModal}>
                <Modal.Body className='p-md-4' dir='rtl'>
                    <form className="row" onSubmit={newSoftwareHandler}>
                        <div className="col-12 mb-4">
                            <h6 className='mb-4 text-red'>افزودن نرم افزار جدید</h6>
                            <Inputs>
                                <span>نام نرم افزار</span>
                                <input
                                    className={'afa-error-input'}
                                    type="text" name={`software_name`}
                                    value={inputs?.[`software_name`]}
                                    onChange={(e) =>
                                        setInputs(prevState => ({
                                            ...prevState,
                                            [`software_name`]: e.target.value
                                        }))
                                    }/>
                            </Inputs>
                        </div>
                        <div
                            className='col-12 w-100 d-flex align-items-center'>
                            <button disabled={inputs?.[`software_name`] ? false : true} type='submit'
                                    className='afa-btn-save-modal'>ذخیره
                            </button>
                            <button type='button' onClick={closeModal}
                                    className='afa-btn-cancel-modal'>انصراف
                            </button>
                        </div>
                    </form>

                </Modal.Body>
            </Modal>

        </MainContent>
    );
}

export default Sofrwares;