import React, {useEffect, useState} from 'react';
import {PanelContent} from "./style";
import {ProgressBar} from "react-bootstrap";
import {Stepper} from '@progress/kendo-react-layout';
import { saveAs } from "file-saver";
// Components
import StepperUserInformation from "./Child/StepperUserInformation";
import FamilyInformation from "./Child/FamilyInformation";
import EducationalBackground from "./Child/EducationalBackground";
import WorkExperience from "./Child/WorkExperience";
import ForeignLanguages from "./Child/ForeignLanguages";
import Skills from "./Child/Skills";
import Certificates from "./Child/Certificates";
import Software from "./Child/Software";
import Documents from "./Child/Documents";
import ReviewData from "./Child/ReviewData";

import {getData, setTitle} from "../../../../assets/scripts/GeneralFunctions";
import {MAIN_URL, MAIN_URL_IMAGE} from "../../../../assets/scripts/GeneralVariables";
import {useDispatch} from "react-redux";
import {setUserInformation} from "../../../../redux";

const items = [{
    label: 'اطلاعات فردی',
    icon: 'icon-steper_user'
}, {
    label: 'اعضای خانواده',
    icon: 'icon-steper_users'
}, {
    label: ' سوابق تحصیلی ',
    icon: 'icon-steper_tahsil'
}, {
    label: 'سوابق شغلی',
    icon: 'icon-steper_job',
}, {
    label: 'زبان های خارجی',
    icon: 'icon-steper_lanquage'
},
    {
        label: 'مهارت ها',
        icon: 'icon-steper_maharat'
    },
    {
        label: 'گواهی ها',
        icon: 'icon-steper_certificate'
    },
    {
        label: 'نرم افزار ها',
        icon: 'icon-steper_software'
    },
    {
        label: 'اپلود مدارک',
        icon: 'icon-steper_upload'
    },
    {
        label: 'پیش نمایش',
        icon: 'icon-check1'
    }
];

function UserInformation(props) {

    const [progress_value, setProgressValue] = useState(0)
    const [stepper_value, setValue] = useState(0);
    const [status_items, setStatus] = useState({list: {}, colors: {}});
    const [guideFile , setGuide] = useState(null)
    const dispatch = useDispatch()
    useEffect(async () => {
        setTitle('آذر فولاد امین | حساب کاربری')
        let api_result = await getData(MAIN_URL, "profile", 'get', {}, true, true);
        if (api_result?.status === 200) {
            let data = api_result?.data
            setProgressValue(parseFloat(data.progress) * 100)
            setStatus({list: data.sheet_status, colors: data.status_colors})
            setGuide(data?.hr_help)
            dispatch(setUserInformation(false))
        }
    }, [stepper_value]);
    const handleChange = e => {
        setValue(e.value);
    };

    const handleStepperChangeWithBtn = value => {
        setValue(value);
    };

    const saveFile = () => {
        saveAs(
            `${MAIN_URL_IMAGE}${guideFile}`,
            "guide.pdf"
        );
    };

    return (
        <>
            {
                guideFile &&
                <div className="dv-guide-file-btn">
                    <button onClick={saveFile} className='afa-excel-export2 mb-3 w-100'>
                        <span className='afa-information-btn-title'>دانلود فایل راهنمای ثبت نام</span>
                    </button>
                </div>
            }
            <PanelContent>
                <ProgressBar now={progress_value}/>
                <div className="d-flex flex-row-reverse flex-lg-column">
                    <Stepper className='mt-4 mb-3' value={stepper_value} onChange={handleChange}
                             orientation={window.innerWidth > 992 ? 'horizontal' : 'vertical'} items={items}/>
                    <div className="afa-size-parent">
                        {
                            stepper_value === 0 &&
                            <StepperUserInformation handleChange={handleStepperChangeWithBtn}
                                                    status_value={status_items.list?.personal_info}
                                                    status_colors={status_items.colors[status_items.list?.personal_info]}/>
                        }
                        {
                            stepper_value === 1 && <FamilyInformation
                                handleChange={handleStepperChangeWithBtn}
                                status_value={status_items.list?.personal_info}
                                status_colors={status_items.colors[status_items.list?.personal_info]}/>
                        }
                        {
                            stepper_value === 2 &&
                            <EducationalBackground
                                handleChange={handleStepperChangeWithBtn}
                                status_value={status_items.list?.personal_info}
                                status_colors={status_items.colors[status_items.list?.personal_info]}/>
                        }
                        {
                            stepper_value === 3 && <WorkExperience
                                handleChange={handleStepperChangeWithBtn}
                                status_value={status_items.list?.personal_info}
                                status_colors={status_items.colors[status_items.list?.personal_info]}/>
                        }
                        {
                            stepper_value === 4 && <ForeignLanguages
                                handleChange={handleStepperChangeWithBtn}
                                status_value={status_items.list?.personal_info}
                                status_colors={status_items.colors[status_items.list?.personal_info]}/>
                        }
                        {
                            stepper_value === 5 && <Skills
                                handleChange={handleStepperChangeWithBtn}
                                status_value={status_items.list?.personal_info}
                                status_colors={status_items.colors[status_items.list?.personal_info]}/>
                        }
                        {
                            stepper_value === 6 && <Certificates
                                handleChange={handleStepperChangeWithBtn}
                                status_value={status_items.list?.personal_info}
                                status_colors={status_items.colors[status_items.list?.personal_info]}/>
                        }
                        {
                            stepper_value === 7 && <Software
                                handleChange={handleStepperChangeWithBtn}
                                status_value={status_items.list?.personal_info}
                                status_colors={status_items.colors[status_items.list?.personal_info]}/>
                        }
                        {
                            stepper_value === 8 && <Documents
                                handleChange={handleStepperChangeWithBtn}
                                status_value={status_items.list?.personal_info}
                                status_colors={status_items.colors[status_items.list?.personal_info]}/>
                        }
                        {
                            stepper_value === 9 && <ReviewData
                                handleChange={handleStepperChangeWithBtn}
                                status_value={status_items.list?.personal_info}
                                status_colors={status_items.colors[status_items.list?.personal_info]}/>
                        }
                    </div>
                </div>
            </PanelContent>
        </>
    );
}

export default UserInformation;