import React from "react";
import "./Loader.styles.scss"
import {Images} from "../../../assets/scripts/Images";

const Loader = (props) => (
    <div id="loader" className={"loader hidden"} >
        <div className="loadingio-spinner-ellipsis-vbzr0p1jua">
            <img src={Images.logo_mini.default} alt="Besas befrush"/>
            <div className="ldio-76msoii4h9c">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div>  </div>
            </div>
        </div>
    </div>
);

export default Loader;
