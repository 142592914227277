import React, {useEffect, useState} from 'react';
import {InformationButton, InformationContent} from "../style";
import {Images} from "../../../../../assets/scripts/Images";
import {setUserInformation} from "../../../../../redux";
import {useDispatch} from "react-redux";
import {Modal} from 'react-bootstrap';
import {getData} from "../../../../../assets/scripts/GeneralFunctions";
import {MAIN_URL, MAIN_URL_IMAGE} from "../../../../../assets/scripts/GeneralVariables";
import {TabStatusComplete, TabStatusContent} from "../../UserInformation/Child/style";
import moment from "moment-jalaali";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";

function UserInformation(props) {

    // states
    const [modal, setModal] = useState({show_user_type: false, show_tab_status: false});
    const [inputs, setInput] = useState({
        certificate: 'بله',
        description: '',
        status: 'denied',
        status_description: ''
    });
    const [data, setData] = useState({personal_info: {}, sheet_status: {}, status_colors: {}})
    // Redux
    const dispatch = useDispatch()

    // get list
    useEffect(async () => {
        let api_result = await getData(MAIN_URL, `hr-management/personal-info/${props?.user_id}`, 'get', {}, true, true);
        if (api_result?.status === 200) {
            setData({
                personal_info: api_result?.data?.personal_info,
                sheet_status: api_result?.data?.sheet_status,
                status_colors: api_result?.data?.status_colors
            })
            setInput(prevState => ({
                ...prevState,
                status: api_result?.data?.sheet_status?.status,
                status_description: api_result?.data?.sheet_status?.message
            }))
        }
    }, [props?.user_id]);

    // back to list
    const usersListHandler = () => {
        dispatch(setUserInformation(false))
    }
    const inputHandler = (e) => {
        setInput(prevState => ({...prevState, [e.target.name]: e.target.value}))
    }

    // tab Status
    const tabStatus = () => {
        setModal(prevState => ({...prevState, show_tab_status: true}))
    }
    const tabStatusHandler = async (e) => {
        e.preventDefault()
        setModal(prevState => ({...prevState, show_tab_status: false}))
        let formData = {};
        formData['status'] = inputs?.status;
        if (inputs?.status_description) {
            formData['message'] = inputs?.status_description;
        }
        let api_result = await getData(MAIN_URL, `hr-management/sheet-status/${props?.user_id}/personal_info`, 'post', formData, true, true);
        if (api_result?.status === 200) {
            setData(prevState => ({...prevState, sheet_status: api_result?.data?.sheet_status}))
            toast.success('با موفقیت ثبت شد')
        }
    }

    const closeModal = () => {
        setModal({show_user_type: false, show_tab_status: false})
    }

    let personal_info = data?.personal_info

    return (
        <>
            <InformationContent>
                <div className="col-12 col-sm-6 col-lg-4 mb-0">
                    <TabStatusComplete
                        style={{borderRightColor: data.status_colors?.[data?.sheet_status?.status]?.colors?.border_right}}>
                        {
                            data.sheet_status?.status === 'pending' || data.sheet_status?.status === 'denied' ?
                                <span style={{
                                    color: data.status_colors?.[data?.sheet_status?.status]?.colors?.color,
                                    backgroundColor: data.status_colors?.[data?.sheet_status?.status]?.colors?.background
                                }} className='icon-Iconly-Bulk-Info-Square afa-icon-tick'/> :
                                <span style={{
                                    color: data.status_colors?.[data?.sheet_status?.status]?.colors?.color,
                                    backgroundColor: data.status_colors?.[data?.sheet_status?.status]?.colors?.background
                                }} className='icon-tick afa-icon-tick'/>
                        }
                        <p className='mb-0 f-14 '>
                            <span>وضعیت : </span><span>{data.status_colors?.[data?.sheet_status?.status]?.title}</span>
                        </p>
                    </TabStatusComplete>
                </div>
                <div className="col-12 col-sm-6 col-lg-8 mb-0">
                    {
                        data?.sheet_status?.message &&
                        <TabStatusContent style={{borderRightColor: data.status_colors?.[data?.sheet_status?.status]?.colors?.border_right}}>
                            <span className="icon-Iconly-Bulk-Message afa-icon-message">
                                <span className="path1"></span>
                                <span className="path2"></span>
                            </span>
                            <p className='mb-0 f-14'>
                                <span>پیام ادمین : </span><span>{data?.sheet_status?.message}</span>
                            </p>
                        </TabStatusContent>
                    }
                </div>
                <div>
                    <span className='afa-title'>نام : </span>
                    {( personal_info?.first_name !== null && personal_info?.first_name !== 'null') ? <span className="afa-value">{personal_info?.first_name}</span> :
                        <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                            className='icon-Info-Circle afa-info-circle'
                            title={'فیلد مورد نظر خالی است'}/></span>}
                </div>
                <div>
                    <span className='afa-title'>نام خانوادگی : </span>
                    {( personal_info?.last_name !== null && personal_info?.last_name !== 'null') ? <span className="afa-value">{personal_info?.last_name}</span> :
                        <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                            className='icon-Info-Circle afa-info-circle'
                            title={'فیلد مورد نظر خالی است'}/></span>}
                </div>
                <div>
                    <span className='afa-title'>کد ملی : </span>
                    {( personal_info?.identification_code !== null && personal_info?.identification_code !== 'null') ?
                        <span className="afa-value">{personal_info?.identification_code}</span> :
                        <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                            className='icon-Info-Circle afa-info-circle'
                            title={'فیلد مورد نظر خالی است'}/></span>}
                </div>
                <div>
                    <span className='afa-title'>شماره شناسنامه : </span>
                    {personal_info?.birth_certificate_id ?
                        <span className="afa-value">{personal_info?.birth_certificate_id}</span> :
                        <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                            className='icon-Info-Circle afa-info-circle'
                            title={'فیلد مورد نظر خالی است'}/></span>}
                </div>
                <div>
                    <span className='afa-title'>نام پدر : </span>
                    {personal_info?.father_name ? <span className="afa-value">{personal_info?.father_name}</span> :
                        <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                            className='icon-Info-Circle afa-info-circle'
                            title={'فیلد مورد نظر خالی است'}/></span>}
                </div>
                <div className='d-flex align-items-center'>
                    <span className='afa-title'>عکس پرسنلی : </span>
                    {
                        personal_info?.personal_photo ?
                            <span className="afa-img-value">
                                <a href={`${MAIN_URL_IMAGE}${personal_info?.personal_photo}`} download={true}
                                   target={'_blank'}>
                                    <img className='afa-img-size-information2'
                                         onError={(e) => {
                                             e.target.onerror = null;
                                             e.target.src = `${Images.placeHolder.default}`
                                         }}
                                         src={`${MAIN_URL_IMAGE}${personal_info?.personal_photo}`}
                                         alt="آذر فولاد امین"/>
                                </a>
                    </span> : <span className="afa-value d-flex align-items-center"><span
                                className='icon-Info-Circle afa-info-circle' title={'فیلد مورد نظر خالی است'}/></span>
                    }
                </div>
                <div>
                    <span className='afa-title'>جنسیت : </span>
                    {personal_info?.gender ? <span className="afa-value">{personal_info?.gender}</span> :
                        <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                            className='icon-Info-Circle afa-info-circle'
                            title={'فیلد مورد نظر خالی است'}/></span>}

                </div>
                <div>
                    <span className='afa-title'>تاریخ تولد : </span>
                    {personal_info?.birth_date ?
                        <span className="afa-value" dir={'ltr'}>{moment(personal_info?.birth_date).format('jYYYY-jMM-jDD')}</span> :
                        <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                            className='icon-Info-Circle afa-info-circle'
                            title={'فیلد مورد نظر خالی است'}/></span>}
                </div>
                <div>
                    <span className='afa-title'>صادره : </span>
                    {personal_info?.issued ? <span className="afa-value">{personal_info?.issued}</span> :
                        <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                            className='icon-Info-Circle afa-info-circle'
                            title={'فیلد مورد نظر خالی است'}/></span>}
                </div>
                <div>
                    <span className='afa-title'>شماره همراه : </span>
                    {personal_info?.mobile ? <span className="afa-value">{personal_info?.mobile}</span> :
                        <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                            className='icon-Info-Circle afa-info-circle'
                            title={'فیلد مورد نظر خالی است'}/></span>}
                </div>
                <div>
                    <span className='afa-title'>شماره تماس ثابت : </span>
                    {personal_info?.telephone ? <span className="afa-value">{personal_info?.telephone}</span> :
                        <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                            className='icon-Info-Circle afa-info-circle'
                            title={'فیلد مورد نظر خالی است'}/></span>}
                </div>
                <div>
                    <span className='afa-title'>استان: </span>
                    {personal_info?.city?.state?.name ? <span className="afa-value">{personal_info?.city?.state?.name}</span> :
                        <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                            className='icon-Info-Circle afa-info-circle'
                            title={'فیلد مورد نظر خالی است'}/></span>}
                </div>
                <div>
                    <span className='afa-title'>شهر : </span>
                    {personal_info?.city?.name ? <span className="afa-value">{personal_info?.city?.name}</span> :
                        <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                            className='icon-Info-Circle afa-info-circle'
                            title={'فیلد مورد نظر خالی است'}/></span>}
                </div>
                <div>
                    <span className='afa-title'>نشانی محل سکونت : </span>
                    {personal_info?.address ? <span className="afa-value">{personal_info?.address}</span> :
                        <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                            className='icon-Info-Circle afa-info-circle'
                            title={'فیلد مورد نظر خالی است'}/></span>}
                </div>
                <div>
                    <span className='afa-title'>کد پستی</span>
                    {personal_info?.postal_code ? <span className="afa-value">{personal_info?.postal_code}</span> :
                        <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                            className='icon-Info-Circle afa-info-circle'
                            title={'فیلد مورد نظر خالی است'}/></span>}
                </div>
                <div>
                    <span className='afa-title'>وضعیت تأهل : </span>
                    {personal_info?.marital_status ?
                        <span className="afa-value">{personal_info?.marital_status}</span> :
                        <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                            className='icon-Info-Circle afa-info-circle'
                            title={'فیلد مورد نظر خالی است'}/></span>}
                </div>
                <div>
                    <span className='afa-title'>تعداد فرزندان : </span>
                    {personal_info?.offspring_count >= 0 ?
                        <span className="afa-value">{personal_info?.offspring_count}</span> :
                        <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                            className='icon-Info-Circle afa-info-circle'
                            title={'فیلد مورد نظر خالی است'}/></span>}
                </div>
                <div>
                    <span className='afa-title'>تعداد افراد تحت تکفل : </span>
                    {personal_info?.dependents_count >= 0 ?
                        <span className="afa-value">{personal_info?.dependents_count}</span> :
                        <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                            className='icon-Info-Circle afa-info-circle'
                            title={'فیلد مورد نظر خالی است'}/></span>}
                </div>
                <div>
                    <span className='afa-title'>قد : </span>
                    {personal_info?.height ? <span className="afa-value">{personal_info?.height}</span> :
                        <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                            className='icon-Info-Circle afa-info-circle'
                            title={'فیلد مورد نظر خالی است'}/></span>}
                </div>
                <div>
                    <span className='afa-title'>وزن : </span>
                    {personal_info?.weight ? <span className="afa-value">{personal_info?.weight}</span> :
                        <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                            className='icon-Info-Circle afa-info-circle'
                            title={'فیلد مورد نظر خالی است'}/></span>}
                </div>
                <div>
                    <span className='afa-title'>شماره بیمه : </span>
                    {( personal_info?.insurance_number !== null && personal_info?.insurance_number !== 'null' && personal_info?.insurance_number !== 'undefined' && personal_info?.insurance_number !== undefined) ?
                        <span className="afa-value">{personal_info?.insurance_number}</span> :
                        <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                            className='icon-Info-Circle afa-info-circle'
                            title={'فیلد مورد نظر خالی است'}/></span>}
                </div>
                {
                    personal_info?.gender === 'مرد' &&
                    <div>
                        <span className='afa-title'>وضعیت نظام وظیفه : </span>
                        {personal_info?.military_service_status ?
                            <span className="afa-value">{personal_info?.military_service_status}</span> :
                            <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                className='icon-Info-Circle afa-info-circle'
                                title={'فیلد مورد نظر خالی است'}/></span>}
                    </div>
                }
                <div>
                    <span className='afa-title'>بیماری قابل توجهی داشته اید : </span>
                    <span className="afa-value">{personal_info?.special_disease == 1 ? 'بله' : 'خیر'}</span>
                </div>
                {
                    personal_info?.special_disease == 1 &&
                    <div>
                        <span className='afa-title'>عنوان بیماری : </span>
                        {( personal_info?.special_disease_description !== null && personal_info?.special_disease_description !== 'null' && personal_info?.special_disease_description !== 'undefined' && personal_info?.special_disease_description !== undefined)?
                            <span className="afa-value">{personal_info?.special_disease_description}</span> :
                            <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                className='icon-Info-Circle afa-info-circle'
                                title={'فیلد مورد نظر خالی است'}/></span>}
                    </div>
                }
                <div>
                    <span className='afa-title'>آیا سیگار میکشید : </span>
                    <span className="afa-value">{personal_info?.does_smoke == 1 ? 'بله' : 'خیر'}</span>
                </div>
                <div>
                    <span className='afa-title'>طریقه آشنایی : </span>
                    {( personal_info?.introduction_method !== null && personal_info?.introduction_method !== 'null' && personal_info?.introduction_method !== 'undefined' && personal_info?.introduction_method !== undefined) ?
                        <span className="afa-value">{personal_info?.introduction_method}</span> :
                        <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                            className='icon-Info-Circle afa-info-circle'
                            title={'فیلد مورد نظر خالی است'}/></span>}
                </div>
                {
                    personal_info?.introduction_method === 'معرف' &&
                    <div>
                        <span className='afa-title'>نام و نام خانوادگی معرف : </span>
                        {( personal_info?.introducer_info !== null && personal_info?.introducer_info !== 'null' && personal_info?.introducer_info !== 'undefined' && personal_info?.introducer_info !== undefined) ?
                            <span className="afa-value">{personal_info?.introducer_info}</span> :
                            <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                className='icon-Info-Circle afa-info-circle'
                                title={'فیلد مورد نظر خالی است'}/></span>}
                    </div>
                }
                <div>
                    <span className='afa-title'>آیا سابقه محکومیت کیفری داشته اید : </span>
                    <span className="afa-value">{personal_info?.criminal_conviction == 1 ? 'بله' : 'خیر'}</span>
                </div>
                {
                    (personal_info?.birth_certificate_images , personal_info?.national_identification_images) &&
                    <div className='d-flex align-content-center'>
                        <span className='afa-title'>ضمایم </span>
                        <div className="d-flex align-items-center flex-wrap">
                            {
                                personal_info?.birth_certificate_images &&
                                <>
                                    {personal_info?.birth_certificate_images?.[1] && <span className="afa-img-value">
                                        <span className="afa-img-title">صفحه اطلاعات شخصی(شناسنامه)</span>
                                        <a href={`${MAIN_URL_IMAGE}${personal_info?.birth_certificate_images?.[1]}`}
                                           download={true} target={'_blank'}>
                                        <img className='afa-img-size-information'
                                             onError={(e) => {
                                                 e.target.onerror = null;
                                                 e.target.src = `${Images.placeHolder.default}`
                                             }}
                                             src={`${MAIN_URL_IMAGE}${personal_info?.birth_certificate_images?.[1]}`}
                                             alt="آذر فولاد امین"/>
                                        </a>
                                    </span>}
                                    {personal_info?.birth_certificate_images?.[2] && <span className="afa-img-value">
                                        <span className="afa-img-title">صفحه اطلاعات همسر(شناسنامه)</span>
                                        <a href={`${MAIN_URL_IMAGE}${personal_info?.birth_certificate_images?.[2]}`}
                                           download={true} target={'_blank'}>
                                        <img className='afa-img-size-information'
                                             onError={(e) => {
                                                 e.target.onerror = null;
                                                 e.target.src = `${Images.placeHolder.default}`
                                             }}
                                             src={`${MAIN_URL_IMAGE}${personal_info?.birth_certificate_images?.[2]}`}
                                             alt="آذر فولاد امین"/></a>
                                    </span>}
                                    {personal_info?.birth_certificate_images?.[3] && <span className="afa-img-value">
                                        <span className="afa-img-title">صفحه اطلاعات فرزندان(شناسنامه)</span>
                                        <a href={`${MAIN_URL_IMAGE}${personal_info?.birth_certificate_images?.[3]}`}
                                           download={true} target={'_blank'}>
                                        <img className='afa-img-size-information'
                                             onError={(e) => {
                                                 e.target.onerror = null;
                                                 e.target.src = `${Images.placeHolder.default}`
                                             }}
                                             src={`${MAIN_URL_IMAGE}${personal_info?.birth_certificate_images?.[3]}`}
                                             alt="آذر فولاد امین"/>
                                        </a>
                                    </span>}
                                    {personal_info?.birth_certificate_images?.[4] && <span className="afa-img-value">
                                        <span className="afa-img-title">صفحه توضیحات(شناسنامه)</span>
                                        <a href={`${MAIN_URL_IMAGE}${personal_info?.birth_certificate_images?.[4]}`}
                                           download={true} target={'_blank'}>
                                        <img className='afa-img-size-information'
                                             onError={(e) => {
                                                 e.target.onerror = null;
                                                 e.target.src = `${Images.placeHolder.default}`
                                             }}
                                             src={`${MAIN_URL_IMAGE}${personal_info?.birth_certificate_images?.[4]}`}
                                             alt="آذر فولاد امین"/>
                                        </a>
                                    </span>}
                                </>
                            }
                            {
                                personal_info?.national_identification_images &&
                                <>
                                    {personal_info?.national_identification_images?.['front'] &&
                                    <span className="afa-img-value">
                                            <span className="afa-img-title">عکس کارت ملی</span>
                                            <a href={`${MAIN_URL_IMAGE}${personal_info?.national_identification_images?.['front']}`}
                                               download={true} target={'_blank'}>
                                            <img className='afa-img-size-information'
                                                 onError={(e) => {
                                                     e.target.onerror = null;
                                                     e.target.src = `${Images.placeHolder.default}`
                                                 }}
                                                 src={`${MAIN_URL_IMAGE}${personal_info?.national_identification_images?.['front']}`}
                                                 alt="آذر فولاد امین"/>
                                            </a>
                                        </span>
                                    }
                                    {
                                        personal_info?.national_identification_images?.['back'] &&
                                        <span className="afa-img-value">
                                            <span className="afa-img-title">عکس پشت کارت ملی</span>
                                            <a href={`${MAIN_URL_IMAGE}${personal_info?.national_identification_images?.['back']}`}
                                               download={true} target={'_blank'}>
                                            <img className='afa-img-size-information'
                                                 onError={(e) => {
                                                     e.target.onerror = null;
                                                     e.target.src = `${Images.placeHolder.default}`
                                                 }}
                                                 src={`${MAIN_URL_IMAGE}${personal_info?.national_identification_images?.['back']}`}
                                                 alt="آذر فولاد امین"/>
                                            </a>
                                        </span>
                                    }
                                </>
                            }
                            {
                                ( personal_info?.gender === 'مرد' && personal_info?.military_service_document) &&
                                <span className="afa-img-value">
                                            <span className="afa-img-title">نظام وظیفه</span>
                                            <a href={`${MAIN_URL_IMAGE}${personal_info?.military_service_document}`}
                                               download={true} target={'_blank'}>
                                            <img className='afa-img-size-information'
                                                 onError={(e) => {
                                                     e.target.onerror = null;
                                                     e.target.src = `${Images.placeHolder.default}`
                                                 }}
                                                 src={`${MAIN_URL_IMAGE}${personal_info?.military_service_document}`}
                                                 alt="آذر فولاد امین"/>
                                            </a>
                                        </span>
                            }
                            {
                                personal_info?.criminal_conviction_document &&
                                <span className="afa-img-value">
                                            <span className="afa-img-title">سابقه کیفری</span>
                                            <a href={`${MAIN_URL_IMAGE}${personal_info?.criminal_conviction_document}`}
                                               download={true} target={'_blank'}>
                                            <img className='afa-img-size-information'
                                                 onError={(e) => {
                                                     e.target.onerror = null;
                                                     e.target.src = `${Images.placeHolder.default}`
                                                 }}
                                                 src={`${MAIN_URL_IMAGE}${personal_info?.criminal_conviction_document}`}
                                                 alt="آذر فولاد امین"/>
                                            </a>
                                        </span>
                            }
                        </div>
                    </div>
                }
            </InformationContent>
            <InformationButton>
                <button onClick={tabStatus}>
                    <span className='afa-information-btn-title'>اعلام وضعیت</span>
                </button>
                <Link to={'/manage-hr'}>
                    <span className='afa-information-btn-title'>لیست کاربران</span>
                </Link>
            </InformationButton>

            <Modal style={{textAlign: 'center'}} centered={true} show={modal?.show_tab_status}
                   onHide={closeModal}>
                <Modal.Body className='p-md-4' dir='rtl'>
                    <form className="row" onSubmit={tabStatusHandler}>
                        <div className="col-12 d-flex align-items-center mb-4">
                            <span style={{marginLeft: '1rem'}}>اعلام وضعیت  : </span>
                            <div className="d-flex align-items-center">
                                <label className="afa-custom-checkbox ml-4 mb-0">
                                    تکمیل
                                    <input type="radio" value='completed'
                                           onChange={inputHandler}
                                           checked={inputs[`status`] === 'completed'}
                                           name={`status`}/>
                                    <span className="checkmark"/>
                                </label>
                                <label className="afa-custom-checkbox ml-4 mb-0">
                                    ناقص
                                    <input type="radio" value='denied'
                                           onChange={inputHandler}
                                           checked={inputs[`status`] === 'denied'}
                                           name={`status`}/>
                                    <span className="checkmark"/>
                                </label>
                            </div>
                        </div>
                        <div className="mb-3 w-100">
                            <p className='k-text-right'>توضیحات</p>
                            <textarea className='afa-textarea' placeholder='توضیحات' name="status_description" rows="2"
                                      value={inputs?.status_description} onChange={inputHandler}></textarea>
                        </div>
                        <div
                            className='col-12 w-100 d-flex align-items-center'>
                            <button type='submit' className='afa-btn-save-modal'>ذخیره</button>
                            <button type='button' onClick={closeModal}
                                    className='afa-btn-cancel-modal'>انصراف
                            </button>
                        </div>
                    </form>

                </Modal.Body>
            </Modal>
        </>
    );
}

export default UserInformation;