import React, {useEffect, useState} from 'react';
import {setTitle, getData} from "../../../../assets/scripts/GeneralFunctions";
import {MAIN_URL} from "../../../../assets/scripts/GeneralVariables";
import {MainContent} from "../style";
import {Inputs} from "../../Child/Supplier/Child/style";
import {Link} from "react-router-dom";
import Skeleton from 'react-loading-skeleton'

function CommunicationWithUniversityInfo(props) {

    const [inputs, setInputs] = useState({
        reason: '',
        full_name: '',
        uni_name: '',
        phone: '',
        email: '',
        description: '',
    });

    const [loader, setLoader] = useState(true)

    useEffect(async () => {
        setTitle('آذر فولاد امین | ارتباط با دانشگاه ')
        let id = props.match.params.id;
        let api_result = await getData(MAIN_URL, `contacts/uni/info/${id}`, 'get', {}, true, false);
        if (api_result?.status === 200) {
            setLoader(false)
            setInputs(prevState => ({
                ...prevState,
                reason: api_result?.data?.contact_uni?.reason,
                full_name: api_result?.data?.contact_uni?.full_name,
                uni_name: api_result?.data?.contact_uni?.uni_name,
                phone: api_result?.data?.contact_uni?.phone,
                email: api_result?.data?.contact_uni?.email,
                description: api_result?.data?.contact_uni?.description,
            }))
        }
    }, []);

    return (
        <MainContent>
            <div className='row gx-3 gy-4'>
                <div
                    className="col-12 w-100 d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center mb-3">
                    <h6 className='text-red fw-bold'>ارتباط با دانشگاه</h6>
                </div>

                <div className="col-12 col-md-6">
                    <Inputs>
                        <span>علت درخواست</span>
                        {
                            loader ?
                                <Skeleton borderRadius={8} height={55}/> :
                                <input
                                    readOnly={true}
                                    className={'afa-error-input'}
                                    type="text" name={`reason`}
                                    value={inputs?.[`reason`]}
                                />
                        }
                    </Inputs>
                </div>
                <div className="col-12 col-md-6">
                    <Inputs>
                        <span>نام و نام خانوادگی</span>
                        {
                            loader ?
                                <Skeleton borderRadius={8} height={55}/> :
                                <input
                                    readOnly={true}
                                    className={'afa-error-input'}
                                    type="text" name={`full_name`}
                                    value={inputs?.[`full_name`]}
                                />
                        }
                    </Inputs>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                    <Inputs>
                        <span>نام دانشگاه</span>
                        {
                            loader ?
                                <Skeleton borderRadius={8} height={55}/> :
                                <input
                                    readOnly={true}
                                    className={'afa-error-input'}
                                    type="text" name={`uni_name`}
                                    value={inputs?.[`uni_name`]}
                                />
                        }
                    </Inputs>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                    <Inputs>
                        <span>شماره تماس</span>
                        {
                            loader ?
                                <Skeleton borderRadius={8} height={55}/> :
                                <input
                                    readOnly={true}
                                    className={'afa-error-input text-left'}
                                    type="number" name={`phone`}
                                    value={inputs?.[`phone`]}
                                />
                        }
                    </Inputs>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                    <Inputs>
                        <span>آدرس ایمیل</span>
                        {
                            loader ?
                                <Skeleton borderRadius={8} height={55}/> :
                                <input
                                    readOnly={true}
                                    className={'afa-error-input text-left'}
                                    type="email" name={`email`}
                                    value={inputs?.[`email`]}
                                />
                        }
                    </Inputs>
                </div>
                <div className="col-12">
                    <Inputs>
                        <span>شرح درخواست</span>
                        {
                            loader ?
                                <Skeleton borderRadius={8} height={100}/> :
                                <textarea
                                    readOnly={true}
                                    className={inputs['description'] && 'afa-error-input'}
                                    rows="3"
                                    name={`description`}
                                    value={inputs?.[`description`]}
                                />
                        }
                    </Inputs>
                </div>

                <div className="col-12 d-flex align-items-center justify-content-center mb-4">
                    <Link to={'/communication-university'} className={'dv-back-to-parent-btn rounded-pill'}>
                        <span className='pr-3'>بازگشت به لیست فرم ها</span>
                    </Link>
                </div>
            </div>
        </MainContent>
    );
}

export default CommunicationWithUniversityInfo;