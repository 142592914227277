import React, {useEffect, useState} from 'react';
import {getData, setTitle} from "../../../../assets/scripts/GeneralFunctions";
import {MainContent} from "../style";
import {Inputs} from "../../Child/Supplier/Child/style";
import {Link} from "react-router-dom";
import Skeleton from 'react-loading-skeleton'
import {MAIN_URL} from "../../../../assets/scripts/GeneralVariables";

function CommentsAndComplaintsInfo(props) {
    const [inputs, setInputs] = useState({
        locale: '',
        name: '',
        phone: '',
        email: '',
        message: '',
        reason: '',
        announcer: '',
        type: '',
    });
    const [loader, setLoader] = useState(true)

    useEffect(async () => {
        setTitle('آذر فولاد امین | نظرات و شکایات ')
        let id = props.match.params.id;
        let api_result = await getData(MAIN_URL, `contacts/contact/info/${id}`, 'get', {}, true, false);
        if (api_result?.status === 200) {
            setLoader(false)
            setInputs(prevState => ({
                ...prevState,
                locale: api_result?.data?.contact?.locale,
                name: api_result?.data?.contact?.name,
                phone: api_result?.data?.contact?.phone,
                email: api_result?.data?.contact?.email,
                message: api_result?.data?.contact?.message,
                reason: api_result?.data?.contact?.reason,
                type: api_result?.data?.contact?.type,
            }))
        }
    }, []);

    return (
        <MainContent>
            <div className='row gx-3 gy-4'>
                <div
                    className="col-12 w-100 d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center mb-3">
                    <h6 className='text-red fw-bold'>نظرات و شکایات</h6>
                </div>

                <div className="col-12">
                    <Inputs>
                        <span>نوع ارتباط</span>
                        {
                            loader ?
                                <Skeleton borderRadius={8} height={55}/> :
                                <input
                                    readOnly={true}
                                    className={'afa-error-input'}
                                    type="text" name={`reason`}
                                    value={inputs?.[`reason`]}/>
                        }
                    </Inputs>
                </div>

                <div className="col-12 col-md-6 col-lg-4">
                    <Inputs>
                        <span>نام و نام خانوادگی</span>
                        {
                            loader ?
                                <Skeleton borderRadius={8} height={55}/> :
                                <input
                                    readOnly={true}
                                    className={'afa-error-input'}
                                    type="text" name={`name`}
                                    value={inputs?.[`name`]}
                                />
                        }
                    </Inputs>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                    <Inputs>
                        <span>شماره تماس</span>
                        {
                            loader ?
                                <Skeleton borderRadius={8} height={55}/> :
                                <input
                                    readOnly={true}
                                    className={'afa-error-input text-left'}
                                    type="number" name={`phone`}
                                    value={inputs?.[`phone`]}
                                />
                        }
                    </Inputs>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                    <Inputs>
                        <span>آدرس ایمیل</span>
                        {
                            loader ?
                                <Skeleton borderRadius={8} height={55}/> :
                                <input
                                    readOnly={true}
                                    className={'afa-error-input text-left'}
                                    maxLength={100}
                                    type="email" name={`email`}
                                    value={inputs?.[`email`]}
                                />
                        }
                    </Inputs>
                </div>

                <div className="col-12">
                    <Inputs>
                        <span>شرح درخواست</span>
                        {
                            loader ?
                                <Skeleton borderRadius={8} height={100}/> :
                                <textarea
                                    readOnly={true}
                                    className={inputs['message'] && 'afa-error-input'}
                                    rows="3"
                                    name={`name`}
                                    value={inputs?.[`message`]}
                                />
                        }
                    </Inputs>
                </div>


                <div className="col-12 d-flex align-items-center justify-content-center mb-4">
                    <Link to={'/comments-complaints'} className={'dv-back-to-parent-btn rounded-pill'}>
                        <span className='pr-3'>بازگشت به لیست فرم ها</span>
                    </Link>
                </div>
            </div>
        </MainContent>
    );
}

export default CommentsAndComplaintsInfo;