import React, {useState} from 'react';
import {Images} from "../../assets/scripts/Images";

function Flags(props) {

    const [flag, setFlag] = useState({
        have_text: {
            name: 'فارسی',
            value: 'fa'
        },
        isOpen: false,
    })

    const flags = [
        {
            name: 'فارسی',
            value: 'fa',
        },
        {
            name: 'انگلیسی',
            value: 'en',
        },
        {
            name: 'ترکی',
            value: 'tr',
        },
        {
            name: 'عربی',
            value: 'ar',
        }
    ]

    const handleText = async (locale) => {
        props.handleLanguage(locale)
        setFlag(prevState => ({
            ...prevState,
            have_text: locale
        }))
    }

    const handleClick = () => {
        setFlag(prevState => ({
            ...prevState,
            isOpen: !flag.isOpen
        }))
    }

    return (
        <div className={flag.isOpen ? "dv-flag-dropdown active" : "dv-flag-dropdown"} onClick={handleClick}>
            <div className="dropdown__text">
                {!flag.have_text ? "انتخاب زبان" : ` زبان : ${flag.have_text?.name}`  }
            </div>
            <div className="dropdown__items">
                {
                    flags.map((item) => (
                        <div
                            onClick={() => handleText(item)}
                            className="dropdown__item align-items-center justify-content-between k-cursor-pointer"
                            key={item.value}>
                            <span>{item?.name}</span>
                            <img src={item?.value === 'fa' ? Images?.persian?.default :
                                item?.value === 'tr' ? Images?.turk?.default :
                                    item?.value === 'en' ? Images?.united_kingdom?.default : Images?.arabic?.default} alt="iran"/>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default Flags;