import React, {useState, useEffect} from 'react';
import {
    TabStatusComplete,
    TabStatusNotComplete,
    Inputs,
    LeftIcon,
    FileInputs, TabStatusContent
} from "./style";
import moment from "moment-jalaali";
import {
    checkCodeMelli,
    fileUpload,
    fixNumber,
    just_persian,
    getData,
    getFormDataPost, edit_item_with_id, remove_item_of_arr_with_id
} from "../../../../../assets/scripts/GeneralFunctions";
import {MAIN_URL, MAIN_URL_IMAGE} from "../../../../../assets/scripts/GeneralVariables";
import {DateInput} from "react-hichestan-datetimepicker";
import {Accordion, Card} from "react-bootstrap";
import {toast} from "react-toastify";
import Swal from "sweetalert2";
import {LoopCircleLoading} from "react-loadingg";

function Services(props) {
    const [error, setError] = useState({})
    const [inputs, setInputs] = useState({
        guaranty: 'بله',
        companies_sample: '',
        description: '',
        certificate_of_competence: '',
        remove_certificate_of_competence : '',

        supplier_services: [],

        main_services: [],
        main_service_id : 'انتخاب کنید',

        parent_service_list : [],
        parent_service_id : 'انتخاب کنید',

        services_list : [],
        service_list_id : 'انتخاب کنید',
    })
    const [arrow_item, setArrowItem] = useState({arrow_id: 0, arrow_status: false})
    const [is_render, setRender] = useState(false);
    const [new_collapse, setNewCollapse] = useState(false);
    const [more_data, setMoreData] = useState({sheet_status: {}, isLoad: true});
    const [loader, setLoader] = useState(false);
    const [loader2, setLoader2] = useState(false);

    useEffect(async () => {
        let api_result = await getData(MAIN_URL, "profile/supplier/services", 'get', {}, true, true);
        if (api_result?.status === 200) {
            let data = api_result?.data
            let supplier_services = data?.supplier_services
            setInputs(prevState => ({
                ...prevState,
                supplier_services : supplier_services,
                main_services : data?.main_services
            }))
            setMoreData({sheet_status: data?.sheet_status,status_colors: data?.status_colors, isLoad: false})
        }
    }, [is_render]);

    const inputHandler = (e) => {
        setInputs(prevState => ({
            ...prevState, [e.target.name]: e.target.value
        }))
    }

    const unitHandler = async (e) => {
        let id = e.target.value
        setLoader(true)
        let api_result = await getData(MAIN_URL, `profile/supplier/services-list/${id}`, 'get', {}, true, false);
        if (api_result?.status === 200) {
            setLoader(false)
            setInputs(prevState => ({
                ...prevState,
                [`${[e.target.name]}_id`] : id,
                [`parent_service_list`]: api_result?.data?.services_list,
                [`parent_service_id`] : 'انتخاب کنید',
            }))
        }
    }
    const unitHandler2 = async (e) => {
        let id = e.target.value
        setLoader(true)
        let api_result = await getData(MAIN_URL, `profile/supplier/services-list/${id}`, 'get', {}, true, false);
        if (api_result?.status === 200) {
            setLoader(false)
            setInputs(prevState => ({
                ...prevState,
                [`${[e.target.name]}_id`] : id,
                [`services_list`]: api_result?.data?.services_list,
                [`service_list_id`] : 'انتخاب کنید',
            }))
        }
    }

    const unitHandler4 = async (e , id) => {
        let value = e.target.value
        setLoader(true)
        let api_result = await getData(MAIN_URL, `profile/supplier/services-list/${value}`, 'get', {}, true, false);
        if (api_result?.status === 200) {
            setLoader(false)
            setInputs(prevState => ({
                ...prevState,
                [`${[e.target.name]}[${id}]`] : value,
                [`parent_service_list[${id}]`]: api_result?.data?.services_list,
                [`parent_service[${id}]_id`] : 'انتخاب کنید',
            }))
        }
    }
    const unitHandler5 = async (e , id) => {
        let value = e.target.value
        setLoader2(true)
        let api_result = await getData(MAIN_URL, `profile/supplier/services-list/${value}`, 'get', {}, true, false);
        if (api_result?.status === 200) {
            setLoader2(false)
            setInputs(prevState => ({
                ...prevState,
                [`${[e.target.name]}_id`] : value,
                [`services_list[${id}]`]: api_result?.data?.services_list,
                [`service_list[${id}]_id`] : 'انتخاب کنید',
            }))
        }
    }

    const changeArrow = (id) => {
        if (arrow_item.arrow_id === id && arrow_item.arrow_status === true) {

            setArrowItem({arrow_id: id, arrow_status: false})

        } else if (arrow_item.arrow_id === id && arrow_item.arrow_status === false) {

            setArrowItem({arrow_id: id, arrow_status: true})

        } else {
            setArrowItem({arrow_id: id, arrow_status: true})
        }

        let data = inputs?.supplier_services?.find((row) => {
            return row.id === id
        })

        if (data) {
            setInputs((prevState => ({
                ...prevState,


                [`service_list[${id}]_id`]: data?.service_list_id,
                [`services_list[${id}]`]: data?.parent_service?.children,

                [`parent_service_list[${id}]_id`]: data?.parent_service_list_id,
                [`parent_service_list[${id}]`]: data?.main_service?.children,

                [`main_service[${id}]_id`]: data?.main_service_list_id,

                [`certificate_of_competence[${id}]`]: data?.certificate_of_competence,
                [`guaranty[${id}]`]: data?.guaranty ,
                [`companies_sample[${id}]`]: data?.companies_sample,
                [`description[${id}]`]: data?.description,
            })))
        }
    }

    const thisUploadDocs = async (e) => {
        let files = e.target.files;
        let file = URL.createObjectURL(await fileUpload(files[0]))
        let upload_file = await fileUpload(files[0]);
        setInputs(prevState => ({
            ...prevState,
            [e.target.name + '_url']: file,
            [e.target.name]: upload_file,
            [e.target.name + '_file']: e.target.files[0]
        }))
    }

    const removeImage = (val) => {
        setInputs(prevState => ({
            ...prevState, [val]: null, [val + '_url']: null, [val + '_file']: null, [`remove_${val}`] : 1
        }))
    }

    const removeTab = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این مورد را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                let remove_result = await getData(MAIN_URL, `profile/supplier/services/remove/${id}`, 'post', {}, true, true);
                if (remove_result?.status === 200) {
                    swalWithBootstrapButtons.fire(
                        'حذف شد!',
                        'با موفقیت حذف شد',
                        'success'
                    )
                    let arr = inputs?.supplier_services;
                    let new_arr = remove_item_of_arr_with_id(arr , id)
                    setInputs(prevState => ({...prevState , supplier_services: new_arr}))
                }
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'لغو شد',
                    ' بدون تغییر باقی ماند',
                    'error'
                )
            }
        })
    }

    const removeTab2 = async () => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این مورد را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                setNewCollapse(false)
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'لغو شد',
                    ' بدون تغییر باقی ماند',
                    'error'
                )
            }
        })
    }

    const saveEditChange = async (id) => {
        let formData = new FormData()

        if (inputs?.[`service_list[${id}]_id`] !== 'انتخاب کنید') {
            formData.append('service_list_id', inputs?.[`service_list[${id}]_id`])
        }
        if (inputs?.[`guaranty[${id}]`] !== undefined) {
            formData.append('guaranty', inputs?.[`guaranty[${id}]`] == 1 ? 'بله' : 'خیر',)
        }
        if (inputs?.[`companies_sample[${id}]`] !== undefined) {
            formData.append('companies_sample', inputs?.[`companies_sample[${id}]`])
        }
        if (inputs?.[`description[${id}]`] !== undefined) {
            formData.append('description', inputs?.[`description[${id}]`])
        }
        if (inputs?.[`remove_certificate_of_competence[${id}]`] == 1) {
            formData.append('remove_certificate_of_competence', 1)
        }
        if(inputs?.[`certificate_of_competence[${id}]_file`] !== undefined){
            formData.append('certificate_of_competence', inputs?.[`certificate_of_competence[${id}]_file`])
        }

        let api_result = await getFormDataPost(`profile/supplier/services/${id}`, formData)
        if (api_result?.status === 200) {
            toast.success('با موفقیت تغییر یافت')
            setError('')
            let arrays = edit_item_with_id(inputs?.supplier_services , api_result?.data?.supplier_service)
            setInputs(prevState => ({...prevState , supplier_services: arrays , [`remove_certificate_of_competence[${id}]`] : 0}))
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }

    const addNewCollapse = () => {
        setInputs(prevState => ({
            ...prevState,
            guaranty: 'بله',
            companies_sample: '',
            description: '',
            certificate_of_competence: '',
            remove_certificate_of_competence : '',

            main_service_id : 'انتخاب کنید',

            parent_service_list : [],
            parent_service_id : 'انتخاب کنید',

            service_list_id : 'انتخاب کنید',
        }));
        setNewCollapse(true)
    }

    const saveNewCollapse = async () => {
        let formData = new FormData()
        if (inputs?.[`service_list_id`] !== 'انتخاب کنید') {
            formData.append('service_list_id', inputs?.[`service_list_id`])
        }
        if (inputs?.[`certificate_of_competence`] !== undefined) {
            formData.append('certificate_of_competence', inputs?.[`certificate_of_competence`])
        }
        if (inputs?.[`guaranty`] !== undefined) {
            formData.append('guaranty', inputs?.[`guaranty`])
        }
        if (inputs?.[`companies_sample`] !== undefined) {
            formData.append('companies_sample', inputs?.[`companies_sample`])
        }
        if (inputs?.[`description`] !== undefined) {
            formData.append('description', inputs?.[`description`])
        }
        if (inputs?.[`certificate_of_competence_file`] !== undefined) {
            formData.append('certificate_of_competence', inputs?.[`certificate_of_competence_file`])
        }
        if (inputs?.[`remove_certificate_of_competence`]) {
            formData.append('remove_certificate_of_competence', 1)
        }

        let api_result = await getFormDataPost('profile/supplier/services', formData)

        if (api_result?.status === 200) {
            toast.success('با موفقیت افزوده شد')
            setError('')
            setRender(!is_render)
            setInputs(prevState => ({
                ...prevState,
                certificate_of_competence_file: '',
                certificate_of_competence_url: '',
                guaranty: 'بله',
                companies_sample: '',
                description: '',
                services_list: [],
                service_list_id : 'انتخاب کنید',
                parent_service_list : [],
                parent_service_id : 'انتخاب کنید',
                main_service_id : 'انتخاب کنید',
            }))
            setNewCollapse(false)
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }

    const formHandler = async (e) => {
        e.preventDefault()
        let formData = new FormData()

        if (inputs?.[`service_list_id`] !== 'انتخاب کنید') {
            formData.append('service_list_id', inputs?.[`service_list_id`])
        }
        if (inputs?.[`certificate_of_competence`] !== undefined) {
            formData.append('certificate_of_competence', inputs?.[`certificate_of_competence`])
        }
        if (inputs?.[`guaranty`] !== undefined) {
            formData.append('guaranty', inputs?.[`guaranty`])
        }
        if (inputs?.[`companies_sample`] !== undefined) {
            formData.append('companies_sample', inputs?.[`companies_sample`])
        }
        if (inputs?.[`description`] !== undefined) {
            formData.append('description', inputs?.[`description`])
        }
        if (inputs?.[`certificate_of_competence_file`] !== undefined) {
            formData.append('certificate_of_competence', inputs?.[`certificate_of_competence_file`])
        }
        if (inputs?.[`remove_certificate_of_competence`]) {
            formData.append('remove_certificate_of_competence', 1)
        }


        let api_result = await getFormDataPost('profile/supplier/services', formData)

        if (api_result?.status === 200) {
            toast.success('با موفقیت افزوده شد')
            setError('')
            if(inputs?.supplier_services?.length > 0 ){
                let arr = inputs?.supplier_services;
                arr.push(api_result?.data?.technical_staff)
                setInputs(prevState => ({
                    ...prevState,
                    certificate_of_competence_file: '',
                    certificate_of_competence_url: '',
                    guaranty: 'بله',
                    companies_sample: '',
                    description: '',
                    services_list: [],
                    service_list_id : 'انتخاب کنید',
                    parent_service_list : [],
                    parent_service_id : 'انتخاب کنید',
                    main_service_id : 'انتخاب کنید',
                    remove_certificate_of_competence : '',
                    supplier_services: arr
                }))
            }else{
                setRender(!is_render)
            }
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }

    return (
        <>
            {
                !inputs?.["isLoad"] &&
                <>
                    <form onSubmit={formHandler} className="row">
                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                            <TabStatusComplete style={{borderRightColor: props.status_colors?.colors?.border_right}}>
                                {
                                    props.status_value === 'pending' || props.status_value === 'denied' ?
                                        <span style={{
                                            color: props.status_colors?.colors?.color,
                                            backgroundColor: props.status_colors?.colors?.background
                                        }} className='icon-Iconly-Bulk-Info-Square afa-icon-tick'/> :
                                        <span style={{
                                            color: props.status_colors?.colors?.color,
                                            backgroundColor: props.status_colors?.colors?.background
                                        }} className='icon-tick afa-icon-tick'/>
                                }
                                <p className='mb-0 f-14 '><span>وضعیت : </span><span>{props.status_colors?.title}</span>
                                </p>
                            </TabStatusComplete>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-8 mb-4">
                            {
                                more_data.sheet_status?.message &&
                                <TabStatusContent style={{borderRightColor: props.status_colors?.colors?.border_right}}>
                            <span className="icon-Iconly-Bulk-Message afa-icon-message">
                                <span className="path1"></span>
                                <span className="path2"></span>
                            </span>
                                    <p className='mb-0 f-14'>
                                        <span>پیام ادمین : </span><span>{more_data.sheet_status?.message}</span>
                                    </p>
                                </TabStatusContent>
                            }
                        </div>
                        {
                            inputs?.supplier_services?.length > 0 ?
                                <>
                                    <Accordion defaultActiveKey="0">
                                        {
                                            inputs?.supplier_services?.map((item, i) => (
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey={`${item.id}`} key={i} onClick={() => changeArrow(item.id)}>
                                                        <div className="d-flex flex-wrap">
                                                            <span className="icon-Delete dv-icon-delete pl-2 my-2" onClick={() => removeTab(item.id)}/>
                                                            {
                                                                item?.main_service?.title && <span
                                                                    className="pl-4 f-14 my-2"> زمینه فعالیت : {item?.main_service?.title}</span>
                                                            }
                                                             <span className="pl-4 f-14 my-2"> امکان تضمین : {item?.guaranty == 1 ? 'دارد' : 'ندارد'}</span>
                                                        </div>
                                                        <span className={(arrow_item.arrow_id === item.id && arrow_item.arrow_status) ? "icon-cheveron-up dv-collapse-arrow" : "icon-cheveron-down dv-collapse-arrow"}/>
                                                    </Accordion.Toggle>

                                                    <Accordion.Collapse eventKey={`${item.id}`}>
                                                        <Card.Body className='row'>


                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs className={'position-relative'}>
                                                                    <span>زمینه فعالیت
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <select
                                                                        className={error[`main_service[${item.id}]`] && 'afa-error-input'}
                                                                        value={inputs?.[`main_service[${item.id}]`]}
                                                                        onChange={(e)=>unitHandler4(e , item.id)}
                                                                        name={`main_service[${item.id}]`}>
                                                                        <option value='انتخاب کنید' disabled>انتخاب کنید</option>
                                                                        {
                                                                            inputs?.main_services?.map((row , index)=>(
                                                                                <option value={row?.id} key={index}>{row?.title}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                    <p className='afa-error-text'>{error[`main_service_id[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4 d-flex align-items-end">
                                                                <Inputs className={'position-relative'}>
                                                                    <span></span>
                                                                    <select
                                                                        className={error[`parent_service[${item.id}]`] && 'afa-error-input'}
                                                                        value={inputs?.[`parent_service[${item.id}]_id`]}
                                                                        onChange={(e)=>unitHandler5(e , item.id)}
                                                                        name={`parent_service[${item.id}]`}>
                                                                        <option value='انتخاب کنید' disabled>انتخاب کنید</option>
                                                                        {
                                                                            inputs?.[`parent_service_list[${item.id}]`]?.map((row , index)=>(
                                                                                <option value={row?.id} key={index}>{row?.title}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                    {
                                                                        loader && <div className="dv-skill-loader">
                                                                            <LoopCircleLoading color={'green'}/>
                                                                        </div>
                                                                    }
                                                                    <p className='afa-error-text'>{error[`parent_service_list_id[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4 d-flex align-items-end">
                                                                <Inputs className={'position-relative'}>
                                                                   <span></span>
                                                                    <select
                                                                        className={error[`service_list_id[${item.id}]`] && 'afa-error-input'}
                                                                        value={inputs?.[`service_list[${item.id}]_id`]}
                                                                        onChange={inputHandler}
                                                                        name={`service_list[${item.id}]_id`}>
                                                                        <option value='انتخاب کنید' disabled>انتخاب کنید
                                                                        </option>
                                                                        {
                                                                            inputs?.[`services_list[${item.id}]`]?.map((row , index)=>(
                                                                                <option value={row?.id} key={index}>{row?.title}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                    {
                                                                        loader2 && <div className="dv-skill-loader">
                                                                            <LoopCircleLoading color={'green'}/>
                                                                        </div>
                                                                    }
                                                                    <p className='afa-error-text'>{error[`service_list_id[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>

                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <span>رضایت نامه کار
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <FileInputs
                                                                    className={error[`certificate_of_competence[${item.id}]`] && 'afa-error-input'}>
                                                                    {
                                                                        (inputs[`certificate_of_competence[${item.id}]_url`] || inputs[`certificate_of_competence[${item.id}]`]) &&
                                                                        <div className="afa-img">
                                                                            <img
                                                                                src={inputs[`certificate_of_competence[${item.id}]_url`] ? inputs[`certificate_of_competence[${item.id}]_url`] : `${MAIN_URL_IMAGE}${inputs[`certificate_of_competence[${item.id}]`]}`}
                                                                                alt="آذر فولاد امین"/>
                                                                            <span
                                                                                className='icon-close-solid afa-remove-position'
                                                                                onClick={() => removeImage(`certificate_of_competence[${item.id}]`)}/>
                                                                        </div>
                                                                    }
                                                                    <label className="afa-center-pos">
                                                                        <span className='icon-cloud-computing'/>
                                                                        <input type="file" accept="image/*"
                                                                               onChange={thisUploadDocs}
                                                                               name={`certificate_of_competence[${item.id}]`}
                                                                               className='dv-upload-file'/>
                                                                        {
                                                                            !inputs[`certificate_of_competence[${item.id}]`] &&
                                                                            <span className='upload-text'>آپلود</span>
                                                                        }
                                                                    </label>
                                                                </FileInputs>
                                                                <p className='afa-error-text'>{error[`certificate_of_competence[${item.id}]`]}</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>امکان تضمین
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <select
                                                                        className={error[`guaranty[${item.id}]`] && 'afa-error-input'}
                                                                        value={inputs?.[`guaranty[${item.id}]`]}
                                                                        onChange={inputHandler}
                                                                        name={`guaranty[${item.id}]`}>
                                                                        <option value='انتخاب کنید' disabled>انتخاب کنید
                                                                        </option>
                                                                        <option value="1">دارد</option>
                                                                        <option value="0">ندارد</option>
                                                                    </select>
                                                                    <p className='afa-error-text'>{error[`guaranty[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>نمونه ارائه شده در شرکت ها و سازمان ها
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <input
                                                                        className={error[`companies_sample[${item.id}]`] && 'afa-error-input'}
                                                                        type="text" name={`companies_sample[${item.id}]`}
                                                                        value={inputs?.[`companies_sample[${item.id}]`]}
                                                                        onChange={(e) =>
                                                                            setInputs(prevState => ({
                                                                                ...prevState,
                                                                                [`companies_sample[${item.id}]`]: e.target.value
                                                                            }))
                                                                        }/>
                                                                    <p className='afa-error-text'>{error[`companies_sample[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>

                                                            <div className="col-12 mb-4">
                                                                <Inputs>
                                                                    <span>توضیحات
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <textarea
                                                                        className={error[`description[${item.id}]`] && 'afa-error-input'}
                                                                        rows={2} name={`description[${item.id}]`}
                                                                        value={inputs?.[`description[${item.id}]`]}
                                                                        onChange={(e) =>
                                                                            setInputs(prevState => ({
                                                                                ...prevState,
                                                                                [`description[${item.id}]`]: e.target.value
                                                                            }))
                                                                        }/>
                                                                    <p className='afa-error-text'>{error[`description[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>

                                                            <div className="col-12 d-flex justify-content-center">
                                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                    <Inputs>
                                                                        <button type='button'
                                                                                onClick={() => saveEditChange(item.id)}
                                                                                className='afa-add-more-btn'>
                                                                            <span className='pr-3'>ذخیره تغییرات</span>
                                                                        </button>
                                                                    </Inputs>
                                                                </div>
                                                            </div>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            ))
                                        }

                                        {
                                            new_collapse &&
                                            <Card>
                                                <Accordion.Toggle as={Card.Header} eventKey={'0'} onClick={() => changeArrow(0)}>
                                                    <div className="d-flex flex-wrap">
                                                        <span className="icon-Delete dv-icon-delete pl-2 my-2"
                                                              onClick={() => removeTab2('rasoul')}></span>
                                                        {/*{*/}
                                                        {/*    inputs?.service_list_id !== 'انتخاب کنید' && <span*/}
                                                        {/*        className="pl-4 f-14 my-2"> زمینه فعالیت : {inputs?.service_list_id !== 'انتخاب کنید'}</span>*/}
                                                        {/*}*/}
                                                        {
                                                            inputs?.guaranty && <span
                                                                className="pl-4 f-14 my-2"> امکان تضمین : {inputs?.guaranty}</span>
                                                        }
                                                    </div>
                                                    <span
                                                        className={(arrow_item.arrow_id === 0 && arrow_item.arrow_status) ? "icon-cheveron-up dv-collapse-arrow" : "icon-cheveron-down dv-collapse-arrow"}/>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey={'0'}>
                                                    <Card.Body className='row'>

                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs className={'position-relative'}>
                                                                <span>زمینه فعالیت
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <select
                                                                    className={error[`main_service_id`] && 'afa-error-input'}
                                                                    value={inputs?.[`main_service_id`]}
                                                                    onChange={(e)=>unitHandler(e)}
                                                                    name={`main_service`}>
                                                                    <option value='انتخاب کنید' disabled>انتخاب کنید</option>
                                                                    {
                                                                        inputs?.main_services?.map((row , index)=>(
                                                                            <option value={row?.id} key={index}>{row?.title}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                                <p className='afa-error-text'>{error[`main_service_list_id`]}</p>
                                                            </Inputs>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4 d-flex align-items-end">
                                                            <Inputs className={'position-relative'}>
                                                                <select
                                                                    className={error[`parent_service_id`] && 'afa-error-input'}
                                                                    value={inputs?.[`parent_service_id`]}
                                                                    onChange={(e)=>unitHandler2(e)}

                                                                    name={`parent_service`}>
                                                                    <option value='انتخاب کنید' disabled>انتخاب کنید</option>
                                                                    {
                                                                        inputs?.parent_service_list?.map((row , index)=>(
                                                                            <option value={row?.id} key={index}>{row?.title}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                                {
                                                                    loader && <div className="dv-skill-loader">
                                                                        <LoopCircleLoading color={'green'}/>
                                                                    </div>
                                                                }
                                                                <p className='afa-error-text'>{error[`parent_service_list_id`]}</p>
                                                            </Inputs>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4 d-flex align-items-end">
                                                            <Inputs className={'position-relative'}>
                                                                <select
                                                                    className={error[`service_list_id`] && 'afa-error-input'}
                                                                    value={inputs?.[`service_list_id`]}
                                                                    onChange={inputHandler}
                                                                    name={`service_list_id`}>
                                                                    <option value='انتخاب کنید' disabled>انتخاب کنید</option>
                                                                    {
                                                                        inputs?.services_list?.map((row , index)=>(
                                                                            <option value={row?.id} key={index}>{row?.title}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                                {
                                                                    loader2 && <div className="dv-skill-loader">
                                                                        <LoopCircleLoading color={'green'}/>
                                                                    </div>
                                                                }
                                                                <p className='afa-error-text'>{error[`service_list_id`]}</p>
                                                            </Inputs>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <span>رضایت نامه کار
                                                            <span className='dv-required-star'>*</span>
                                                            </span>
                                                            <FileInputs
                                                                className={error[`certificate_of_competence`] && 'afa-error-input'}>
                                                                {
                                                                    (inputs[`certificate_of_competence_url`] || inputs[`certificate_of_competence`]) &&
                                                                    <div className="afa-img">
                                                                        <img
                                                                            src={inputs[`certificate_of_competence_url`] ? inputs[`certificate_of_competence_url`] : `${MAIN_URL_IMAGE}${inputs[`certificate_of_competence`]}`}
                                                                            alt="آذر فولاد امین"/>
                                                                        <span
                                                                            className='icon-close-solid afa-remove-position'
                                                                            onClick={() => removeImage(`certificate_of_competence`)}/>
                                                                    </div>
                                                                }
                                                                <label className="afa-center-pos">
                                                                    <span className='icon-cloud-computing'/>
                                                                    <input type="file" accept="image/*"
                                                                           onChange={thisUploadDocs}
                                                                           name={`certificate_of_competence`}
                                                                           className='dv-upload-file'/>
                                                                    {
                                                                        !inputs[`certificate_of_competence`] &&
                                                                        <span className='upload-text'>آپلود</span>
                                                                    }
                                                                </label>
                                                            </FileInputs>
                                                            <p className='afa-error-text'>{error[`certificate_of_competence`]}</p>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <span>امکان تضمین
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <select
                                                                    className={error[`guaranty`] && 'afa-error-input'}
                                                                    value={inputs?.[`guaranty`]}
                                                                    onChange={inputHandler}
                                                                    name={`guaranty`}>
                                                                    <option value='انتخاب کنید' disabled>انتخاب کنید
                                                                    </option>
                                                                    <option value="بله">دارد</option>
                                                                    <option value="خیر">ندارد</option>
                                                                </select>
                                                                <p className='afa-error-text'>{error[`guaranty`]}</p>
                                                            </Inputs>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <span>نمونه ارائه شده در شرکت ها و سازمان ها
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <input
                                                                    className={error[`companies_sample`] && 'afa-error-input'}
                                                                    type="text" name={`companies_sample`}
                                                                    value={inputs?.[`companies_sample`]}
                                                                    onChange={(e) =>
                                                                        setInputs(prevState => ({
                                                                            ...prevState,
                                                                            [`companies_sample`]: e.target.value
                                                                        }))
                                                                    }/>
                                                                <p className='afa-error-text'>{error[`companies_sample`]}</p>
                                                            </Inputs>
                                                        </div>
                                                        <div className="col-12 mb-4">
                                                            <Inputs>
                                                                <span>توضیحات
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <textarea
                                                                    className={error[`description`] && 'afa-error-input'}
                                                                    rows={2} name={`description`}
                                                                    value={inputs?.[`description`]}
                                                                    onChange={(e) =>
                                                                        setInputs(prevState => ({
                                                                            ...prevState,
                                                                            [`description`]: e.target.value
                                                                        }))
                                                                    }/>
                                                                <p className='afa-error-text'>{error[`description`]}</p>
                                                            </Inputs>
                                                        </div>

                                                        <div className="col-12 d-flex justify-content-center">
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <button type='button'
                                                                            onClick={saveNewCollapse}
                                                                            className='afa-add-more-btn'>
                                                                        <span className='pr-3'>ذخیره تغییرات</span>
                                                                    </button>
                                                                </Inputs>
                                                            </div>
                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        }

                                    </Accordion>

                                    <div className="row">
                                        <div className="col-12 col-sm-6 col-lg-8 mb-4"/>

                                        <div className={"col-12 col-sm-6 col-lg-4 mb-4 d-flex align-items-center"}>

                                            <Inputs>

                                                <button type='button' onClick={addNewCollapse}
                                                        className='afa-add-more-btn'>

                                                    <span className='icon-add f-20'/>

                                                    <span className='pr-3'>افزودن اطلاعات</span>

                                                </button>

                                            </Inputs>

                                        </div>
                                    </div>
                                </> :
                                <>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs className={'position-relative'}>
                                            <span>زمینه فعالیت
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <select
                                                className={error[`main_service_id`] && 'afa-error-input'}
                                                value={inputs?.[`main_service_id`]}
                                                onChange={(e)=>unitHandler(e)}
                                                name={`main_service`}>
                                                <option value='انتخاب کنید' disabled>انتخاب کنید</option>
                                                {
                                                    inputs?.main_services?.map((row , index)=>(
                                                        <option value={row?.id} key={index}>{row?.title}</option>
                                                    ))
                                                }
                                            </select>
                                            <p className='afa-error-text'>{error[`main_service_list_id`]}</p>
                                        </Inputs>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4 d-flex align-items-end">
                                        <Inputs className={'position-relative'}>
                                            <select
                                                className={error[`parent_service_id`] && 'afa-error-input'}
                                                value={inputs?.[`parent_service_id`]}
                                                onChange={(e)=>unitHandler2(e)}

                                                name={`parent_service`}>
                                                <option value='انتخاب کنید' disabled>انتخاب کنید</option>
                                                {
                                                    inputs?.parent_service_list?.map((row , index)=>(
                                                        <option value={row?.id} key={index}>{row?.title}</option>
                                                    ))
                                                }
                                            </select>
                                            {
                                                loader && <div className="dv-skill-loader">
                                                    <LoopCircleLoading color={'green'}/>
                                                </div>
                                            }
                                            <p className='afa-error-text'>{error[`parent_service_list_id`]}</p>
                                        </Inputs>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4 d-flex align-items-end">
                                        <Inputs className={'position-relative'}>
                                           <span></span>
                                            <select
                                                className={error[`service_list_id`] && 'afa-error-input'}
                                                value={inputs?.[`service_list_id`]}
                                                onChange={inputHandler}
                                                name={`service_list_id`}>
                                                <option value='انتخاب کنید' disabled>انتخاب کنید</option>
                                                {
                                                    inputs?.services_list?.map((row , index)=>(
                                                        <option value={row?.id} key={index}>{row?.title}</option>
                                                    ))
                                                }
                                            </select>
                                            {
                                                loader2 && <div className="dv-skill-loader">
                                                    <LoopCircleLoading color={'green'}/>
                                                </div>
                                            }
                                            <p className='afa-error-text'>{error[`service_list_id`]}</p>
                                        </Inputs>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <span>رضایت نامه کار
                                        <span className='dv-required-star'>*</span>
                                        </span>
                                        <FileInputs
                                            className={error[`certificate_of_competence`] && 'afa-error-input'}>
                                            {
                                                (inputs[`certificate_of_competence_url`] || inputs[`certificate_of_competence`]) &&
                                                <div className="afa-img">
                                                    <img
                                                        src={inputs[`certificate_of_competence_url`] ? inputs[`certificate_of_competence_url`] : `${MAIN_URL_IMAGE}${inputs[`certificate_of_competence`]}`}
                                                        alt="آذر فولاد امین"/>
                                                    <span
                                                        className='icon-close-solid afa-remove-position'
                                                        onClick={() => removeImage(`certificate_of_competence`)}/>
                                                </div>
                                            }
                                            <label className="afa-center-pos">
                                                <span className='icon-cloud-computing'/>
                                                <input type="file" accept="image/*"
                                                       onChange={thisUploadDocs}
                                                       name={`certificate_of_competence`}
                                                       className='dv-upload-file'/>
                                                {
                                                    !inputs[`certificate_of_competence`] &&
                                                    <span className='upload-text'>آپلود</span>
                                                }
                                            </label>
                                        </FileInputs>
                                        <p className='afa-error-text'>{error[`certificate_of_competence`]}</p>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <span>امکان تضمین
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <select
                                                className={error[`guaranty`] && 'afa-error-input'}
                                                value={inputs?.[`guaranty`]}
                                                onChange={inputHandler}
                                                name={`guaranty`}>
                                                <option value='انتخاب کنید' disabled>انتخاب کنید
                                                </option>
                                                <option value="بله">دارد</option>
                                                <option value="خیر">ندارد</option>
                                            </select>
                                            <p className='afa-error-text'>{error[`guaranty`]}</p>
                                        </Inputs>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <span>نمونه ارائه شده در شرکت ها و سازمان ها
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <input
                                                className={error[`companies_sample`] && 'afa-error-input'}
                                                type="text" name={`companies_sample`}
                                                value={inputs?.[`companies_sample`]}
                                                onChange={(e) =>
                                                    setInputs(prevState => ({
                                                        ...prevState,
                                                        [`companies_sample`]: e.target.value
                                                    }))
                                                }/>
                                            <p className='afa-error-text'>{error[`companies_sample`]}</p>
                                        </Inputs>
                                    </div>
                                    <div className="col-12 mb-4">
                                        <Inputs>
                                            <span>توضیحات
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <textarea
                                                className={error[`description`] && 'afa-error-input'}
                                                rows={2} name={`description`}
                                                value={inputs?.[`description`]}
                                                onChange={(e) =>
                                                    setInputs(prevState => ({
                                                        ...prevState,
                                                        [`description`]: e.target.value
                                                    }))
                                                }/>
                                            <p className='afa-error-text'>{error[`description`]}</p>
                                        </Inputs>
                                    </div>

                                    <div className="col-12 d-flex justify-content-center">
                                        <div className={"col-12 col-sm-6 col-lg-4 mb-4 d-flex align-items-center"}>
                                            <Inputs>
                                                <button type='submit' className='afa-add-more-btn'>
                                                    <span className='icon-add f-20'/>
                                                    <span className='pr-3'>افزودن اطلاعات</span>
                                                </button>
                                            </Inputs>
                                        </div>
                                    </div>
                                </>
                        }
                    </form>
                </>
            }
        </>
    )
}

export default Services;