import React, {useEffect, useState} from 'react';
import Swal from "sweetalert2";
import {MainContent} from "../style";
import {SearchInput} from "../../Child/SupplierMangement/style";
import {Link} from "react-router-dom";
import {Modal} from "react-bootstrap";
import {Inputs} from "../../Child/Supplier/Child/style";
import {Images} from "../../../../assets/scripts/Images";

function StaffInfo(props) {
    const [inputs, setInputs] = useState({
        search_value: '',
        user_status: 1,
        permission_type: [
            {
                name : "امکان ویرایش نام و نام خانوادگی",
                id : 1
            },
            {
                name : "امکان تغییر شماره همراه",
                id : 2
            },
        ],
        selected: [],
    })
    const [modal, setModal] = useState({show_user_status: false, show_user_permission : false});

    useEffect(()=>{
        let unit_id = props.match.params.unit_id;
    } , [])

    const searchHandler = async (e) => {
        // let obj = {};
        // setInputs(prevState => ({
        //     ...prevState,
        //     search_value: e.target.value
        // }))
        // let api_result = await getData(MAIN_URL, `hr-management`, 'get', e.target.value, true, true);
        // if (api_result?.status === 200) {
        //     let data = api_result?.data
        //     setInputs(prevState => ({
        //         ...prevState,
        //     }))
        // }
    }

    const inputHandler = (e) => {
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}))
    }

    // user status
    const showUserStatus = (id) => {
        setModal({show_user_status: true})
    }

    const userStatusHandler = async (e) => {
        e.preventDefault()
        setModal({show_user_status: false})
    }

    // user permisson
    const UserPermissionShow = (id) => {
        setModal({show_user_permission: true})
    }

    const checkBoxHandler = (id) => {
        let selected = [];
        if (inputs?.selected?.length > 0) {
            selected = inputs?.selected
        }
        let find = selected?.indexOf(id)

        if (find > -1) {
            selected?.splice(find, 1)
        } else {
            selected?.push(id)
        }

        setInputs(prevState => ({...prevState, selected: selected}))
    }

    const userPermissionHandler = async (e) => {
        e.preventDefault()

        setModal({show_user_permission: false})
    }

    const closeModal = () => {
        setModal({show_user_status: false})
    }
    return (
        <MainContent>
            <div className='row'>
                <div className="col-12 col-sm-6 col-lg-9 mb-3"><h6 className='text-red fw-bold'>پرسنل ها</h6></div>
                <div className="col-12 col-sm-6 col-lg-3 mb-3">
                    <SearchInput className='mb-3 w-100'>
                        <span className='icon-Search dv-search-icon'/>
                        <input type="search" value={inputs?.search_value} onChange={searchHandler} placeholder='جستجو'/>
                    </SearchInput>
                </div>
                <div className="col-12 mb-3 afa-lazyload">
                    <table id={'afa_user_table'}>
                        <thead>
                        <tr>
                            <td></td>
                            <td>نام کاربر</td>
                            <td>کد ملی</td>
                            <td>شماره موبایل</td>
                            <td>نوع کاربر</td>
                            <td>واحد</td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr className='afa-inactive-user'>
                            <td className='afa-user-width'>
                                <div className="d-flex align-items-center justify-content-between">
                                    <span className="icon-star-full afa-empty-start"/>
                                    <img src={Images.perseneli.default} className='afa-user-avatar' alt="آذر فولاد امین"/>
                                </div>
                            </td>
                            <td>
                                <span className='afa-tbody-text'>کاربر تست</span>
                            </td>
                            <td><span className="afa-tbody-text">1710290668</span></td>
                            <td><span className="afa-tbody-text">09106878810</span></td>
                            <td><span className="afa-tbody-text">حقیقی</span></td>
                            <td><span className="afa-tbody-text">فنی</span></td>
                            <td className='afa-table-btns-width'>
                                <div className="d-flex align-items-center">
                                    <button className='afa-user-status-table-btn' onClick={()=>showUserStatus(1)}>غیر فعالسازی</button>
                                    <button className="afa-table-edit-btn" onClick={()=>UserPermissionShow(1)}>
                                        <span className="icon-edit"></span>
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className='afa-user-width'>
                                <div className="d-flex align-items-center justify-content-between">
                                    <span className="icon-star-full afa-gold-start"/>
                                    <img src={Images.perseneli.default} className='afa-user-avatar' alt="آذر فولاد امین"/>
                                </div>
                            </td>
                            <td>
                                <span className='afa-tbody-text'>کاربر تست</span>
                            </td>
                            <td><span className="afa-tbody-text">1710290668</span></td>
                            <td><span className="afa-tbody-text">09106878810</span></td>
                            <td><span className="afa-tbody-text">حقیقی</span></td>
                            <td><span className="afa-tbody-text">فنی</span></td>
                            <td className='afa-table-btns-width'>
                                <div className="d-flex align-items-center">
                                    <button className='afa-user-status-table-btn' onClick={()=>showUserStatus(2)}>غیر فعالسازی</button>
                                    <button className="afa-table-edit-btn" onClick={()=>UserPermissionShow(2)}>
                                        <span className="icon-edit"></span>
                                    </button>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>


            <Modal centered={true} show={modal?.show_user_status}
                   onHide={closeModal}>
                <Modal.Body className='p-md-4' dir='rtl'>
                    <form className="row" onSubmit={userStatusHandler}>
                        <div className="col-12 mb-4">
                            <h6 className='mb-4 text-red'>وضعیت کاربر</h6>
                            <p className='mb-5'>آیا مایل به غیر فعال سازی کاربر تست هستید ؟ </p>
                            <div className="d-flex align-items-center mb-4">
                                <label className="afa-custom-checkbox ml-4 mb-0">
                                    بله
                                    <input type="radio" value={1}
                                           onChange={inputHandler}
                                           checked={inputs[`user_status`] == 1}
                                           name={`user_status`}/>
                                    <span className="checkmark"/>
                                </label>
                                <label className="afa-custom-checkbox ml-4 mb-0">
                                    خیر
                                    <input type="radio" value={0}
                                           onChange={inputHandler}
                                           checked={inputs[`user_status`] == 0}
                                           name={`user_status`}/>
                                    <span className="checkmark"/>
                                </label>
                            </div>
                        </div>
                        <div
                            className='col-12 w-100 d-flex align-items-center'>
                            <button type='submit' className='afa-btn-save-modal'>ذخیره</button>
                            <button type='button' onClick={closeModal}
                                    className='afa-btn-cancel-modal'>انصراف
                            </button>
                        </div>
                    </form>

                </Modal.Body>
            </Modal>

            <Modal centered={true} className='afa-user-status-modal' show={modal?.show_user_permission}
                   onHide={closeModal}>
                <Modal.Body className='p-md-4' dir='rtl'>
                    <form className="row" onSubmit={userPermissionHandler}>
                        <div className="col-12 mb-4">
                            <h6 className='mb-4 text-red'>ویرایش دسترسی ها</h6>
                            <div className="d-flex flex-column w-100">
                                {
                                    inputs?.permission_type?.map((item, index) => (
                                        <label className="afa-custom-checkbox afa-permission-checkbox" key={index}>{item?.name}
                                            <input type="checkbox" name='certificate_type'
                                                   value={item?.id}
                                                   onChange={() => checkBoxHandler(item?.id)}
                                                   checked={inputs.selected?.includes(item?.id)}
                                            />
                                            <span className="checkmark2"></span>
                                        </label>
                                    ))
                                }
                            </div>
                        </div>
                        <div
                            className='col-12 w-100 d-flex align-items-center'>
                            <button type='submit' className='afa-btn-save-modal'>ذخیره</button>
                            <button type='button' onClick={closeModal}
                                    className='afa-btn-cancel-modal'>انصراف
                            </button>
                        </div>
                    </form>

                </Modal.Body>
            </Modal>

        </MainContent>
    );
}

export default StaffInfo;