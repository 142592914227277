import React, {useEffect, useState} from 'react';
import {MainContent} from "../style";
import {
    edit_item_with_id,
    fileUpload,
    fixNumber,
    getData,
    getFormDataPost,
    just_number, remove_item_of_arr_with_id, setTitle
} from "../../../../assets/scripts/GeneralFunctions";
import {Images} from "../../../../assets/scripts/Images";
import {BannerFile, BannerFileMini, Inputs} from "../../Child/Supplier/Child/style";
import {MAIN_URL, MAIN_URL_IMAGE} from "../../../../assets/scripts/GeneralVariables";
import {Modal} from "react-bootstrap";
import Select from 'react-select';
import {toast} from "react-toastify";
import Swal from "sweetalert2";
import {MapContainer, Marker, Popup, TileLayer, useMapEvents} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import GuideUploadingFile from "./GuideUploadingFile";
import {useDispatch} from "react-redux";
import CkEditorText from "../Article/CkEditorText";
import Flags from "../../../utils/Flags";
import InputField from "./InputField";

function Setting() {

    const [inputs, setInputs] = useState({
        title: '',
        short_description: '',
        description: '',
        tags: [],
        banner_image_url: '',
        banner_image: '',
        banner_image_file: '',
        social_name: 'انتخاب کنید',
        social_media: [],
        link: '',
        help_social_media: [],
        locale: 'fa',
        contact_email: '',
        wage_receipt: '',
        amir_steel_wage_receipt: '',
        automation_url: '',
        banners: [],
        banner_id: null,
        banner_title: '',
        banner_description: '',
        show_open_modal_value: '',
        show_open_modal_value_without_s: '',
        certificates: [],
        chart_image: '',
        chart_image_url: '',
        chart_description: '',
        certificate_description: '',
        help_supplier_help: '',
        supplier_help: '',
        help_hr_help: '',
        hr_help: '',
        mission_and_vision: '',
        policy: '',
        management_message: '',
        management_name: '',
        management_image_url: '',
        management_image_doc_url: '',
        management_image: '',
        office_address: '',
        office_phone: '',
        office_zip_code: '',
        office_fax: '',
        factory_address: '',
        factory_phone: '',
        factory_zip_code: '',
        factory_fax: '',
        location: '',
        factory_location: '',
        tehran_location: '',
        tehran_office_fax: '',
        tehran_office_phone: '',
        tehran_office_address: '',
        tehran_office_zip_code: '',

        home_page_meta_description: '',
        gallery_page_meta_description: '',
        afa_meta_description: '',
        foulad_amir_azarbaijan_meta_description: '',
        azar_artin_meta_description: '',
        directorates_meta_description: '',
        mission_and_vision_meta_description: '',
        policy_meta_description: '',
        management_message_meta_description: '',
        contact_us_suggestions_message_meta_description: '',
        contact_us_contact_uni_meta_description: '',
        contact_us_suggestion_system_meta_description: '',
        contact_us_management_meta_description: '',
        blog_meta_description: '',
        organization_meta_description: '',
        laboratory_meta_description: '',
        certificates_meta_description: '',
    })
    const [error, setError] = useState({})
    const [is_render, setRender] = useState(false)
    const [modal, setModal] = useState({show_edit_banner: false, show_add_item_modal: false});
    const [position, setPosition] = useState([]);
    const [factory_position, setFactoryPosition] = useState([]);
    const [tehran_position, setTehranPosition] = useState([]);
    const dispatch = useDispatch()
    const data = [
        {
            value: 'facebook',
            type: 'facebook',
            icon: <span className="icon-telegram"><span className="path1"></span><span className="path2"></span><span
                className="path3"></span><span className="path4"></span></span>
        },
        {
            value: 'twitter',
            type: 'twitter',
            icon: <span className="icon-whatsapp"><span className="path1"></span><span className="path2"></span><span
                className="path3"></span><span className="path4"></span><span className="path5"></span></span>
        },
        {
            value: 'linked_in',
            type: 'linked_in',
            icon: <span className="icon-linkedin"></span>
        },
        {
            value: 'instagram',
            type: 'instagram',
            icon: <span className="icon-linkedin"></span>
        },
        {
            value: 'telegram',
            type: 'telegram',
            icon: <span className="icon-linkedin"></span>
        },
        {
            value: 'youtube',
            type: 'youtube',
            icon: <span className="icon-linkedin"></span>
        },
        {
            value: 'aparat',
            type: 'aparat',
            icon: <span className="icon-linkedin"></span>
        }
    ];
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(async () => {
        setTitle('آذر فولاد امین | مدیریت سایت')
        let api_result = await getData(MAIN_URL, `site-management/${inputs?.locale}`, 'get', {}, true, true);
        if (api_result?.status === 200) {
            let arr = []
            arr[0] = parseFloat(api_result?.data?.office_location?.split(',')[0]);
            arr[1] = parseFloat(api_result?.data?.office_location?.split(',')[1]);
            setPosition(arr);

            let factory_arr = []
            factory_arr[0] = parseFloat(api_result?.data?.factory_location?.split(',')[0]);
            factory_arr[1] = parseFloat(api_result?.data?.factory_location?.split(',')[1]);
            setFactoryPosition(factory_arr);

            let tehran_arr = []
            tehran_arr[0] = parseFloat(api_result?.data?.tehran_office_location?.split(',')[0]);
            tehran_arr[1] = parseFloat(api_result?.data?.tehran_office_location?.split(',')[1]);
            setTehranPosition(tehran_arr);

            setInputs(prevState => ({
                ...prevState,
                banners: api_result?.data?.banners,
                certificates: api_result?.data?.certificates,
                contact_email: api_result?.data?.contact_email,

                office_phone: api_result?.data?.office_phone,
                factory_phone: api_result?.data?.factory_phone,
                tehran_office_phone: api_result?.data?.tehran_office_phone,

                office_address: api_result?.data?.office_address,
                factory_address: api_result?.data?.factory_address,
                tehran_office_address: api_result?.data?.tehran_office_address,

                office_zip_code: api_result?.data?.office_zip_code,
                factory_zip_code: api_result?.data?.factory_zip_code,
                tehran_office_zip_code: api_result?.data?.tehran_office_zip_code,

                office_fax: api_result?.data?.office_fax,
                factory_fax: api_result?.data?.factory_fax,
                tehran_office_fax: api_result?.data?.tehran_office_fax,


                social_media: api_result?.data?.social_media,
                automation_url: api_result?.data?.automation_url,
                wage_receipt: api_result?.data?.wage_receipt,
                amir_steel_wage_receipt: api_result?.data?.amir_steel_wage_receipt,
                chart_image_url: api_result?.data?.chart,
                chart_description: api_result?.data?.chart_description,
                certificate_description: api_result?.data?.certificates_description,
                hr_help: api_result?.data?.hr_help,
                help_hr_help: api_result?.data?.hr_help,
                supplier_help: api_result?.data?.supplier_help,
                help_supplier_help: api_result?.data?.supplier_help,
                policy: api_result?.data?.policy,
                mission_and_vision: api_result?.data?.mission_and_vision,
                management_message: api_result?.data?.management_message,
                management_name: api_result?.data?.management_name,
                management_image_url: api_result?.data?.management_image,

                home_page_meta_description: api_result?.data?.home_page_meta_description,
                gallery_page_meta_description: api_result?.data?.gallery_page_meta_description,
                afa_meta_description: api_result?.data?.afa_meta_description,
                foulad_amir_azarbaijan_meta_description: api_result?.data?.foulad_amir_azarbaijan_meta_description,
                azar_artin_meta_description: api_result?.data?.azar_artin_meta_description,
                directorates_meta_description: api_result?.data?.directorates_meta_description,
                mission_and_vision_meta_description: api_result?.data?.mission_and_vision_meta_description,
                policy_meta_description: api_result?.data?.policy_meta_description,
                management_message_meta_description: api_result?.data?.management_message_meta_description,
                contact_us_suggestions_message_meta_description: api_result?.data?.contact_us_suggestions_message_meta_description,
                contact_us_contact_uni_meta_description: api_result?.data?.contact_us_contact_uni_meta_description,
                contact_us_suggestion_system_meta_description: api_result?.data?.contact_us_suggestion_system_meta_description,
                contact_us_management_meta_description: api_result?.data?.contact_us_management_meta_description,
                blog_meta_description: api_result?.data?.blog_meta_description,
                organization_meta_description: api_result?.data?.organization_meta_description,
                laboratory_meta_description: api_result?.data?.laboratory_meta_description,
                certificates_meta_description: api_result?.data?.certificates_meta_description,
            }))
            setError('')
        }
    }, [is_render]);

    const thisUploadDocs = async (e) => {
        let files = e.target.files;
        let file = URL.createObjectURL(await fileUpload(files[0]))
        let upload_file = await fileUpload(files[0]);
        setInputs(prevState => ({
            ...prevState,
            [e.target.name + '_url']: file,
            [e.target.name + '_doc_url']: file,
            [e.target.name]: upload_file,
            [e.target.name + '_file']: e.target.files[0]
        }))
    }

    const removeImage = (val) => {
        setInputs(prevState => ({
            ...prevState, [val]: null, [val + '_url']: null, [val + '_file']: null
        }))
    }

    // handle onChange event of the dropdown
    const handleChange = e => {
        setSelectedOption(e);
    }

    const addNewLink = () => {
        let help_arr = [];
        help_arr = inputs?.help_social_media;
        let obj = {}
        let help_obj = {}
        if (inputs?.link && selectedOption?.value) {
            help_obj['link'] = inputs?.link
            help_obj['type'] = selectedOption?.type
            help_obj['value'] = selectedOption?.value
            // help_obj['icon'] = selectedOption?.icon
            help_arr.push(help_obj)
            setInputs(prevState => ({...prevState, link: '', help_social_media: help_arr}))
            setSelectedOption(null);
        } else {
            toast.error('فیلد های مورد نظر نمی توانند خالی باشند')
        }

    }

    const handleRemoveSocial = (index, type) => {
        if (type === 'help') {
            let help_arr = inputs?.help_social_media
            help_arr?.splice(index, 1)
            setInputs(prevState => ({...prevState, help_social_media: help_arr}))
        } else {
            let arr = inputs?.social_media
            arr?.splice(index, 1)
            setInputs(prevState => ({...prevState, social_media: arr}))
        }
    }

    const closeModal = () => {
        setModal(prevState => ({
            ...prevState,
            [`show_edit_${inputs?.show_open_modal_value}`]: false,
            [`show_add_${inputs?.show_open_modal_value}_item_modal`]: false
        }))
    }

    const handleLanguage = async (locale) => {
        setInputs(prevState => ({...prevState, locale: locale?.value}))
        setError('')
        setRender(!is_render)

    }

    let latlng;
    const GetIcon = (_iconSize) => {
        return L.icon({
            iconUrl: Images.mapIcon.default,
            iconSize: [_iconSize]
        })
    }
    const LocationMarker = (props) => {
        const [position, setPosition] = useState(props?.latLng)
        latlng = props.latLng
        const map = useMapEvents({
            click(e) {
                setPosition(e.latlng)
                latlng = e.latlng
                // setPosition([position?.lat,position?.lng])
            }
        })


        return position === null ? null : (
            <Marker position={position} {...props}>
                <Popup>You are here</Popup>
            </Marker>
        )
    }

    let factory_latlng;
    const FactoryGetIcon = (_iconSize) => {
        return L.icon({
            iconUrl: Images.mapIcon.default,
            iconSize: [_iconSize]
        })
    }
    const FactoryLocationMarker = (props) => {
        const [position, setPosition] = useState(props?.latLng)
        factory_latlng = props.latLng
        const map = useMapEvents({
            click(e) {
                setPosition(e.latlng)
                factory_latlng = e.latlng
                // setPosition([position?.lat,position?.lng])
            }
        })
        return position === null ? null : (
            <Marker position={position} {...props}>
                <Popup>You are here</Popup>
            </Marker>
        )
    }

    let tehran_latlng;
    const TehranGetIcon = (_iconSize) => {
        return L.icon({
            iconUrl: Images.mapIcon.default,
            iconSize: [_iconSize]
        })
    }
    const TehranLocationMarker = (props) => {
        const [position, setPosition] = useState(props?.latLng)
        tehran_latlng = props.latLng
        const map = useMapEvents({
            click(e) {
                setPosition(e.latlng)
                tehran_latlng = e.latlng
            }
        })
        return position === null ? null : (
            <Marker position={position} {...props}>
                <Popup>You are here</Popup>
            </Marker>
        )
    }

    // guide files function
    const onChangeGuideFile = ({file, name}) => {
        setInputs(prevState => ({...prevState, [name]: file}))
    }

    const formHandler = async (e) => {
        e.preventDefault()

        let form_data = new FormData()

        let location = (latlng?.lat && latlng?.lng) ? `${latlng?.lat},${latlng?.lng}` : latlng?.toString()

        let factory_location = (factory_latlng?.lat && factory_latlng?.lng) ? `${factory_latlng?.lat},${factory_latlng?.lng}` : factory_latlng?.toString()

        let tehran_location = (tehran_latlng?.lat && tehran_latlng?.lng) ? `${tehran_latlng?.lat},${tehran_latlng?.lng}` : tehran_latlng?.toString()

        form_data.append('contact_email', inputs?.contact_email)

        form_data.append('office_address', inputs?.office_address)
        form_data.append('factory_address', inputs?.factory_address)
        form_data.append('tehran_office_address', inputs?.tehran_office_address)

        form_data.append('office_phone', inputs?.office_phone)
        form_data.append('factory_phone', inputs?.factory_phone)
        form_data.append('tehran_office_phone', inputs?.tehran_office_phone)

        form_data.append('office_zip_code', inputs?.office_zip_code)
        form_data.append('factory_zip_code', inputs?.factory_zip_code)
        form_data.append('tehran_office_zip_code', inputs?.tehran_office_zip_code)

        form_data.append('office_fax', inputs?.office_fax)
        form_data.append('factory_fax', inputs?.factory_fax)
        form_data.append('tehran_office_fax', inputs?.tehran_office_fax)

        form_data.append('office_location', location)
        form_data.append('factory_location', factory_location)
        form_data.append('tehran_office_location', tehran_location)

        form_data.append('automation_url', inputs?.automation_url)
        form_data.append('wage_receipt', inputs?.wage_receipt)
        form_data.append('amir_steel_wage_receipt', inputs?.amir_steel_wage_receipt)
        form_data.append('certificates_description', inputs?.certificate_description)
        form_data.append('chart_description', inputs?.chart_description)

        // register Guide
        if (inputs?.help_hr_help !== inputs?.hr_help) {
            form_data.append('hr_help', inputs?.hr_help)
        }
        if (inputs?.help_supplier_help !== inputs?.supplier_help) {
            form_data.append('supplier_help', inputs?.supplier_help)
        }

        form_data.append('management_message', inputs?.management_message)
        form_data.append('management_name', inputs?.management_name)
        if (inputs?.management_image) {
            form_data.append('management_image', inputs?.management_image)
        }
        form_data.append('mission_and_vision', inputs?.mission_and_vision)
        form_data.append('policy', inputs?.policy)

        form_data.append('home_page_meta_description', inputs?.home_page_meta_description)
        form_data.append('gallery_page_meta_description', inputs?.gallery_page_meta_description)
        form_data.append('afa_meta_description', inputs?.afa_meta_description)
        form_data.append('foulad_amir_azarbaijan_meta_description', inputs?.foulad_amir_azarbaijan_meta_description)
        form_data.append('azar_artin_meta_description', inputs?.azar_artin_meta_description)
        form_data.append('directorates_meta_description', inputs?.directorates_meta_description)
        form_data.append('mission_and_vision_meta_description', inputs?.mission_and_vision_meta_description)
        form_data.append('policy_meta_description', inputs?.policy_meta_description)
        form_data.append('management_message_meta_description', inputs?.management_message_meta_description)
        form_data.append('contact_us_suggestions_message_meta_description', inputs?.contact_us_suggestions_message_meta_description)
        form_data.append('contact_us_contact_uni_meta_description', inputs?.contact_us_contact_uni_meta_description)
        form_data.append('contact_us_suggestion_system_meta_description', inputs?.contact_us_suggestion_system_meta_description)
        form_data.append('contact_us_management_meta_description', inputs?.contact_us_management_meta_description)
        form_data.append('blog_meta_description', inputs?.blog_meta_description)
        form_data.append('organization_meta_description', inputs?.organization_meta_description)
        form_data.append('laboratory_meta_description', inputs?.laboratory_meta_description)
        form_data.append('certificates_meta_description', inputs?.certificates_meta_description)

        if (inputs?.chart_image) {
            form_data.append('chart', inputs?.chart_image)
        }

        let arr = inputs?.social_media.concat(inputs?.help_social_media)
        for (let i = 0; i < arr?.length; i++) {
            form_data.append(`social_media[${i}][type]`, arr[i]['type'])
            form_data.append(`social_media[${i}][link]`, arr[i]['link'])
            // form_data.append(`social_media[${i}][icon]`, arr[i]['icon'])
            form_data.append(`social_media[${i}][value]`, arr[i]['value'])
        }
        // for (let pair of form_data.entries()) {
        //     console.log(pair[0] + ', ' + pair[1]);
        // }

        let api_result = await getFormDataPost(`site-management/edit/${inputs?.locale}`, form_data)
        if (api_result?.status === 200) {
            let arr = []
            arr[0] = parseFloat(api_result?.data?.office_location?.split(',')[0]);
            arr[1] = parseFloat(api_result?.data?.office_location?.split(',')[1]);
            setPosition(arr);

            let factory_arr = []
            factory_arr[0] = parseFloat(api_result?.data?.factory_location?.split(',')[0]);
            factory_arr[1] = parseFloat(api_result?.data?.factory_location?.split(',')[1]);
            setFactoryPosition(factory_arr);

            let tehran_arr = []
            tehran_arr[0] = parseFloat(api_result?.data?.tehran_office_location?.split(',')[0]);
            tehran_arr[1] = parseFloat(api_result?.data?.tehran_office_location?.split(',')[1]);
            setTehranPosition(tehran_arr);

            setInputs(prevState => ({
                ...prevState,
                banners: api_result?.data?.banners,
                certificates: api_result?.data?.certificates,
                contact_email: api_result?.data?.contact_email,

                office_phone: api_result?.data?.office_phone,
                factory_phone: api_result?.data?.factory_phone,
                tehran_office_phone: api_result?.data?.tehran_office_phone,

                office_address: api_result?.data?.office_address,
                factory_address: api_result?.data?.factory_address,
                tehran_office_address: api_result?.data?.tehran_office_address,

                office_zip_code: api_result?.data?.office_zip_code,
                factory_zip_code: api_result?.data?.factory_zip_code,
                tehran_office_zip_code: api_result?.data?.tehran_office_zip_code,

                office_fax: api_result?.data?.office_fax,
                factory_fax: api_result?.data?.factory_fax,
                tehran_office_fax: api_result?.data?.tehran_office_fax,


                social_media: api_result?.data?.social_media,
                automation_url: api_result?.data?.automation_url,
                wage_receipt: api_result?.data?.wage_receipt,
                amir_steel_wage_receipt: api_result?.data?.amir_steel_wage_receipt,
                chart_image_url: api_result?.data?.chart,
                chart_description: api_result?.data?.chart_description,
                certificate_description: api_result?.data?.certificates_description,
                hr_help: api_result?.data?.hr_help,
                help_hr_help: api_result?.data?.hr_help,
                supplier_help: api_result?.data?.supplier_help,
                help_supplier_help: api_result?.data?.supplier_help,
                policy: api_result?.data?.policy,
                mission_and_vision: api_result?.data?.mission_and_vision,
                management_message: api_result?.data?.management_message,
                management_name: api_result?.data?.management_name,
                management_image_url: api_result?.data?.management_image,
            }))
            setError('')

            toast.success('با موفقیت ثبت شد')
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }

    // Add
    const addItemShowModal = (value, value_without_s) => {
        setInputs(prevState => ({
            ...prevState,
            [`${value}_id`]: null,
            [`${value}_image`]: '',
            [`${value}_description`]: '',
            [`${value}_title`]: '',
            [`${value}_image_url`]: '',
            [`${value}_image_file`]: '',
            show_open_modal_value: value,
            show_open_modal_value_without_s: value_without_s,
        }))
        setModal(prevState => ({...prevState, [`show_add_${value}_item_modal`]: true}))
    }
    const addModalSubmit = async (e) => {
        e.preventDefault()
        let form_data = new FormData();
        form_data.append('title', inputs[`${inputs?.show_open_modal_value}_title`])
        form_data.append('description', inputs[`${inputs?.show_open_modal_value}_description`])
        if (inputs[`${inputs?.show_open_modal_value}_image_file`]) {
            form_data.append('image', inputs[`${inputs?.show_open_modal_value}_image_file`])
        }
        let api_result = await getFormDataPost(`site-management/${inputs?.show_open_modal_value}/create/${inputs?.locale}`, form_data);
        if (api_result?.status === 200) {
            let arr = []
            arr[0] = api_result?.data[`${inputs?.show_open_modal_value_without_s}`];
            setInputs(prevState => ({
                ...prevState,
                [`${inputs?.show_open_modal_value}`]: arr.concat(inputs[`${inputs?.show_open_modal_value}`])
            }))
            toast.success('با موفقیت اضافه شد')
            setError('')
            setModal(prevState => ({...prevState, [`show_add_${inputs?.show_open_modal_value}_item_modal`]: false}))
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا اطلاعات بنر را با دقت تکمیل فرمایید')
        }
    }
    // Edit
    const editBanner = (id, row, value, value_without_s) => {
        setInputs(prevState => ({
            ...prevState,
            [`${value}_id`]: id,
            [`${value}_title`]: row?.title,
            [`${value}_description`]: row?.description,
            [`${value}_image`]: row[`${value_without_s}`],
            show_open_modal_value: value,
            show_open_modal_value_without_s: value_without_s,
        }))
        setModal(prevState => ({
            ...prevState, [`show_edit_${value}`]: true,
        }))
    }
    const editBannerHandler = async (e) => {
        e.preventDefault()
        let form_data = new FormData();
        form_data.append('title', inputs[`${inputs?.show_open_modal_value}_title`])
        form_data.append('description', inputs[`${inputs?.show_open_modal_value}_description`])
        if (inputs[`${inputs?.show_open_modal_value}_image_file`]) {
            form_data.append('image', inputs[`${inputs?.show_open_modal_value}_image_file`])
        }
        let api_result = await getFormDataPost(`site-management/${inputs['show_open_modal_value']}/edit/${inputs[`${inputs?.show_open_modal_value}_id`]}`, form_data);
        if (api_result?.status === 200) {
            let arr = edit_item_with_id(inputs[`${inputs?.show_open_modal_value}`], api_result?.data[`${inputs?.show_open_modal_value_without_s}`])
            setInputs(prevState => ({
                ...prevState,
                [`${inputs?.show_open_modal_value}`]: arr,
                [`${inputs?.show_open_modal_value}_id`]: null,
                [`${inputs?.show_open_modal_value}_image`]: '',
                [`${inputs?.show_open_modal_value}_description`]: '',
                [`${inputs?.show_open_modal_value}_title`]: '',
                [`${inputs?.show_open_modal_value}_image_url`]: '',
                [`${inputs?.show_open_modal_value}_image_file`]: ''
            }))
            setModal(prevState => ({...prevState, [`show_edit_${inputs?.show_open_modal_value}`]: false}))
            setError('')
            toast.success('با موفقیت تغییر یافت')
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error(`خطا : لطفا اطلاعات را با دقت تکمیل فرمایید`)
        }
    }
    // remove
    const handleRemoveBanner = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این مورد را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                setModal(prevState => ({...prevState, [`show_edit_${inputs?.show_open_modal_value}`]: false}))
                let remove_result = await getData(MAIN_URL, `site-management/${inputs?.show_open_modal_value}/remove/${id}`, 'post', {}, true, false);
                if (remove_result?.status === 200) {
                    let arr = inputs[`${inputs?.show_open_modal_value}`];
                    let new_arr = remove_item_of_arr_with_id(arr, id)
                    setInputs(prevState => ({...prevState, [`${inputs?.show_open_modal_value}`]: new_arr}))
                    swalWithBootstrapButtons.fire(
                        'حذف شد!',
                        'با موفقیت حذف شد',
                        'success'
                    )
                }
            }
        })
    }

    const setPolicyData = (value) => {
        setInputs(prevState => ({...prevState, policy: value}))
    }

    const setMissionData = (value) => {
        setInputs(prevState => ({...prevState, mission_and_vision: value}))
    }
    return (
        <MainContent>
            <form onSubmit={formHandler} className='row'>
                <div
                    className="col-12 w-100 d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center mb-3">
                    <h6 className='text-red fw-bold'>مدیریت بنرها</h6>
                    <Flags handleLanguage={handleLanguage}/>
                </div>
                <div className="col-12 mb-3">
                    <div className="row px-0">
                        <div className="col-12 col-md-6 col-lg-3 mb-3">
                            <div className="afa-upload-file-parent">
                                <BannerFile>
                                    <label
                                        className="afa_upload_file d-flex flex-column align-items-center justify-content-center">
                                        <img src={Images?.article_upload?.default}
                                             className='afa-article-image-to-upload' alt="آذر فولاد امین"/>
                                        <span className='upload-text mt-4'
                                              onClick={() => addItemShowModal('banners', 'banner')}>ایجاد بنر</span>
                                    </label>
                                </BannerFile>
                            </div>
                        </div>
                        {
                            inputs?.banners?.length > 0 &&
                            inputs?.banners?.map((row, index) => (
                                <div className="col-12 col-md-6 col-lg-3 mb-3" key={index}>
                                    <div className="afa-upload-file-parent">
                                        <BannerFile>
                                            <div className="afa-img">
                                                <img
                                                    src={`${MAIN_URL_IMAGE}${row?.thumbnail}`}
                                                    alt="آذر فولاد امین"/>
                                                <span
                                                    className='afa-remove-position icon-edit'
                                                    onClick={() => editBanner(row?.id, row, 'banners', 'banner')}/>
                                            </div>
                                        </BannerFile>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>

                {/*کارخانه*/}
                <div className="col-12 col-md-6 mb-4">
                    <h6 className='text-red fw-bold mb-4'>اطلاعات تماس کارخانه</h6>
                    <Inputs className='mb-3'>
                        <span>ادرس کامل</span>
                        <input className={'afa-error-input'}
                               type="text"
                               name={'factory_address'}
                               value={inputs.factory_address}
                               onChange={(e) =>
                                   setInputs(prevState => ({
                                       ...prevState, 'factory_address': e.target.value
                                   }))
                               }/>
                        <p className='afa-error-text'>{error[`factory_address`]}</p>
                    </Inputs>
                    <Inputs className='mb-3'>
                        <span>شماره تماس</span>
                        <input
                            className={error[`factory_phone`] && 'afa-error-input'}
                            type="text"
                            max={99999999999}
                            maxLength={11}
                            name={`factory_phone`}
                            value={inputs?.[`factory_phone`]}
                            onChange={(e) =>
                                just_number(e.target.value) && setInputs(prevState => ({
                                    ...prevState, [`factory_phone`]: fixNumber(e.target.value)
                                }))
                            } placeholder='- - - - - - - - - - -'/>
                        <p className='afa-error-text'>{error[`factory_phone`]}</p>
                    </Inputs>
                    <Inputs className='mb-3'>
                        <span>کد پستی</span>
                        <input
                            className={error[`factory_zip_code`] && 'afa-error-input'}
                            type="text"
                            max={9999999999}
                            maxLength={10}
                            name={`factory_zip_code`}
                            value={inputs?.[`factory_zip_code`]}
                            onChange={(e) =>
                                just_number(e.target.value) && setInputs(prevState => ({
                                    ...prevState, [`factory_zip_code`]: fixNumber(e.target.value)
                                }))
                            } placeholder='- - - - - - - - - - -'/>
                        <p className='afa-error-text'>{error[`factory_zip_code`]}</p>
                    </Inputs>
                    <Inputs className='mb-3'>
                        <span>فکس</span>
                        <input
                            className={error[`factory_fax`] && 'afa-error-input'}
                            type="text"
                            name={`factory_fax`}
                            value={inputs?.[`factory_fax`]}
                            onChange={(e) =>
                                just_number(e.target.value) && setInputs(prevState => ({
                                    ...prevState, [`factory_fax`]: fixNumber(e.target.value)
                                }))
                            } placeholder='- - - - - - - - - - -'/>
                        <p className='afa-error-text'>{error[`factory_fax`]}</p>
                    </Inputs>
                    <span className="f-14">آدرس روی نقشه</span>
                    {
                        factory_position[0] &&
                        <div style={{width: '100%', height: '400px'}}>
                            <MapContainer center={factory_position} zoom={13} scrollWheelZoom={false}
                                          style={{height: '400px'}}>
                                <TileLayer
                                    attribution='Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <FactoryLocationMarker icon={FactoryGetIcon(50)} latLng={factory_position}/>
                            </MapContainer>
                        </div>
                    }
                </div>

                {/*دفتر مرکزی*/}
                <div className="col-12 col-md-6 mb-4">
                    <h6 className='text-red fw-bold mb-4'>اطلاعات تماس دفتر مرکزی</h6>
                    <Inputs className='mb-3'>
                        <span>ادرس کامل</span>
                        <input className={'afa-error-input'}
                               type="text"
                               name={'office_address'}
                               value={inputs.office_address}
                               onChange={(e) =>
                                   setInputs(prevState => ({
                                       ...prevState, 'office_address': e.target.value
                                   }))
                               }/>
                        <p className='afa-error-text'>{error[`office_address`]}</p>
                    </Inputs>
                    <Inputs className='mb-3'>
                        <span>شماره تماس</span>
                        <input
                            className={error[`office_phone`] && 'afa-error-input'}
                            type="text"
                            max={99999999999}
                            maxLength={11}
                            name={`office_phone`}
                            value={inputs?.[`office_phone`]}
                            onChange={(e) =>
                                just_number(e.target.value) && setInputs(prevState => ({
                                    ...prevState, [`office_phone`]: fixNumber(e.target.value)
                                }))
                            } placeholder='- - - - - - - - - - -'/>
                        <p className='afa-error-text'>{error[`office_phone`]}</p>
                    </Inputs>
                    <Inputs className='mb-3'>
                        <span>کد پستی</span>
                        <input
                            className={error[`office_zip_code`] && 'afa-error-input'}
                            type="text"
                            max={9999999999}
                            maxLength={10}
                            name={`office_zip_code`}
                            value={inputs?.[`office_zip_code`]}
                            onChange={(e) =>
                                just_number(e.target.value) && setInputs(prevState => ({
                                    ...prevState, [`office_zip_code`]: fixNumber(e.target.value)
                                }))
                            } placeholder='- - - - - - - - - - -'/>
                        <p className='afa-error-text'>{error[`office_zip_code`]}</p>
                    </Inputs>
                    <Inputs className='mb-3'>
                        <span>فکس</span>
                        <input
                            className={error[`office_fax`] && 'afa-error-input'}
                            type="text"
                            name={`office_fax`}
                            value={inputs?.[`office_fax`]}
                            onChange={(e) =>
                                just_number(e.target.value) && setInputs(prevState => ({
                                    ...prevState, [`office_fax`]: fixNumber(e.target.value)
                                }))
                            } placeholder='- - - - - - - - - - -'/>
                        <p className='afa-error-text'>{error[`office_fax`]}</p>
                    </Inputs>
                    <span className="f-14">آدرس روی نقشه</span>
                    {
                        position[0] &&
                        <div style={{width: '100%', height: '400px'}}>
                            <MapContainer center={position} zoom={13} scrollWheelZoom={false}
                                          style={{height: '400px'}}>
                                <TileLayer
                                    attribution='Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <LocationMarker icon={GetIcon(50)} latLng={position}/>
                            </MapContainer>
                        </div>
                    }
                </div>

                {/*دفتر تهران*/}
                <div className="col-12">
                    <h6 className='text-red fw-bold mb-4'>اطلاعات تماس دفتر تهران</h6>
                </div>
                <div className="col-12 col-md-6 mb-4">
                    <Inputs className='mb-3'>
                        <span>ادرس کامل</span>
                        <input className={'afa-error-input'}
                               type="text"
                               name={'tehran_office_address'}
                               value={inputs.tehran_office_address}
                               onChange={(e) =>
                                   setInputs(prevState => ({
                                       ...prevState, 'tehran_office_address': e.target.value
                                   }))
                               }/>
                        <p className='afa-error-text'>{error[`tehran_office_address`]}</p>
                    </Inputs>
                    <Inputs className='mb-3'>
                        <span>شماره تماس</span>
                        <input
                            className={error[`tehran_office_phone`] && 'afa-error-input'}
                            type="text"
                            max={99999999999}
                            maxLength={11}
                            name={`tehran_office_phone`}
                            value={inputs?.[`tehran_office_phone`]}
                            onChange={(e) =>
                                just_number(e.target.value) && setInputs(prevState => ({
                                    ...prevState, [`tehran_office_phone`]: fixNumber(e.target.value)
                                }))
                            } placeholder='- - - - - - - - - - -'/>
                        <p className='afa-error-text'>{error[`tehran_office_phone`]}</p>
                    </Inputs>
                    <Inputs className='mb-3'>
                        <span>کد پستی</span>
                        <input
                            className={error[`tehran_office_zip_code`] && 'afa-error-input'}
                            type="text"
                            max={9999999999}
                            maxLength={10}
                            name={`tehran_office_zip_code`}
                            value={inputs?.[`tehran_office_zip_code`]}
                            onChange={(e) =>
                                just_number(e.target.value) && setInputs(prevState => ({
                                    ...prevState, [`tehran_office_zip_code`]: fixNumber(e.target.value)
                                }))
                            } placeholder='- - - - - - - - - - -'/>
                        <p className='afa-error-text'>{error[`tehran_office_zip_code`]}</p>
                    </Inputs>
                    <Inputs className='mb-3'>
                        <span>فکس</span>
                        <input
                            className={error[`tehran_office_fax`] && 'afa-error-input'}
                            type="text"
                            name={`tehran_office_fax`}
                            value={inputs?.[`tehran_office_fax`]}
                            onChange={(e) =>
                                just_number(e.target.value) && setInputs(prevState => ({
                                    ...prevState, [`tehran_office_fax`]: fixNumber(e.target.value)
                                }))
                            } placeholder='- - - - - - - - - - -'/>
                        <p className='afa-error-text'>{error[`tehran_office_fax`]}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-md-6 mb-4">
                    <span className="f-14">آدرس روی نقشه</span>
                    {
                        tehran_position[0] ?
                            <div style={{width: '100%', height: '400px'}}>
                                <MapContainer center={tehran_position} zoom={13} scrollWheelZoom={false}
                                              style={{height: '400px'}}>
                                    <TileLayer
                                        attribution='Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    <TehranLocationMarker icon={TehranGetIcon(50)} latLng={tehran_position}/>
                                </MapContainer>
                            </div> :
                            <div style={{width: '100%', height: '400px'}}>
                                <MapContainer center={[37.74990309920065, 46.93917047640875]} zoom={13}
                                              scrollWheelZoom={false}
                                              style={{height: '400px'}}>
                                    <TileLayer
                                        attribution='Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    <TehranLocationMarker icon={TehranGetIcon(50)}
                                                          latLng={[37.74990309920065, 46.93917047640875]}/>
                                </MapContainer>
                            </div>
                    }
                </div>
                {/*chart_description*/}
                <div className="col-12 mb-4">
                    <h6 className='text-red fw-bold mb-3'>ساختار سازمانی</h6>
                </div>
                <div className="col-12 col-md-3 mb-4">
                    <div className="afa-upload-file-parent w-100 ml-auto" style={{marginRight: 0}}>
                        <BannerFileMini
                            className={error[`chart_image`] && 'afa-error-input'}>
                            {
                                (inputs[`chart_image_url`] || inputs[`chart_image`]) ?
                                    <div className="afa-img">
                                        <img
                                            src={inputs[`chart_image_doc_url`] ? inputs[`chart_image_doc_url`] : `${MAIN_URL_IMAGE}${inputs[`chart_image_url`]}`}
                                            alt="آذر فولاد امین"/>
                                        <span
                                            className='afa-remove-position icon-delete'
                                            onClick={() => removeImage(`chart_image`)}/>
                                    </div> :
                                    <label
                                        className="afa_upload_file d-flex flex-column align-items-center justify-content-center">
                                        <img src={Images?.article_upload?.default}
                                             className='afa-article-image-to-upload' alt="آذر فولاد امین"/>
                                        <input type="file" accept="image/*"
                                               onChange={thisUploadDocs}
                                               name={`chart_image`}
                                               className='dv-upload-file'/>
                                        {
                                            !inputs[`chart_image`] &&
                                            <span className='upload-text'>بارگزاری ساختار سازمانی</span>
                                        }
                                    </label>
                            }
                        </BannerFileMini>
                        <p className='afa-error-text'>{error[`chart_image`]}</p>
                    </div>
                </div>
                <div className="col-12 col-md-9 mb-4">
                    <Inputs className='mb-3'>
                        <span>توضیحات</span>
                        <textarea
                            className={error[`chart_description`] && 'afa-error-input'}
                            rows={4}
                            name={`chart_description`}
                            value={inputs?.[`chart_description`]}
                            onChange={(e) =>
                                setInputs(prevState => ({
                                    ...prevState, [`chart_description`]: e.target.value
                                }))
                            }/>
                        <p className='afa-error-text'>{error[`chart_description`]}</p>
                    </Inputs>
                </div>

                {/*manage certificate*/}
                <div className="col-12 mb-3">
                    <div className="row px-0">
                        <div className="col-12 mb-4">
                            <h6 className='text-red fw-bold'>مدیریت گواهینامه ها</h6>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 mb-3">
                            <div className="afa-upload-file-parent">
                                <BannerFile>
                                    <label
                                        className="afa_upload_file d-flex flex-column align-items-center justify-content-center">
                                        <img src={Images?.article_upload?.default}
                                             className='afa-article-image-to-upload' alt="آذر فولاد امین"/>
                                        <span className='upload-text mt-4'
                                              onClick={() => addItemShowModal('certificates', 'certificate')}>افزودن گواهینامه</span>
                                    </label>
                                </BannerFile>
                            </div>
                        </div>
                        {
                            inputs?.certificates?.length > 0 &&
                            inputs?.certificates?.map((row, index) => (
                                <div className="col-12 col-md-6 col-lg-3 mb-3" key={index}>
                                    <div className="afa-upload-file-parent">
                                        <BannerFile>
                                            <div className="afa-img">
                                                <img
                                                    src={`${MAIN_URL_IMAGE}${row?.thumbnail}`}
                                                    alt="آذر فولاد امین"/>
                                                <span
                                                    className='afa-remove-position icon-edit'
                                                    onClick={() => editBanner(row?.id, row, 'certificates', 'certificate')}/>
                                            </div>
                                        </BannerFile>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                {/*certificate description*/}
                <div className="col-12">
                    <Inputs className='mb-3'>
                        <span>توضیحات گواهینامه ها</span>
                        <textarea
                            className={error[`certificate_description`] && 'afa-error-input'}
                            rows={4}
                            name={`certificate_description`}
                            value={inputs?.[`certificate_description`]}
                            onChange={(e) =>
                                setInputs(prevState => ({
                                    ...prevState, [`certificate_description`]: e.target.value
                                }))
                            }/>
                        <p className='afa-error-text'>{error[`certificate_description`]}</p>
                    </Inputs>
                </div>

                {/*guide files*/}
                <div className="col-12 mb-4">
                    <h6 className='text-red fw-bold'>مدیریت فایل راهنما</h6>
                </div>
                <div className="col-12 col-md-6 col-lg-3 mb-3">
                    <GuideUploadingFile onChangeGuideFile={onChangeGuideFile} value={inputs?.['hr_help']}
                                        name={'hr_help'} title={'منابع انسانی'}/>
                    <p className='afa-error-text'>{error[`hr_help`]}</p>
                </div>
                <div className="col-12 col-md-6 col-lg-3 mb-3">
                    <GuideUploadingFile onChangeGuideFile={onChangeGuideFile} value={inputs?.['supplier_help']}
                                        name={'supplier_help'} title={'تامین کننده'}/>
                    <p className='afa-error-text'>{error[`supplier_help`]}</p>
                </div>

                {/*management message*/}
                <div className="col-12 mb-2">
                    <h6 className='text-red fw-bold'>پیام مدیریت</h6>
                </div>
                <div className="col-12 col-md-3 mb-4">
                    <div className="afa-upload-file-parent w-100 ml-auto h-100" style={{marginRight: 0}}>
                        <BannerFileMini
                            className={error[`management_image`] && 'afa-error-input'} style={{height: '84%'}}>
                            {
                                (inputs[`management_image_url`] || inputs[`management_image`]) ?
                                    <div className="afa-img h-100">
                                        <img
                                            src={inputs[`management_image_doc_url`] ? inputs[`management_image_doc_url`] : `${MAIN_URL_IMAGE}${inputs[`management_image_url`]}`}
                                            style={{maxHeight: '100%'}}
                                            alt="آذر فولاد امین"/>
                                        <span
                                            className='afa-remove-position icon-delete'
                                            onClick={() => removeImage(`management_image`)}/>
                                    </div> :
                                    <label
                                        className="afa_upload_file d-flex flex-column align-items-center justify-content-center h-100">
                                        <img src={Images?.article_upload?.default}
                                             className='afa-article-image-to-upload' alt="آذر فولاد امین"/>
                                        <input type="file" accept="image/*"
                                               onChange={thisUploadDocs}
                                               name={`management_image`}
                                               className='dv-upload-file'/>
                                        {
                                            !inputs[`management_image`] &&
                                            <span className='upload-text'>بارگزاری عکس مدیر</span>
                                        }
                                    </label>
                            }
                        </BannerFileMini>
                        <p className='afa-error-text'>{error[`management_image`]}</p>
                    </div>
                </div>
                <div className="col-12 col-md-9 mb-4">
                    <Inputs className='mb-3'>
                        <span>نام مدیر</span>
                        <input className={'afa-error-input'}
                               type="text"
                               placeholder='نام مدیر'
                               name={'management_name'}
                               value={inputs.management_name}
                               onChange={(e) =>
                                   setInputs(prevState => ({
                                       ...prevState, 'management_name': e.target.value
                                   }))
                               }/>
                        <p className='afa-error-text'>{error[`management_name`]}</p>
                    </Inputs>
                    <Inputs className='mb-3'>
                        <span>توضیحات</span>
                        <textarea
                            className={error[`management_message`] && 'afa-error-input'}
                            rows={4}
                            name={`management_message`}
                            value={inputs?.[`management_message`]}
                            onChange={(e) =>
                                setInputs(prevState => ({
                                    ...prevState, [`management_message`]: e.target.value
                                }))
                            }/>
                        <p className='afa-error-text'>{error[`management_message`]}</p>
                    </Inputs>
                </div>

                {/*mission_and_vision*/}
                <div className="col-12 mb-3">
                    <div className="row px-0">
                        <div className="col-12 mb-2">
                            <h6 className='text-red fw-bold'>خط مشی سیستم مدیریت یکپارچه</h6>
                        </div>
                        <div className={
                            inputs?.locale === 'fa' ? "dv-rtl col-12 mb-3" : "dv-ltr col-12 mb-3"
                        }>
                            <CkEditorText setData={setPolicyData} data={inputs?.policy}
                                          fa={inputs?.locale === 'fa' ? true : false}/>
                            <p className='afa-error-text'>{error['policy']}</p>
                        </div>
                    </div>
                </div>

                {/*policy*/}
                <div className="col-12 mb-3">
                    <div className="row px-0">
                        <div className="col-12 mb-2">
                            <h6 className='text-red fw-bold'>بیانیه ماموریت و چشم انداز</h6>
                        </div>
                        {/*mission_and_vision description*/}
                        <div className="col-12">
                            <div className={
                                inputs?.locale === 'fa' ? "dv-rtl col-12 mb-3" : "dv-ltr col-12 mb-3"
                            }>
                                <CkEditorText setData={setMissionData} data={inputs?.mission_and_vision}
                                              fa={inputs?.locale === 'fa' ? true : false}/>
                                <p className='afa-error-text'>{error['mission_and_vision']}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 mb-3">
                    <h6 className='text-red fw-bold mb-3'>لینک های مهم</h6>
                    {
                        inputs?.social_media?.length > 0 &&
                        inputs?.social_media?.map((row, index) => (
                            <div className="row px-0" key={index}>
                                <div className="col-12 col-md-6 mb-3">
                                    <div className="d-flex align-items-center">
                                        <div className="afa-remove-link"
                                             onClick={() => handleRemoveSocial(index, 'main')}>
                                            <span className="icon-Delete"/>
                                        </div>
                                        <Inputs>
                                            <span>شبکه اجتماعی</span>
                                            <div className='afa-social-link-array-name'
                                                 style={{display: 'flex', alignItems: 'center'}}>
                                                {/*<span className="afa-social-icon">{row?.icon}</span>*/}
                                                <span style={{marginLeft: 5}}>{row?.type}</span>
                                            </div>
                                        </Inputs>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 mb-3">
                                    <div className="d-flex align-items-center">
                                        <Inputs>
                                            <span>لینک</span>
                                            <div className='afa-social-link-array-name'
                                                 style={{display: 'flex', alignItems: 'center'}}>
                                                <span style={{marginLeft: 5}}>{row?.link}</span>
                                            </div>
                                        </Inputs>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    {
                        inputs?.help_social_media?.length > 0 &&
                        inputs?.help_social_media?.map((row, index) => (
                            <div className="row px-0" key={index}>
                                <div className="col-12 col-md-6 mb-3">
                                    <div className="d-flex align-items-center">
                                        <div className="afa-remove-link"
                                             onClick={() => handleRemoveSocial(index, 'help')}>
                                            <span className="icon-Delete"/>
                                        </div>
                                        <Inputs>
                                            <span>شبکه اجتماعی</span>
                                            <div className='afa-social-link-array-name'
                                                 style={{display: 'flex', alignItems: 'center'}}>
                                                {/*<span className="afa-social-icon">{row?.icon}</span>*/}
                                                <span style={{marginLeft: 5}}>{row?.value}</span>
                                            </div>
                                        </Inputs>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 mb-3">
                                    <div className="d-flex align-items-center">
                                        <Inputs>
                                            <span>لینک</span>
                                            <div className='afa-social-link-array-name'
                                                 style={{display: 'flex', alignItems: 'center'}}>
                                                <span style={{marginLeft: 5}}>{row?.link}</span>
                                            </div>
                                        </Inputs>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    <div className="row px-0">
                        <div className="col-12 col-md-6 mb-3">
                            <div className="d-flex align-items-center">
                                <Inputs>
                                    <span>شبکه اجتماعی</span>
                                    <Select
                                        className={'afa-icon-select'}
                                        placeholder="انتخاب کنید"
                                        value={selectedOption}
                                        options={data}
                                        onChange={handleChange}
                                        getOptionLabel={e => (
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                {/*{e.icon}*/}
                                                <span style={{marginLeft: 5}}>{e.value}</span>
                                            </div>
                                        )}
                                    />
                                    <p className='afa-error-text'>{error[`social_media`]}</p>
                                </Inputs>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 mb-3">
                            <div className="d-flex align-items-center">
                                <Inputs className='mb-3'>
                                    <span>لینک</span>
                                    <input
                                        className={error[`link`] && 'afa-error-input'}
                                        type="text"
                                        name={`link`}
                                        value={inputs?.[`link`]}
                                        onChange={(e) =>
                                            setInputs(prevState => ({
                                                ...prevState, [`link`]: e.target.value
                                            }))
                                        }/>
                                </Inputs>
                                <div className="afa-add-link" onClick={addNewLink}>+</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 mb-3">
                    <Inputs className='mb-3'>
                        <span>ایمیل</span>
                        <input
                            className={error[`contact_email`] && 'afa-error-input'}
                            type="email"
                            name={`contact_email`}
                            value={inputs?.[`contact_email`]}
                            onChange={(e) =>
                                setInputs(prevState => ({
                                    ...prevState, [`contact_email`]: e.target.value
                                }))
                            } placeholder='- - - - - - - - - - -'/>
                        <p className='afa-error-text'>{error[`contact_email`]}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-md-6 mb-3">
                    <Inputs className='mb-3'>
                        <span>لینک اتوماسیون اداری</span>
                        <input
                            className={error[`automation_url`] && 'afa-error-input'}
                            type="text"
                            name={`automation_url`}
                            value={inputs?.[`automation_url`]}
                            onChange={(e) =>
                                setInputs(prevState => ({
                                    ...prevState, [`automation_url`]: e.target.value
                                }))
                            }/>
                        <p className='afa-error-text'>{error[`automation_url`]}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-md-6 mb-3">
                    <Inputs className='mb-3'>
                        <span>لینک فیش حقوقی آذر فولاد امین</span>
                        <input
                            className={error[`wage_receipt`] && 'afa-error-input'}
                            type="text"
                            name={`wage_receipt`}
                            value={inputs?.[`wage_receipt`]}
                            onChange={(e) =>
                                setInputs(prevState => ({
                                    ...prevState, [`wage_receipt`]: e.target.value
                                }))
                            }/>
                        <p className='afa-error-text'>{error[`wage_receipt`]}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-md-6 mb-3">
                    <Inputs className='mb-3'>

                        <span>لینک فیش حقوقی فولاد امیر آذربایجان</span>
                        <input
                            className={error[`amir_steel_wage_receipt`] && 'afa-error-input'}
                            type="text"
                            name={`amir_steel_wage_receipt`}
                            value={inputs?.[`amir_steel_wage_receipt`]}
                            onChange={(e) =>
                                setInputs(prevState => ({
                                    ...prevState, [`amir_steel_wage_receipt`]: e.target.value
                                }))
                            }/>
                        <p className='afa-error-text'>{error[`amir_steel_wage_receipt`]}</p>
                    </Inputs>
                </div>


                <div className="col-12 mb-4">
                    <h6 className='text-red fw-bold mb-4'>توضیحات کوتاه سئو</h6>
                </div>
                <div className="col-12 col-md-6 mb-2">
                    <InputField
                        title={'home_page_meta_description'}
                        value={inputs.home_page_meta_description}
                        error={error}
                        label={'صفحه اصلی'}
                        setInputs={setInputs}
                    />
                </div>
                <div className="col-12 col-md-6 mb-2">
                    <InputField
                        title={'blog_meta_description'}
                        value={inputs.blog_meta_description}
                        error={error}
                        label={'وبلاگ'}
                        setInputs={setInputs}
                    />
                </div>
                <div className="col-12 col-md-6 mb-2">
                    <InputField
                        title={'gallery_page_meta_description'}
                        value={inputs.gallery_page_meta_description}
                        error={error}
                        label={'گالری تصاویر'}
                        setInputs={setInputs}
                    />
                </div>
                <div className="col-12 col-md-6 mb-2">
                    <InputField
                        title={'afa_meta_description'}
                        value={inputs.afa_meta_description}
                        error={error}
                        label={'آذر فولاد امین'}
                        setInputs={setInputs}
                    />
                </div>
                <div className="col-12 col-md-6 mb-2">
                    <InputField
                        title={'foulad_amir_azarbaijan_meta_description'}
                        value={inputs.foulad_amir_azarbaijan_meta_description}
                        error={error}
                        label={'فولاد امیر آذربایجان'}
                        setInputs={setInputs}
                    />
                </div>

                <div className="col-12 col-md-6 mb-2">
                    <InputField
                        title={'azar_artin_meta_description'}
                        value={inputs.azar_artin_meta_description}
                        error={error}
                        label={'آذر ساخت آرتین'}
                        setInputs={setInputs}
                    />
                </div>

                <div className="col-12 col-md-6 mb-2">
                    <InputField
                        title={'directorates_meta_description'}
                        value={inputs.directorates_meta_description}
                        error={error}
                        label={'هیئت مدیره'}
                        setInputs={setInputs}
                    />
                </div>
                <div className="col-12 col-md-6 mb-2">
                    <InputField
                        title={'mission_and_vision_meta_description'}
                        value={inputs.mission_and_vision_meta_description}
                        error={error}
                        label={'ماموریت و چشم انداز'}
                        setInputs={setInputs}
                    />
                </div>

                <div className="col-12 col-md-6 mb-2">
                    <InputField
                        title={'policy_meta_description'}
                        value={inputs.policy_meta_description}
                        error={error}
                        label={'خط مشی نظامهای مدیریتی'}
                        setInputs={setInputs}
                    />
                </div>

                <div className="col-12 col-md-6 mb-2">
                    <InputField
                        title={'management_message_meta_description'}
                        value={inputs.management_message_meta_description}
                        error={error}
                        label={'پیام مدیریت'}
                        setInputs={setInputs}
                    />
                </div>
                <div className="col-12 col-md-6 mb-2">
                    <InputField
                        title={'contact_us_suggestions_message_meta_description'}
                        value={inputs.contact_us_suggestions_message_meta_description}
                        error={error}
                        label={'تماس با ما'}
                        setInputs={setInputs}
                    />
                </div>

                <div className="col-12 col-md-6 mb-2">
                    <InputField
                        title={'contact_us_contact_uni_meta_description'}
                        value={inputs.contact_us_contact_uni_meta_description}
                        error={error}
                        label={'ارتباط با دانشگاه'}
                        setInputs={setInputs}
                    />
                </div>

                <div className="col-12 col-md-6 mb-2">
                    <InputField
                        title={'contact_us_suggestion_system_meta_description'}
                        value={inputs.contact_us_suggestion_system_meta_description}
                        error={error}
                        label={'نظام پیشنهادات'}
                        setInputs={setInputs}
                    />
                </div>

                <div className="col-12 col-md-6 mb-2">
                    <InputField
                        title={'contact_us_management_meta_description'}
                        value={inputs.contact_us_management_meta_description}
                        error={error}
                        label={'ارتباط با مدیریت'}
                        setInputs={setInputs}
                    />
                </div>

                <div className="col-12 col-md-6 mb-2">
                    <InputField
                        title={'organization_meta_description'}
                        value={inputs.organization_meta_description}
                        error={error}
                        label={'چارت سازمانی'}
                        setInputs={setInputs}
                    />
                </div>

                <div className="col-12 col-md-6 mb-2">
                    <InputField
                        title={'laboratory_meta_description'}
                        value={inputs.laboratory_meta_description}
                        error={error}
                        label={'آزمایشگاه'}
                        setInputs={setInputs}
                    />
                </div>

                <div className="col-12 col-md-6 mb-2">
                    <InputField
                        title={'certificates_meta_description'}
                        value={inputs.certificates_meta_description}
                        error={error}
                        label={'گواهینامه'}
                        setInputs={setInputs}
                    />
                </div>

                <div className="col-12 d-flex align-items-center justify-content-center mb-4">
                    <button type='submit'
                            className='m-auto afa-save-super-admin-btn'>
                        <span className='pr-3'>ذخیره</span>
                    </button>
                </div>
            </form>

            <Modal centered={true} className='afa-user-status-modal'
                   show={modal[`show_add_${inputs?.show_open_modal_value}_item_modal`]} onHide={closeModal}>
                <Modal.Body className='p-md-4' dir='rtl'>
                    <form className="row" onSubmit={addModalSubmit}>
                        <div className="col-12 col-md-7 mb-3">
                            <p className='text-red fw-bold'>افزودن
                                {
                                    inputs?.show_open_modal_value === 'banners' ? ' بنر' :
                                        inputs?.show_open_modal_value === 'charts' ? ' ساختار سازمانی' : ' گواهینامه'
                                }
                            </p>
                            <Inputs>
                                <span>عنوان
                                    {
                                        inputs?.show_open_modal_value === 'banners' ? ' بنر' :
                                            inputs?.show_open_modal_value === 'charts' ? ' ساختار سازمانی' : ' گواهینامه'
                                    }
                                </span>
                                <input className={'afa-error-input'}
                                       type="text"
                                       name={[`${inputs?.show_open_modal_value}_title`]}
                                       value={inputs[`${inputs?.show_open_modal_value}_title`]}
                                       onChange={(e) =>
                                           setInputs(prevState => ({
                                               ...prevState, [`${inputs?.show_open_modal_value}_title`]: e.target.value
                                           }))
                                       }/>
                            </Inputs>
                            <p className='afa-error-text'>{error[`title`]}</p>
                        </div>
                        <div className="col-12 col-md-5 mb-3">
                            <div className="afa-upload-file-parent">
                                <BannerFileMini
                                    className={error[`image`] && 'afa-error-input'}>
                                    {
                                        (inputs[`${inputs?.show_open_modal_value}_image_url`] || inputs[`${inputs?.show_open_modal_value}_image`]) ?
                                            <div className="afa-img">
                                                <img
                                                    src={inputs[`${inputs?.show_open_modal_value}_image_url`] ? inputs[`${inputs?.show_open_modal_value}_image_url`] : `${MAIN_URL_IMAGE}${inputs[`${inputs?.show_open_modal_value}_image`]}`}
                                                    alt="آذر فولاد امین"/>
                                                <span
                                                    className='afa-remove-position icon-delete'
                                                    onClick={() => removeImage(`${inputs?.show_open_modal_value}_image`)}/>
                                            </div> :
                                            <label
                                                className="afa_upload_file d-flex flex-column align-items-center justify-content-center">
                                                <img src={Images?.article_upload?.default}
                                                     className='afa-article-image-to-upload' alt="آذر فولاد امین"/>
                                                <input type="file" accept="image/*"
                                                       onChange={thisUploadDocs}
                                                       name={`${inputs?.show_open_modal_value}_image`}
                                                       className='dv-upload-file'/>
                                                {
                                                    !inputs[`${inputs?.show_open_modal_value}_image`] &&
                                                    <span className='upload-text'>بارگزاری
                                                        {
                                                            inputs?.show_open_modal_value === 'banners' ? ' بنر' :
                                                                inputs?.show_open_modal_value === 'charts' ? ' ساختار سازمانی' : ' گواهینامه'
                                                        }
                                                    </span>
                                                }
                                            </label>
                                    }
                                </BannerFileMini>
                                <p className='afa-error-text'>{error[`image`]}</p>
                            </div>
                        </div>
                        <div className="col-12 mb-4">
                            <Inputs>
                                <span>توضیحات
                                    {
                                        inputs?.show_open_modal_value === 'banners' ? ' بنر' :
                                            inputs?.show_open_modal_value === 'charts' ? ' ساختار سازمانی' : ' گواهینامه'
                                    }
                                </span>
                                <textarea className={'afa-error-input'}
                                          rows={2}
                                          name={`${inputs?.show_open_modal_value}_description`}
                                          value={inputs[`${inputs?.show_open_modal_value}_description`]}
                                          onChange={(e) =>
                                              setInputs(prevState => ({
                                                  ...prevState,
                                                  [`${inputs?.show_open_modal_value}_description`]: fixNumber(e.target.value)
                                              }))
                                          }/>
                            </Inputs>
                            <p className='afa-error-text'>{error[`description`]}</p>
                        </div>
                        <div className='col-12 w-100 d-flex align-items-center'>
                            <button type='submit' className='afa-btn-save-modal'>ذخیره</button>
                            <button type='button' onClick={closeModal}
                                    className='afa-btn-cancel-modal'>انصراف
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal centered={true} className='afa-user-status-modal'
                   show={modal[`show_edit_${inputs?.show_open_modal_value}`]} onHide={closeModal}>
                <Modal.Body className='p-md-4' dir='rtl'>
                    <form className="row" onSubmit={editBannerHandler}>
                        <div className="col-12 col-md-7 mb-3">
                            <div className=" d-flex align-items-center justify-content-between">
                                <p className='text-red fw-bold'>ویرایش
                                    {
                                        inputs?.show_open_modal_value === 'banners' ? ' بنر' :
                                            inputs?.show_open_modal_value === 'charts' ? ' ساختار سازمانی' : ' گواهینامه'
                                    }
                                </p>
                                <div className="afa-remove-link"
                                     onClick={() => handleRemoveBanner(inputs[`${inputs?.show_open_modal_value}_id`])}>
                                    <span className="icon-Delete"/>
                                </div>
                            </div>
                            <Inputs>
                                <span>عنوان
                                    {
                                        inputs?.show_open_modal_value === 'banners' ? ' بنر' :
                                            inputs?.show_open_modal_value === 'charts' ? ' ساختار سازمانی' : ' گواهینامه'
                                    }
                                </span>
                                <input className={'afa-error-input'}
                                       type="text"
                                       name={`${inputs?.show_open_modal_value}_title`}
                                       value={inputs[`${inputs?.show_open_modal_value}_title`]}
                                       onChange={(e) =>
                                           setInputs(prevState => ({
                                               ...prevState, [`${inputs?.show_open_modal_value}_title`]: e.target.value
                                           }))
                                       }/>
                            </Inputs>
                            <p className='afa-error-text'>{error[`title`]}</p>
                        </div>
                        <div className="col-12 col-md-5 mb-3">
                            <div className="afa-upload-file-parent">
                                <BannerFileMini
                                    className={error[`image`] && 'afa-error-input'}>
                                    {
                                        (inputs[`${inputs?.show_open_modal_value}_image_url`] || inputs[`${inputs?.show_open_modal_value}_image`]) ?
                                            <div className="afa-img">
                                                <img
                                                    src={inputs[`${inputs?.show_open_modal_value}_image_url`] ? inputs[`${inputs?.show_open_modal_value}_image_url`] : `${MAIN_URL_IMAGE}${inputs[`${inputs?.show_open_modal_value}_image`]}`}
                                                    alt="آذر فولاد امین"/>
                                                <span
                                                    className='afa-remove-position icon-delete'
                                                    onClick={() => removeImage(`${inputs?.show_open_modal_value}_image`)}/>
                                            </div> :
                                            <label
                                                className="afa_upload_file d-flex flex-column align-items-center justify-content-center">
                                                <img src={Images?.article_upload?.default}
                                                     className='afa-article-image-to-upload' alt="آذر فولاد امین"/>
                                                <input type="file" accept="image/*"
                                                       onChange={thisUploadDocs}
                                                       name={`${inputs?.show_open_modal_value}_image`}
                                                       className='dv-upload-file'/>
                                                {
                                                    !inputs[`${inputs?.show_open_modal_value}_image`] &&
                                                    <span className='upload-text'>بارگزاری
                                                        {
                                                            inputs?.show_open_modal_value === 'banners' ? ' بنر' :
                                                                inputs?.show_open_modal_value === 'charts' ? ' ساختار سازمانی' : ' گواهینامه'
                                                        }
                                                    </span>
                                                }
                                            </label>
                                    }
                                </BannerFileMini>
                                <p className='afa-error-text'>{error[`image`]}</p>
                            </div>
                        </div>
                        <div className="col-12 mb-4">
                            <Inputs>
                                <span>توضیحات
                                    {
                                        inputs?.show_open_modal_value === 'banners' ? ' بنر' :
                                            inputs?.show_open_modal_value === 'charts' ? ' ساختار سازمانی' : ' گواهینامه'
                                    }
                                </span>
                                <textarea className={'afa-error-input'}
                                          rows={2}
                                          name={`${inputs?.show_open_modal_value}_description`}
                                          value={inputs[`${inputs?.show_open_modal_value}_description`]}
                                          onChange={(e) =>
                                              setInputs(prevState => ({
                                                  ...prevState,
                                                  [`${inputs?.show_open_modal_value}_description`]: fixNumber(e.target.value)
                                              }))
                                          }/>
                            </Inputs>
                            <p className='afa-error-text'>{error[`description`]}</p>
                        </div>
                        <div className='col-12 w-100 d-flex align-items-center'>
                            <button type='submit' className='afa-btn-save-modal'>ذخیره</button>
                            <button type='button' onClick={closeModal}
                                    className='afa-btn-cancel-modal'>انصراف
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

        </MainContent>
    );
}

export default Setting;