import React, {useEffect, useState} from 'react';
import {getData, setTitle} from "../../../../assets/scripts/GeneralFunctions";
import {MainContent} from "../style";
import {Inputs} from "../../Child/Supplier/Child/style";
import {Link} from "react-router-dom";
import Skeleton from 'react-loading-skeleton'
import {MAIN_URL} from "../../../../assets/scripts/GeneralVariables";

function ProposedSystemInfo(props) {
    const [inputs, setInputs] = useState({
        title: "",
        full_name: "",
        department_name: "",
        position: "",
        job: "",
        educational: "",
        mobile: "",
        email: "",
        situation_description: "",
        procedure_description: "",
        advantages: "",
        requirements: "",
        departments_need_to_change: "",
        more_description: "",
    });
    const [loader, setLoader] = useState(true)

    useEffect(async () => {
        setTitle('آذر فولاد امین | نظام پیشنهادات ')
        let id = props.match.params.id;
        let api_result = await getData(MAIN_URL, `contacts/suggestion-system/info/${id}`, 'get', {}, true, false);
        if (api_result?.status === 200) {
            setLoader(false)
            setInputs(prevState => ({
                ...prevState,
                title: api_result?.data?.suggestion_system?.title,
                full_name: api_result?.data?.suggestion_system?.full_name,
                department_name: api_result?.data?.suggestion_system?.department_name,
                position: api_result?.data?.suggestion_system?.position,
                job: api_result?.data?.suggestion_system?.job,
                educational: api_result?.data?.suggestion_system?.educational,
                mobile: api_result?.data?.suggestion_system?.mobile,
                email: api_result?.data?.suggestion_system?.email,
                situation_description: api_result?.data?.suggestion_system?.situation_description,
                procedure_description: api_result?.data?.suggestion_system?.procedure_description,
                advantages: api_result?.data?.suggestion_system?.advantages,
                requirements: api_result?.data?.suggestion_system?.requirements,
                departments_need_to_change: api_result?.data?.suggestion_system?.departments_need_to_change,
                more_description: api_result?.data?.suggestion_system?.more_description,
            }))
        }
    }, []);

    return (
        <MainContent>
            <div className='row gx-3 gy-4'>
                <div
                    className="col-12 w-100 d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center mb-3">
                    <h6 className='text-red fw-bold'>نظام پیشنهادات</h6>
                </div>

                <div className="col-12 col-md-6 col-lg-4">
                    <Inputs>
                        <span>عنوان پیشنهاد</span>
                        {
                            loader ?
                                <Skeleton borderRadius={8} height={55}/> :
                                <input
                                    readOnly={true}
                                    type="text" name={`title`}
                                    value={inputs?.[`title`]}
                                />
                        }
                    </Inputs>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                    <Inputs>
                        <span>نام و نام خانوادگی</span>
                        {
                            loader ?
                                <Skeleton borderRadius={8} height={55}/> :
                                <input
                                    readOnly={true}
                                    type="text" name={`full_name`}
                                    value={inputs?.[`full_name`]}
                                />
                        }
                    </Inputs>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                    <Inputs>
                        <span>محل خدمت</span>
                        {
                            loader ?
                                <Skeleton borderRadius={8} height={55}/> :
                                <input
                                    readOnly={true}
                                    type="text" name={`department_name`}
                                    value={inputs?.[`department_name`]}
                                />
                        }
                    </Inputs>
                </div>

                <div className="col-12 col-md-6 col-lg-4">
                    <Inputs>
                        <span>پست سازمانی</span>
                        {
                            loader ?
                                <Skeleton borderRadius={8} height={55}/> :
                                <input
                                    readOnly={true}
                                    type="text" name={`position`}
                                    value={inputs?.[`position`]}
                                />
                        }
                    </Inputs>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                    <Inputs>
                        <span>شغل فعلی</span>
                        {
                            loader ?
                                <Skeleton borderRadius={8} height={55}/> :
                                <input
                                    readOnly={true}
                                    type="text" name={`job`}
                                    value={inputs?.[`job`]}
                                />
                        }
                    </Inputs>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                    <Inputs>
                        <span>مدرک و رشته تحصیلی</span>
                        {
                            loader ?
                                <Skeleton borderRadius={8} height={55}/> :
                                <input
                                    readOnly={true}
                                    type="text" name={`educational`}
                                    value={inputs?.[`educational`]}
                                />
                        }
                    </Inputs>
                </div>

                <div className="col-12 col-md-6">
                    <Inputs>
                        <span>شماره همراه</span>
                        {
                            loader ?
                                <Skeleton borderRadius={8} height={55}/> :
                                <input
                                    readOnly={true}
                                    className={'text-left'}
                                    type="number" name={`mobile`}
                                    value={inputs?.[`mobile`]}
                                />
                        }
                    </Inputs>
                </div>
                <div className="col-12 col-md-6">
                    <Inputs>
                        <span>پست الکترونیک</span>
                        {
                            loader ?
                                <Skeleton borderRadius={8} height={55}/> :
                                <input
                                    readOnly={true}
                                    className={'text-left'}
                                    type="email" name={`email`}
                                    value={inputs?.[`email`]}
                                />
                        }
                    </Inputs>
                </div>

                <div className="col-12">
                    <Inputs>
                        <span>شرح وضعیت موجود با ذکر معایب و مزایا</span>
                        {
                            loader ?
                                <Skeleton borderRadius={8} height={100}/> :
                                <textarea
                                    readOnly={true}
                                    rows="3"
                                    name={`situation_description`}
                                    value={inputs?.[`situation_description`]}
                                />
                        }
                    </Inputs>
                </div>

                <div className="col-12">
                    <Inputs>
                        <span>شرح دقیق پیشنهاد و روش اجرا</span>
                        {
                            loader ?
                                <Skeleton borderRadius={8} height={100}/> :
                                <textarea
                                    readOnly={true}
                                    name={`procedure_description`}
                                    value={inputs?.[`procedure_description`]}
                                />
                        }
                    </Inputs>
                </div>

                <div className="col-12 col-md-6">
                    <Inputs>
                        <span>مزاياي طرح</span>
                        {
                            loader ?
                                <Skeleton borderRadius={8} height={55}/> :
                                <input
                                    readOnly={true}
                                    type="text" name={`advantages`}
                                    value={inputs?.[`advantages`]}
                                />
                        }
                    </Inputs>
                </div>
                <div className="col-12 col-md-6">
                    <Inputs>
                        <span>پیش نیازها و امکانات مورد نیاز جهت اجرا</span>
                        {
                            loader ?
                                <Skeleton borderRadius={8} height={55}/> :
                                <input
                                    readOnly={true}
                                    type="text" name={`requirements`}
                                    value={inputs?.[`requirements`]}
                                />
                        }
                    </Inputs>
                </div>

                <div className="col-12">
                    <Inputs>
                        <span>در اجرای این پیشنهاد چه افراد یا واحدهایی نیاز است همکاری یا حمایت نمایند و یا تغییراتی در روش کار خود ایجاد کنند؟</span>
                        {
                            loader ?
                                <Skeleton borderRadius={8} height={100}/> :
                                <textarea
                                    readOnly={true}
                                    rows="3"
                                    name={`departments_need_to_change`}
                                    value={inputs?.[`departments_need_to_change`]}
                                />
                        }
                    </Inputs>
                </div>

                <div className="col-12">
                    <Inputs>
                        <span>در صورت نیاز به شرح  بيشتر( بیان اهداف و ... ) اضافه شوند</span>
                        {
                            loader ?
                                <Skeleton borderRadius={8} height={100}/> :
                                <textarea
                                    readOnly={true}
                                    rows="3"
                                    name={`more_description`}
                                    value={inputs?.[`more_description`]}
                                />
                        }
                    </Inputs>
                </div>


                <div className="col-12 d-flex align-items-center justify-content-center mb-4">
                    <Link to={'/proposed-system'} className={'dv-back-to-parent-btn rounded-pill'}>
                        <span className='pr-3'>بازگشت به لیست فرم ها</span>
                    </Link>
                </div>
            </div>
        </MainContent>
    );
}

export default ProposedSystemInfo;