import React, {useState, useEffect} from 'react';
import {MainContent} from "../style";
import {Images} from "../../../../assets/scripts/Images";
import {ArticleFile, Inputs} from "../../Child/Supplier/Child/style";
import {MAIN_URL_IMAGE} from "../../../../assets/scripts/GeneralVariables";
import {fileUpload, getFormDataPost, setTitle} from "../../../../assets/scripts/GeneralFunctions";
import CkEditorText from "./CkEditorText";
import {toast} from "react-toastify";
import Skeleton from 'react-loading-skeleton'

function MessageFromManagement() {
    const [inputs, setInputs] = useState({title: '', description: '', content: '', tags: []})
    const [error, setError] = useState({})
    const [loader, setLoader] = useState(true)

    useEffect(() => {
        setTitle('آذر فولاد امین | پیام مدیریت')
        // setLoader(false)
    }, []);

    const thisUploadDocs = async (e) => {
        let files = e.target.files;
        let file = URL.createObjectURL(await fileUpload(files[0]))
        let upload_file = await fileUpload(files[0]);
        setInputs(prevState => ({
            ...prevState,
            [e.target.name + '_url']: file,
            [e.target.name]: upload_file,
            [e.target.name + '_file']: e.target.files[0]
        }))
    }

    const removeImage = (val) => {

        setInputs(prevState => ({
            ...prevState, [val]: null, [val + '_url']: null, [val + '_file']: null, [`remove_${val}`]: 1
        }))
    }

    const setData = (value) => {
        setInputs(prevState => ({...prevState, content: value}))
    }


    const formHandler = async (e) => {
        e.preventDefault()

        let formData = new FormData()
        formData.append(`title`, inputs?.title)
        formData.append(`image`, inputs?.article_file_file)
        formData.append(`description`, inputs?.description)
        formData.append(`content`, inputs?.content)

        let api_result = await getFormDataPost(`blog-management/posts/create/fa`, formData)

        if (api_result?.status === 200) {
            setError('')
            toast.success('با موفقیت ثبت شد')
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
        }
    }
    return (
        <MainContent>
            <form onSubmit={formHandler} className='row'>
                <div className="col-12 col-sm-6 col-lg-6 mb-3"><h6 className='text-red fw-bold'>پیام مدیریت</h6></div>
                <div className="col-12 col-md-9 mb-3">
                    <div className="row px-0">
                        <div className="col-12 mb-4">
                            <Inputs>
                                <span>نام مدیر</span>
                                {
                                    loader ?
                                        <Skeleton borderRadius={8} height={50}/> :
                                        <input
                                            className={'afa-error-input'}
                                            maxLength={75}
                                            type="text" name={`title`}
                                            value={inputs?.[`title`]}
                                            placeholder={'نام مدیر'}
                                            onChange={(e) =>
                                                setInputs(prevState => ({
                                                    ...prevState,
                                                    [`title`]: e.target.value
                                                }))
                                            }/>
                                }
                            </Inputs>
                            <p className='afa-error-text'>{error['title']}</p>
                        </div>
                        <div className="col-12 mb-3 dv-about-us-textarea">
                            <span className="f-14">متن پیام مدیریت</span>
                            {
                                loader ?
                                    <Skeleton borderRadius={8} height={250}/> :
                                    <CkEditorText setData={setData} fa={true}/>
                            }
                            <p className='afa-error-text'>{error['content']}</p>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-3 mb-3">
                    <div className="afa-upload-file-parent">
                        <span className='f-14'>تصویر مدیر</span>
                        {
                            loader ?
                                <Skeleton borderRadius={8} height={280}/> :
                                <ArticleFile
                                    style={{height: '270px'}}
                                    className={error[`image`] && 'afa-error-input'}>
                                    {
                                        (inputs[`article_file_url`] || inputs[`article_file`]) ?
                                            <div className="afa-img">
                                                <img
                                                    src={inputs[`article_file_url`] ? inputs[`article_file_url`] : `${MAIN_URL_IMAGE}${inputs[`article_file`]}`}
                                                    alt="آذر فولاد امین" className={'img-fluid'}
                                                    style={{maxWidth: '100%', maxHeight: '100%'}}/>
                                                <span
                                                    className='afa-remove-position icon-delete'
                                                    onClick={() => removeImage(`article_file`)}/>
                                            </div> :
                                            <label
                                                className="afa_upload_file d-flex flex-column align-items-center justify-content-center">
                                                <img src={Images?.article_upload?.default}
                                                     className='afa-article-image-to-upload'
                                                     style={{width: '110px', marginTop: '2rem', height: 'auto'}}
                                                     alt="آذر فولاد امین"/>
                                                <input type="file" accept="image/*"
                                                       onChange={thisUploadDocs}
                                                       name={`article_file`}
                                                       className='dv-upload-file'/>
                                                {
                                                    !inputs[`article_file`] &&
                                                    <span className='upload-text'>اپلود عکس مربوط به مدیر</span>
                                                }
                                            </label>
                                    }
                                </ArticleFile>
                        }
                        <p className='afa-error-text'>{error[`image`]}</p>
                    </div>
                </div>

                <div className="col-12 d-flex align-items-center justify-content-center mb-4">
                    <Inputs>
                        <button type='submit'
                                className='m-auto afa-save-super-admin-btn'>
                            <span className='pr-3'>ذخیره</span>
                        </button>
                    </Inputs>
                </div>
            </form>
        </MainContent>
    );
}

export default MessageFromManagement;