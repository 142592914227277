import React, {useEffect, useState} from 'react';
import {fileUpload, getData, getFormDataPost, setTitle} from "../../../../assets/scripts/GeneralFunctions";
import {MainContent} from "../style";
import {Images} from "../../../../assets/scripts/Images";
import {userFile, Inputs, ArticleFile} from "../../Child/Supplier/Child/style";
import {MAIN_URL, MAIN_URL_IMAGE} from "../../../../assets/scripts/GeneralVariables";
import CkEditorText from "../Article/CkEditorText";
import {toast} from "react-toastify";

function TestimonialArchive(props) {
    const [inputs, setInputs] = useState({announcer: '', description: '', image_url : '',image : '' , testimonial : '' , image_api : '' , locale : 'fa' , id : null})
    const [error, setError] = useState({})

    useEffect(async ()=>{
        setTitle('آذر فولاد امین | جزئیات توصیه نامه')
        let testimonial_id = props.match.params.testimonial_id;
        let api_result = await getData(MAIN_URL, `testimonial-management/testimonials/info/${testimonial_id}`, 'get', {}, true, true);
        if (api_result?.status === 200) {
            let data = api_result?.data?.testimonial
            setInputs(prevState => ({
                ...prevState,
                locale: data?.locale,
                announcer: data?.announcer,
                image_api: data?.image,
                testimonial: data?.testimonial,
                id : data?.id
            }))
        }
    } , [])

    const thisUploadDocs = async (e) => {
        let files = e.target.files;
        let file = URL.createObjectURL(await fileUpload(files[0]))
        let upload_file = await fileUpload(files[0]);
        setInputs(prevState => ({
            ...prevState,
            [e.target.name + '_url']: file,
            [e.target.name]: upload_file,
            [e.target.name + '_file']: e.target.files[0]
        }))
    }

    const removeImage = (val) => {

        setInputs(prevState => ({
            ...prevState, [val]: null, [val + '_url']: null, [val + '_file']: null,[val + '_api'] : null
        }))
    }

    const formHandler = async (e) => {

        e.preventDefault()
        let formData = new FormData()
        formData.append(`announcer`, inputs?.announcer)
        if(inputs?.image){
            formData.append(`image`, inputs?.image)
        }
        formData.append(`testimonial`, inputs?.testimonial)

        let api_result = await getFormDataPost(`testimonial-management/testimonials/edit/${inputs?.id}`, formData)

        if (api_result?.status === 200) {
            setError('')
            toast.success('با موفقیت ثبت شد')
        }else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }
    return (
        <MainContent>
            <form onSubmit={formHandler} className='row'>
                <div className="col-12 w-100 d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center mb-3">
                    <h6 className='text-red fw-bold'>ویرایش توصیه نامه</h6>
                </div>
                <div className="col-12 col-md-8 mb-3">
                    <div className="row px-0">
                        <div className="col-12 col-md-6 mb-3">
                            <Inputs>
                                <span>نام شخص</span>
                                <input
                                    className={'afa-error-input'}
                                    maxLength={100}
                                    type="text" name={`announcer`}
                                    value={inputs?.[`announcer`]}
                                    onChange={(e) =>
                                        setInputs(prevState => ({
                                            ...prevState,
                                            [`announcer`]: e.target.value
                                        }))
                                    }/>
                                <p className='afa-error-text'>{error[`announcer`]}</p>
                            </Inputs>
                        </div>
                        {/*<div className="col-12 col-md-6 mb-3">*/}
                        {/*    <Inputs>*/}
                        {/*        <span>زبان</span>*/}
                        {/*        <select*/}
                        {/*            className={'afa-error-input'}*/}
                        {/*            name={`locale`}*/}
                        {/*            value={inputs?.[`locale`]}*/}
                        {/*            onChange={(e) =>*/}
                        {/*                setInputs(prevState => ({*/}
                        {/*                    ...prevState,*/}
                        {/*                    [`locale`]: e.target.value*/}
                        {/*                }))*/}
                        {/*            }>*/}
                        {/*            <option value="fa">فارسی</option>*/}
                        {/*            <option value="en">انگلیسی</option>*/}
                        {/*        </select>*/}
                        {/*        <p className='afa-error-text'>{error[`locale`]}</p>*/}
                        {/*    </Inputs>*/}
                        {/*</div>*/}
                    </div>
                    <Inputs>
                        <span>توضیحات توصیه نامه</span>
                        <textarea
                            className={inputs['testimonial'] && 'afa-error-input'}
                            rows="3"
                            name={`testimonial`}
                            value={inputs?.[`testimonial`]}
                            onChange={(e) =>
                                setInputs(prevState => ({
                                    ...prevState,
                                    [`testimonial`]: e.target.value
                                }))
                            }/>
                        <p className='afa-error-text'>{error[`testimonial`]}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-md-4 mb-3">
                    <div className="afa-upload-file-parent">
                        <span className='f-14'>تصویر شخص</span>
                        <ArticleFile
                            className={error[`image`] && 'afa-error-input'}>
                            {
                                (inputs[`image_api`] || inputs[`image`]) ?
                                    <div className="afa-img">
                                        <img
                                            src={inputs[`image_url`] ? inputs[`image_url`] : `${MAIN_URL_IMAGE}${inputs[`image_api`]}`}
                                            alt="آذر فولاد امین"/>
                                        <span
                                            className='afa-remove-position icon-delete'
                                            onClick={() => removeImage(`image`)}/>
                                    </div> :
                                    <label
                                        className="afa_upload_file d-flex flex-column align-items-center justify-content-center">
                                        <img src={Images?.article_upload?.default}
                                             className='afa-article-image-to-upload' alt="آذر فولاد امین"/>
                                        <input type="file" accept="image/*"
                                               onChange={thisUploadDocs}
                                               name={`image`}
                                               className='dv-upload-file'/>
                                        {
                                            (!inputs[`image_api`] && !inputs[`image`]) &&
                                            <span className='upload-text'>اپلود عکس مربوط به شخص</span>
                                        }
                                    </label>
                            }
                        </ArticleFile>
                        <p className='afa-error-text'>{error[`image`]}</p>
                    </div>
                </div>


                <div className="col-12 d-flex align-items-center justify-content-center mb-4">
                    <Inputs>
                        <button type='submit'
                                className='m-auto afa-save-super-admin-btn'>
                            <span className='pr-3'>ذخیره</span>
                        </button>
                    </Inputs>
                </div>
            </form>
        </MainContent>
    );
}

export default TestimonialArchive;