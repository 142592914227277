export const Images = {
    main_logo: require('../images/afa.png'),
    logo_mini: require('../images/logo192.png'),
    login_left: require('../images/login.png'),
    bell: require('../images/bell.png'),
    article: require('../images/article.png'),
    article_upload: require('../images/article_upload.png'),
    right_top: require('../images/right-top.png'),
    right_bottom: require('../images/right-bottom.png'),
    perseneli : require('../images/عکس پرسنلی.jpg'),
    mapIcon: require('../images/mapIcon.png'),
    placeHolder: require('../images/placeholder.png'),
    mini_placeHolder: require('../images/mini-placeholder.png'),
    pdf_place_holder: require('../images/pdf.png'),
    print: require('../images/resume.png'),
    checkCircle: require('../images/check-circle.png'),
    united_kingdom: require('../images/flags/en.png'),
    persian: require('../images/flags/ir.png'),
    turk: require('../images/flags/tr.png'),
    arabic: require('../images/flags/ar.png'),
}