import React, {useState, useEffect} from 'react';
import {useLocation} from "react-router-dom";
import {Images} from "../../../../assets/scripts/Images";
import {getData, priceFormat} from "../../../../assets/scripts/GeneralFunctions";
import {MAIN_URL, MAIN_URL_IMAGE} from "../../../../assets/scripts/GeneralVariables";
import moment from "moment-jalaali";
import '../../../../assets/scss/a4.scss'

function Resume(props) {

    const [show_print_btn, setShow] = useState(false);
    const [inputs, setInputs] = useState({
        "personal_info": {},
        "user_jobs": [],
        "user": {},
        "family_members": [],
        "insurance_certificate": null,
        "educational_document": null,
        "educational_backgrounds": [],
        "professional_experiences": [],
        "languages": [],
        "skills": [],
        "documents": [],
        "softwares": [],
        "certificates": [],
        drivers_licence: [],
    })
    const location = useLocation();

    useEffect(() => {
        async function fetchData() {
            window.scrollTo(0, 0)
            let data = location.state
            let api_result = await getData(MAIN_URL, `hr-management/cv/${data?.user_id}`, 'get', {}, true, true);
            if (api_result?.status === 200) {
                setInputs(prevState => ({
                    ...prevState,
                    "personal_info": api_result?.data?.personal_info,
                    "user_jobs": api_result?.data?.user_jobs,
                    "user": api_result?.data?.user,
                    "family_members": api_result?.data?.family_members,
                    "insurance_certificate": api_result?.data?.insurance_certificate,
                    "educational_document": api_result?.data?.educational_document,
                    "educational_backgrounds": api_result?.data?.educational_backgrounds,
                    "professional_experiences": api_result?.data?.professional_experiences,
                    "languages": api_result?.data?.languages,
                    "skills": api_result?.data?.skills,
                    "documents": api_result?.data?.documents,
                    "softwares": api_result?.data?.softwares,
                    "certificates": api_result?.data?.certificates,
                    "drivers_licence": api_result?.data?.drivers_licence,
                }))
            }
        }

        fetchData();
    }, [])

    const printData = () => {
        setShow(true);

        setTimeout(function () {
            window.print();
        }, 1000);
    }

    const {
        personal_info, user_jobs, user, family_members, certificates, drivers_licence,
        educational_backgrounds, professional_experiences, skills, softwares, languages
    } = inputs

    const UserInfoCard50 = ({title, value, last_child}) => {
        return (
            <div className={`user-info-card d-flex align-items-center mb-3 w-50 + ${last_child ? 'ms-0' : 'ms-3'}`}>
                <div className="user-info-card-title">{title}</div>
                <div className="user-info-card-value">{value}</div>
            </div>
        )
    }

    const UserInfoCard100 = ({title, value}) => {
        return (
            <div className="user-info-card user-info-card-100 d-flex align-items-center mb-3 w-100 ms-3">
                <div className="user-info-card-title">{title}</div>
                <div className="user-info-card-value">{value}</div>
            </div>
        )
    }

    const UserInfoCardLarge50 = ({title, value, last_child}) => {
        return (
            <div className={`user-info-card user-info-card-large d-flex align-items-center mb-3 w-50 + ${last_child ? 'ms-0' : 'ms-3'}`}>
                <div className="user-info-card-title">{title}</div>
                <div className="user-info-card-value">{value}</div>
            </div>
        )
    }

    const UserInfoCard33 = ({title, value, last_child}) => {
        return (
            <div className={`user-info-card d-flex align-items-center mb-3 w-33 + ${last_child ? 'ms-0' : 'ms-3'}`}>
                <div className="user-info-card-title">{title}</div>
                <div className="user-info-card-value">{value}</div>
            </div>
        )
    }
    const UserInfoCardLarge33 = ({title, value, last_child}) => {
        return (
            <div
                className={`user-info-card user-info-card-large-33 d-flex align-items-center mb-3 w-33 + ${last_child ? 'ms-0' : 'ms-3'}`}>
                <div className="user-info-card-title">{title}</div>
                <div className="user-info-card-value">{value}</div>
            </div>
        )
    }
    return (
        <div className="dv-resume-parent">
            <page className={"dv-a4"}>
                <div className="row dv-center-padding">
                    <h4 className={'col-12 text-center fw-bold my-5'}>فرم درخواست استخدام {user?.name}</h4>

                    <div className="col-12 d-flex align-items-center justify-content-between">
                        <h6 className={'mb-4 dv-default-text'}>الف - اطلاعات فردی :</h6>
                        {
                            !show_print_btn &&
                            <button onClick={printData}
                                    className={'dv-print-factor-btn bg-transparent border-0 px-2 mb-3'}>
                                <img className={'img-fluid'} src={Images.print.default}
                                     alt={'pera plastic print pre estimate'}/>
                                {/*<span>پرینت</span>*/}
                            </button>
                        }
                    </div>
                    <div className="col-12 d-flex">
                        <div className="d-flex flex-column dv-table-with-image">
                            <div className="d-flex align-items-center">
                                <UserInfoCard50 title={'نام'} value={personal_info?.first_name}/>
                                <UserInfoCard50 title={'نام خانوادگی'} value={personal_info?.last_name}/>
                            </div>
                            {/*<div className="d-flex align-items-center">*/}
                            {/*    <UserInfoCard100 title={'شغل-گرایش'} value={ user_jobs?.length > 0 ?*/}
                            {/*        user_jobs?.map((item) => (*/}
                            {/*            <span key={item.id} className={'mb-2'}>{item.title} - {item.job.title}</span>*/}
                            {/*        )) : '-'*/}
                            {/*    }/>*/}
                            {/*</div>*/}
                            <div className="d-flex align-items-center">
                                <UserInfoCard50 title={'کد ملی'} value={personal_info?.identification_code}/>
                                <UserInfoCard50 title={'شماره شناسنامه'} value={personal_info?.birth_certificate_id ? personal_info?.birth_certificate_id : '-'}/>
                            </div>
                        </div>
                        <div className="dv-personal-image mb-3">
                            <img src={personal_info?.personal_photo ? `${MAIN_URL_IMAGE}/${personal_info?.personal_photo}` : Images.perseneli.default} alt="user image" className={'img-fluid'}/>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="d-flex align-items-center">
                            <UserInfoCard33 title={'نام پدر'} value={personal_info?.father_name ? personal_info?.father_name : '-'}/>
                            <UserInfoCard33 title={'تاریخ تولد'}
                                            value={personal_info?.birth_date ? moment(personal_info?.birth_date).format('jYYYY-jMM-jDD') : '-'}/>
                            <UserInfoCard33 last_child={true} title={'جنسیت'} value={personal_info?.gender ? personal_info?.gender : '-'}/>
                        </div>
                        <div className="d-flex align-items-center">
                            <UserInfoCard33 title={'صادره'} value={personal_info?.issued ? personal_info?.issued : '-'}/>
                            <UserInfoCard33 title={'شماره تماس'} value={personal_info?.mobile ? personal_info?.mobile : '-'}/>
                            <UserInfoCard33 last_child={true} title={'شماره ثابت'} value={personal_info?.telephone ? personal_info?.telephone : '-'}/>
                        </div>

                        <UserInfoCard100 title={'نشانی محل سکونت'} value={personal_info?.address ? personal_info?.address : '-'}/>

                        <div className="d-flex align-items-center">
                            <UserInfoCard33 title={'کد پستی'} value={personal_info?.postal_code ? personal_info?.postal_code : '-'}/>
                            <UserInfoCard33 title={'قد'}
                                            value={personal_info?.height ? personal_info?.height : '-'}/>
                            <UserInfoCard33 last_child={true} title={'وزن'}
                                            value={personal_info?.weight ? personal_info?.weight : '-'}/>

                        </div>

                        <div className="d-flex align-items-center">
                            <UserInfoCardLarge33 title={'وضعیت تأهل'} value={personal_info?.marital_status ? personal_info?.marital_status : '-'}/>
                            <UserInfoCardLarge33 title={'تعداد فرزندان'} value={personal_info?.offspring_count ? personal_info?.offspring_count : '-'}/>
                            <UserInfoCardLarge33 last_child={true} title={'افراد تحت تکفل'} value={personal_info?.dependents_count ? personal_info?.dependents_count : '-'}/>
                        </div>

                        {
                            personal_info?.introduction_method === 'معرف' ?
                                <div className="d-flex align-items-center">
                                    <UserInfoCard50 title={'طریقه آشنایی'} value={
                                        <span>{personal_info.introduction_method} ( {personal_info?.introducer_info} )</span>
                                    }/>
                                    <UserInfoCard50 last_child={true} title={'وضعیت نظام وظیفه'}
                                                    value={personal_info?.military_service_status ? personal_info?.military_service_status : '-'}/>
                                </div> :
                                <UserInfoCard100 title={'طریقه آشنایی'} value={<>
                                    <span className={' px-1 pe-3'}>معرف </span>
                                    {personal_info.introduction_method === 'معرف' &&
                                        <img src={Images.checkCircle.default} alt="checkCircle"/>}

                                    <span className={' px-1 pe-3'}>مراجعه شخصی </span>
                                    {personal_info.introduction_method === 'مراجعه شخصی' &&
                                        <img src={Images.checkCircle.default} alt="checkCircle"/>}

                                    <span className={' px-1 pe-3'}>اداره کار </span>
                                    {personal_info.introduction_method === 'اداره کار' &&
                                        <img src={Images.checkCircle.default} alt="checkCircle"/>}

                                    <span
                                        className={' px-1 pe-3'}>موسسات کاریابی </span>
                                    {personal_info.introduction_method === 'موسسات کاریابی' &&
                                        <img src={Images.checkCircle.default} alt="checkCircle"/>}

                                    <span
                                        className={' px-1 pe-3'}>سایت و شبکه های اجتماعی </span>
                                    {personal_info.introduction_method === 'سایت و شبکه های اجتماعی' &&
                                        <img src={Images.checkCircle.default} alt="checkCircle"/>}
                                </>}/>
                        }


                        <div className="d-flex align-items-center">
                            <UserInfoCardLarge50 title={'آیا تا کنون بیماری قابل توجهی داشته اید'} value={parseInt(personal_info?.special_disease) === 1 ? 'بله' : 'خیر'}/>
                            <UserInfoCard50 title={'نام بیماری'} last_child={true}
                                            value={personal_info?.special_disease_description ? personal_info?.special_disease_description : '-'}/>
                        </div>
                        {
                            personal_info?.introduction_method === 'معرف' ?
                                    <div className="d-flex align-items-center">
                                        <UserInfoCardLarge50 title={'آیا سابقه محکومیت کیفری داشته اید'} value={parseInt(personal_info?.criminal_conviction) === 1 ? 'بله' : 'خیر'}/>
                                        <UserInfoCardLarge50 last_child={true} title={'آیا سیگار میکشید'} value={personal_info?.does_smoke === 1 ? 'بله' : 'خیر'}/>
                                    </div>
                                :
                                <div className="d-flex align-items-center">
                                    <UserInfoCardLarge33 title={'وضعیت نظام وظیفه'}
                                                    value={personal_info?.military_service_status ? personal_info?.military_service_status : '-'}/>
                                    <UserInfoCardLarge33 title={'سابقه کیفری'} value={parseInt(personal_info?.criminal_conviction) === 1 ? 'بله' : 'خیر'}/>
                                    <UserInfoCardLarge33 last_child={true} title={'آیا سیگار میکشید'} value={personal_info?.does_smoke === 1 ? 'بله' : 'خیر'}/>
                                </div>
                        }

                    </div>
                </div>

                <div className="row dv-center-padding">
                    {/*family members*/}
                    <div className="dv-min-height-resume-table">
                        <div className="col-12 d-flex align-items-center justify-content-between mt-3">
                            <h6 className={'mb-4 dv-default-text'}>ب - اطلاعات اعضای خانواده :</h6>
                        </div>
                        <div className="col-12 mb-5">
                            <table className="dv-resume-table">
                                <thead>
                                <tr>
                                    <th>نام و نام خانوادگی</th>
                                    <th>نسبت</th>
                                    <th>کد ملی</th>
                                    <th>تاریخ تولد</th>
                                    <th>جنسیت (فرزند)</th>
                                    <th>شماره تماس</th>
                                    <th>بیمه تکمیلی</th>
                                </tr>
                                </thead>
                                <tbody>
                                {family_members?.map((member, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{member.name} {member.full_name}</td>
                                            <td>{member.relation}</td>
                                            <td>{member.identification_code}</td>
                                            <td dir={'rtl'}>{moment(member.bird_date).format('jYYYY-jMM-jDD')}</td>
                                            <td>{member.child_gender ? member.child_gender : '- - -'}</td>
                                            <td>{member.telephone ? member.telephone : ' - - - '}</td>
                                            <td>{member?.supplementary_insurance ? member?.supplementary_insurance : '- - -'}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {/*educational backgrounds*/}
                    <div className="dv-min-height-resume-table">
                        <div className="col-12 d-flex align-items-center justify-content-between">
                            <h6 className={'mb-4 dv-default-text'}>ج - سوابق تحصیلی :</h6>
                        </div>
                        <div className="col-12 mb-5">
                            <table className="dv-resume-table">
                                <thead>
                                <tr>
                                    <th>مقطع</th>
                                    <th>رشته تحصیلی</th>
                                    <th>گرایش</th>
                                    <th>دانشگاه/موسسه</th>
                                    <th>معدل</th>
                                    <th>از تاریخ تا تاریخ</th>
                                </tr>
                                </thead>
                                <tbody>
                                {educational_backgrounds?.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item?.grade ? item?.grade : '- - -'}</td>
                                            <td>{item?.field ? item?.field : '- - -'}</td>
                                            <td>{item?.orientation ? item?.orientation : '- - -'}</td>
                                            <td>{item?.institute ? item?.institute : '- - -'}</td>
                                            <td>{item?.grade_average ? item?.grade_average : '- - -'}</td>
                                            <td>{item?.from_date ? `${item?.from_date} - ${item?.to_date}` : '- - -'}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="row dv-center-padding">
                    {/*Professional Experiences*/}
                    <div className="dv-min-height-resume-table">
                        <div className="col-12 d-flex align-items-center justify-content-between mt-3">
                            <h6 className={'mb-4 dv-default-text'}>د - سوابق شغلی :</h6>
                        </div>
                        <div className="col-12 mb-5">
                            <table className="dv-resume-table">
                                <thead>
                                <tr>
                                    <th>نام سازمان/ شرکت</th>
                                    <th>سمت</th>
                                    <th>از تاریخ تا تاریخ</th>
                                    <th>حقوق دریافتی(ریال)</th>
                                    <th>علت قطع همکاری</th>
                                </tr>
                                </thead>
                                <tbody>
                                {professional_experiences?.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item?.organization ? item?.organization : '- - -'}</td>
                                            <td>{item?.position ? item?.position : '- - -'}</td>
                                            <td>{item?.from_date ? `${item?.from_date} - ${item?.to_date}` : '- - -'}</td>
                                            <td>{item?.salary ? priceFormat(item?.salary) : '- - -'}</td>
                                            <td>{item?.termination_of_cooperation_reason ? item?.termination_of_cooperation_reason : '- - -'}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/*languages*/}
                    <div className="dv-min-height-resume-table">
                        <div className="col-12 d-flex align-items-center justify-content-between">
                            <h6 className={'mb-4 dv-default-text'}>ه - زبان های خارجه :</h6>
                        </div>
                        <div className="col-12 mb-5">
                            <table className="dv-resume-table">
                                <thead>
                                <tr>
                                    <th>عنوان</th>
                                    <th>مکالمه</th>
                                    <th>خواندن و نوشتن</th>
                                    <th>ترجمه و گزارش نویسی</th>
                                    <th>مذاکره تجاری</th>
                                </tr>
                                </thead>
                                <tbody>
                                {languages?.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.language ? item.language : '- - -'}</td>
                                            <td>{item.conversation ? item.conversation : '- - -'}</td>
                                            <td>{item.reading_writing ? item.reading_writing : '- - -'}</td>
                                            <td>{item.translation_report ? item.translation_report : '- - -'}</td>
                                            <td>{item.business_negotiation ? item.business_negotiation : '- - -'}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="row dv-center-padding">

                    {/*Skills*/}
                    <div className="dv-min-height-resume-table3">
                        <div className="col-12 d-flex align-items-center justify-content-between mt-3">
                            <h6 className={'mb-4 dv-default-text'}>و - مهارت ها :</h6>
                        </div>
                        <div className="col-12 mb-5">
                            <table className="dv-resume-table">
                                <thead>
                                <tr>
                                    <th>واحد</th>
                                    <th>نام مهارت</th>
                                    <th>سطح</th>
                                </tr>
                                </thead>
                                <tbody>
                                {skills?.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.skill?.technical_department?.name ? item.skill?.technical_department?.name : '- - -'}</td>
                                            <td>{item.skill?.name ? item.skill?.name : '- - -'}</td>
                                            <td>
                                                {
                                                    !item.level ? '- - -' :
                                                        <>
                                                                <span className={' px-1 pe-3'}> {item.level} </span>
                                                            {/*    <span className={' px-1 pe-3'}> مبتدی </span>*/}
                                                            {/*{item.level === 'مبتدی' &&*/}
                                                            {/*    <img src={Images.checkCircle.default}*/}
                                                            {/*         alt="checkCircle"/>}*/}
                                                            {/*{item.level === 'متوسط' &&*/}
                                                            {/*    <img src={Images.checkCircle.default}*/}
                                                            {/*         alt="checkCircle"/>}*/}
                                                            {/*<span*/}
                                                            {/*    className={' px-1 pe-3'}> متوسط </span>*/}
                                                            {/*{item.level === 'پیشرفته' &&*/}
                                                            {/*    <img src={Images.checkCircle.default}*/}
                                                            {/*         alt="checkCircle"/>}*/}
                                                            {/*<span*/}
                                                            {/*    className={' px-1 pe-3'}> پیشرفته </span>*/}
                                                        </>
                                                }


                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {/*certificates*/}
                    <div className="dv-min-height-resume-table3">
                        <div className="col-12 d-flex align-items-center justify-content-between">
                            <h6 className={'mb-4 dv-default-text'}>ز - گواهی ها :</h6>
                        </div>
                        <div className="col-12 mb-5">
                            <table className="dv-resume-table">
                                <thead>
                                <tr>
                                    <th>نام دوره</th>
                                    <th>محل آموزش</th>
                                    <th>سال</th>
                                    <th>مدت (ماه)</th>
                                    <th>گواهی نامه</th>
                                </tr>
                                </thead>
                                <tbody>
                                {certificates?.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item?.course}</td>
                                            <td>{item?.location}</td>
                                            <td>{item.year}</td>
                                            <td>{item?.duration}</td>
                                            <td>
                                                <span className={'px-1 ps-3'}>{item?.certificate ? 'دارد' : 'ندارد'}</span>
                                                {/*{item?.certificate &&*/}
                                                {/*    <img src={Images.checkCircle.default} alt="checkCircle"/>}*/}
                                                {/*<span className={'px-1 ps-3'}>دارد</span>*/}

                                                {/*{!item?.certificate &&*/}
                                                {/*    <img src={Images.checkCircle.default} alt="checkCircle"/>}*/}
                                                {/*<span className={' px-3'}>ندارد</span>*/}
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/*Software*/}
                    <div className="dv-min-height-resume-table3">
                        <div className="col-12 d-flex align-items-center justify-content-between">
                            <h6 className={'mb-4 dv-default-text'}>ح - نرم افزار ها :</h6>
                        </div>
                        <div className="col-12 mb-5">
                            <table className="dv-resume-table">
                                <thead>
                                <tr>
                                    <th>نرم افزار</th>
                                    <th>سطح</th>
                                </tr>
                                </thead>
                                <tbody>
                                {softwares?.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.name}</td>
                                            <td>
                                                <span className={'px-1 ps-3'}> {item.pivot?.level} </span>
                                                {/*<span className={'px-1 ps-3'}> پیشرفته </span>*/}
                                                {/*{item.pivot?.level === 'پیشرفته' &&*/}
                                                {/*    <img src={Images.checkCircle.default} alt="checkCircle"/>}*/}

                                                {/*<span className={'ps-3'}> متوسط </span>*/}
                                                {/*{item.pivot?.level === 'متوسط' &&*/}
                                                {/*    <img src={Images.checkCircle.default} alt="checkCircle"/>}*/}

                                                {/*<span className={'pe-2'}> مبتدی </span>*/}
                                                {/*{item.pivot?.level === 'مبتدی' &&*/}
                                                {/*    <img src={Images.checkCircle.default} alt="checkCircle"/>}*/}
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>

                <div className="row dv-center-padding">
                    <div className="col-12 mt-3">
                        <p className={"f-14"}>
                            اینجانب <b>{user?.name}</b> با مشخصات فوق اعلام میدارم کلیه سئوالات فوق را با تفهیم کامل پاسخ صحیح
                            داده
                            و در صورتیکه تمام و یا قسمتی از اطلاعات اعلام شده عاری از حقیقت باشد، شرکت میتواند
                            از همان زمان به خدمتم خاتمه داده و هیجگونه ادعائی نخواهم داشت و بدین وسیله حق هرگونه
                            اعتراض بعدی را از خود سلب می نمایم.
                        </p>
                        <div className="d-flex align-items-center justify-content-end my-3">
                            <span className={'ps-5 ms-5'}>تاریخ : </span>
                            <span className={'ps-5 ms-5'}>امضا و اثرانگشت : </span>
                        </div>

                        <p className={'fw-bold f-14'}>( مخصوص عملیات اداری ) لطفا در قسمت زیر چیزی ننویسید</p>
                        <table className="table dv-resume-table mb-2">
                            <thead>
                            <tr>
                                <th className={'w-75'}>نظر مصاحبه کننده اول</th>
                                <th className={'w-25'}>نام و امضاء - تاریخ</th>
                            </tr>
                            </thead>

                            <tbody>
                            <tr className={'dv-resume-td-height'}>
                                <td></td>
                                <td></td>
                            </tr>
                            </tbody>
                        </table>
                        <table className="table dv-resume-table mb-2">
                            <thead>
                            <tr>
                                <th className={'w-75'}>نظر مصاحبه کننده دوم</th>
                                <th className={'w-25'}>نام و امضاء - تاریخ</th>
                            </tr>
                            </thead>

                            <tbody>
                            <tr className={'dv-resume-td-height'}>
                                <td></td>
                                <td></td>
                            </tr>
                            </tbody>
                        </table>
                        <table className="table dv-resume-table mb-2">
                            <thead>
                            <tr>
                                <th className={'w-75'}>نظر نهایی (مدیرعامل)</th>
                                <th className={'w-25'}>نام و امضاء - تاریخ</th>
                            </tr>
                            </thead>

                            <tbody>
                            <tr className={'dv-resume-td-height'}>
                                <td></td>
                                <td></td>
                            </tr>
                            </tbody>
                        </table>
                        <table className="table dv-resume-table">
                            <thead>
                            <tr>
                                <th className={'w-75'}>اقدام واحد اداری و منابع انسانی</th>
                                <th className={'w-25'}>نام و امضاء - تاریخ</th>
                            </tr>
                            </thead>

                            <tbody>
                            <tr className={'dv-resume-td-height'}>
                                <td></td>
                                <td></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </page>
        </div>
    );
}

export default Resume;