import styled from "styled-components";

const custom_gray = '#5A5A5A';
const custom_red = '#E30613';
export const LoginTheme = styled.div`
  width: 100vw;
  // height: 100%;
  background-color: #191A1B;
  background-size: 100% 100%;
  display: flex;
  //overflow-y: hidden;
  @media (max-width: 992px) {
    flex-direction: column;
    // height: auto;
    background-size:cover;
  
  }
`;

export const RightContent = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 3.85rem;

  h6 {
    font-size: 16px;
    line-height: 27px;
    color: #fff;
    margin-bottom: 2rem;
    margin-top: 0;
  }

  @media (max-width: 992px) {
    width: 100%;
    height: 100vh;
  }
`;

export const LeftContent = styled.div`
  width: 50%;
  height: 100vh;

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    display: none;
  }
`

export const LoginInput = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 350px;
  color: #fff;
  margin: auto;
  margin-top: 3rem;
  background-color: #191A1B;

  span {
    margin-bottom: 0.5rem;
  }

  input {
    background-color: ${custom_gray};
    padding: 0.75rem 1rem;
    border-radius: 10px;
    margin-bottom: 2rem;
    width: 100%;
    border: none;
    color: #fff;

    &:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }

    &::placeholder {
      color: rgba(255, 255, 255, 0.8);
    }
  }

  button {
    background-color: ${custom_red};
    color: #fff;
    padding: 0.75rem 1rem;
    border-radius: 10px;
    width: 100%;
    border: none;
    margin-bottom: 1rem;
    &:hover{
      background-color: #c71111;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    background-color: #191A1B;
    padding: 1rem;
  }
`

export const ConfirmCodeDiv = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #191A1B;
  width: 350px;
  color: #fff;
  margin: auto;
  margin-top: 3rem;

  input {
    background-color: ${custom_gray};
    padding: 0.75rem 1rem;
    border-radius: 10px !important;
    width: 100%;
    border: none !important;
    color: #fff;
    margin: 0 0.25rem 2rem 0.25rem;

    &:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }

    &::placeholder {
      color: rgba(255, 255, 255, 0.8);
    }
  }

  button {
    background-color: ${custom_red};
    color: #fff;
    padding: 0.75rem 1rem;
    border-radius: 10px;
    width: 100%;
    border: none;
    margin-bottom: 1rem;
    transition: all 0.25s linear;

    &:hover {
      background-color: #c71111;
    }
  }

  span {
    margin-bottom: 1rem;
  }

  @media (max-width: 768px) {
    width: 100%;
    background-color: #191A1B;
    padding: 1rem;
  }
`

export const EditNumber = styled.div`
  width: 70%;
  margin: auto;

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      color: #FC4C56;
      font-size: 14px;
      line-height: 25px;

      &:hover {
        color: ${custom_red};
      }
    }

    p {
      color: #fff;
      text-align: left;
      direction: ltr;
      margin-bottom: 0;
    }
  }
`