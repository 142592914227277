import React, {useEffect, useState} from 'react';
import {
    getData,
    getFormDataPost,
    setTitle
} from "../../../assets/scripts/GeneralFunctions";
import {MainContent} from "../super admin child/style";
import {BannerFile, BannerFileMini, Inputs} from "../Child/Supplier/Child/style";
import {MAIN_URL, MAIN_URL_IMAGE} from "../../../assets/scripts/GeneralVariables";
import {Images} from "../../../assets/scripts/Images";
import CkEditorText from "./Article/CkEditorText";
import TagsInput from "react-tagsinput";
import {toast} from "react-toastify";
import Swal from "sweetalert2";
import {Dropdown, ProgressBar} from "react-bootstrap";
import axios from "axios";
import Flags from '../../utils/Flags';

function MachineManufacturing(props) {

    const [inputs, setInputs] = useState({
        mashin_sazi_title: '',
        mashin_sazi_description: '',
        mashin_sazi_links: [],
        article_file_api: '',
        article_file_url: '',
        article_file: '',
        en_null: false,
        locale: 'fa',
        mashin_sazi_galleries: [],
        uploading_file_list_base64: []
    })
    const [error, setError] = useState({})
    const [progress, setProgress] = useState()

    useEffect(async () => {
        setTitle('آذر فولاد امین | ماشین سازی')
        let api_result = await getData(MAIN_URL, `mashin-sazi-management/${inputs?.locale}`, 'get', {}, true, true);
        if (api_result?.status === 200) {
            let data = api_result?.data
            setInputs(prevState => ({
                ...prevState,
                mashin_sazi_title: data?.mashin_sazi_title,
                mashin_sazi_description: data?.mashin_sazi_description,
                mashin_sazi_links: data?.mashin_sazi_links,
                mashin_sazi_galleries: data?.mashin_sazi_galleries,
            }))
        }
    }, [])

    const handleChange = (links) => {
        setInputs(prevState => ({...prevState, mashin_sazi_links: links}))
    }

    const setData = (value) => {
        setInputs(prevState => ({...prevState, mashin_sazi_description: value}))
    }

    // edit data
    const formHandler = async (e) => {
        e.preventDefault()
        let formData = new FormData()

        formData.append(`mashin_sazi_title`, inputs?.mashin_sazi_title)

        formData.append(`mashin_sazi_description`, inputs?.mashin_sazi_description)

        for (let i = 0; i < inputs?.mashin_sazi_links?.length; i++) {
            formData.append(`mashin_sazi_links[${i}]`, inputs?.mashin_sazi_links[i])
        }

        let api_result = await getFormDataPost(`mashin-sazi-management/edit/${inputs?.locale}`, formData)

        if (api_result?.status === 200) {
            setError('')
            let data = api_result?.data
            setInputs(prevState => ({
                ...prevState,
                mashin_sazi_title: data?.mashin_sazi_title,
                mashin_sazi_description: data?.mashin_sazi_description,
                mashin_sazi_links: data?.social_media,
            }))
            toast.success('با موفقیت ثبت شد')
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
        }
    }

    // Upload File
    const uploadBlogGalleryFiles = async (e) => {
        if (e.target.files) {
            let files = e.target.files;
            let fileBase64 = URL.createObjectURL(files[0]);
            let authToken = localStorage.getItem('AFAToken');
            let file_type = files[0]?.type?.split('/')[0];
            let formData = new FormData()
            formData.append("file", files[0])
            formData.append("type", file_type === 'image' ? 'photo' : 'video')
            axios.post(`${MAIN_URL}mashin-sazi-management/add-gallery`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${authToken}`,
                },
                onUploadProgress: data => {
                    //Set the progress value to show the progress bar
                    setProgress(Math.round((100 * data.loaded) / data.total))
                },
            }).then(response => {
                console.log(response.data)
                setInputs(prevState => ({
                    ...prevState,
                    mashin_sazi_galleries: response?.data?.mashin_sazi_galleries
                    // let uploading_file_list_base64 = []
                    // uploading_file_list_base64: [...inputs?.uploading_file_list_base64, {
                    //     'file': fileBase64,
                    //     'type': file_type,
                    //     'id': response?.data?.mashin_sazi_galleries.id,
                    // }],
                }))
                setProgress()
                setError({})
            }).catch(e => {
                if (e.response?.['status'] === 400) {
                    setError(e.response?.data['errors'])
                    setProgress()
                }
            })
        }
    }

// Remove File
    const handleRemoveBlogGalley = async (src, id, title) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این فایل را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                let remove_result = await getData(MAIN_URL, `mashin-sazi-management/remove-gallery`, 'post', {src}, true, false);
                if (remove_result?.status === 200) {
                    setInputs(prevState => ({
                        ...prevState,
                        mashin_sazi_galleries: remove_result?.data?.mashin_sazi_galleries
                    }))
                    swalWithBootstrapButtons.fire(
                        'حذف شد!',
                        'با موفقیت حذف شد',
                        'success'
                    )
                }
            }
        })
    }


    const handleLanguage = async (locale) => {
        let api_result = await getData(MAIN_URL, `mashin-sazi-management/${locale?.value}`, 'get', {}, true, true);
        if (api_result?.status === 200) {
            let data = api_result?.data
            if (data !== null) {
                setInputs(prevState => ({
                    ...prevState,
                    locale: locale?.value,
                    mashin_sazi_title: data?.mashin_sazi_title,
                    mashin_sazi_description: data?.mashin_sazi_description,
                    mashin_sazi_links: data?.mashin_sazi_links,
                }))
            } else {
                setInputs(prevState => ({
                    ...prevState,
                    locale: locale?.value,
                    mashin_sazi_title: '',
                    mashin_sazi_description: '',
                    mashin_sazi_links: '',
                    en_null: true
                }))
            }
        }
    }

    return (
        <MainContent>
            <form onSubmit={formHandler} className='row'>
                <div
                    className="col-12 w-100 d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center mb-3 position-relative">
                    <h6 className='text-red fw-bold'>ماشین سازی</h6>

                    <Flags handleLanguage={handleLanguage}/>
                </div>

                <div className="col-12 col-md-6 mb-3">
                    <div className="row px-0">
                        <div className="col-12 mb-4">
                            <Inputs>
                                <span>عنوان</span>
                                <input
                                    className={'afa-error-input'}
                                    type="text" name={`mashin_sazi_title`}
                                    value={inputs?.[`mashin_sazi_title`]}
                                    placeholder={'عنوان محتوا'}
                                    onChange={(e) =>
                                        setInputs(prevState => ({
                                            ...prevState,
                                            [`mashin_sazi_title`]: e.target.value
                                        }))
                                    }/>
                            </Inputs>
                            {error['mashin_sazi_title'] &&
                                <p className='afa-error-text'>{error['mashin_sazi_title']}</p>}
                        </div>
                    </div>
                </div>
                <div className="col-12 mb-3">
                    <span className="f-14">توضیحات کامل</span>
                    <CkEditorText data={inputs?.mashin_sazi_description ? inputs?.mashin_sazi_description : ''} setData={setData}/>
                    {error['mashin_sazi_description'] &&
                        <p className='afa-error-text'>{error['mashin_sazi_description']}</p>}
                </div>
                <div className="col-12 mb-4">
                    <span className='f-14 mb-2'>افزودن لینک</span>
                    <TagsInput value={inputs?.mashin_sazi_links ? inputs?.mashin_sazi_links : []} onChange={handleChange}/>
                    {error['mashin_sazi_links'] &&
                        error['mashin_sazi_links']?.map((item, index) => (
                            <p key={index} className='afa-error-text'>{item}</p>
                        ))
                    }
                </div>

                <div className={'row'}>
                    <div className="col-12 mb-3">
                        <span className='f-14 mb-2'>تصاویر و ویدیو های مربوطه</span>
                        <div className="row px-0">
                            <div className="col-12 col-md-6 col-lg-3 mb-3 d-flex justify-content-start">
                                <div className="afa-upload-file-parent w-100"
                                     style={{marginLeft: 'auto', marginRight: '0'}}>
                                    {
                                        progress ?
                                            <div
                                                className={'d-flex flex-column align-items-center justify-content-center w-100 h-100'}>
                                                <ProgressBar className={'mt-3 w-100'} now={progress}
                                                             label={`${progress}%`}
                                                             variant="danger" animated/>
                                                <h6 className={'pt-2 text-center'}>در حال بارگزاری</h6>
                                            </div> :
                                            <BannerFileMini style={{height: '100%'}}
                                                            className={error[`image`] && 'afa-error-input'}>
                                                <label
                                                    className="afa_upload_file d-flex flex-column align-items-center justify-content-center h-100">
                                                    <img src={Images?.article_upload?.default}
                                                         className='afa-article-image-to-upload'
                                                         alt="آذر فولاد امین"/>
                                                    <input type="file" accept="video/*,image/*"
                                                           onChange={uploadBlogGalleryFiles}
                                                           name={`photos_image`} className='dv-upload-file'/>

                                                    <span className='upload-text'>بارگزاری</span>

                                                </label>
                                            </BannerFileMini>
                                    }
                                    <p className='afa-error-text'>{error[`file`]}</p>
                                    <p className='afa-error-text'>{error[`type`]}</p>
                                </div>
                            </div>
                            {/*{*/}
                            {/*    inputs?.uploading_file_list_base64?.length > 0 &&*/}
                            {/*    inputs?.uploading_file_list_base64?.map((row, index) => (*/}
                            {/*        <div className="col-12 col-md-6 col-lg-3 mb-3" key={index}>*/}
                            {/*            <div className="afa-upload-file-parent me-0 ms-auto">*/}
                            {/*                <BannerFile>*/}
                            {/*                    <div className="afa-img d-flex align-items-center">*/}
                            {/*                        {*/}
                            {/*                            row?.type === 'video' ?*/}
                            {/*                                <video*/}
                            {/*                                    style={{*/}
                            {/*                                        width: '100%',*/}
                            {/*                                    }}*/}
                            {/*                                    autoPlay={true}*/}
                            {/*                                    muted={true}*/}
                            {/*                                    controls*/}
                            {/*                                >*/}
                            {/*                                    <source src={row.file}/>*/}
                            {/*                                </video>*/}
                            {/*                                :*/}
                            {/*                                <img*/}
                            {/*                                    src={row.file}*/}
                            {/*                                    alt="آذر فولاد امین"/>*/}
                            {/*                        }*/}
                            {/*                        <span*/}
                            {/*                            className='afa-remove-position icon-delete'*/}
                            {/*                            onClick={() => handleRemoveBlogGalley(row.id , 'base64')}/>*/}
                            {/*                    </div>*/}
                            {/*                </BannerFile>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    ))*/}
                            {/*}*/}

                            {
                                inputs?.mashin_sazi_galleries?.length > 0 &&
                                inputs?.mashin_sazi_galleries?.map((row, index) => (
                                    <div className="col-12 col-md-6 col-lg-3 mb-3" key={index}>
                                        <div className="afa-upload-file-parent me-0 ms-auto">
                                            <BannerFile>
                                                <div className="afa-img d-flex align-items-center">
                                                    {
                                                        row?.type === 'video' ?
                                                            <video
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                                autoPlay={false}
                                                                muted={true}
                                                                controls
                                                            >
                                                                <source src={`${MAIN_URL_IMAGE}${row.src}`}/>
                                                            </video>
                                                            :
                                                            <img
                                                                src={`${MAIN_URL_IMAGE}${row.src}`}
                                                                alt="آذر فولاد امین"/>
                                                    }
                                                    <span
                                                        className='afa-remove-position icon-delete'
                                                        onClick={() => handleRemoveBlogGalley(row.src, row.id, 'api')}/>
                                                </div>
                                            </BannerFile>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>

                <div className="col-12 d-flex align-items-center justify-content-center mb-4">
                    <Inputs>
                        <button type='submit'
                                className='m-auto afa-save-super-admin-btn'>
                            <span className='pr-3'>ذخیره</span>
                        </button>
                    </Inputs>
                </div>
            </form>
        </MainContent>
    );
}

export default MachineManufacturing;