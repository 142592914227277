import React, {useEffect, useState} from 'react';
import {TabStatusComplete, Inputs, LeftIcon, HomeNumberInputs, TabStatusContent} from "./style";
import {
    just_persian,
    getData, getFormDataPost, fixNumber, just_number
} from "../../../../../assets/scripts/GeneralFunctions";
import {DateInput} from "react-hichestan-datetimepicker";
import {MAIN_URL, MAIN_URL_IMAGE} from "../../../../../assets/scripts/GeneralVariables";
import {toast} from "react-toastify";
import Multiselect from 'multiselect-react-dropdown';

function PublicInformation(props) {
    const [error, setError] = useState({})
    const [inputs, setInputs] = useState({
        supplier_supplier_first_name: '',//نام تامین کننده
        supplier_last_name: '',//نام خانوادگی تامین کننده
        institute_name: '',//نام شرکت
        type: 'انتخاب کنید',
        legal_status: 'انتخاب کنید',//وضعیت حقوقی
        identification_code: '',//شناسه / کد ملی
        mobile: '',//موبایل
        nationality: '',//ملیت
        economical_code: '',//کد اقتصادی
        registration_number: '',//شماره ثبت
        registration_location: '',//محل ثبت
        registration_date: '',//تاریخ ثبت
        previous_name: '',//نام قبلی
        ownership_type: 'انتخاب کنید',//نوع مالکیت
        selected_supply_value: [{name: 'نمایندگی'}, {name: 'شرکت اصلی'}],
        supply_type: [],//نحوه تامین
        selected_supply_list: [],
        shaba_id: '',//شماره شبا
        bank_account_id: '',//شماره حساب
        bank_name: '', //نام بانک
        city_name: '',//نام شهر
        bank_account_owner_name: '',//نام دارنده حساب
        distance_from_afa: 'انتخاب کنید', //فاصله از آذر فولاد امین
        office_phone: '',//شماره دفتر
        office_address: '',//آدرس دفتر
        office_website: '',//سایت دفتر*
        office_email: '',//ایمیل دفتر *
        office_postal_code: '',//کد پستی دفتر
        office_mobile: '',//موبایل دفتر
        office_fax: '',//فکس دفتر *
        office_prev_code_number: '',
        office_home_number: '',
        factory_address: '',//آدرس کارخانه
        factory_website: '',//وبسایت کارخانه *
        factory_email: '',//ایمیل کارخانه *
        factory_postal_code: '',//کد پستی کارخانه
        factory_mobile: '',//موبایل کارخانه
        factory_fax: '',//فکس کارخانه *
        factory_prev_code_number: '',
        factory_home_number: '',
        factory_phone: ''//تلفن کارخانه
    })
    const [is_render, setRender] = useState(false);
    const [more_data, setMoreData] = useState({sheet_status: {}, isLoad: true});
    let arr = [];
    useEffect(async () => {
        let api_result = await getData(MAIN_URL, "profile/supplier/supplier-info", 'get', {}, true, true);
        if (api_result?.status === 200) {
            let data = api_result?.data
            let supplier = data?.supplier_info
            setInputs(prevState => ({
                ...prevState,
                'supplier_first_name': supplier?.supplier_first_name,
                'supplier_last_name': supplier?.supplier_last_name,
                'institute_name': supplier?.institute_name,
                'mobile': supplier?.mobile,
                'identification_code': supplier?.identification_code,
                'legal_status': supplier?.legal_status,
                'nationality': supplier?.nationality,
                'economical_code': supplier?.economical_code,
                'registration_number': supplier?.registration_number,
                'registration_date': supplier?.registration_date,
                'previous_name': supplier?.previous_name,
                'ownership_type': supplier?.ownership_type,
                'shaba_id': supplier?.shaba_id?.substr(2),
                'bank_account_id': supplier?.bank_account_id,
                'bank_name': supplier?.bank_name,
                'bank_account_owner_name': supplier?.bank_account_owner_name,
                'distance_from_afa': supplier?.distance_from_afa,
                'city_name': supplier?.city_name,
                'office_home_number': supplier?.office_phone?.substr(3),
                'office_prev_code_number': supplier?.office_phone?.substr(0, 3),
                'office_fax': supplier?.office_fax,
                'office_mobile': supplier?.office_mobile,
                'office_postal_code': supplier?.office_postal_code,
                'office_email': supplier?.office_email,
                'office_website': supplier?.office_website,
                'office_address': supplier?.office_address,
                'factory_home_number': supplier?.factory_phone?.substr(3),
                'factory_prev_code_number': supplier?.factory_phone?.substr(0, 3),
                'factory_fax': supplier?.factory_fax,
                'factory_mobile': supplier?.factory_mobile,
                'factory_postal_code': supplier?.factory_postal_code,
                'factory_email': supplier?.factory_email,
                'factory_website': supplier?.factory_website,
                'factory_address': supplier?.factory_address,
                'supply_type': supplier?.supply_type,
                'type': supplier?.type,
                'registration_location': supplier?.registration_location,
                'office_home_number_fax': supplier?.office_fax?.substr(3),
                'office_prev_code_number_fax': supplier?.office_fax?.substr(0, 3),
                'factory_home_number_fax': supplier?.factory_fax?.substr(3),
                'factory_prev_code_number_fax': supplier?.factory_fax?.substr(0, 3),
            }))
            let items = [];
            supplier?.supply_type?.map((row) => {
                let obj = {}
                obj['name'] = row
                items.push(obj)
            })
            setInputs(prevState => ({...prevState, selected_supply_list: items}))
            setMoreData({sheet_status: data?.sheet_status, status_colors: data?.status_colors, isLoad: false})
        }
    }, [is_render]);

    const onSelect = (selectedList, selectedItem) => {

        if (inputs?.supply_type !== undefined) {
            arr = inputs?.supply_type;
        }
        arr?.push(selectedItem?.name)
        setInputs(prevState => ({...prevState, supply_type: arr, selected_supply_list: selectedList}))
    }

    const onRemove = (selectedList, removedItem) => {
        if (inputs?.supply_type !== undefined) {
            arr = inputs?.supply_type;
        }
        arr?.splice(removedItem?.name, 1)
        setInputs(prevState => ({...prevState, supply_type: arr, selected_supply_list: selectedList}))
    }

    const inputHandler = (e) => {
        setInputs(prevState => ({
            ...prevState, [e.target.name]: e.target.value
        }))
    }

    const numberInputsHandler = (e) => {
        const regexp = /^[0-9\b]+$/;
        if (e.target.value === '' || regexp.test(e.target.value)) {
            setInputs(prevState => ({
                ...prevState, [e.target.name]: e.target.value
            }))
        }
    }

    const datePickerHandler = (e) => {
        let today = new Date();
        let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        let selected_date = e.target.value
        if (date.normalize() < selected_date.normalize() + 1) {
            toast.error('تاریخ انتخابی نمیتواند بعد امروز باشد')
            setInputs(prevState => ({
                ...prevState, [e.target.name]: ''
            }))
            return false
        } else {
            let date2 = e.target.value
            setInputs(prevState => ({
                ...prevState, [e.target.name]: date2?.split('T')[0]
            }))
        }
    }

    const formHandler = async (e) => {
        e.preventDefault()
        // return false
        let formData = new FormData()

        if (inputs['supplier_first_name']) {
            formData.append('supplier_first_name', inputs['supplier_first_name'])
        }
        if (inputs['supplier_last_name']) {
            formData.append('supplier_last_name', inputs['supplier_last_name'])
        }
        if (inputs['institute_name']) {
            formData.append('institute_name', inputs['institute_name'])
        }
        if (inputs['mobile']) {
            formData.append('mobile', inputs['mobile'])
        }
        if (inputs['identification_code']) {
            formData.append('identification_code', inputs['identification_code'])
        }
        if (inputs['legal_status'] !== 'انتخاب کنید') {
            formData.append('legal_status', inputs['legal_status'])
        }
        if (inputs['nationality']) {
            formData.append('nationality', inputs['nationality'])
        }
        if (inputs['economical_code']) {
            formData.append('economical_code', inputs['economical_code'])
        }
        if (inputs['registration_number']) {
            formData.append('registration_number', inputs['registration_number'])
        }
        if (inputs['registration_date']) {
            formData.append('registration_date', inputs['registration_date']?.split('T')[0])
        }
        if (inputs['previous_name']) {
            formData.append('previous_name', inputs['previous_name'])
        }
        if (inputs['ownership_type'] !== 'انتخاب کنید') {
            formData.append('ownership_type', inputs['ownership_type'])
        }
        if (inputs['bank_account_id']) {
            formData.append('bank_account_id', inputs['bank_account_id'])
        }
        if (inputs['bank_name']) {
            formData.append('bank_name', inputs['bank_name'])
        }
        if (inputs['bank_account_owner_name']) {
            formData.append('bank_account_owner_name', inputs['bank_account_owner_name'])
        }
        if (inputs['distance_from_afa'] !== 'انتخاب کنید') {
            formData.append('distance_from_afa', inputs['distance_from_afa'])
        }
        if (inputs['city_name']) {
            formData.append('city_name', inputs['city_name'])
        }
        if (inputs['office_fax']) {
            formData.append('office_fax', inputs['office_fax'])
        }
        if (inputs['office_mobile']) {
            formData.append('office_mobile', inputs['office_mobile'])
        }
        if (inputs['office_postal_code']) {
            formData.append('office_postal_code', inputs['office_postal_code'])
        }
        if (inputs['office_email']) {
            formData.append('office_email', inputs['office_email'])
        }
        if (inputs['office_website']) {
            formData.append('office_website', inputs['office_website'])
        }
        if (inputs['office_address']) {
            formData.append('office_address', inputs['office_address'])
        }
        if (inputs['factory_fax']) {
            formData.append('factory_fax', inputs['factory_fax'])
        }
        if (inputs['factory_mobile']) {
            formData.append('factory_mobile', inputs['factory_mobile'])
        }
        if (inputs['factory_postal_code']) {
            formData.append('factory_postal_code', inputs['factory_postal_code'])
        }
        if (inputs['factory_email']) {
            formData.append('factory_email', inputs['factory_email'])
        }
        if (inputs['factory_website']) {
            formData.append('factory_website', inputs['factory_website'])
        }
        if (inputs['factory_address']) {
            formData.append('factory_address', inputs['factory_address'])
        }

        if (inputs['registration_location']) {
            formData.append('registration_location', inputs['registration_location'])
        }

        if (inputs['factory_home_number'] && inputs['factory_prev_code_number']) {
            formData.append('factory_phone', `${inputs['factory_prev_code_number']}${inputs['factory_home_number']}`)
        }
        if (inputs['office_home_number'] && inputs['office_prev_code_number']) {
            formData.append('office_phone', `${inputs['office_prev_code_number']}${inputs['office_home_number']}`)
        }


        if (inputs['shaba_id']) {
            formData.append('shaba_id', `IR${inputs['shaba_id']}`)
        }


        if (inputs['office_home_number_fax'] && inputs['office_prev_code_number_fax']) {
            formData.append('office_fax', `${inputs['office_prev_code_number_fax']}${inputs['office_home_number_fax']}`)
        }
        if (inputs['factory_home_number_fax'] && inputs['factory_prev_code_number_fax']) {
            formData.append('factory_fax', `${inputs['factory_prev_code_number_fax']}${inputs['factory_home_number_fax']}`)
        }

        if (inputs['supply_type']?.length > 0) {
            for (let i = 0; i < inputs['supply_type']?.length; i++) {
                formData.append(`supply_type[${i}]`, inputs['supply_type'][i])
            }
        }

        let api_result = await getFormDataPost('profile/supplier/supplier-info', formData)

        if (api_result?.status === 200) {

            toast.success('با موفقیت تغییر یافت')

            setError('')

            let data = api_result?.data
            let supplier = data?.supplier_info
            setInputs(prevState => ({
                ...prevState,
                'supplier_first_name': supplier?.supplier_first_name,
                'supplier_last_name': supplier?.supplier_last_name,
                'institute_name': supplier?.institute_name,
                'mobile': supplier?.mobile,
                'identification_code': supplier?.identification_code,
                'legal_status': supplier?.legal_status,
                'nationality': supplier?.nationality,
                'economical_code': supplier?.economical_code,
                'registration_number': supplier?.registration_number,
                'registration_date': supplier?.registration_date,
                'previous_name': supplier?.previous_name,
                'ownership_type': supplier?.ownership_type,
                'shaba_id': supplier?.shaba_id?.substr(2),
                'bank_account_id': supplier?.bank_account_id,
                'bank_name': supplier?.bank_name,
                'bank_account_owner_name': supplier?.bank_account_owner_name,
                'distance_from_afa': supplier?.distance_from_afa,
                'city_name': supplier?.city_name,
                'office_home_number': supplier?.office_phone?.substr(3),
                'office_prev_code_number': supplier?.office_phone?.substr(0, 3),
                'factory_home_number': supplier?.factory_phone?.substr(3),
                'factory_prev_code_number': supplier?.factory_phone?.substr(0, 3),
                'office_fax': supplier?.office_fax,
                'office_mobile': supplier?.office_mobile,
                'office_postal_code': supplier?.office_postal_code,
                'office_email': supplier?.office_email,
                'office_website': supplier?.office_website,
                'office_address': supplier?.office_address,
                'office_home_number_fax': supplier?.office_fax?.substr(3),
                'office_prev_code_number_fax': supplier?.office_fax?.substr(0, 3),
                'factory_home_number_fax': supplier?.factory_fax?.substr(3),
                'factory_prev_code_number_fax': supplier?.factory_fax?.substr(0, 3),
                'factory_fax': supplier?.factory_fax,
                'factory_mobile': supplier?.factory_mobile,
                'factory_postal_code': supplier?.factory_postal_code,
                'factory_email': supplier?.factory_email,
                'factory_website': supplier?.factory_website,
                'factory_address': supplier?.factory_address,
            }))
            let items = [];
            supplier?.supply_type?.map((row) => {
                let obj = {}
                obj['name'] = row
                items.push(obj)
            })
            setInputs(prevState => ({...prevState, selected_supply_list: items}))
            // setRender(!is_render)
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
        // }
    }
    return (
        <>
            {/*{*/}
            {/*    !more_data["isLoad"] &&*/}
            <form onSubmit={formHandler} className="row">
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <TabStatusComplete style={{borderRightColor: props.status_colors?.colors?.border_right}}>
                        {
                            props.status_value === 'pending' || props.status_value === 'denied' ?
                                <span style={{
                                    color: props.status_colors?.colors?.color,
                                    backgroundColor: props.status_colors?.colors?.background
                                }} className='icon-Iconly-Bulk-Info-Square afa-icon-tick'/> :
                                <span style={{
                                    color: props.status_colors?.colors?.color,
                                    backgroundColor: props.status_colors?.colors?.background
                                }} className='icon-tick afa-icon-tick'/>
                        }
                        <p className='mb-0 f-14 '><span>وضعیت : </span><span>{props.status_colors?.title}</span></p>
                    </TabStatusComplete>
                </div>
                <div className="col-12 col-sm-6 col-lg-8 mb-4">
                    {
                        more_data.sheet_status?.message &&
                        <TabStatusContent style={{borderRightColor: props.status_colors?.colors?.border_right}}>
                            <span className="icon-Iconly-Bulk-Message afa-icon-message">
                                <span className="path1"></span>
                                <span className="path2"></span>
                            </span>
                            <p className='mb-0 f-14'>
                                <span>پیام ادمین : </span><span>{more_data.sheet_status?.message}</span>
                            </p>
                        </TabStatusContent>
                    }
                </div>

                <div className="col-12 mb-4">
                    <h6 className='afa-part-title'>اطلاعات عمومی تامین کنندگان</h6>
                </div>

                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>نام تامین کننده
                        <span className='dv-required-star'>*</span>
                        </span>
                        <input className={error['supplier_first_name'] && 'afa-error-input'} type="text"
                               name={'supplier_first_name'}
                               value={inputs.supplier_first_name} onChange={(e) =>
                            just_persian(e.target.value) && setInputs(prevState => ({
                                ...prevState, 'supplier_first_name': e.target.value
                            }))
                        } placeholder='نام تامین کننده'/>
                        <p className='afa-error-text'>{error['supplier_first_name']}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>نام خانوادگی تامین کننده
                        <span className='dv-required-star'>*</span>
                        </span>
                        <input className={error['supplier_last_name'] && 'afa-error-input'} type="text"
                               name={'supplier_last_name'}
                               value={inputs.supplier_last_name} onChange={(e) =>
                            just_persian(e.target.value) && setInputs(prevState => ({
                                ...prevState, 'supplier_last_name': e.target.value
                            }))
                        } placeholder='نام خانوادگی تامین کننده'/>
                        <p className='afa-error-text'>{error['supplier_last_name']}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span className='f-14 mb-2'>ماهیت
                        <span className='dv-required-star'>*</span>
                        </span>
                        <select disabled={true} name="type" value={inputs?.type} onChange={inputHandler}
                                className={error['type'] && 'afa-error-input'}>
                            <option value="انتخاب کنید">انتخاب کنید</option>
                            <option value="individual">حقیقی</option>
                            <option value="entity">حقوقی</option>
                        </select>
                    </Inputs>
                </div>

                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span className=' f-14 mb-2'>وضعیت حقوقی
                        <span className='dv-required-star'>*</span>
                        </span>
                        <select name="legal_status" value={inputs?.legal_status} onChange={inputHandler}
                                className={error['legal_status'] && 'afa-error-input'}>
                            <option value="انتخاب کنید">انتخاب کنید</option>
                            <option value="سهامی عام">سهامی عام</option>
                            <option value="سهامی خاص">سهامی خاص</option>
                            <option value="با مسئولیت محدود">با مسئولیت محدود</option>
                            <option value="سایر">سایر</option>
                        </select>
                        <p className='afa-error-text'>{error['legal_status']}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs className='mb-3'>
                        <span>نام تجاری / اختصاری
                        <span className='dv-required-star'>*</span>
                        </span>
                        <input className={error['institute_name'] && 'afa-error-input'} type="text"
                               name={'institute_name'}
                               value={inputs.institute_name} onChange={(e) =>
                            setInputs(prevState => ({
                                ...prevState, 'institute_name': e.target.value
                            }))
                        } placeholder='نام تجاری / اختصاری'/>
                        <p className='afa-error-text'>{error['institute_name']}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>کدملی/شناسه ملی
                        <span className='dv-required-star'>*</span>
                        </span>
                        <input className={error['identification_code'] && 'afa-error-input'}
                               type="text"
                               max={9999999999}
                               maxLength={10}
                               name={'identification_code'}
                               value={inputs.identification_code} onChange={numberInputsHandler}
                               placeholder='- - - - - - - - - - '/>
                        <p className='afa-error-text'>{error['identification_code']}</p>
                    </Inputs>
                </div>

                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>شماره موبایل (مدیرعامل)
                        <span className='dv-required-star'>*</span>
                        </span>
                        <input className={error['mobile'] && 'afa-error-input'} type="text"
                               max={99999999999}
                               maxLength={11} name={'mobile'}
                               value={inputs.mobile} onChange={numberInputsHandler} placeholder='09123456789'/>
                        <p className='afa-error-text'>{error['mobile']}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>ملیت
                        <span className='dv-required-star'>*</span>
                        </span>
                        <input className={error['nationality'] && 'afa-error-input'} type="text" name={'nationality'}
                               value={inputs.nationality} onChange={(e) =>
                            just_persian(e.target.value) && setInputs(prevState => ({
                                ...prevState, 'nationality': e.target.value
                            }))
                        } placeholder='ایرانی'/>
                        <p className='afa-error-text'>{error['nationality']}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>کد اقتصادی
                        <span className='dv-required-star'>*</span>
                        </span>
                        <input className={error['economical_code'] && 'afa-error-input'} type="text"
                               name={'economical_code'}
                               value={inputs.economical_code} onChange={(e) =>
                            setInputs(prevState => ({
                                ...prevState, 'economical_code': e.target.value
                            }))
                        } placeholder='- - - - - - -'/>
                        <p className='afa-error-text'>{error['economical_code']}</p>
                    </Inputs>
                </div>

                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>شماره ثبت
                        <span className='dv-required-star'>*</span>
                        </span>
                        <input className={error['registration_number'] && 'afa-error-input'} type="number"
                               name={'registration_number'}
                               value={inputs.registration_number} onChange={numberInputsHandler}
                               placeholder='- - - - - - -'/>
                        <p className='afa-error-text'>{error['registration_number']}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>محل ثبت
                        <span className='dv-required-star'>*</span>
                        </span>
                        <input className={error['registration_location'] && 'afa-error-input'} type="text"
                               name={'registration_location'}
                               value={inputs.registration_location} onChange={(e) =>
                            just_persian(e.target.value) && setInputs(prevState => ({
                                ...prevState, 'registration_location': e.target.value
                            }))
                        } placeholder='محل ثبت'/>
                        <p className='afa-error-text'>{error['registration_location']}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>تاریخ ثبت
                        <span className='dv-required-star'>*</span>
                        </span>
                        <DateInput
                            value={inputs['registration_date']}
                            name={'registration_date'}
                            className={error['registration_date'] && 'afa-error-input'}
                            placeholder={'تاریخ ثبت را انتخاب کنید'}
                            onChange={datePickerHandler}/>
                        <p className='afa-error-text'>{error['registration_date']}</p>
                    </Inputs>
                </div>

                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>نام قبلی(اختیاری)</span>
                        <input className={error['previous_name'] && 'afa-error-input'} type="text"
                               name={'previous_name'}
                               value={inputs.previous_name} onChange={(e) =>
                            just_persian(e.target.value) && setInputs(prevState => ({
                                ...prevState, 'previous_name': e.target.value
                            }))
                        } placeholder='نام قبلی'/>
                    </Inputs>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span className='f-14 mb-2'>نوع مالکیت
                        <span className='dv-required-star'>*</span>
                        </span>
                        <select name="ownership_type" value={inputs?.ownership_type} onChange={inputHandler}
                                className={error['ownership_type'] && 'afa-error-input'}>
                            <option value="انتخاب کنید">انتخاب کنید</option>
                            <option value="دولتی">دولتی</option>
                            <option value="خصوصی">خصوصی</option>
                            <option value="تحت پوشش">تحت پوشش</option>
                            <option value="خارجی">خارجی</option>
                        </select>
                        <p className='afa-error-text'>{error['ownership_type']}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span className='f-14 mb-2'>نحوه تامین
                        <span className='dv-required-star'>*</span>
                        </span>

                        <Multiselect
                            className={error['supply_type'] && 'afa-error-input'}
                            options={inputs?.selected_supply_value} // Options to display in the dropdown
                            selectedValues={inputs?.selected_supply_list} // Preselected value to persist in dropdown
                            onSelect={onSelect} // Function will trigger on select event
                            onRemove={onRemove} // Function will trigger on remove event
                            displayValue="name" // Property name to display in the dropdown options
                            placeholder={'انتخاب کنید'}
                        />
                        <p className='afa-error-text'>{error['supply_type']}</p>
                    </Inputs>
                </div>

                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>

                        <span>شماره شبا
                        <span className='dv-required-star'>*</span>
                        </span>
                        <div className="input-group mb-3 afa-shaba-input">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">IR</span>
                            </div>
                            <input
                                className={error['shaba_id'] ? 'afa-error-input form-control afa-shba-number-input' : 'form-control afa-shba-number-input'}
                                aria-label="shaba_id"
                                maxLength={24}
                                type="text" name={'shaba_id'}
                                value={inputs.shaba_id}
                                onChange={(e) => just_number(e.target.value) && setInputs(prevState => ({
                                    ...prevState, [`shaba_id`]: fixNumber(e.target.value)
                                }))}
                                aria-describedby="basic-addon1"/>
                        </div>
                        <p className='afa-error-text'>{error['shaba_id']}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>شماره حساب(اختیاری)</span>
                        <input className={error['bank_account_id'] && 'afa-error-input'} type="number"
                               name={'bank_account_id'}
                               value={inputs.bank_account_id} onChange={numberInputsHandler}
                               placeholder='- - - - - - -'/>
                    </Inputs>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>نام بانک
                        <span className='dv-required-star'>*</span>
                        </span>
                        <input className={error['bank_name'] && 'afa-error-input'} type="text" name={'bank_name'}
                               value={inputs.bank_name} onChange={(e) =>
                            just_persian(e.target.value) && setInputs(prevState => ({
                                ...prevState, 'bank_name': e.target.value
                            }))
                        } placeholder='ملی'/>
                        <p className='afa-error-text'>{error['bank_name']}</p>
                    </Inputs>
                </div>

                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>نام صاحب حساب
                        <span className='dv-required-star'>*</span>
                        </span>
                        <input className={error['bank_account_owner_name'] && 'afa-error-input'} type="text"
                               name={'bank_account_owner_name'}
                               value={inputs.bank_account_owner_name} onChange={(e) =>
                            just_persian(e.target.value) && setInputs(prevState => ({
                                ...prevState, 'bank_account_owner_name': e.target.value
                            }))
                        } placeholder='نام'/>
                        <p className='afa-error-text'>{error['bank_account_owner_name']}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span className='f-14 mb-2'>مسافت از آذر فولاد امین
                        <span className='dv-required-star'>*</span>
                        </span>
                        <select name="distance_from_afa" value={inputs?.distance_from_afa} onChange={inputHandler}
                                className={error['distance_from_afa'] && 'afa-error-input'}>
                            <option value="انتخاب کنید">انتخاب کنید</option>
                            <option value="تا 50 کیلومتر">تا 50 کیلومتر</option>
                            <option value="50 تا 300 کیلومتر">50 تا 300 کیلومتر</option>
                            <option value="بیشتر از 300 کیلومتر">بیشتر از 300 کیلومتر</option>
                        </select>
                        <p className='afa-error-text'>{error['distance_from_afa']}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>نام شهر
                        <span className='dv-required-star'>*</span>
                        </span>
                        <input className={error['city_name'] && 'afa-error-input'} type="text" name={'city_name'}
                               value={inputs.city_name} onChange={(e) =>
                            just_persian(e.target.value) && setInputs(prevState => ({
                                ...prevState, 'city_name': e.target.value
                            }))
                        } placeholder='تبریز'/>
                        <p className='afa-error-text'>{error['city_name']}</p>
                    </Inputs>
                </div>

                {/*End Part 1*/}

                <div className="col-12 mb-4">
                    <h6 className='afa-part-title'>اطلاعات آدرس تولید کنندگان/ سازندگان (دفتر مرکزی)</h6>
                </div>

                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <HomeNumberInputs>
                        <span>شماره تماس ثابت
                        <span className='dv-required-star'>*</span>
                        </span>
                        <div
                            className={error['office_home_number'] ? 'afa-error-input afa_home_number' : "afa_home_number"}>
                            <input className={'afa-phone-input'} type="text" max={99999999} maxLength={8}
                                   name={'office_home_number'}
                                   value={inputs.office_home_number} onChange={numberInputsHandler}
                                   placeholder='43332020'/>
                            <input className={'afa-prev-code'} type="text" max={999} maxLength={3}
                                   name={'office_prev_code_number'}
                                   value={inputs.office_prev_code_number} onChange={numberInputsHandler}
                                   placeholder='041'/>
                        </div>
                        <p className='afa-error-text'>{error['office_phone']}</p>
                    </HomeNumberInputs>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <HomeNumberInputs>
                        <span>فکس</span>
                        <div
                            className={error['office_fax'] ? 'afa-error-input afa_home_number' : "afa_home_number"}>
                            <input className={'afa-phone-input'} type="text" max={99999999} maxLength={8}
                                   name={'office_home_number_fax'}
                                   value={inputs.office_home_number_fax} onChange={numberInputsHandler}
                                   placeholder='43332020'/>
                            <input className={'afa-prev-code'} type="text" max={999} maxLength={3}
                                   name={'office_prev_code_number_fax'}
                                   value={inputs.office_prev_code_number_fax} onChange={numberInputsHandler}
                                   placeholder='041'/>
                        </div>
                        <p className='afa-error-text'>{error['office_fax']}</p>
                    </HomeNumberInputs>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>تلفن همراه
                        <span className='dv-required-star'>*</span>
                        </span>
                        <input className={error['office_mobile'] && 'afa-error-input'} type="text"
                               max={99999999999}
                               maxLength={11}
                               name={'office_mobile'}
                               value={inputs.office_mobile} onChange={numberInputsHandler}/>
                        <p className='afa-error-text'>{error['office_mobile']}</p>
                    </Inputs>
                </div>

                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>کد پستی
                        <span className='dv-required-star'>*</span>
                        </span>
                        <input className={error['office_postal_code'] && 'afa-error-input'} type="text"
                               max={9999999999}
                               maxLength={10}
                               name={'office_postal_code'}
                               value={inputs.office_postal_code} onChange={numberInputsHandler}/>
                        <p className='afa-error-text'>{error['office_postal_code']}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>ایمیل</span>
                        <input className={error['office_email'] && 'afa-error-input'} type="email"
                               name={'office_email'}
                               value={inputs.office_email} onChange={(e) =>
                            setInputs(prevState => ({
                                ...prevState, 'office_email': e.target.value
                            }))
                        }/>
                        <p className='afa-error-text'>{error['office_email']}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>وب سایت</span>
                        <input className={error['office_website'] && 'afa-error-input'} type="text"
                               name={'office_website'}
                               value={inputs.office_website} onChange={(e) =>
                            setInputs(prevState => ({
                                ...prevState, 'office_website': e.target.value
                            }))
                        } placeholder={'باید url کامل باشد'}/>
                        <p className='afa-error-text'>{error['office_website']}</p>
                    </Inputs>
                </div>

                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>آدرس دقیق
                        <span className='dv-required-star'>*</span>
                        </span>
                        <input className={error['office_address'] && 'afa-error-input'} type="text"
                               name={'office_address'}
                               value={inputs.office_address} onChange={(e) =>
                            setInputs(prevState => ({
                                ...prevState, 'office_address': e.target.value
                            }))
                        }/>
                        <p className='afa-error-text'>{error['office_address']}</p>
                    </Inputs>
                </div>

                {/*End Part 2*/}

                <div className="col-12 mb-4">
                    <h6 className='afa-part-title'>اطلاعات آدرس تولید کنندگان/ سازندگان (کارخانه/کارگاه)</h6>
                </div>

                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <HomeNumberInputs>
                        <span>شماره تماس ثابت
                        <span className='dv-required-star'>*</span>
                        </span>
                        <div
                            className={error['factory_home_number'] ? 'afa-error-input afa_home_number' : "afa_home_number"}>
                            <input className={'afa-phone-input'} type="text" max={99999999} maxLength={8}
                                   name={'factory_home_number'}
                                   value={inputs.factory_home_number} onChange={numberInputsHandler}
                                   placeholder='43332020'/>
                            <input className={'afa-prev-code'} type="text" max={999} maxLength={3}
                                   name={'factory_prev_code_number'}
                                   value={inputs.factory_prev_code_number} onChange={numberInputsHandler}
                                   placeholder='041'/>
                        </div>
                        <p className='afa-error-text'>{error['factory_phone']}</p>
                    </HomeNumberInputs>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <HomeNumberInputs>
                        <span>فکس</span>
                        <div
                            className={error['factory_fax'] ? 'afa-error-input afa_home_number' : "afa_home_number"}>
                            <input className={'afa-phone-input'} type="text" max={99999999} maxLength={8}
                                   name={'factory_home_number_fax'}
                                   value={inputs.factory_home_number_fax} onChange={numberInputsHandler}
                                   placeholder='43332020'/>
                            <input className={'afa-prev-code'} type="text" max={999} maxLength={3}
                                   name={'factory_prev_code_number_fax'}
                                   value={inputs.factory_prev_code_number_fax} onChange={numberInputsHandler}
                                   placeholder='041'/>
                        </div>
                        <p className='afa-error-text'>{error['factory_fax']}</p>
                    </HomeNumberInputs>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>تلفن همراه
                        <span className='dv-required-star'>*</span>
                        </span>
                        <input className={error['factory_mobile'] && 'afa-error-input'} type="text"
                               max={99999999999} maxLength={11}
                               name={'factory_mobile'}
                               value={inputs.factory_mobile} onChange={numberInputsHandler}/>
                        <p className='afa-error-text'>{error['factory_mobile']}</p>
                    </Inputs>
                </div>

                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>کد پستی
                        <span className='dv-required-star'>*</span>
                        </span>
                        <input className={error['factory_postal_code'] && 'afa-error-input'} type="number"
                               name={'factory_postal_code'}
                               value={inputs.factory_postal_code} onChange={numberInputsHandler}/>
                        <p className='afa-error-text'>{error['factory_postal_code']}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>ایمیل</span>
                        <input className={error['factory_email'] && 'afa-error-input'} type="email"
                               name={'factory_email'}
                               value={inputs.factory_email} onChange={(e) =>
                            setInputs(prevState => ({
                                ...prevState, 'factory_email': e.target.value
                            }))
                        }/>
                        <p className='afa-error-text'>{error['factory_email']}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>وب سایت</span>
                        <input className={error['factory_website'] && 'afa-error-input'} type="text"
                               name={'factory_website'}
                               value={inputs.factory_website} onChange={(e) =>
                            setInputs(prevState => ({
                                ...prevState, 'factory_website': e.target.value
                            }))
                        } placeholder={'باید url کامل باشد'}/>
                        <p className='afa-error-text'>{error['factory_website']}</p>
                    </Inputs>
                </div>

                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <Inputs>
                        <span>آدرس دقیق
                        <span className='dv-required-star'>*</span>
                        </span>
                        <input className={error['factory_address'] && 'afa-error-input'} type="text"
                               name={'factory_address'}
                               value={inputs.factory_address} onChange={(e) =>
                            setInputs(prevState => ({
                                ...prevState, 'factory_address': e.target.value
                            }))
                        }/>
                        <p className='afa-error-text'>{error['factory_address']}</p>
                    </Inputs>
                </div>

                {/*End Part 3*/}

                <div className="col-12 col-sm-6 col-lg-4"/>
                <div className="col-12 col-sm-6 col-lg-4 mb-4 d-flex align-items-center">
                    <Inputs>
                        <button type='submit'>
                            <span>ثبت نهایی</span>
                            <LeftIcon><span className='icon-cheveron-left'></span></LeftIcon>
                        </button>
                    </Inputs>
                </div>
            </form>
            {/*}*/}
        </>
    )
}

export default PublicInformation;