import React, {useState} from 'react';
import {useHistory, useLocation} from "react-router";
import {mobileRegex, getData, fixNumber} from "../../assets/scripts/GeneralFunctions";
import {LeftContent, LoginInput, LoginTheme, RightContent} from "./styles";
import {Images} from "../../assets/scripts/Images";
import {MAIN_URL} from "../../assets/scripts/GeneralVariables";
import {LoopCircleLoading} from "react-loadingg";

function LegalRegister(props) {
    const [data, setData] = useState({company_name: '', phone: ''})
    const [error, setError] = useState({})
    const [loader, setLoader] = useState(false)
    const history = useHistory()
    let location = useLocation();
    const formHandler = async (e) => {
        e.preventDefault()
        if (!mobileRegex(data.phone)) {
            setLoader(true)
            let api_result = await getData(MAIN_URL, "register", 'post', {
                type: 'حقوقی',
                entity_identification_code: location.state.entity_identification_code,
                institute_name: data.company_name,
                mobile: data.phone,
            }, null, false);
            if (api_result?.status === 200) {
                setLoader(false)
                history.push({
                    pathname: '/verify_code',
                    state: {
                        time: api_result.data['remaining'],
                        user_type: 'legal',
                        mobile: api_result.data['mobile'],
                        entity_identification_code: location.state.entity_identification_code
                    }
                })
            }
            else if (api_result?.status === 400) {
                setLoader(false)
                let error_result = true
                let error = {};
                if (api_result.error['entity_identification_code']) {
                    error['entity_identification_code'] = api_result.error['entity_identification_code'][0]
                    error_result = false
                }
                if (api_result.error['institute_name']) {
                    error['institute_name'] = api_result.error?.institute_name[0]
                    error_result = false
                }
                if (api_result.error['mobile']) {
                    error['mobile'] = api_result.error?.mobile[0]
                    error_result = false
                }

                setError(error)
                return error_result
            }
        }
    }

    return (
        <>
            <LoginTheme>
                <RightContent>
                    <img src={Images.main_logo.default} alt="آذر فولاد امین"/>
                    <h6>ایجاد حساب کاربری</h6>
                    <p className='afa-error-text'>{error['entity_identification_code']}</p>
                    <LoginInput onSubmit={formHandler}>
                        <span>نام موسسه</span>
                        <input type="text" autoFocus name='company_name' value={data.company_name}
                               onChange={(e) => setData(prevState => ({...prevState, company_name: e.target.value}))}
                               placeholder={'آفا'}/>
                        <p className='afa-error-text'>{error['institute_name']}</p>
                        <span>شماره مدیر عامل</span>
                        <input className='text-left' dir='ltr' type="number" name='phone' value={data.phone}
                               onChange={(e) => setData(prevState => ({...prevState, phone: fixNumber(e.target.value)}))}
                               placeholder={'09123456789'}/>
                        <p className='afa-error-text'>{error['mobile']}</p>
                        <div className="position-relative w-100">
                            <button type='submit'
                                    className='w-100'
                                    disabled={data.company_name?.length > 0 && data.phone?.length > 0 ? false : true}>ثبت
                                نام
                            </button>
                            {
                                loader && <LoopCircleLoading/>
                            }
                        </div>

                    </LoginInput>
                </RightContent>
                <LeftContent>
                    <img src={Images.login_left.default} alt="آذر فولاد امین"/>
                </LeftContent>
            </LoginTheme>
        </>
    );
}

export default LegalRegister;